import { AntDesign, Entypo, EvilIcons, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import * as React from 'react';
import { TouchableOpacity, StyleSheet, View, Text, Image, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import PrimaryButton from "./primary-button";
import { SheetManager } from "react-native-actions-sheet";
import i18next from "i18next";

import { withTranslation } from "react-i18next";
import navigationService from "../services/navigation-service";
import { AppContext, IContext } from "../redux/store/app-provider";

interface Props {
  t: any
  index: any
  item: any
  videoTime: any
  onPress: any
}

interface State {
  hideBanner: boolean
}

class VideoClip extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  //allText: { text: string, start: number, duration: number }[] = [];
  constructor(props: Props) {
    super(props);
    this.state = { hideBanner: false };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps: any) {

  }

  render() {

    return <View style={{ alignItems: 'center', width: '100%' }}>
      {/*{this.props.index === 0 && Platform.OS !== 'web' && !this.context.state.isPremium && this.context.state.checkedStore ?
        <PrimaryButton align={'left'} icon={
          <FontAwesome5 size={25} color={colors.iconColor} name='cart-plus'></FontAwesome5>} onPress={() => {
          SheetManager.show("buy_premium")
        }} label={i18next.t('Hide Ads') + ' $1.99'} style={{
          width: '100%',
          marginBottom: 10
        }}/> : null}*/}
      {!this.context.state.isPremium && (this.props.index + 3) % this.context.state.adData?.banSkip === 0 && this.context.state.checkedStore && Platform.OS !== 'web' && this.context.state.appConfig ?
        <View style={[style.row, style.hcenter, style.vcenter, style.fullWidth, {
          borderWidth: 0,
          borderColor: colors.backgroundColor,
          borderRadius: 0,
          height: 250,
          marginBottom: 20,
          backgroundColor: colors.backgroundColor
        }]}>

          <navigationService.props.BannerAd onAdFailedToLoad={() => {
            this.setState({ hideBanner: true })
          }}
                                            unitId={this.context.state.adData?.ban}
            /*unitId={navigationService.props.TestIds.BANNER}*/
                                            size={navigationService.props.BannerAdSize.MEDIUM_RECTANGLE}
                                            requestOptions={{
                                              requestNonPersonalizedAdsOnly: false,
                                            }}
          />
        </View> : null}
      <TouchableOpacity style={{ width: '100%' }} onPress={() => {
        this.props.onPress(this.props.item)
      }}>

        <View style={{
          width: '100%',
          marginBottom: 20,
          borderWidth: 0,
          padding: 0,
          borderRadius: colors.borderRadius,
          overflow: 'hidden',
          alignItems: 'center',

        }}>
          <Image blurRadius={0} style={{
            width: '100%',
            height: 190,
            resizeMode: 'cover',
            marginTop: 0,
            marginBottom: 0
          }} source={{ uri: this.props.item.snippet.thumbnails.medium.url }}/>

          <View style={{
            width: '100%',
            borderRadius: colors.borderRadius,
            borderTopEndRadius: 0,
            borderTopStartRadius: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            marginTop: 0,
            position: 'absolute',
            left: 0,
            bottom: 0,
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',

          }}>
            <Text style={[{
              color: 'white',
              fontSize: 13,
              fontWeight: 'bold',
              width: '100%',
              padding: 0,
              marginTop: 0,
              textAlign: 'left'
            }]}>{this.props.item.title || this.props.item.snippet.title}</Text>
            {/* Display Text component to show post createdAt date property as date and time formatted */}
            <View style={[style.row, style.fullWidth, style.vcenter, {
              marginTop: 3,
              padding: 5,
              paddingLeft: 0,
              paddingBottom: 0
            }]}>
              <Text style={{
                color: colors.textColor,
                marginRight: 10,
                textAlign: 'left',
                fontSize: 11
              }}>{this.props.item.snippet.publishedAt}</Text>
              <FontAwesome style={{
                marginRight: 10,
                display: this.props.item.viewCount ? "flex" : "none"
              }} size={15} color={colors.textColor} name="eye"></FontAwesome>
              <Text style={{
                color: colors.textColor,
                fontWeight: 'bold',
                marginRight: 10,
                fontSize: 11,
                display: this.props.item.viewCount ? "flex" : "none"
              }}>{this.props.item.viewCount}</Text>

              <AntDesign name="like2" style={{
                marginRight: 10,
                display: this.props.item.likesCountLabel ? "flex" : "none"
              }} size={15} color={colors.textColor}/>
              <Text style={{
                color: colors.textColor,
                marginRight: 10,
                fontWeight: 'bold',
                fontSize: 11,
                display: this.props.item.likesCountLabel ? "flex" : "none"
              }}>{this.props.item.likesCountLabel}</Text>
              <View style={{ flex: 1 }}></View>
              {/*{!this.context.state.isPremium && this.context.state.checkedStore && Platform.OS !== 'web' ?
                <TouchableOpacity onPress={() => {
                  this.context.setAppProps({ shareFriendsClip: this.props.item });
                  SheetManager.show('share_friends')
                }}>
                  <View style={[style.row, style.vcenter]}>
                    <AntDesign name="star" style={{ marginRight: 5 }} size={14} color="yellow"/>
                    <Entypo name="share" size={22} color={colors.iconColor}/>
                  </View>
                </TouchableOpacity> : null}*/}

            </View>

          </View>
          {/*<Image style={{
            position: 'absolute',
            alignSelf: 'center',
            top: '25%',
            width: 71,
            height: 50
          }} source={{ uri: 'https://raw.githubusercontent.com/crisand/youvistore/main/yt_icon_mono_dark.png' }}></Image>*/}
          {this.props.videoTime ? <View style={{
            position: 'absolute',
            width: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            bottom: 0,
            left: 0,
            height: 2,
            backgroundColor: '#ffffff'
          }}>
            <View style={{
              bottom: 0,
              left: 0,
              height: '100%',
              backgroundColor: colors.primaryColor,
              width: this.props.videoTime.currentTime * 100 / this.props.videoTime.videoDuration + "%"
            }}></View>
          </View> : null}
        </View>

      </TouchableOpacity>
    </View>
  }
}

export default withTranslation()(VideoClip);
