import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, ActivityIndicator, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';

import { FontAwesome, SimpleLineIcons } from "@expo/vector-icons";

interface State {
  progress: number;
}

interface Props {
  isSecondary?: boolean;
  progress?: boolean;
  isBusy?: boolean
  label: string;
  iconPlacement?: 'left' | 'right';
  icon?: any;
  align?: 'left' | 'right';
  onPress?: any
  style?: ViewStyle
}

class PrimaryButton extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);
    this.state = { progress: -1 }

  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {

  }

  componentWillUnmount() {

  }

  render() {
    const busyIndicator = () => {
      if (this.props.isBusy) {
        return <View style={[style.row, style.fullHeight, style.vcenter, {
          position: 'absolute',
          right: 0,
          paddingLeft: 15
        }]}>

          <ActivityIndicator size="small" color={colors.textColor}/>
        </View>

      }
    }
    return (
      <TouchableOpacity disabled={this.props.isBusy} onPress={this.props.onPress} style={[this.props.style, this.props.isSecondary ? null : style.shadowButton, {

        padding: 8,
        borderWidth: 1,
        borderColor: colors.orange,
        borderRadius: colors.borderRadius,
        backgroundColor: this.props.isSecondary ? '#fff' : colors.orange,
      }]}>
        <View style={[style.row, style.vcenter, {
          padding: 6,
          flexDirection: this.props.align === 'right' ? 'row-reverse' : 'row'
        }]}>
          {this.props.iconPlacement === 'left' && !this.props.isBusy && this.props.icon}
          <Text style={{
            fontSize: 21,
            fontWeight: 'bold',
            flex: 1,
            marginBottom: 3,
            textAlign: 'center',
            color: this.props.isSecondary ? colors.orange : '#fff'
          }}>{this.props.label} </Text>
          {this.props.iconPlacement === 'right' && !this.props.isBusy && this.props.icon}
          {busyIndicator()}
        </View>
      </TouchableOpacity>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default PrimaryButton;
