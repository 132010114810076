import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
  View,
  ViewStyle
} from 'react-native';
import { withTranslation } from 'react-i18next';
import ChannelClipsScreen from "./channel-clips";
import { AppContext, IContext } from "../redux/store/app-provider";
import VideoClip from "../components/video-clip";
import style from "../constants/style";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";

interface State {

}

interface Props {
  t?: any
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

class ChannelListScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  getRelatedClips = async (token: string) => {
    const channelId = this.context.state.currentChannelId
    //console.log("received token", token)
    const queryStringParameters: any = { channelId, nextToken: token };
    queryStringParameters.lang = this.context.state.selectedLanguage;
    let videos = await API.get('ginierestapi', '/rest/channel-videos', {
      queryStringParameters
    })
    videos.items = videos.videoItems;
    this.context.setVideosTimes(videos.items.map((video: any) => {
      return video.videoId;
    }))
    delete videos.videoItems;
    return videos;
  }

  onSelectVideo(videoItem: any) {
    this.context.gotoVideo(videoItem);
  }

  render() {

    const renderYoutubeVideoItem = ({ item, index }: any) => {
      return (
        <VideoClip item={item} index={index} videoTime={this.context.state.currentVideoTimes?.[item.id?.videoId || item.id]} onPress={this.onSelectVideo.bind(this)}></VideoClip>
      )
    }
    return (
      <View style={[style.container, { padding: 10, paddingBottom: 0 }]}>
        <View style={{ width: '100%', maxWidth: 400, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <ItemsList cache={false} nextTokenName={'nextToken'} fn={this.getRelatedClips} renderItem={renderYoutubeVideoItem} stateKey={'myChannelList'} stateItem={this.context.state.myChannelList}/>
        </View>

      </View>
    )
  };

}

export default withTranslation()(ChannelListScreen);

