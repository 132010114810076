import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, ActivityIndicator } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from "../redux/store/app-provider";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";
import VideoClip from "../components/video-clip";

interface State {

}

interface Props {
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

class RelatedClipsScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  onSelectVideo = (videoItem: any) => {
    //this.context.gotoVideo(videoItem)
    this.props.onPress && this.props.onPress(videoItem);
  }
  getRelatedClips = async (token: string) => {
    let queryStringParameters: any = {};
    queryStringParameters["query"] = ""
    queryStringParameters.relatedToVideoId = this.context.state.relatedVideoId;
    queryStringParameters.lang = this.context.state.selectedLanguage;
    if (token) {
      queryStringParameters.nextToken = token
    }
    let videos: any = [];
    queryStringParameters["order"] = "relevance";
    videos = (await API.get('ginierestapi', '/rest/search-videos', {
      queryStringParameters
    })).body
    return videos;
  }

  render() {

    const renderYoutubeVideoItem = ({ item, index }: any) => {
      return (
        <VideoClip item={item} index={index} videoTime={this.context.state.currentVideoTimes?.[item.videoId]} onPress={this.onSelectVideo.bind(this)}></VideoClip>
      )
    }
    return (
      <View style={[style.container, { padding: 10, paddingBottom: 0 }]}>
        <View style={{ width: '100%', maxWidth: 400, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <ItemsList cache={true} nextTokenName={'nextToken'} fn={this.getRelatedClips} renderItem={renderYoutubeVideoItem} stateKey={'relatedList'} stateItem={this.context.state.relatedList}/>
        </View>

      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default RelatedClipsScreen;
