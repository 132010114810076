import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  AppState,
  ScrollView, ViewStyle, ActivityIndicator
} from 'react-native';
import Alert from "../components/alert";
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from "../redux/store/app-provider";
import PrimaryButton from "../components/primary-button";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { API, graphqlOperation } from "aws-amplify";
import { deleteUser, deleteUserVideoTime } from "../graphql/mutations";

interface State {
  isBusyDelete: boolean
}

interface Props {
  t?: any
  style?: ViewStyle
}

class MyAccountScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

    this.state = {
      isBusyDelete: false
    }
  }

  async componentDidMount() {

  }

  deleteAccount() {
    SheetManager.show(`delete_account_sheet`);

  }

  async reallyDelete() {
    SheetManager.hide('delete_account_sheet');
    this.setState({ isBusyDelete: true });
    //await API.graphql(graphqlOperation(deleteUserVideoTime, { input: { id: this.context.state.user?.id } }))
    await API.graphql(graphqlOperation(deleteUser, { input: { id: this.context.state.user?.id } }))
    this.setState({ isBusyDelete: false });
    this.context.showToast(this.props.t('Account deleted'), 'success');
    this.context.signOut();
  }

  render() {
    const appBusyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator style={[style.busyIndicator]} size="large" color={colors.textColor}/>
      }
    }
    return (

      <View style={[style.container, { padding: 0 }]}>
        <View style={[style.page, { padding: 20 }]}>
          <View style={{ flex: 1 }}></View>
          <PrimaryButton iconPlacement={'left'} isBusy={this.state.isBusyDelete} isSecondary={false} onPress={this.deleteAccount.bind(this)} icon={
            <FontAwesome5 size={20} color={colors.textColor} name='trash-alt'></FontAwesome5>} label={this.props.t('Delete Account')} style={{
            width: '100%',
            marginTop: 10
          }}/>
        </View>
        <ActionSheet containerStyle={{
          maxWidth: 400, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="delete_account_sheet">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert style={{ width: '100%' }} onAccept={this.reallyDelete.bind(this)} message={this.props.t("Are you sure you want to delete your account? This action is irreversible!")} acceptLabel={this.props.t("Delete")}/>
          </View>

        </ActionSheet>
        {appBusyIndicator()}
      </View>

    )
  };

}

export default withTranslation()(MyAccountScreen);

const styles = StyleSheet.create({});
