import { FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  TouchableWithoutFeedback,
  View,
  Image,
  TextInput,
  FlatList,
  TouchableNativeFeedback,
  ActivityIndicator,
  Linking,
  Platform,
  AppState,
  ScrollView,
  Dimensions,
  ViewStyle
} from 'react-native';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import env from '../constants/env';

import { WebView } from 'react-native-webview';

interface State {

}

interface Props {
  t?: any

}

class GeoScreen extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  render() {

    return (

      <View style={[style.container, { padding: 0 }]}>
        <WebView automaticallyAdjustContentInsets={false}
                 domStorageEnabled={true}
                 javaScriptEnabled={true} originWhitelist={['*']} scalesPageToFit={true} style={{
          backgroundColor: "#000000",
          marginTop: 20,
          width: Dimensions.get('window').width,
          height: Dimensions.get('window').height
        }} source={{
          uri: 'https://datastudio.google.com/embed/reporting/282dce1e-1d8f-4e18-8414-297609fa8ac3/page/bZqsC'
        }}></WebView>
      </View>

    )
  };

}

export default withTranslation()(GeoScreen);

const styles = StyleSheet.create({});
