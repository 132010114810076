import { FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  AppState,
  ScrollView, ViewStyle
} from 'react-native';

import colors from '../constants/colors';
import style from '../constants/style';

import { withTranslation } from 'react-i18next';
//@ts-ignore
import { FormattedText } from 'react-native-formatted-text';
import { AppContext, IContext } from "../redux/store/app-provider";

interface State {

}

interface Props {
  t?: any
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

class PrivacyScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  render() {

    return (

      <View style={[style.container, { padding: 10 }]}>
        <ScrollView style={[style.fullSize, { padding: 20 }]}>
          <Text style={[style.fullSize, { color: colors.textColor }]}>
            {this.context.state.appConfig?.privacy?.replace(/<br\/>/gi, '\n')}
          </Text>
        </ScrollView>
      </View>

    )
  };

}

export default withTranslation()(PrivacyScreen);

const styles = StyleSheet.create({});
