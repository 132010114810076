import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  ActivityIndicator,
  Dimensions,
  FlatList, Platform
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from "../redux/store/app-provider";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";
import VideoClip from "../components/video-clip";
import * as queries from "../graphql/queries";
///@ts-ignore
import VideoPlayer from 'react-native-video-controls';
import { FastClips } from '../models';
import { Entypo, Feather, FontAwesome } from '@expo/vector-icons';
import env from "../constants/env";
import { withTranslation } from "react-i18next";

interface State {

  selectedVideo: any,
  showControls: boolean,
  currentIndex: number,
  cheight: number,
  nextToken?: string,
  items: FastClips[]
  isBusy: boolean
}

interface Props {
  t?: any
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

const screenWidth = Dimensions.get('window').width;

class FastClipsWebScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  tm: any;
  flatListRef: any;

  constructor(props: any) {
    super(props);
    this.state = { cheight: 0, items: [], currentIndex: -1, isBusy: false, selectedVideo: null, showControls: false }

  }

  async componentDidMount() {

  }

  downloadApp(os: string) {

    if (os === 'ios') {
      window.open(env.iOSApp)

    } else {
      window.open(env.androidApp)
    }
  }

  render() {

    const busyIndicator = () => {
      if (this.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator}/>
      }
    }
    return (
      <View onLayout={(event) => {
        if (!this.state.cheight) {
          this.setState({ cheight: event.nativeEvent.layout.height })
        }
      }} style={[style.container, { padding: 0, paddingBottom: 0 }]}>
        <View style={{
          width: '100%',
          height: '100%',
          padding: 20,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.backgroundColor,
          borderRadius: colors.borderRadius
        }}>
          <Text style={{
            color: colors.textColor,
            fontSize: 16,
            textAlign: 'center',
            width: '100%',
            marginBottom: 300
          }}>{this.props.t("This video can be played only on YouVi mobile app")}</Text>
          <TouchableOpacity style={{ display: this.context.state.os === 'ios' ? "flex" : "none" }} onPress={this.downloadApp.bind(this, 'ios')}>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              borderRadius: colors.borderRadius,
              borderColor: '#ffffff',
              backgroundColor: colors.orange,
              width: 300,
              padding: 15,
              borderWidth: 0,
              alignItems: 'center'
            }}>
              <Text style={{
                color: colors.textColor,
                flex: 1,
                fontSize: 20,
                fontWeight: 'bold',
                marginRight: 10
              }}>App Store</Text>
              <Image style={{
                height: 21,
                width: 21
              }} source={{ uri: 'https://raw.githubusercontent.com/crisand/youvistore/main/appstore.png' }}></Image>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={{
            marginLeft: 10,
            display: this.context.state.os === 'android' ? "flex" : "none"
          }} onPress={this.downloadApp.bind(this, 'android')}>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              borderRadius: colors.borderRadius,
              backgroundColor: colors.orange,
              borderColor: '#ffffff',
              padding: 15,
              width: 300,
              borderWidth: 0,
              alignItems: 'center'
            }}>
              <Text style={{
                color: colors.textColor, flex: 1, fontSize: 20,
                fontWeight: 'bold', marginRight: 10
              }}>Google Play</Text>
              <Image style={{
                height: 21,
                width: 21
              }} source={{ uri: 'https://raw.githubusercontent.com/crisand/youvistore/main/googleplay.png' }}></Image>
            </View>
          </TouchableOpacity>
        </View>

        {busyIndicator()}
        {/*  {description()} */}
      </View>
    )
  };

}

const styles = StyleSheet.create({
  imageContainer: {
    width: screenWidth,

    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
});
export default withTranslation()(FastClipsWebScreen);
