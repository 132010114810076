import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, ActivityIndicator } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from "../redux/store/app-provider";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";
import VideoClip from "../components/video-clip";
import * as queries from "../graphql/queries";

interface State {

}

interface Props {
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

class HistoryClipsScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  onSelectVideo = (videoItem: any) => {
    this.context.gotoVideo(videoItem)
    //this.props.onPress && this.props.onPress(videoItem);
  }

  async getAccountHistory(token?: string) {
    let user = this.context.state.user;
    let history = (await API.graphql({
      query: queries.getHistoryByTime,
      variables: { userEmail: user?.email, limit: 10, sortDirection: 'DESC', nextToken: token },
    }) as any).data.getHistoryByTime;
    const videoIds = history.items.map((h: any) => h.videoId).join(",");
    //console.log("videoIds", videoIds)
    let videoItems = (await API.get('ginierestapi', '/rest/video-info', {
      queryStringParameters: {
        videoId: videoIds,
      }
    })).body.items;
    //console.log("videoItems", JSON.stringify(videoItems));
    videoItems = videoItems.map((v: any, i: any) => {

      v.snippet.publishedAt = new Date(history.items.find((it: any) => it.videoId === v.videoId)?.updated).toLocaleDateString();
      v.videoId = v.id;
      return v;
    })
    this.context.setPlayingTimes(history.items);
    return { items: videoItems, nextToken: history.nextToken };

  }

  render() {

    const renderYoutubeVideoItem = ({ item, index }: any) => {
      return (
        <VideoClip item={item} index={index} videoTime={this.context.state.currentVideoTimes?.[item.videoId]} onPress={this.onSelectVideo.bind(this)}></VideoClip>
      )
    }
    return (
      <View style={[style.container, { padding: 10, paddingBottom: 0 }]}>
        <View style={{ width: '100%', maxWidth: 400, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <ItemsList cache={false} renderItem={renderYoutubeVideoItem} stateKey={'historyList'} fn={this.getAccountHistory.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.historyList}></ItemsList>
        </View>

      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default HistoryClipsScreen;
