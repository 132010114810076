import { FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  View, ViewStyle,

} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';

import VideoClip from '../components/video-clip';
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";
import { AppContext, IContext } from "../redux/store/app-provider";

interface State {

}

interface Props {
  t?: any
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

class VideoListScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  getSearchResults = async (token: string) => {
    /* config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3000/"
   Amplify.configure(config); */
    let queryStringParameters: any = {};
    if (token) {
      queryStringParameters["nextToken"] = token;
    }
    queryStringParameters["query"] = this.context.state.translatedSearch
    let videos: any = []
    queryStringParameters.lang = this.context.state.selectedLanguage;
    queryStringParameters["order"] = "relevance";
    videos = (await API.get('ginierestapi', '/rest/search-videos', {
      queryStringParameters
    })).body
    // navigationService.registerGAEvent("Search Query Video");
    return videos;

  }

  componentDidUpdate(props: any, prev: any) {

  }

  onSelectVideo = (videoItem: any) => {
    if (this.props.onPress) {
      this.props.onPress(videoItem);
    } else {
      this.context.gotoVideo(videoItem)
    }
  }

  render() {

    const renderYoutubeVideoItem = ({ item, index }: any) => {
      return (
        <VideoClip item={item} index={index} videoTime={this.context.state.currentVideoTimes?.[item.id?.videoId || item.id]} onPress={this.onSelectVideo.bind(this)}></VideoClip>
      )
    }

    return (
      <View style={[style.container, { padding: 10, paddingBottom: 0 }]}>
        <View style={{ width: '100%', maxWidth: 400, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <ItemsList cache={false} nextTokenName={'nextToken'} fn={this.getSearchResults} renderItem={renderYoutubeVideoItem} stateKey={'searchList'} stateItem={this.context.state.searchList}/>
        </View>

      </View>

    )
  };

}

export default withTranslation()(VideoListScreen);

const styles = StyleSheet.create({
  input: {
    padding: 5,
    height: 35,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: colors.textColor,
    color: colors.textColor
  },
  screenItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.textColor
  }
});
