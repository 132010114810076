import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  RefreshControl,
  FlatList,
  ActivityIndicator, Platform
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';

import * as queries from './../graphql/queries';
import { AppContext, IContext } from "../redux/store/app-provider";

interface State {

}

interface Props {
  nextTokenName: any,
  fn: any
  t?: any
  cache: boolean
  style?: ViewStyle
  renderItem: any,
  stateKey: string,
  stateItem: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
}

class ItemsList extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.cache) {
        if (!this.props.stateItem.items?.length) {
          this.getListItems(true);
        }
      } else {
        this.getListItems(true);
      }
    }, 10)

  }

  getListItems = async (refresh: boolean) => {
    this.context.getListItems(this.props.stateKey, this.props.fn, this.props.nextTokenName, refresh);
  }

  render() {
    const busyIndicator = () => {
      if (this.props.stateItem.loading) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator}/>
      }
    }
    return (
      <View style={[style.fullWidth, { flex: 1 }]}>

        {!this.props.stateItem.items?.length && !this.props.stateItem.loading && !this.props.stateItem.refreshing ?
          <View style={[style.fullWidth, style.column, style.vcenter, style.hcenter, { flex: 1 }]}><Text style={{ color: colors.textColor }}>Nothing to show here</Text></View> :
          <FlatList ref={(ref) => {

          }} refreshControl={
            <RefreshControl
              enabled={true}
              refreshing={this.props.stateItem.refreshing || false}
              onRefresh={() => {
                this.getListItems(true);
              }}
            />
          } horizontal={false} onEndReached={() => {
            if (this.props.nextTokenName) this.getListItems(false);
          }} contentContainerStyle={{
            width: '100%',
            flexDirection: 'column'
          }} data={this.props.stateItem.items} style={{
            flex: 1,
            width: '100%'
          }} renderItem={this.props.renderItem} keyExtractor={(item) => item.key}
          />
        }
        {busyIndicator()}
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default ItemsList;
