import React, { Component } from 'react';

import Amplify, { API, Auth, graphqlOperation } from "aws-amplify";
import { User } from "../../models";
import { Feather, MaterialIcons } from "@expo/vector-icons";

import DeviceInfo from "react-native-device-info";
import { Alert, AppState, AsyncStorage, BackHandler, Dimensions, Linking, Platform, Share } from "react-native";
import env from "../../constants/env";
import navigationService from "../../services/navigation-service";
import { activateAdapty, adapty } from "react-native-adapty";
import * as queries from "../../graphql/queries";
import i18next from "i18next";
import { createShareClip, createTrendingList, createUser, updateShareClip, updateUser } from "../../graphql/mutations";
import NavigationService from "../../services/navigation-service";
import { InAppBrowser } from "react-native-inappbrowser-reborn";
import config from "../../aws-exports";
import * as mutations from "../../graphql/mutations";
import ReactGA from "react-ga4";
import analytics from "@react-native-firebase/analytics";
import checkVersion from "react-native-store-version";
import { getLocales } from "react-native-localize";

export interface IAppState {
  sessionPlayVideoCount: number;
  showRewardModal: boolean;
  adData: any;
  isAdPlaying: boolean;
  hasNicePlayer: boolean;
  videoUrl: string;
  firstScreen: string;
  userChannels: any[];
  isIntroVisible: boolean;
  filterLang: string;
  translatedSearch: string;
  languageModalVisible: boolean;
  relatedModalVisible: boolean;
  channelModalVisible: boolean;
  currentChannelId: string
  relatedVideoId: string;
  shareModalVisible: boolean;
  showDownloadAppModal: boolean;
  currentPlayingTime: number;
  subtitles: any;
  os: string;
  routeParams: any;
  search: string,
  searchFilter: string
  currentVideoInfo: any;
  selectedLanguage: string;
  shareFriendsClip: any
  shareCodeItem: any
  checkedStore: boolean;
  isPremium: boolean;
  isMobile: boolean;
  isTablet: boolean;
  screen: string
  userPicture: string;
  isPortrait: boolean;
  trendingList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  searchList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  historyList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  relatedList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  channelList: { nextToken?: string, items: any[], loading: boolean, channel: any, refreshing: boolean }
  myChannelList: { nextToken?: string, items: any[], loading: boolean, channel: any, refreshing: boolean }
  lastSeenIds?: string[]
  videoCategoryId?: number
  playCount?: number
  currentVideoTimes: any
  appConfig: any
  categories: any[]
  user: User | null;
  isBusy: boolean
}

export interface IContext {
  state: IAppState,
  setAppProps: (props: any, callback?: any) => void
  gotoVideo: (video: any) => void
  getSubtitles: (videoId: any) => void
  getUserChannels: () => void
  getVideoUrl: (videoId: string) => any
  setVideosTimes: (payload: any) => void
  logEvent: (name: string) => void

  shareMe: (title: string, shareUrl: string) => void
  shareFast: (videoId: string) => void
  setPlayingTimes: (videoItems: any) => void
  registerSeenVideoIp: (payload: any) => void
  createAd: (addType: string, callback?: any, onClose?: any) => void
  setPlayTime: (payload: any) => void
  subscribeToChannel: (channelId: string) => void
  sendFeedback: (subject: string, message: string) => void
  getVideoInfo: (videoId: any, translateTitle?: boolean) => void
  setSearch: (search: string) => void
  setScreen: (screen: string, params?: any, navigate?: boolean) => void
  getTrending: (videoCategoryId: number, list: any) => void
  getListItems: (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => void,
  getChannels: () => void
  showToast: (message: string, type: 'error' | 'success') => void,
  setUser: (user: any) => void
  signOut: () => void
  buyPremium: () => void
  shareFriends: () => void
  restorePurchase: () => void
}

const initialState: any = {
  videoCategoryId: -1,
  trendingList: { items: [], loading: false, refreshing: false },
  searchList: { items: [], loading: false, refreshing: false },
  relatedList: { items: [], loading: false, refreshing: false },
  channelList: { items: [], loading: false, refreshing: false },
  historyList: { items: [], loading: false, refreshing: false },
  myChannelList: { items: [], loading: false, refreshing: false },
  checkedStore: false,
  isPremium: false,
  user: null,
  isBusy: false
}

export const AppContext = React.createContext<IContext>(initialState);
export const AppContextProvider = AppContext.Provider

export class AppProvider extends Component {
  state = initialState
  rewarded: any
  doNotPlayAd: boolean = false

  constructor(props: any) {
    super(props);

  }

  sendNewsletter = async () => {
    /* config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
    Amplify.configure(config) */
    /*  const message = `
     Hello, YouVi community!
     \n\n
     It's been a great year for YouVi, we have been working hard to bring you the best experience possible. We developed  a lot of new features and improvements. We recently made an update with a complete overhaul of the design and lots of app optimizations, so please make sure you have installed the latest version of the app.
     \n
     With the latest app version, we also included a Feedback module that will allow you to send us your feedback and suggestions. We are looking forward to hearing from you if you have any suggestions on how to make the app function better for you or any suggestions for a feature you would like to be added and we will try to respond as fast as we can!
     \n
     Cheers, YouVi team!
     \n
       `;
     const result = await API.post('ginierestapi', '/rest/send-blast-email', {
       body: { subject: "YouVi Newsletter 2022", message, templateId: "YouViMassTemplate25" }
     })
     console.log("result", result) */
  }

  async componentDidMount() {
    //this.sendNewsletter();
    ///return;
    /*config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
    Amplify.configure(config)*/
    /*const initialUrl = await Linking.getInitialURL();
    let isIntroVisible = true;
     if (initialUrl && initialUrl.indexOf('videoId')) {
       isIntroVisible = false;
       //genie://video?videoId=ETSit2XvZjI&lang=ro&shareCode=d9ef6d33-8a13-4a80-bd83-c9d87e7ed4d3
       //get all the url params
       /!* const urlParams = initialUrl.split('?')[1];
        const params = urlParams.split('&');
        const videoId = params[0].split('=')[1];
        const lang = params[1].split('=')[1];
        const shareCode = params[2].split('=')[1];
        ///console.log("params", params)
        this.getVideoInfo(videoId, true, () => {
          this.gotoVideo(this.state.currentVideoInfo, shareCode);
        });*!/
     }*/
    let lang = ""
    const nativeLang = getLocales().filter(l => l.languageCode.indexOf("en") === -1)[0]?.languageCode;
    const exists = env.languages.find(l => l.symbol === nativeLang);
    if (nativeLang?.indexOf("en") === -1 && exists && !lang) {
      lang = nativeLang;
    }
    if (!lang) {
      lang = "en";
    }
    this.setState({ selectedLanguage: lang, isIntroVisible: Platform.OS !== 'web' });
    if (Platform.OS === 'web') {
      window.addEventListener("resize", this.checkOrientation.bind(this));
      this.checkOrientation();
    } else {
      if (Platform.OS === 'android') {
        BackHandler.addEventListener('hardwareBackPress', () => {
          console.log("back pressed", this.state.screen)
          if (this.state.screen === 'Home') {
            //throw {};
            BackHandler.exitApp();
            return true;
          }
          return true;
        });
      }
      Dimensions.addEventListener('change', () => {
        this.checkOrientationMobile();
      });
      this.checkOrientationMobile();
      /* ReceiveSharingIntent.getReceivedFiles(async (files: any) => {
           const link = files[0]?.weblink || "";
           if (link.indexOf("youtube") !== -1 || link.indexOf("youtu.be") !== -1) {
             const videoId = link.replace("https://youtu.be/", "").replace("https://www.youtube.com/", "").replace("watch?v=", "");
             this.getVideoInfo(videoId, true, () => {
               this.gotoVideo(this.state.currentVideoInfo);
             });
           }
         },
         (error: any) => {
           console.log(error);
         },
         'com.can.genie' // share url protocol (must be unique to your app, suggest using your apple bundle id)
       );*/
    }
    this.getAppConfig();

    if (Platform.OS !== "web") {
      try {
        const check = await checkVersion({
          version: DeviceInfo.getVersion(),
          iosStoreURL: env.iOSApp,
          country: 'ro',
          androidStoreURL: env.androidApp,
        });
        console.log("check", check)
        console.log("DeviceInfo.getVersion()", DeviceInfo.getVersion())
        if (check.result === 'new') {
          Alert.alert(
            'Update Needed',
            `Hey, there''s a new version in town, please update for the best performance!`,
            [
              { text: 'Cancel', onPress: () => console.log('Cancel Pressed'), style: 'cancel' },
              {
                text: 'Update',
                onPress: () => Linking.openURL(Platform.OS === 'android' ? env.androidApp : env.iOSApp)
              },
            ],
            { cancelable: false }
          )
        }

      } catch (e) {
        console.log(e);
      }
    }
  }

  shareFriends = async () => {
    //Linking.openURL('https://rr4---sn-4g5lznlz.googlevideo.com/videoplayback?expire=1668306136&ei=dwBwY4buPMWGgQfKkL7gBg&ip=23.88.39.196&id=o-AIMjxumZSEaDF1McsIsKzZRqtmbwHhI8LJZv0IxfhWSD&itag=136&source=youtube&requiressl=yes&mh=S8&mm=31%2C26&mn=sn-4g5lznlz%2Csn-f5f7lnld&ms=au%2Conr&mv=m&mvi=4&pl=26&initcwndbps=270000&vprv=1&svpuc=1&mime=video%2Fmp4&gir=yes&clen=22825797&dur=179.960&lmt=1643840107073139&mt=1668284278&fvip=2&keepalive=yes&fexp=24001373%2C24007246&beids=24239134&c=ANDROID&txp=4535434&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cvprv%2Csvpuc%2Cmime%2Cgir%2Cclen%2Cdur%2Clmt&sig=AOq0QJ8wRAIgAZt59gM_vWnL4vWyHcKVZc0xMsu5IUGJQpDB5Nw5yOkCIDknZm9AEd-YhzjDaPB_MeakqlhDZJXVQWKPwN3Qfx6q&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=AG3C_xAwRAIgVMbmrAe8K8XcbMdDvSA9WLfJf2-rYpYQ6374JvZr7usCIHNfHtqMfL8wFIlq3UJAen_-KU7qWkGwf9HbcnO9f0g_');
    const lang = `&lang=${this.state.selectedLanguage}`;
    const shareFriendsClip = this.state.shareFriendsClip;
    const shareCode = (await API.graphql(graphqlOperation(createShareClip, {
      input: {
        videoId: shareFriendsClip.id,
        seenByIps: []
      }
    })) as any).data.createShareClip;
    const shareUrl = `https://preview.youvi.io/preview?vid=${shareFriendsClip.id}${lang}&shareCode=${shareCode.id}`;
    AsyncStorage.setItem("shareCode", shareCode.id);

    await Share.share({
      message: shareUrl,
      url: shareUrl
    });
    this.logEvent("share_friends");

    console.log("shareFriendsClip", shareUrl)

  }
  shareMe = async (title: string, shareUrl: string) => {
    this.setPlayAd(true);
    await Share.share({
      title: title,
      message: shareUrl,
      url: shareUrl
    });
    this.logEvent("share");
    this.setPlayAd(false);
  }
  shareFast = async (videoId: string) => {
    this.setPlayAd(true);
    const shareUrl = `https://preview.youvi.io/preview?fast=${videoId}`;
    await Share.share({
      title: '',
      message: shareUrl,
      url: shareUrl
    });
    this.logEvent("share_fast");
    this.setPlayAd(false);
  }
  getVideoInfo = async (videoId: any, translateTitle?: boolean, callback?: any) => {
    const currentVideoInfo = (await API.get('ginierestapi', '/rest/video-info', {
      queryStringParameters: {
        videoId
      }
    })).body.items[0]
    currentVideoInfo.videoId = currentVideoInfo.id;
    if (translateTitle) {
      currentVideoInfo.snippet.title = (await API.get('ginierestapi', '/rest/translate-to-lang', {
        queryStringParameters: {
          text: currentVideoInfo.snippet.title,
          lang: this.state.selectedLanguage
        }
      })).result
    }
    console.log("currentVideoInfo", currentVideoInfo, videoId)

    this.setState({

      currentVideoInfo,
      currentChannelId: currentVideoInfo.channelId || currentVideoInfo.snippet.channelId
    }, callback);
  }
  buyPremium = async () => {
    console.log("buyPremium")
    try {
      const { products } = await adapty.paywalls.getPaywalls();
      console.log("products", products)
      this.setPlayAd(true);
      const packName = Platform.OS === "ios" ? "com.youvi.premiumios2" : "com.youvi.premium";
      const adaptyProduct = products.find((p: any) => p.vendorProductId === packName) || products[0];
      const {
        receipt,
        purchaserInfo,
        product
      }: any = await adapty.purchases.makePurchase(adaptyProduct);
      console.log("receipt", receipt);
      console.log("purchaserInfo", JSON.stringify(purchaserInfo));
      console.log("product", JSON.stringify(product));
      //toast.show("Success, you are now a YouVi Premium user!", { type: 'success' })
      this.showToast("Success, you are now a YouVi Premium user!", "success")
      this.setState({ isPremium: true })
      this.logEvent("buy_premium");
      this.setPlayAd(false);
    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription, "error")
      this.setPlayAd(false);
      //this.showToast(error.localizedDescription, "error");
    }

  };
  restorePurchase = async () => {
    console.log("restore")
    try {
      const { products } = await adapty.paywalls.getPaywalls();
      console.log("products", products)

      const prodId = Platform.OS === "ios" ? "com.youvi.premiumios2" : "com.youvi.premium";
      const adaptyProduct = products.find((p: any) => p.vendorProductId === prodId) || products[0];
      const {
        purchaserInfo,
        receipt,
        googleValidationResults,
      } = await adapty.purchases.restore()
      console.log("info", JSON.stringify(purchaserInfo))
      let isPremium = false;
      console.log("receipt", receipt);
      console.log("purchaserInfo", JSON.stringify(purchaserInfo));
      if (purchaserInfo?.nonSubscriptions?.[prodId]) {
        isPremium = true;
        console.log("premium")
      }

      if (purchaserInfo?.accessLevels?.['premium']?.isActive || isPremium) {
        //toast.show("Success, you are now a YouVi Premium user!", { type: 'success' })
        this.showToast("Success, you are now a YouVi Premium user!", "success")
        this.setState({ isPremium: true })

      }
      //console.log("product", JSON.stringify(product));

    } catch (error: any) {
      console.log("error", error);
      this.showToast(error.localizedDescription, "error")
    }
  };
  getTrending = async (videoCategoryId: number, list: any) => {
    /*config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
    Amplify.configure(config);*/
    //return;
    const { trendingList } = this.state
    trendingList.loading = true
    this.setState({ trendingList, isBusy: true })
    let queryStringParameters: any = { lang: this.state.selectedLanguage };
    if (videoCategoryId !== -1) {
      queryStringParameters["videoCategoryId"] = videoCategoryId;
    }
    let categoryLabel = this.state.categories?.find((c: any) => c.videoCategoryId === videoCategoryId)?.title || "Trending";
    if (!categoryLabel) {
      categoryLabel = "Trending";
    }
    const date = new Date();
    let trendingId = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}_${Math.floor(date.getHours() / 6)}_${videoCategoryId}_${this.state.selectedLanguage}`;

    console.log("trendingId", trendingId)
    let cachedVideos
    try {
      cachedVideos = (await API.graphql({
        query: queries.getTrendingList,
        variables: { id: trendingId }
      }) as any).data.getTrendingList;
    } catch (e) {
      console.log("error", JSON.stringify(e))
    }
    //cachedVideos = null;
    let videoItems;
    let videos;
    //cachedVideos = null;
    //console.log("cachedVideos", cachedVideos)
    if (!cachedVideos || videoCategoryId === 69) {
      videos = videoCategoryId === 69 ? (await API.post('ginierestapi', '/rest/recommanded', {
        body: { ids: this.state.lastSeenIds, lang: this.state.selectedLanguage }
      })).body : (await API.get('ginierestapi', '/rest/get-trending', {
        queryStringParameters
      })).body
      videoItems = videos.items;
      try {
        API.graphql(graphqlOperation(createTrendingList, {
          input: {
            id: trendingId,
            videoList: JSON.stringify(videoItems)
          }
        }))
      } catch (e) {
        console.log("error", e)
      }
    } else {
      videos = {};
      videoItems = JSON.parse(cachedVideos.videoList);
    }

    if (list) list.scrollToOffset({ animated: false, offset: 0 });
    /*this.setVideosTimes(videoItems.map((video: any) => {
      return video.id
    }))*/
    videoItems = videoItems.map((video: any) => {
      //key is a random number
      video.key = Math.random().toString(36).substring(7);
      return video;
    })
    this.logEvent(`trending_${categoryLabel.toLowerCase()}`);
    this.setState({ isBusy: false, trendingList: { items: videoItems, loading: false } })
    if (this.state.isIntroVisible) {
      setTimeout(async () => {
        let isIntroVisible = false;
        if (Platform.OS !== 'web') {
          isIntroVisible = !((await AsyncStorage.getItem("hideIntro")) === "true");
        }
        this.setState({ isIntroVisible: false })
        console.log("isIntroVisible", isIntroVisible)
      }, 1000);
    }
  }
  setVideosTimes = async (videoIds: any) => {
    let user = this.state.user;
    if (!user) return;
    console.log("videoIds", videoIds)
    const ors: any[] = videoIds.map((videoId: string) => {
      return { id: { eq: `${videoId}_${user.id}` } }
    });
    let times = (await API.graphql({
      query: queries.listUserVideoTimes,
      variables: { filter: { or: ors } }
    }) as any).data.listUserVideoTimes.items;
    //console.log("setVideosTimes", times)
    let playingTimes = this.state.currentVideoTimes || {};
    times.forEach((item: any) => {
      playingTimes[item.videoId] = item;
    })
    console.log("playingTimes", playingTimes)
    this.setState({ currentVideoTimes: playingTimes });
  }

  setPlayingTimes = async (videoItems: any) => {
    let playingTimes = this.state.currentVideoTimes || {};
    videoItems.forEach((item: any) => {
      playingTimes[item.videoId] = item;
    })
    this.setState({ currentVideoTimes: playingTimes });
  }
  getListItems = async (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => {
    const obj = { ...this.state[stateKey] }
    //console.log("nextTokenName", obj.nextToken)
    if (!refresh && !obj.nextToken) return;
    obj.loading = !refresh || !obj.items.length;
    obj.refreshing = refresh && obj.items.length !== 0;
    if (refresh) {
      obj.items = [];
    }
    this.setState({ [stateKey]: { ...obj } });
    let items = refresh ? await fn() : await fn(obj.nextToken);
    items.items = items.items.map((video: any) => {
      video.key = Math.random().toString(36).substring(7);
      return video;
    })
    obj.items = refresh ? items.items : obj.items.concat(items.items)
    //make sure obj.items contains unique objects with videoId
    obj.items = obj.items.filter((item: any, index: any, self: any) => {
      return self.findIndex((t: any) => {
        return t.videoId === item.videoId
      }) === index
    }) as any;
    /*obj.items = obj.items.filter((item: any) => {
      return item.reports ? item.reports.length < 5 : true
    })*/
    if (items.channel) {
      obj.channel = items.channel;
      console.log("channel", items.channel)
    }
    obj.nextToken = items[nextTokenName]
    obj.loading = false;
    obj.refreshing = false;
    this.setState({ [stateKey]: obj });
  }
  setAuthType = (type: 'AWS_IAM' | 'API_KEY') => {
    config.aws_appsync_authenticationType = type;
    API.configure(config);
  }
  getChannels = async () => {
    try {
      /*let categories = (await API.get('ginierestapi', '/rest/video-categories', {
        queryStringParameters: {
          regionCode: 'US'

        }
      })).body*/
      let categories = this.state.appConfig.channels;
      let hasFast = false;
      if (Platform.OS !== 'web' && this.state.playCount >= this.state.adData.fastShowMax) {
        hasFast = true;
        categories.splice(1, 0, {
          "title": 'Fast',
          "icon": "fast-forward",
          "type": "ma",
          'query': 'Fast',
          'videoCategoryId': 555
        })
      }
      //inster one item in categories at index 1
      categories.splice(Platform.OS !== 'web' ? (hasFast ? 2 : 1) : 1, 0, {
        "title": i18next.t(('Recommended')),
        "icon": "star-border",
        "type": "ma",
        "c": "now",
        'query': 'Trending',
        'videoCategoryId': 69
      })
      const selectedRegionLang = "en";
      this.setState({ categories })
    } catch (e) {
      console.log("error", e)
    }
  }
  isPortrait = () => {
    const dim = Dimensions.get('window');
    return dim.height >= dim.width;
  };

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  checkOrientationMobile() {
    const orientation = this.isPortrait() ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      isMobile: true,
      os: Platform.OS.toString(),
      isTablet: DeviceInfo.isTablet(),
      isPortrait: orientation === "portrait"
    });
  }

  checkOrientation() {
    let os;
    if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
      os = "android";
    } else if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      os = "ios";

    } else {
      os = "kaka";
    }

    const orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      os,
      isMobile: this.detectMob(),
      isTablet: window.innerWidth > 1024,
      isPortrait: orientation === 'portrait'
    });

  }

  setPlayTime = async (payload: any) => {
    let user = this.state.user;
    /*console.log("setPlayTime", payload)
    return;*/
    if (!user) return;
    if (!payload.lastPlayedVideoDuration || !payload.time || !payload.videoId) return;
    let key = `${payload.videoId}_${user.id}`;

    let timeu = {
      id: key,
      userEmail: user.email,
      videoDuration: payload.lastPlayedVideoDuration,
      videoId: payload.videoId,
      currentTime: payload.time,
      updated: (new Date()).toISOString()
    }
    let userTimeVideo = (await API.graphql({
      query: queries.getUserVideoTime,
      variables: { id: key }
    }) as any).data.getUserVideoTime;

    if (!userTimeVideo) {
      userTimeVideo = (await API.graphql(graphqlOperation(mutations.createUserVideoTime, { input: timeu })) as any).data.createUserVideoTime;
      console.log("CREATE")
    } else {
      userTimeVideo = (await API.graphql(graphqlOperation(mutations.updateUserVideoTime, { input: timeu })) as any).data.updateUserVideoTime;
      console.log("UPDATE")
    }
    this.setPlayingTimes([userTimeVideo]);
  }
  getAppConfig = async () => {
    const appConfig = (await API.get('ginierestapi', '/rest/config', {
      queryStringParameters: {
        internalVersion: env.internalVersion
      }
    }))
    let playCount = Platform.OS !== 'web' ? Number(await AsyncStorage.getItem("playCount")) || 0 : 0;
    const adData = Platform.OS !== 'web' ? appConfig.adds[Platform.OS.toString()] : {};
    adData.maxYouVids = 2;
    adData.hasNicePlayer = true;
    /*adData.maxYouVids = 2;
    adData.banSkip = 4;
    adData.maxFastClips = 5;
    adData.fastShowMax = 2;
    adData.maxVidAdIntro = 2;
    adData.inbetweenType = "inre";
    adData.hasIntroAd = true;
    adData.openType = "inre";*/
    //adData.openType = 're';

    this.setState({ appConfig, adData, playCount, hasNicePlayer: adData.hasNicePlayer }, () => {
      this.initStore();
      this.getChannels();
    })
    if (Platform.OS !== "web" && this.state.adData.hasIntroAd) {
      AppState.addEventListener("change", (nextAppState: any) => {
        if (nextAppState === "active" && !this.doNotPlayAd) {
          setTimeout(() => {
            this.activeAd();
          }, 1000);
        }

      });

    }
  }
  initStore = async () => {
    if (Platform.OS === "web") return;
    await activateAdapty({ sdkKey: 'public_live_TjFLA4Kd.yuK0ogUmdOj40lOxaK41' });
    let seenIds = JSON.parse(await AsyncStorage.getItem("seenIds") as string) || [];
    this.setState({ lastSeenIds: seenIds })
    const isSharePremium = await AsyncStorage.getItem("isSharePremium");
    let isPremium = false;
    if (!isSharePremium) {
      try {
        const info = await adapty.purchases.getInfo() || null;
        const prodId = Platform.OS === "ios" ? "com.youvi.premiumios2" : "com.youvi.premium";

        if (info?.nonSubscriptions?.[prodId]) {
          isPremium = true;
          console.log("premium")
        }
        console.log("info", JSON.stringify(info))
        if (info?.accessLevels?.['premium']?.isActive || isPremium) {
          console.log("IS ACTIVE")
          isPremium = true;
          this.setState({ isPremium: true })
        }
      } catch (error: any) {
        console.log("error", error);
        //toast.show(error.localizedDescription, { type: 'error' })
        this.showToast(error.localizedDescription, "error")
      }
    } else {
      isPremium = true;
      this.setState({ isPremium: true })
    }
    this.setState({ isPremium: false })
    const shareCode = await AsyncStorage.getItem("shareCode");
    this.setState({ checkedStore: true, isPremium })

    if (shareCode) {
      const shareCodeItem = (await API.graphql({
        query: queries.getShareClip,
        variables: { id: shareCode }
      }) as any).data.getShareClip;
      console.log("shareCodeItem", shareCodeItem)
      if (shareCodeItem) {
        this.setState({ shareCodeItem })
        if (shareCodeItem.seenByIps.length >= 15) {
          this.setState({ isPremium: true })
          AsyncStorage.removeItem("shareCode");
          AsyncStorage.setItem("isSharePremium", "true");
          Alert.alert(
            i18next.t('Seen shared video'),
            i18next.t(`Congratulations, 15 friends have seen your share video and you are now a premium user!`),
            [
              { text: i18next.t("Continue"), onPress: () => console.log("OK Pressed") }
            ]
          )
        } else {
          Alert.alert(
            i18next.t('Seen shared video'),
            `${shareCodeItem.seenByIps.length} ${i18next.t('friends have seen your shared video!')}`,
            [
              {
                text: i18next.t("Don't show again"), style: "cancel", onPress: () => {
                  AsyncStorage.removeItem('shareCode')
                }
              },
              { text: i18next.t("Continue"), onPress: () => console.log("OK Pressed") }
            ]
          )
        }
      }
    }
    if (!isPremium) {
      this.activeAd();
    }

    console.log("shareCode", shareCode)
  }
  getAd = (addType: string, callback?: any) => {
    let ad;
    console.log("getAd", addType, this.state.adData[addType])
    switch (addType) {
      case "re":
        ad = navigationService.props.RewardedAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: false
        });
        break;
      case "in":
        ad = navigationService.props.InterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: false
        });
        break;
      case "inre":
        ad = navigationService.props.RewardedInterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: false
        });
        break;
      case "op":
        ad = navigationService.props.AppOpenAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: false
        });
        break;
    }
    return ad;
  }

  setPlayAd(doNot?: boolean) {
    if (doNot) {
      this.doNotPlayAd = true;
    } else {
      setTimeout(() => {
        this.doNotPlayAd = false;
      }, 3000);
    }

  }

  createAd = (addType: string, callback?: any, onClose?: any) => {
    let ad: any;

    if (Platform.OS === "web" || this.state.isPremium) return;
    if (addType === 'open') {
      ad = this.getAd(this.state.adData['openType']);
    } else {
      ad = this.getAd(addType);
    }
    //console.log("createAd", addType, ad)

    try {
      ad.addAdEventListener('rewarded_loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true });
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true });
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('closed', () => {
        this.setState({ isAdPlaying: false })
        if (onClose) onClose();

      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('error', () => {
        this.setState({ isAdPlaying: false })
        if (onClose) onClose();

      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('rewarded_earned_reward', () => {
        this.setState({ isAdPlaying: false })
        if (onClose) onClose();

      });
    } catch (e) {
    }
    ad.load();
  }
  activeAd = async () => {
    const appConfig = this.state.appConfig;
    if (this.state.isPremium) return;
    let count = Number(await AsyncStorage.getItem("playCount")) || 0
    //console.log("count", this.state.adData)
    console.log("appConfig.vidAdIntroMata", this.state.isPremium, appConfig.hasIntroAd, count, this.state.adData.maxVidAdIntro, this.state.isAdPlaying)
    if (this.state.adData.hasIntroAd && count >= this.state.adData.maxVidAdIntro && !this.state.isAdPlaying && !this.doNotPlayAd) {

      this.createAd('open', (ad: any) => {
        console.log("created rewarded")
        setTimeout(() => {
          this.doNotPlayAd = false;
        }, 60 * 1000)
        /* rewarded.addAdEventListener('closed', () => {
           rewarded = null;
           console.log("CLOSED")
           /!* setTimeout(() => {
              isAdPlaying = false;
            }, 3000);*!/
         })
         rewarded.addAdEventListener('rewarded_earned_reward', (reward: any) => {
           //createRewarded();
           console.log("REWARDED")
           rewarded = null;
           /!* setTimeout(() => {
              isAdPlaying = false;
            }, 3000);*!/
         });*/
      });

    }
  }
  getUserChannels = async () => {
    /* config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
     Amplify.configure(config)*/
    const channels = this.state.user.channels;
    if (!channels || channels.length === 0) {
      this.showToast(i18next.t("You don't have any channels yet. Please create one!"), "error")
      return;
    }
    ;
    this.setState({ isBusy: true })
    console.log("channels", channels)
    const result = await API.post('ginierestapi', '/rest/channels', {
      body: { channelIds: channels }
    })

    //populate each channel data with the channel id
    result.map((channel: any, index: any) => {
      channel.id = channels[index];
      return channel;
    })
    this.logEvent("user_channel_videos");
    console.log("result", result);
    this.setState({ isBusy: false, userChannels: result })
  }
  sendFeedback = async (subject: string, message: string) => {
    let result: any;
    //this.setAuthType('AWS_IAM')
    /* config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3003"
     Amplify.configure(config);*/
    try {
      const result = (await API.post('ginierestapi', '/rest/message/', {
        body: {
          mail: this.state.user.email,
          subject,
          message
        }
      }))
      //this.setAuthType('API_KEY');
      this.showToast(i18next.t("Feedback sent"), "success");
    } catch (e) {
      console.log("eeee", e)
      //this.setAuthType('API_KEY');
      this.showToast(e.response.data.message, "error")
    }

  }
  setScreen = (payload: any, params: any, doNotNavigate?: boolean) => {
    if (!this.state.firstScreen) {
      this.setState({ firstScreen: payload })
    } else {
      this.setState({ firstScreen: "kaka" })
      if (!NavigationService.props.setHome) {
        NavigationService.resetRoutes();
        NavigationService.props.setHome = true;
      }
    }
    if (payload === 'HomeHeader' && !doNotNavigate) {
      this.setAppProp({ search: "", searchFilter: "" })
      payload = "Home";
    }
    //console.log("payload,payload", payload)
    this.setState({ screen: payload, routeParams: params })
    setTimeout(() => {
      if (!doNotNavigate) {
        /* if (!NavigationService.props.setHome) {
           //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
           NavigationService.resetRoutes();
           NavigationService.props.setHome = true;
         } else {
           NavigationService.navigate(payload, params)
         }*/
        NavigationService.navigate(payload, params)
      }
      ;
    }, 100)

  };
  gotoVideo = async (video: any, shareCode?: string) => {
    console.log("gotoVideo", video)

    this.setState({
      currentVideoInfo: video,
      translatedTitle: video.title,
      relatedVideoId: video.videoId,
      currentChannelId: video.channelId || video.snippet.channelId,
      channelList: { items: [], loading: false, nextToken: null },
      relatedList: { items: [], loading: false, nextToken: null },
    }, () => {
      this.getSubtitles(video.videoId)
      let obj: any = { videoId: video.videoId, lang: this.state.selectedLanguage }
      if (shareCode) {
        obj['shareCode'] = shareCode;
      }
      this.setScreen("MobileVideo", obj)
    });

    //NavigationService.navigate('MobileVideo', { videoId: video.videoId, lang: this.state.selectedLanguage });
  }
  getVideoUrl = async (videoId: string) => {
    const result = (await API.get('ginierestapi', '/rest/vidrl', {
      queryStringParameters: {
        videoId
      }
    }))
    //console.log("details", JSON.stringify(result))
    const video = result.find((v: any) => v.height === 720 && v.mimeType.indexOf('mp4') !== -1) || null;
    console.log("video", video)
    return video;
    /*const results = (await API.get('ginierestapi', '/rest/vidrl', {
      queryStringParameters: {
        videoId
      }
    }))
    console.log("kakayay", results[results.length - 23].qualityLabel)
    return results[results.length - 23] || null;*/

  }
  registerSeenVideoIp = async (payload: any) => {
    let localClipId: any
    if (Platform.OS === 'web') {
      localClipId = localStorage.getItem(payload)
    } else {
      localClipId = await AsyncStorage.getItem(payload)
    }
    if (!localClipId) {
      const shareCodeItem = (await API.graphql({
        query: queries.getShareClip,
        variables: { id: payload }
      }) as any).data.getShareClip;
      if (!shareCodeItem) return;
      //console.log("shareCode", payload, shareCodeItem);
      let ids = shareCodeItem.seenByIps;
      //insert a random id string
      ids.push(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
      const updated = (await API.graphql(graphqlOperation(updateShareClip, {
        input: {
          id: shareCodeItem.id,
          seenByIps: ids
        }
      })) as any).data.updateShareClip;
      if (Platform.OS === 'web') {
        localStorage.setItem(payload, "true");
      } else {
        await AsyncStorage.setItem(payload, "true");
      }
    }

  };
  getSubtitles = async (videoId: string) => {
    //console.log("getSubtitles", videoId)
    /*config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3000/"
    Amplify.configure(config)*/

    const subtitles = await API.post('ginierestapi', '/translation', {
      body: ({
        "requestType": "getTranscriptTranslated",
        "videoId": videoId,
        "fromLang": "en",
        "lang": this.state.selectedLanguage
      })
    })
    this.logEvent(`subtitle_${this.state.selectedLanguage}_${videoId}`);
    //console.log("subtitles", subtitles)
    if (typeof subtitles === 'object' && subtitles.error) {
      this.setState({ subtitles: [] })
      this.showToast(i18next.t('Could not find any subtitles'), 'error')
    } else {
      this.setState({ subtitles: subtitles })
    }
  }
  setAppProp = (obj: any, callback?: any) => {
    let appConfig = { ...this.state };
    appConfig = { ...appConfig, ...obj };
    this.setState(appConfig, () => {
      if (callback) {
        callback();
      }
    });
  }

  setURLOpener() {
    let opener;
    if (Platform.OS === 'web') {
      opener = (url: string) => {
        console.log("url", url)
        window.open(`${url}&access_type=offline&prompt=consent&approval_prompt=force&authorization_code`, "_self")
      };

    } else {
      opener = (url: string) => {
        //Browser.open({ url })
        InAppBrowser.open(url)
      }
    }

    const config: any = Auth.configure(null);
    config.oauth.urlOpener = opener;
    Auth.configure(config);
    ;
  }

  signOut = async () => {
    this.setUser(null);
    this.setURLOpener();
    await Auth.signOut({ global: true });
    this.setScreen('Home', {});
    /* GoogleSignin.signOut();
     GoogleSignin.clearCachedAccessToken(this.googleToken);*/
    try {
      InAppBrowser.close();
    } catch (e) {
    }
  }
  setUser = async (user: any) => {
    if (user) {
      setTimeout(() => {
        if (this.state.screen !== 'MobileVideo' && this.state.screen !== 'Fast') this.setScreen('Home', {});
      }, 1000)

      let account: any = {
        id: user.attributes.email,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        email: user.attributes.email
      };
      let acc
      config.aws_appsync_authenticationType = "AWS_IAM";
      Amplify.configure(config);
      acc = (await API.graphql({ query: queries.getUser, variables: { id: account.email } }) as any).data.getUser;
      if (!acc) {
        acc = (await API.graphql(graphqlOperation(createUser, { input: account })) as any).data.createUser;
      }
      account.picture = user.username.indexOf('facebook') !== -1 ? JSON.parse(user.attributes['picture']).data.url : user.attributes.picture
      try {
        InAppBrowser.close();
      } catch (e) {
      }
      console.log("acc", acc)
      this.setState({ user: acc, userPicture: account.picture });
    } else {
      this.setState({ user: null, userPicture: null });
      config.aws_appsync_authenticationType = "API_KEY";
      Amplify.configure(config);
    }
  }
  error = (e: any) => {
    this.setState({ isBusy: false })
    this.showToast(e.message, 'error')
  }
  setSearch = async (search: string) => {
    if (search && (search.indexOf("youtube") !== -1 || search.indexOf("youtu.be") !== -1)) {
      const videoId = search.replace("https://youtu.be/", "").replace("https://www.youtube.com/", "").replace("watch?v=", "");
      this.getVideoInfo(videoId, true, () => {
        this.gotoVideo(this.state.currentVideoInfo);
      });
    } else {
      if (search) {
        const translateResult = (await API.get('ginierestapi', '/rest/translate', {
          queryStringParameters: {
            text: search
          }
        })).result;
        //console.log("translateResult", translateResult)
        //query = translateResult.result;
        this.setState({ translatedSearch: translateResult })
        this.setScreen("Videos", {})
      }

    }
    this.logEvent('search_query');
  }
  subscribeToChannel = async (channelId: any) => {
    this.setState({ isBusy: true })
    let pastUser = this.state.user;
    delete pastUser.createdAt;
    delete pastUser.updatedAt;
    delete pastUser.owner;
    delete pastUser.isAdmin;

    if (!pastUser) {
      //toast.show(i18next.t('You must be signed in to subscribe'), { type: 'error' })
      this.showToast(i18next.t('You must be signed in to subscribe'), "error")
      return;
    }
    if (pastUser.channels && pastUser.channels?.indexOf(channelId) !== -1) {
      this.showToast(i18next.t("Already subscribed"), "error")
      return;
    }
    let channels = pastUser.channels || [];
    channels.push(channelId);
    pastUser.channels = channels
    const user = (await API.graphql(graphqlOperation(updateUser, { input: pastUser })) as any).data.updateUser;
    this.showToast(i18next.t("Successfully subscribed"), "success")
    this.setState({ user: user, isBusy: false })
    this.logEvent('subscribe_channel');
  }
  logEvent = (eventName: string) => {
    const eve = `${Platform.OS.toString().toLowerCase()}_${eventName}`;
    try {
      if (Platform.OS === "web") {
        ReactGA.event({ category: "", action: eve });
      } else {
        analytics().logEvent(eve);
      }
    } catch (e) {
      console.log("log error", JSON.stringify(e))
    }
  }
  showToast = (message: string, type: 'error' | 'success') => {
    let tp = type.toString();
    if (tp === 'error') {
      tp = 'danger';
    }
    const options: any = {
      placement: 'bottom',
      type: tp,
      duration: 2000,
      animationType: 'slide-in',
      animationDuration: 250,
      successColor: "#17ad3f",
      dangerColor: "#ad2b1a",
      warningColor: "orange",
      normalColor: "gray",
      textStyle: { fontSize: 15, color: "#ffffff" },
      swipeEnabled: true
    }
    toast.show(message, options);
    //toast.show("Show custom toast", { data: { title: 'Toast title' } })
  }

  render() {
    return (
      <AppContext.Provider value={
        {
          state: this.state,
          showToast: this.showToast,
          getVideoInfo: this.getVideoInfo,
          getListItems: this.getListItems,
          createAd: this.createAd,
          buyPremium: this.buyPremium,
          restorePurchase: this.restorePurchase,
          setVideosTimes: this.setVideosTimes,
          setPlayingTimes: this.setPlayingTimes,
          registerSeenVideoIp: this.registerSeenVideoIp,
          getSubtitles: this.getSubtitles,
          logEvent: this.logEvent,
          shareMe: this.shareMe,
          shareFast: this.shareFast,
          shareFriends: this.shareFriends,
          getVideoUrl: this.getVideoUrl,
          setPlayTime: this.setPlayTime,
          sendFeedback: this.sendFeedback,
          getUserChannels: this.getUserChannels,
          setScreen: this.setScreen,
          getTrending: this.getTrending,
          subscribeToChannel: this.subscribeToChannel,
          getChannels: this.getChannels,
          setSearch: this.setSearch,
          gotoVideo: this.gotoVideo,
          signOut: this.signOut,
          setAppProps: this.setAppProp,
          setUser: this.setUser,
        }}>
        {this.props.children}
      </AppContext.Provider>)
  }
}
