export default {
  //youtubeApiKey: 'AIzaSyB1hGy2KC_MwOjTO_aki_omZG8Bv2wL5hY',
  internalVersion: 18,
  youtubeApiKey: 'AIzaSyAXivOP9CIbLeET_HW-8t5NgBcBqV1X_Vg',
  androidApp: 'https://play.google.com/store/apps/details?id=com.can.genie',
  iOSApp: 'https://apps.apple.com/ro/app/youvi/id1613563464',
  versions: {
    "iOS": "10",
    "Android": "14"
  },
  transcriptGateway: 'https://c6ppy42hub.execute-api.us-east-1.amazonaws.com/Prod/rest',
  youtubeGateway: 'https://www.googleapis.com/youtube/v3/',
  /* labels: ["Related videos","Channel videos","Subscribe to channel","Download the mobile app for a better experience", "You need to be signed in and have a premium subscription to access this feature", "Make me premium", "Get more videos like this", "Signing in is available only on the mobile app", "You have no channel subscriptions", "Sign in with your Google account to view the latest on your channel subscriptions", "Trending", "News", "Entertainment", "Comedy", "Music", "Film", "Science&Tech", "Gaming", "Sports", "Education", "Autos", "Pets", "People", "Style", "Search", "Email Address", "Signing in is available only on the mobile app", 'First Name', 'Last Name', 'Password', "No seen videos yet", "Sign Out", "Search or paste link", "Language", "Donate", "Default", "translated clips", "Could not find any subtitles"], */
  labels: ["Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model", "Account deleted", "My Account", "Are you sure you want to delete your account? This action is irreversible!", "Delete", "Delete Account"],
  languages: [

    { "lang": "Afrikaans", "symbol": "af" },
    { "lang": "Albanian", "symbol": "sq" },
    { "lang": "Amharic", "symbol": "am" },
    { "lang": "Arabic", "symbol": "ar" },
    { "lang": "Armenian", "symbol": "hy" },
    { "lang": "Azerbaijani", "symbol": "az" },
    { "lang": "Basque", "symbol": "be" },
    { "lang": "Bengali", "symbol": "bn" },
    { "lang": "Burmese", "symbol": "my" },
    { "lang": "Bosnian", "symbol": "bs" },
    { "lang": "Bulgarian", "symbol": "bg" },
    { "lang": "Catalonian", "symbol": "ca" },
    { "lang": "Cebuano", "symbol": "ceb" },
    { "lang": "Czech", "symbol": "cs" },
    { "lang": "Chinese", "symbol": "zh-Hans" },
    { "lang": "Traditional Chinese", "symbol": "zh-Hant" },
    { "lang": "Korean", "symbol": "ko" },
    { "lang": "Corsican", "symbol": "co" },
    { "lang": "Croatian", "symbol": "hr" },
    { "lang": "Dane", "symbol": "da" },
    { "lang": "Hebrew", "symbol": "iw" },
    { "lang": "English", "symbol": "en" },
    { "lang": "Esperanto", "symbol": "eo" },
    { "lang": "Estonian", "symbol": "et" },
    { "lang": "Filipino", "symbol": "fil" },
    { "lang": "Finnish", "symbol": "fi" },
    { "lang": "French", "symbol": "fr" },
    { "lang": "West Frisian", "symbol": "fy" },
    { "lang": "Scottish Gaelic", "symbol": "gd" },
    { "lang": "Welsh", "symbol": "cy" },
    { "lang": "Galician", "symbol": "gl" },
    { "lang": "Georgian", "symbol": "ka" },
    { "lang": "German", "symbol": "de" },
    { "lang": "Greek", "symbol": "el" },
    { "lang": "Gujarati", "symbol": "gu" },
    { "lang": "Haitian", "symbol": "ht" },
    { "lang": "Hausa", "symbol": "ha" },
    { "lang": "Hawaiian", "symbol": "haw" },
    { "lang": "Hindi", "symbol": "hi" },
    { "lang": "Hmong", "symbol": "hmn" },
    { "lang": "Idiș", "symbol": "yi" },
    { "lang": "Igbo", "symbol": "ig" },
    { "lang": "Indonesian", "symbol": "id" },
    { "lang": "Irish", "symbol": "ga" },
    { "lang": "Icelandic", "symbol": "is" },
    { "lang": "Italian", "symbol": "it" },
    { "lang": "Japanese", "symbol": "ja" },
    { "lang": "Javanese", "symbol": "jv" },
    { "lang": "Kannada", "symbol": "kn" },
    { "lang": "Kazakh", "symbol": "kk" },
    { "lang": "Kyrgyz", "symbol": "ky" },
    { "lang": "Khmer", "symbol": "km" },
    { "lang": "Kinyarwanda", "symbol": "rw" },
    { "lang": "Kurdish", "symbol": "ku" },
    { "lang": "Lao", "symbol": "lo" },
    { "lang": "Latin", "symbol": "la" },
    { "lang": "Latvian", "symbol": "lv" },
    { "lang": "Lithuanian", "symbol": "lt" },
    { "lang": "Luxembourgish", "symbol": "lb" },
    { "lang": "Macedonian", "symbol": "mk" },
    { "lang": "Hungarian", "symbol": "hu" },
    { "lang": "Malay", "symbol": "ms" },
    { "lang": "Malayalam", "symbol": "ml" },
    { "lang": "Malagasy", "symbol": "mg" },
    { "lang": "Maltese", "symbol": "mt" },
    { "lang": "Maori", "symbol": "mi" },
    { "lang": "Marathi", "symbol": "mr" },
    { "lang": "Mongolian", "symbol": "mn" },
    { "lang": "Dutch", "symbol": "nl" },
    { "lang": "Nepali", "symbol": "ne" },
    { "lang": "Norwegian", "symbol": "no" },
    { "lang": "Nyanja", "symbol": "ny" },
    { "lang": "Hate", "symbol": "or" },
    { "lang": "Pashto", "symbol": "ps" },
    { "lang": "Persian", "symbol": "fa" },
    { "lang": "Polish", "symbol": "pl" },
    { "lang": "Portuguese", "symbol": "pt" },
    { "lang": "Punjabi", "symbol": "pa" },
    { "lang": "Romanian", "symbol": "ro" },
    { "lang": "Russian", "symbol": "ru" },
    { "lang": "Samoan", "symbol": "sm" },
    { "lang": "Serbian", "symbol": "sr" },
    { "lang": "Southern", "symbol": "st" },
    { "lang": "Shona", "symbol": "sn" },
    { "lang": "Sindhi", "symbol": "sd" },
    { "lang": "Sinhalese", "symbol": "si" },
    { "lang": "Slovak", "symbol": "sk" },
    { "lang": "Slovenian", "symbol": "sl" },
    { "lang": "Somali", "symbol": "so" },
    { "lang": "Spanish", "symbol": "es" },
    { "lang": "Swedish", "symbol": "sv" },
    { "lang": "Sundanese", "symbol": "su" },
    { "lang": "Swahili", "symbol": "sw" },
    { "lang": "Tajik", "symbol": "tg" },
    { "lang": "Tamil", "symbol": "ta" },
    { "lang": "Tatar", "symbol": "tt" },
    { "lang": "Telugu", "symbol": "te" },
    { "lang": "Thai", "symbol": "th" },
    { "lang": "Turkish", "symbol": "tr" },
    { "lang": "Turkmen", "symbol": "tk" },
    { "lang": "Ukrainian", "symbol": "uk" },
    { "lang": "Uyghur", "symbol": "ug" },
    { "lang": "Urdu", "symbol": "ur" },
    { "lang": "Uzbek", "symbol": "uz" },
    { "lang": "Vietnamese", "symbol": "vi" },
    { "lang": "Xhosa", "symbol": "xh" },
    { "lang": "Yoruba", "symbol": "yo" },
    { "lang": "Zulu", "symbol": "zu" },
  ], regionCodes: [
    {
      "kind": "youtube#i18nRegion",
      "etag": "28OqIZd_GkOlFiUAZQDahAOLdDY",
      "id": "AE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "Eqw-eASTdpiuawBfwq4hynFHUUk",
      "id": "BH"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "RTcgWzogGWzI6-Icw6QwlZ90yDo",
      "id": "DZ"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "O0pgfyoaxZZEVWMZit8SxtfQ4FU",
      "id": "EG"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "Jk7Zl0QDEKAY9zkBUQaGzGrVS2o",
      "id": "IQ"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "KefbVaI7eaBGzf99W3rrIcOzSec",
      "id": "JO"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "rNhi5XvTo9WIPSwroQXK783V2pk",
      "id": "KW"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "Hl6kIR6N9rkmvy4TnZXsIR6QA5E",
      "id": "LB"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "oq8hKB51SBnNFA5gxO9Y-NRvhos",
      "id": "LY"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "XhzY5RfTcanhpz6j1fEIUAudjs8",
      "id": "MA"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "Qv3a1xr8g7tZLEs7tvTACPeLoiM",
      "id": "OM"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "Y2Ta3LrvNU_hITKYylh0fOdm5CY",
      "id": "QA"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "7cP4PN23alhmcO6bTXEIPQiA2Xc",
      "id": "SA"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "LSA4R1TzVFjC8cCDFBLImYySjzA",
      "id": "TN"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "_dYaWFv9t1vlRoO8CAr9nhfyS6M",
      "id": "YE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "GqA5M5QNvnRAcfGs-4uZNuTTf6s",
      "id": "AZ"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "mE3_tc0YHkuEQImq_krMNz5lDBQ",
      "id": "BY"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "O80y4s39dEWEK1AnVcfuiZuLfa8",
      "id": "BG"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "ESXusNswZ5gLR_wXGKaHqyVsEYU",
      "id": "BD"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "v0gIpYV4EbFV9cQ_65CRrHbZcf0",
      "id": "BA"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "utJM_ggTt0sAIwPjxYZGTU3m3As",
      "id": "CZ"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "0bymdhTX8PCB_R3qkm5_OwtNCA0",
      "id": "DK"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "9Ao1-HPlFlQ49GIY_ZP9lX1aF54",
      "id": "AT"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "4nRBAO7Cu35effhvTIwLlXrS3KE",
      "id": "CH"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "vkUjvmBLbDQnis46varvZUIwRkQ",
      "id": "DE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "yPwRE_XVBYrWiG0EPF-WwL_wYU0",
      "id": "GR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "UV20EC3v1iii3nhMsNdXtWN2uWM",
      "id": "AU"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "2UCpKWN9hh_FgDWIgD5twT2ibsc",
      "id": "BE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "bidX9wjLZmKq_2L5Wwn-muKylG8",
      "id": "CA"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "YVw-GWtdiOE_9aH_9ZtDD7Mp1EU",
      "id": "GB"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "EgDs0vIdK2bL48wzKFX-MwKgQDM",
      "id": "GH"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "bSpHmAaE6f_W0Yg9AJZu7DJlH-8",
      "id": "IE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "9rYQz9yyDmbTs1CmxqvIuZsYOIU",
      "id": "IL"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "fzfy91jVCGLqYwRXycYBgXPlDjE",
      "id": "IN"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "eVbD9kMBSbAu4doIXbj7agCoZBA",
      "id": "JM"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "XJMRWauDF3Z2HcHYPr1PSIPvIPc",
      "id": "KE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "5ITvd51gfKQXzMuGBmHlfjRLvrQ",
      "id": "MT"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "2-Dhpgml3xdu5n8loKMarnsLj_k",
      "id": "NG"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "kPRL8mgXtq0BoVVMe6Vby13juDQ",
      "id": "NZ"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "pg-LT8Q8C5fHyjeje_o6KZ3dPPw",
      "id": "SG"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "l8-fSuAd9sZKbH-1YApkEh30iSc",
      "id": "UG"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "dHVoBYarrQzuTtzPcjWu4XhNQjQ",
      "id": "US"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "5SSntZIhCWmlZn49jB1tYGXJzr4",
      "id": "ZA"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "cV1ObtKKA1DLRlsdh5UKU9D2q1g",
      "id": "ZW"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "sTb_pQ_9KToJxcXCZKW_EalMge4",
      "id": "AR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "HdKKFZ0qyZwjYPea9Ap9gUcKsOk",
      "id": "BO"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "5FfCF9i2HmIsJal7nFuz-RkH6ug",
      "id": "CL"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "YyaiMof6UawTMJSBxD-v-7avOYg",
      "id": "CO"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "guAZtwOaTjXo13E074ul7TnwVBs",
      "id": "CR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "2hkj-blLjZf7V3aPCVl5JarHbPM",
      "id": "DO"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "z-_I31zjusbRRXgU033ghEC-YpE",
      "id": "EC"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "19E1oOAfkUogM0H3IwapYF6DwMk",
      "id": "ES"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "1u9L9XEa47tM1fd6ABjov6PRrDI",
      "id": "GT"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "0xabTH91n8sf-jdK7Md22hFCxyk",
      "id": "HN"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "HKAGPa9q8WfyWV-B7mpKeT7jnXg",
      "id": "MX"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "N0SyE2wI9w052c3cc--9MqoMgWA",
      "id": "NI"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "OOH4ii6siKfbl_lRqRZ9zBwptTk",
      "id": "PA"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "JT4If1j8oJCviS6m8GejHxHjOSg",
      "id": "PE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "MM1q6Xt9cjyBOp9zMlr_CSnJZNQ",
      "id": "PR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "SPRnFjTFky-vMDdn2OlhvOfYGJI",
      "id": "PY"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "8VeP4vl_vpW_8derpZQZleF0rf0",
      "id": "SV"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "OkPWUBtGtVHv6nDUFlw0HCsM6C4",
      "id": "UY"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "j6x7KyBkORwPW9LXPzB4AFimrSo",
      "id": "VE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "MEferA1QIPWLGXRlfjU-nHfJBvk",
      "id": "EE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "dLlAQ-zek0coDbgsHUNdNSrKLis",
      "id": "FI"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "znRzoatIQNJAoT0mV-iwBTmWPW8",
      "id": "PH"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "ucr6J4lBo7k0HVq3QER8K9p5Iqo",
      "id": "FR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "CxUxC0mxWVWkxFpqsq6_jY_kUbc",
      "id": "SN"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "qsirtEhRa2d1k1OBlr7-1ZyeQTE",
      "id": "HR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "73a0Y5AyaX_ETfkeNoWIG32ts-8",
      "id": "HU"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "LqpD7WAvy8AB5Ea-paHLh_M3oD0",
      "id": "ID"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "j_Pt10w9PHTPPE-kJeiArkfcDrI",
      "id": "IS"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "p7qab065m6BhpjXdS4lsXqrdB8s",
      "id": "IT"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "osUIa1n4RiGy6-E1NUl6i1_oa1Q",
      "id": "JP"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "SA_SRO0YWjXWODJbQwDMQuzbPNc",
      "id": "GE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "UyK8-8Thbxhi4xgu_a4dJzk-QO4",
      "id": "KZ"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "VHhmhp1S5o6TZImcMsiXmDLZhls",
      "id": "KR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "e8XxiLIbsNbiiorX2-NBPKPY4Wk",
      "id": "LU"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "MxTxkLhYxlsfBZdvwAo63Jes6ds",
      "id": "LT"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "5cKchW0CABWV3jviVDW46lpbRuU",
      "id": "LV"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "Oirr1anncToHcF8M7xs7Q-NiLoM",
      "id": "MK"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "eRNRCAEt7cchVR8ZUZsMf0zGKQ4",
      "id": "MY"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "31DeE_zNAe0khRqMzLDZck3VGMs",
      "id": "NO"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "WRktEi84XssFywIkVM_7kcg4V6A",
      "id": "NP"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "0ZX5SxpV3vroDV7RUL-OESU7Bi0",
      "id": "NL"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "E5hN7R7usLFIZqVDkKrkG1IivcE",
      "id": "PL"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "P75Pmzw4JuDQymp_ZRL5bQDCD58",
      "id": "BR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "UsvdQy21BLBVsdKqZP4z2dsMIbU",
      "id": "PT"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "icWoPTO9Tg4ueqpI9t_wEvavf00",
      "id": "RO"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "M4Hxs-FC0SPGEMFWGMm0neL6kAQ",
      "id": "RU"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "mY6vzrx9ZiE4fo1ZTW517Zyyog8",
      "id": "LK"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "URm8e-ZjFgQt1toLx4-FS2BpF8M",
      "id": "SK"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "SGdKQh4VJWmU81gZOD9Y7MUFjeA",
      "id": "SI"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "W-TmZDS1X-1AGrbTAK1Vu1MbRCA",
      "id": "ME"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "JPL9xkVEV9zF4UqoPOQAW97u7z4",
      "id": "RS"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "GkDUrJ6irWBUpyliIq19hTASIz0",
      "id": "SE"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "mZXEaI_KXKdMG7eRKqSryO5Vq64",
      "id": "TZ"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "X0SSuoEeO9HqFDuwCLkkxD_aJTA",
      "id": "TH"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "yXGhsG0kgasjG_QZCGA-mvI7qFw",
      "id": "TR"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "6kY_JaHK3XIQBE29HF9DER20EQk",
      "id": "UA"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "AJ157jvP1qCK07OVL5JWIn8QYYI",
      "id": "PK"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "ZsRnw_5LKED6xxj0QAEaAQdJ2jQ",
      "id": "VN"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "qgiKXPdRiZ4CrcdK-sy75XyuM0I",
      "id": "HK"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "ICiScMjduNSiINHwIv76sd0LtXI",
      "id": "TW"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "ind5RJ38Mv-pnxHd7XEeiRAeGYk",
      "id": "CY"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "UJc-Y4YXmgF7bda-HyZWkRQYg0c",
      "id": "LI"
    },
    {
      "kind": "youtube#i18nRegion",
      "etag": "gPDAYCwf-iCPP3muZYHuxuPvI3o",
      "id": "PG"
    }
  ],
  langCountry: [{
    "country_name": "Afghanistan",
    "country_code": "93",
    "lang_name": "pashto",
    "country_code_name": "af",
    "lang_code": "ps"
  }, {
    "country_name": "Albania",
    "country_code": "355",
    "lang_name": "albanian",
    "country_code_name": "al",
    "lang_code": "sq"
  }, {
    "country_name": "Algeria",
    "country_code": "213",
    "lang_name": "tamazight (latin)",
    "country_code_name": "dz",
    "lang_code": "tzm"
  }, {
    "country_code": "1684",
    "country_code_name": "as",
    "country_name": "American Samoa"
  }, {
    "country_code": "376",
    "country_code_name": "ad",
    "country_name": "Andorra"
  }, {
    "country_code": "244",
    "country_code_name": "ao",
    "country_name": "Angola"
  }, {
    "country_code": "1264",
    "country_code_name": "ai",
    "country_name": "Anguilla"
  }, {
    "country_code": "1268",
    "country_code_name": "ag",
    "country_name": "Antigua and Barbuda"
  }, {
    "country_name": "Argentina",
    "country_code": "54",
    "lang_name": "spanish",
    "country_code_name": "ar",
    "lang_code": "es"
  }, {
    "country_name": "Armenia",
    "country_code": "374",
    "lang_name": "armenian",
    "country_code_name": "am",
    "lang_code": "hy"
  }, {
    "country_code": "297",
    "country_code_name": "aw",
    "country_name": "Aruba"
  }, {
    "country_name": "Australia",
    "country_code": "61",
    "lang_name": "english",
    "country_code_name": "au",
    "lang_code": "en"
  }, {
    "country_name": "Austria",
    "country_code": "43",
    "lang_name": "german",
    "country_code_name": "at",
    "lang_code": "de"
  }, {
    "country_name": "Azerbaijan",
    "country_code": "994",
    "lang_name": "azeri (latin)",
    "country_code_name": "az",
    "lang_code": "az"
  }, {
    "country_code": "1242",
    "country_code_name": "bs",
    "country_name": "Bahamas"
  }, {
    "country_name": "Bahrain",
    "country_code": "973",
    "lang_name": "arabic",
    "country_code_name": "bh",
    "lang_code": "ar"
  }, {
    "country_name": "Bangladesh",
    "country_code": "880",
    "lang_name": "bengali",
    "country_code_name": "bd",
    "lang_code": "bn"
  }, {
    "country_code": "1246",
    "country_code_name": "bb",
    "country_name": "Barbados"
  }, {
    "country_name": "Belarus",
    "country_code": "375",
    "lang_name": "belarusian",
    "country_code_name": "by",
    "lang_code": "be"
  }, {
    "country_name": "Belgium",
    "country_code": "32",
    "lang_name": "french",
    "country_code_name": "be",
    "lang_code": "fr"
  }, {
    "country_name": "Belize",
    "country_code": "501",
    "lang_name": "english",
    "country_code_name": "bz",
    "lang_code": "en"
  }, {
    "country_code": "229",
    "country_code_name": "bj",
    "country_name": "Benin"
  }, {
    "country_code": "1441",
    "country_code_name": "bm",
    "country_name": "Bermuda"
  }, {
    "country_code": "975",
    "country_code_name": "bt",
    "country_name": "Bhutan"
  }, {
    "country_name": "Bolivia",
    "country_code": "591",
    "lang_name": "spanish",
    "country_code_name": "bo",
    "lang_code": "es"
  }, {
    "country_name": "Bosnia and Herzegovina",
    "country_code": "387",
    "lang_name": "serbian (latin)",
    "country_code_name": "ba",
    "lang_code": "sr"
  }, {
    "country_code": "267",
    "country_code_name": "bw",
    "country_name": "Botswana"
  }, {
    "country_name": "Brazil",
    "country_code": "55",
    "lang_name": "portuguese",
    "country_code_name": "br",
    "lang_code": "pt"
  }, {
    "country_code": "246",
    "country_code_name": "io",
    "country_name": "British Indian Ocean Territory"
  }, {
    "country_code": "1284",
    "country_code_name": "vg",
    "country_name": "British Virgin Islands"
  }, {
    "country_name": "Brunei",
    "country_code": "673",
    "lang_name": "malay",
    "country_code_name": "bn",
    "lang_code": "ms"
  }, {
    "country_name": "Bulgaria",
    "country_code": "359",
    "lang_name": "bulgarian",
    "country_code_name": "bg",
    "lang_code": "bg"
  }, {
    "country_code": "226",
    "country_code_name": "bf",
    "country_name": "Burkina Faso"
  }, {
    "country_code": "95",
    "country_code_name": "mm",
    "country_name": "Burma-Myanmar"
  }, {
    "country_code": "257",
    "country_code_name": "bi",
    "country_name": "Burundi"
  }, {
    "country_name": "Cambodia",
    "country_code": "855",
    "lang_name": "khmer",
    "country_code_name": "kh",
    "lang_code": "km"
  }, {
    "country_code": "237",
    "country_code_name": "cm",
    "country_name": "Cameroon"
  }, {
    "country_name": "Canada",
    "country_code": "1",
    "lang_name": "mohawk",
    "country_code_name": "ca",
    "lang_code": "moh"
  }, {
    "country_code": "238",
    "country_code_name": "cv",
    "country_name": "Cape Verde"
  }, {
    "country_code": "1345",
    "country_code_name": "ky",
    "country_name": "Cayman Islands"
  }, {
    "country_code": "236",
    "country_code_name": "cf",
    "country_name": "Central African Republic"
  }, {
    "country_code": "235",
    "country_code_name": "td",
    "country_name": "Chad"
  }, {
    "country_name": "Chile",
    "country_code": "56",
    "lang_name": "spanish",
    "country_code_name": "cl",
    "lang_code": "es"
  }, {
    "country_name": "China",
    "country_code": "86",
    "lang_name": "yi",
    "country_code_name": "cn",
    "lang_code": "ii"
  }, {
    "country_code": "6189",
    "country_code_name": "cx",
    "country_name": "Christmas Island"
  }, {
    "country_name": "Colombia",
    "country_code": "57",
    "lang_name": "spanish",
    "country_code_name": "co",
    "lang_code": "es"
  }, {
    "country_code": "269",
    "country_code_name": "km",
    "country_name": "Comoros"
  }, {
    "country_code": "242",
    "country_code_name": "cg",
    "country_name": "Congo"
  }, {
    "country_code": "243",
    "country_code_name": "cd",
    "country_name": "Congo (The Democratic Republic)"
  }, {
    "country_code": "682",
    "country_code_name": "ck",
    "country_name": "Cook Islands"
  }, {
    "country_name": "Costa Rica",
    "country_code": "506",
    "lang_name": "spanish",
    "country_code_name": "cr",
    "lang_code": "es"
  }, {
    "country_name": "Croatia",
    "country_code": "385",
    "lang_name": "croatian",
    "country_code_name": "hr",
    "lang_code": "hr"
  }, {
    "country_code": "53",
    "country_code_name": "cu",
    "country_name": "Cuba"
  }, {
    "country_code": "357",
    "country_code_name": "cy",
    "country_name": "Cyprus"
  }, {
    "country_name": "Czech Republic",
    "country_code": "420",
    "lang_name": "czech",
    "country_code_name": "cz",
    "lang_code": "cs"
  }, {
    "country_name": "Denmark",
    "country_code": "45",
    "lang_name": "danish",
    "country_code_name": "dk",
    "lang_code": "da"
  }, {
    "country_code": "253",
    "country_code_name": "dj",
    "country_name": "Djibouti"
  }, {
    "country_code": "1767",
    "country_code_name": "dm",
    "country_name": "Dominica"
  }, {
    "country_name": "Dominican Republic",
    "country_code": "1849",
    "lang_name": "spanish",
    "country_code_name": "do",
    "lang_code": "es"
  }, {
    "country_name": "Dominican Republic",
    "country_code": "1829",
    "lang_name": "spanish",
    "country_code_name": "do",
    "lang_code": "es"
  }, {
    "country_name": "Dominican Republic",
    "country_code": "1809",
    "lang_name": "spanish",
    "country_code_name": "do",
    "lang_code": "es"
  }, {
    "country_code": "670",
    "country_code_name": "tl",
    "country_name": "East Timor"
  }, {
    "country_name": "Ecuador",
    "country_code": "593",
    "lang_name": "spanish",
    "country_code_name": "ec",
    "lang_code": "es"
  }, {
    "country_name": "Egypt",
    "country_code": "20",
    "lang_name": "arabic",
    "country_code_name": "eg",
    "lang_code": "ar"
  }, {
    "country_name": "El Salvador",
    "country_code": "503",
    "lang_name": "spanish",
    "country_code_name": "sv",
    "lang_code": "es"
  }, {
    "country_code": "240",
    "country_code_name": "gq",
    "country_name": "Equatorial Guinea"
  }, {
    "country_code": "291",
    "country_code_name": "er",
    "country_name": "Eritrea"
  }, {
    "country_name": "Estonia",
    "country_code": "372",
    "lang_name": "estonian",
    "country_code_name": "ee",
    "lang_code": "et"
  }, {
    "country_name": "Ethiopia",
    "country_code": "251",
    "lang_name": "amharic",
    "country_code_name": "et",
    "lang_code": "am"
  }, {
    "country_name": "Faroe Islands",
    "country_code": "298",
    "lang_name": "faroese",
    "country_code_name": "fo",
    "lang_code": "fo"
  }, {
    "country_code": "679",
    "country_code_name": "fj",
    "country_name": "Fiji"
  }, {
    "country_name": "Finland",
    "country_code": "358",
    "lang_name": "swedish",
    "country_code_name": "fi",
    "lang_code": "sv"
  }, {
    "country_name": "France",
    "country_code": "33",
    "lang_name": "occitan",
    "country_code_name": "fr",
    "lang_code": "fr"
  }, {
    "country_code": "594",
    "country_code_name": "gf",
    "country_name": "French Guiana"
  }, {
    "country_code": "689",
    "country_code_name": "pf",
    "country_name": "French Polynesia"
  }, {
    "country_code": "241",
    "country_code_name": "ga",
    "country_name": "Gabon"
  }, {
    "country_code": "220",
    "country_code_name": "gm",
    "country_name": "Gambia"
  }, {
    "country_name": "Georgia",
    "country_code": "995",
    "lang_name": "georgian",
    "country_code_name": "ge",
    "lang_code": "ka"
  }, {
    "country_name": "Germany",
    "country_code": "49",
    "lang_name": "upper sorbian",
    "country_code_name": "de",
    "lang_code": "hsb"
  }, {
    "country_code": "233",
    "country_code_name": "gh",
    "country_name": "Ghana"
  }, {
    "country_code": "350",
    "country_code_name": "gi",
    "country_name": "Gibraltar"
  }, {
    "country_name": "Greece",
    "country_code": "30",
    "lang_name": "greek",
    "country_code_name": "gr",
    "lang_code": "el"
  }, {
    "country_name": "Greenland",
    "country_code": "299",
    "lang_name": "greenlandic",
    "country_code_name": "gl",
    "lang_code": "kl"
  }, {
    "country_code": "1473",
    "country_code_name": "gd",
    "country_name": "Grenada"
  }, {
    "country_code": "590",
    "country_code_name": "gp",
    "country_name": "Guadeloupe"
  }, {
    "country_code": "1671",
    "country_code_name": "gu",
    "country_name": "Guam"
  }, {
    "country_name": "Guatemala",
    "country_code": "502",
    "lang_name": "spanish",
    "country_code_name": "gt",
    "lang_code": "es"
  }, {
    "country_code": "224",
    "country_code_name": "gn",
    "country_name": "Guinea"
  }, {
    "country_code": "245",
    "country_code_name": "gw",
    "country_name": "Guinea-Bissau"
  }, {
    "country_code": "592",
    "country_code_name": "gy",
    "country_name": "Guyana"
  }, {
    "country_code": "509",
    "country_code_name": "ht",
    "country_name": "Haiti"
  }, {
    "country_name": "Honduras",
    "country_code": "504",
    "lang_name": "spanish",
    "country_code_name": "hn",
    "lang_code": "es"
  }, {
    "country_name": "Hong Kong",
    "country_code": "852",
    "lang_name": "chinese (traditional) legacy",
    "country_code_name": "hk",
    "lang_code": "zh"
  }, {
    "country_name": "Hungary",
    "country_code": "36",
    "lang_name": "hungarian",
    "country_code_name": "hu",
    "lang_code": "hu"
  }, {
    "country_name": "Iceland",
    "country_code": "354",
    "lang_name": "icelandic",
    "country_code_name": "is",
    "lang_code": "is"
  }, {
    "country_name": "India",
    "country_code": "91",
    "lang_name": "telugu",
    "country_code_name": "in",
    "lang_code": "te"
  }, {
    "country_name": "Indonesia",
    "country_code": "62",
    "lang_name": "indonesian",
    "country_code_name": "id",
    "lang_code": "id"
  }, {
    "country_name": "Iran",
    "country_code": "98",
    "lang_name": "persian",
    "country_code_name": "ir",
    "lang_code": "fa"
  }, {
    "country_name": "Iraq",
    "country_code": "964",
    "lang_name": "arabic",
    "country_code_name": "iq",
    "lang_code": "ar"
  }, {
    "country_name": "Ireland",
    "country_code": "353",
    "lang_name": "irish",
    "country_code_name": "ie",
    "lang_code": "ga"
  }, {
    "country_name": "Israel",
    "country_code": "972",
    "lang_name": "hebrew",
    "country_code_name": "il",
    "lang_code": "he"
  }, {
    "country_name": "Italy",
    "country_code": "39",
    "lang_name": "italian",
    "country_code_name": "it",
    "lang_code": "it"
  }, {
    "country_code": "225",
    "country_code_name": "ci",
    "country_name": "Ivory Coast"
  }, {
    "country_name": "Jamaica",
    "country_code": "1876",
    "lang_name": "english",
    "country_code_name": "jm",
    "lang_code": "en"
  }, {
    "country_name": "Japan",
    "country_code": "81",
    "lang_name": "japanese",
    "country_code_name": "jp",
    "lang_code": "ja"
  }, {
    "country_name": "Jordan",
    "country_code": "962",
    "lang_name": "arabic",
    "country_code_name": "jo",
    "lang_code": "ar"
  }, {
    "country_name": "Kazakhstan",
    "country_code": "7",
    "lang_name": "kazakh",
    "country_code_name": "kz",
    "lang_code": "kk"
  }, {
    "country_name": "Kenya",
    "country_code": "254",
    "lang_name": "kiswahili",
    "country_code_name": "ke",
    "lang_code": "sw"
  }, {
    "country_code": "686",
    "country_code_name": "ki",
    "country_name": "Kiribati"
  }, {
    "country_name": "Kuwait",
    "country_code": "965",
    "lang_name": "arabic",
    "country_code_name": "kw",
    "lang_code": "ar"
  }, {
    "country_name": "Kyrgyzstan",
    "country_code": "996",
    "lang_name": "kyrgyz",
    "country_code_name": "kg",
    "lang_code": "ky"
  }, {
    "country_name": "Laos",
    "country_code": "856",
    "lang_name": "lao",
    "country_code_name": "la",
    "lang_code": "lo"
  }, {
    "country_name": "Latvia",
    "country_code": "371",
    "lang_name": "latvian",
    "country_code_name": "lv",
    "lang_code": "lv"
  }, {
    "country_name": "Lebanon",
    "country_code": "961",
    "lang_name": "arabic",
    "country_code_name": "lb",
    "lang_code": "ar"
  }, {
    "country_code": "266",
    "country_code_name": "ls",
    "country_name": "Lesotho"
  }, {
    "country_code": "231",
    "country_code_name": "lr",
    "country_name": "Liberia"
  }, {
    "country_name": "Libya",
    "country_code": "218",
    "lang_name": "arabic",
    "country_code_name": "ly",
    "lang_code": "ar"
  }, {
    "country_name": "Liechtenstein",
    "country_code": "423",
    "lang_name": "german",
    "country_code_name": "li",
    "lang_code": "de"
  }, {
    "country_name": "Lithuania",
    "country_code": "370",
    "lang_name": "lithuanian",
    "country_code_name": "lt",
    "lang_code": "lt"
  }, {
    "country_name": "Luxembourg",
    "country_code": "352",
    "lang_name": "luxembourgish",
    "country_code_name": "lu",
    "lang_code": "lb"
  }, {
    "country_name": "Macau",
    "country_code": "853",
    "lang_name": "chinese (traditional) legacy",
    "country_code_name": "mo",
    "lang_code": "zh"
  }, {
    "country_name": "Macedonia",
    "country_code": "389",
    "lang_name": "macedonian (fyrom)",
    "country_code_name": "mk",
    "lang_code": "mk"
  }, {
    "country_code": "261",
    "country_code_name": "mg",
    "country_name": "Madagascar"
  }, {
    "country_code": "265",
    "country_code_name": "mw",
    "country_name": "Malawi"
  }, {
    "country_name": "Malaysia",
    "country_code": "60",
    "lang_name": "malay",
    "country_code_name": "my",
    "lang_code": "ms"
  }, {
    "country_name": "Maldives",
    "country_code": "960",
    "lang_name": "divehi",
    "country_code_name": "mv",
    "lang_code": "dv"
  }, {
    "country_code": "223",
    "country_code_name": "ml",
    "country_name": "Mali"
  }, {
    "country_name": "Malta",
    "country_code": "356",
    "lang_name": "maltese",
    "country_code_name": "mt",
    "lang_code": "mt"
  }, {
    "country_code": "692",
    "country_code_name": "mh",
    "country_name": "Marshall Islands"
  }, {
    "country_code": "596",
    "country_code_name": "mq",
    "country_name": "Martinique"
  }, {
    "country_code": "222",
    "country_code_name": "mr",
    "country_name": "Mauritania"
  }, {
    "country_code": "230",
    "country_code_name": "mu",
    "country_name": "Mauritius"
  }, {
    "country_code": "262",
    "country_code_name": "yt",
    "country_name": "Mayotte"
  }, {
    "country_name": "Mexico",
    "country_code": "52",
    "lang_name": "spanish",
    "country_code_name": "mx",
    "lang_code": "es"
  }, {
    "country_code": "373",
    "country_code_name": "md",
    "country_name": "Moldova"
  }, {
    "country_name": "Monaco",
    "country_code": "377",
    "lang_name": "french",
    "country_code_name": "mc",
    "lang_code": "fr"
  }, {
    "country_name": "Mongolia",
    "country_code": "976",
    "lang_name": "mongolian (cyrillic)",
    "country_code_name": "mn",
    "lang_code": "mn"
  }, {
    "country_name": "Montenegro",
    "country_code": "382",
    "lang_name": "serbian (latin)",
    "country_code_name": "me",
    "lang_code": "sr"
  }, {
    "country_code": "1664",
    "country_code_name": "ms",
    "country_name": "Montserrat"
  }, {
    "country_name": "Morocco",
    "country_code": "212",
    "lang_name": "arabic",
    "country_code_name": "ma",
    "lang_code": "ar"
  }, {
    "country_code": "258",
    "country_code_name": "mz",
    "country_name": "Mozambique"
  }, {
    "country_code": "264",
    "country_code_name": "na",
    "country_name": "Namibia"
  }, {
    "country_code": "674",
    "country_code_name": "nr",
    "country_name": "Nauru"
  }, {
    "country_name": "Nepal",
    "country_code": "977",
    "lang_name": "nepali",
    "country_code_name": "np",
    "lang_code": "ne"
  }, {
    "country_name": "Netherlands",
    "country_code": "31",
    "lang_name": "frisian",
    "country_code_name": "nl",
    "lang_code": "fy"
  }, {
    "country_code": "599",
    "country_code_name": "cw",
    "country_name": "Cura\u00e7ao"
  }, {
    "country_code": "687",
    "country_code_name": "nc",
    "country_name": "New Caledonia"
  }, {
    "country_name": "New Zealand",
    "country_code": "64",
    "lang_name": "maori",
    "country_code_name": "nz",
    "lang_code": "mi"
  }, {
    "country_name": "Nicaragua",
    "country_code": "505",
    "lang_name": "spanish",
    "country_code_name": "ni",
    "lang_code": "es"
  }, {
    "country_code": "227",
    "country_code_name": "ne",
    "country_name": "Niger"
  }, {
    "country_name": "Nigeria",
    "country_code": "234",
    "lang_name": "yoruba",
    "country_code_name": "ng",
    "lang_code": "yo"
  }, {
    "country_code": "683",
    "country_code_name": "nu",
    "country_name": "Niue"
  }, {
    "country_code": "672",
    "country_code_name": "nf",
    "country_name": "Norfolk Island"
  }, {
    "country_code": "1670",
    "country_code_name": "mp",
    "country_name": "Northern Mariana Islands"
  }, {
    "country_code": "850",
    "country_code_name": "kp",
    "country_name": "North Korea"
  }, {
    "country_name": "Norway",
    "country_code": "47",
    "lang_name": "sami (southern)",
    "country_code_name": "no",
    "lang_code": "sma"
  }, {
    "country_name": "Oman",
    "country_code": "968",
    "lang_name": "arabic",
    "country_code_name": "om",
    "lang_code": "ar"
  }, {
    "country_name": "Pakistan",
    "country_code": "92",
    "lang_name": "urdu",
    "country_code_name": "pk",
    "lang_code": "ur"
  }, {
    "country_code": "680",
    "country_code_name": "pw",
    "country_name": "Palau"
  }, {
    "country_code": "970",
    "country_code_name": "ps",
    "country_name": "Palestine"
  }, {
    "country_name": "Panama",
    "country_code": "507",
    "lang_name": "spanish",
    "country_code_name": "pa",
    "lang_code": "es"
  }, {
    "country_code": "675",
    "country_code_name": "pg",
    "country_name": "Papua New Guinea"
  }, {
    "country_name": "Paraguay",
    "country_code": "595",
    "lang_name": "spanish",
    "country_code_name": "py",
    "lang_code": "es"
  }, {
    "country_name": "Peru",
    "country_code": "51",
    "lang_name": "spanish",
    "country_code_name": "pe",
    "lang_code": "es"
  }, {
    "country_name": "Philippines",
    "country_code": "63",
    "lang_name": "english",
    "country_code_name": "ph",
    "lang_code": "en"
  }, {
    "country_code": "870",
    "country_code_name": "pn",
    "country_name": "Pitcairn Islands"
  }, {
    "country_name": "Poland",
    "country_code": "48",
    "lang_name": "polish",
    "country_code_name": "pl",
    "lang_code": "pl"
  }, {
    "country_name": "Portugal",
    "country_code": "351",
    "lang_name": "portuguese",
    "country_code_name": "pt",
    "lang_code": "pt"
  }, {
    "country_name": "Puerto Rico",
    "country_code": "1787",
    "lang_name": "spanish",
    "country_code_name": "pr",
    "lang_code": "es"
  }, {
    "country_name": "Qatar",
    "country_code": "974",
    "lang_name": "arabic",
    "country_code_name": "qa",
    "lang_code": "ar"
  }, {
    "country_code": "262",
    "country_code_name": "re",
    "country_name": "R\u00e9union"
  }, {
    "country_name": "Romania",
    "country_code": "40",
    "lang_name": "romanian",
    "country_code_name": "ro",
    "lang_code": "ro"
  }, {
    "country_name": "Russia",
    "country_code": "7",
    "lang_name": "yakut",
    "country_code_name": "ru",
    "lang_code": "sah"
  }, {
    "country_name": "Rwanda",
    "country_code": "250",
    "lang_name": "kinyarwanda",
    "country_code_name": "rw",
    "lang_code": "rw"
  }, {
    "country_code": "290",
    "country_code_name": "sh",
    "country_name": "Saint Helena"
  }, {
    "country_code": "1869",
    "country_code_name": "kn",
    "country_name": "Saint Kitts and Nevis"
  }, {
    "country_code": "1758",
    "country_code_name": "lc",
    "country_name": "Saint Lucia"
  }, {
    "country_code": "1599",
    "country_code_name": "mf",
    "country_name": "Saint Martin"
  }, {
    "country_code": "508",
    "country_code_name": "pm",
    "country_name": "Saint Pierre and Miquelon"
  }, {
    "country_code": "1784",
    "country_code_name": "vc",
    "country_name": "Saint Vincent and the Grenadines"
  }, {
    "country_code": "685",
    "country_code_name": "ws",
    "country_name": "Samoa"
  }, {
    "country_code": "378",
    "country_code_name": "sm",
    "country_name": "San Marino"
  }, {
    "country_code": "239",
    "country_code_name": "st",
    "country_name": "S\u00e3o Tom\u00e9 and Pr\u00edncipe"
  }, {
    "country_name": "Saudi Arabia",
    "country_code": "966",
    "lang_name": "arabic",
    "country_code_name": "sa",
    "lang_code": "ar"
  }, {
    "country_name": "Senegal",
    "country_code": "221",
    "lang_name": "wolof",
    "country_code_name": "sn",
    "lang_code": "wo"
  }, {
    "country_name": "Serbia",
    "country_code": "381",
    "lang_name": "serbian (latin)",
    "country_code_name": "rs",
    "lang_code": "sr"
  }, {
    "country_code": "248",
    "country_code_name": "sc",
    "country_name": "Seychelles"
  }, {
    "country_code": "500",
    "country_code_name": "fk",
    "country_name": "Falkland Islands"
  }, {
    "country_code": "232",
    "country_code_name": "sl",
    "country_name": "Sierra Leone"
  }, {
    "country_name": "Singapore",
    "country_code": "65",
    "lang_name": "english",
    "country_code_name": "sg",
    "lang_code": "en"
  }, {
    "country_name": "Slovakia",
    "country_code": "421",
    "lang_name": "slovak",
    "country_code_name": "sk",
    "lang_code": "sk"
  }, {
    "country_name": "Slovenia",
    "country_code": "386",
    "lang_name": "slovenian",
    "country_code_name": "si",
    "lang_code": "sl"
  }, {
    "country_code": "677",
    "country_code_name": "sb",
    "country_name": "Solomon Islands"
  }, {
    "country_code": "252",
    "country_code_name": "so",
    "country_name": "Somalia"
  }, {
    "country_name": "South Africa",
    "country_code": "27",
    "lang_name": "setswana",
    "country_code_name": "za",
    "lang_code": "tn"
  }, {
    "country_name": "South Korea",
    "country_code": "82",
    "lang_name": "korean",
    "country_code_name": "kr",
    "lang_code": "ko"
  }, {
    "country_code": "211",
    "country_code_name": "ss",
    "country_name": "South Sudan"
  }, {
    "country_name": "Spain",
    "country_code": "34",
    "lang_name": "spanish",
    "country_code_name": "es",
    "lang_code": "es"
  }, {
    "country_name": "Sri Lanka",
    "country_code": "94",
    "lang_name": "sinhala",
    "country_code_name": "lk",
    "lang_code": "si"
  }, {
    "country_code": "249",
    "country_code_name": "sd",
    "country_name": "Sudan"
  }, {
    "country_code": "597",
    "country_code_name": "sr",
    "country_name": "Suriname"
  }, {
    "country_code": "268",
    "country_code_name": "sz",
    "country_name": "Swaziland"
  }, {
    "country_name": "Sweden",
    "country_code": "46",
    "lang_name": "swedish",
    "country_code_name": "se",
    "lang_code": "sv"
  }, {
    "country_name": "Switzerland",
    "country_code": "41",
    "lang_name": "romansh",
    "country_code_name": "ch",
    "lang_code": "rm"
  }, {
    "country_name": "Syria",
    "country_code": "963",
    "lang_name": "syriac",
    "country_code_name": "sy",
    "lang_code": "syr"
  }, {
    "country_name": "Taiwan",
    "country_code": "886",
    "lang_name": "chinese (traditional) legacy",
    "country_code_name": "tw",
    "lang_code": "zh"
  }, {
    "country_name": "Tajikistan",
    "country_code": "992",
    "lang_name": "tajik (cyrillic)",
    "country_code_name": "tj",
    "lang_code": "tg"
  }, {
    "country_code": "255",
    "country_code_name": "tz",
    "country_name": "Tanzania"
  }, {
    "country_name": "Thailand",
    "country_code": "66",
    "lang_name": "thai",
    "country_code_name": "th",
    "lang_code": "th"
  }, {
    "country_code": "228",
    "country_code_name": "tg",
    "country_name": "Togo"
  }, {
    "country_code": "690",
    "country_code_name": "tk",
    "country_name": "Tokelau"
  }, {
    "country_code": "676",
    "country_code_name": "to",
    "country_name": "Tonga"
  }, {
    "country_name": "Trinidad and Tobago",
    "country_code": "1868",
    "lang_name": "english",
    "country_code_name": "tt",
    "lang_code": "en"
  }, {
    "country_name": "Tunisia",
    "country_code": "216",
    "lang_name": "arabic",
    "country_code_name": "tn",
    "lang_code": "ar"
  }, {
    "country_name": "Turkey",
    "country_code": "90",
    "lang_name": "turkish",
    "country_code_name": "tr",
    "lang_code": "tr"
  }, {
    "country_name": "Turkmenistan",
    "country_code": "993",
    "lang_name": "turkmen",
    "country_code_name": "tm",
    "lang_code": "tk"
  }, {
    "country_code": "1649",
    "country_code_name": "tc",
    "country_name": "Turks and Caicos Islands"
  }, {
    "country_code": "688",
    "country_code_name": "tv",
    "country_name": "Tuvalu"
  }, {
    "country_code": "256",
    "country_code_name": "ug",
    "country_name": "Uganda"
  }, {
    "country_name": "United Kingdom",
    "country_code": "44",
    "lang_name": "welsh",
    "country_code_name": "gb",
    "lang_code": "cy"
  }, {
    "country_name": "Ukraine",
    "country_code": "380",
    "lang_name": "ukrainian",
    "country_code_name": "ua",
    "lang_code": "uk"
  }, {
    "country_name": "United Arab Emirates",
    "country_code": "971",
    "lang_name": "arabic",
    "country_code_name": "ae",
    "lang_code": "ar"
  }, {
    "country_name": "Uruguay",
    "country_code": "598",
    "lang_name": "spanish",
    "country_code_name": "uy",
    "lang_code": "es"
  }, {
    "country_name": "United States",
    "country_code": "1",
    "lang_name": "english",
    "country_code_name": "us",
    "lang_code": "en"
  }, {
    "country_name": "Uzbekistan",
    "country_code": "998",
    "lang_name": "uzbek (latin)",
    "country_code_name": "uz",
    "lang_code": "uz"
  }, {
    "country_code": "678",
    "country_code_name": "vu",
    "country_name": "Vanuatu"
  }, {
    "country_name": "Venezuela",
    "country_code": "58",
    "lang_name": "spanish",
    "country_code_name": "ve",
    "lang_code": "es"
  }, {
    "country_name": "Vietnam",
    "country_code": "84",
    "lang_name": "vietnamese",
    "country_code_name": "vn",
    "lang_code": "vi"
  }, {
    "country_code": "1340",
    "country_code_name": "vi",
    "country_name": "Virgin Islands"
  }, {
    "country_code": "681",
    "country_code_name": "wf",
    "country_name": "Wallis and Futuna"
  }, {
    "country_name": "Yemen",
    "country_code": "967",
    "lang_name": "arabic",
    "country_code_name": "ye",
    "lang_code": "ar"
  }, {
    "country_code": "260",
    "country_code_name": "zm",
    "country_name": "Zambia"
  }, {
    "country_name": "Zimbabwe",
    "country_code": "263",
    "lang_name": "english",
    "country_code_name": "zw",
    "lang_code": "en"
  }]
};
