import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, ActivityIndicator } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from "../redux/store/app-provider";
import ItemsList from "../components/items-list";
import navigationService from "../services/navigation-service";
import { API } from "aws-amplify";
import VideoClip from "../components/video-clip";
import { withTranslation } from "react-i18next";

interface State {

}

interface Props {
  t?: any
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

class ChannelClipsScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  onSelectVideo = (videoItem: any) => {
    //this.context.gotoVideo(videoItem)
    this.props.onPress && this.props.onPress(videoItem);
  }
  getRelatedClips = async (token: string) => {
    const channelId = this.context.state.currentChannelId
    //console.log("received token", token)
    const queryStringParameters: any = { channelId, nextToken: token };
    queryStringParameters.lang = this.context.state.selectedLanguage;
    let videos = await API.get('ginierestapi', '/rest/channel-videos', {
      queryStringParameters
    })
    videos.items = videos.videoItems;
    this.context.setVideosTimes(videos.items.map((video: any) => {
      return video.videoId;
    }))
    delete videos.videoItems;
    return videos;
  }

  render() {

    const renderYoutubeVideoItem = ({ item, index }: any) => {
      return (
        <VideoClip item={item} index={index} videoTime={this.context.state.currentVideoTimes?.[item.id?.videoId || item.id]} onPress={this.onSelectVideo.bind(this)}></VideoClip>
      )
    }
    return (
      <View style={[style.container, { padding: 10, paddingBottom: 0 }]}>
        <View style={{ width: '100%', maxWidth: 400, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <ItemsList cache={true} nextTokenName={'nextToken'} fn={this.getRelatedClips} renderItem={renderYoutubeVideoItem} stateKey={'channelList'} stateItem={this.context.state.channelList}/>
        </View>

      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});
export default withTranslation()(ChannelClipsScreen);

