import { Entypo, Feather, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { AsyncStorage, Dimensions, Platform, SafeAreaView } from 'react-native';
import {
  NativeStackHeaderProps,
  NativeStackNavigationOptions,
  NativeStackNavigationProp
} from '@react-navigation/native-stack';
import { ParamListBase, Route } from '@react-navigation/routers';
import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from '../services/navigation-service';
import { withTranslation } from 'react-i18next';
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { AppContext, IContext } from "../redux/store/app-provider";
import NavigationService from "../services/navigation-service";

type Props = {
  back?: {
    title: string;
  },
  title: string,
  t: any,
  options: NativeStackNavigationOptions,
  route: Route<string>;
  navigation: NativeStackNavigationProp<ParamListBase>,
  user: any,
  userPicture: any,
  app: any
}

interface State {
  orientation: string
}

class Header extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  title: string = "";

  constructor(props: any) {
    super(props);
    this.state = { orientation: 'portrait' }
    this.title = props.route.name

  }

  gotoProfile() {

    if (!this.context.state.user) {
      this.context.setScreen("Sign In", true);
      this.context.logEvent("sign_in");
    } else {
      SheetManager.show(`user_menu`);
    }

  }

  gotoHome(header?: boolean) {
    this.context.setScreen("Home", {});
    NavigationService.resetRoutes();
    NavigationService.props.setHome = true;
  }

  goBack() {
    //navigationService.pop();

    this.gotoHome()
    console.log("go home")
  }

  goHome() {
    this.context.setScreen("Home", {});
  }

  goBackBack() {
    console.log("go back")
    if (!navigationService.props.setHome) {
      this.gotoHome(true)
    } else {
      navigationService.pop();
    }

  }

  componentDidMount() {

  }

  openMenu() {
    SheetManager.show("menu_sheet");
  }

  gotoAnalytics() {

  }

  render() {

    return (
      <SafeAreaView>
        <View style={[{
          height: 70,
          width: '100%',
          marginTop: 0,
          display: !this.context.state.isPortrait && this.context.state.isMobile && !this.context.state.isTablet ? "none" : "flex",
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: colors.backgroundColor,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0
        }]}>

          <View style={[{ flex: 1, height: '100%' }, style.row, style.vcenter, style.hleft]}>
            {/*{this.props.options.title !== 'Video' && this.context.state.isTablet ?
              <View style={[style.row, style.vcenter, style.hcenter, style.fullSize, { position: 'absolute' }]}>
                <Feather name="play-circle" style={{}} size={34} color={colors.orange}/>
              </View> : null}*/}
            <Entypo.Button size={18} style={{
              width: 20,
              padding: 0,
              marginLeft: 10
            }} name="dots-three-vertical" color={'#fff'} backgroundColor="#000000" onPress={this.openMenu.bind(this)}>

            </Entypo.Button>

            {this.context.state.firstScreen === 'MobileVideo' ? <Entypo.Button size={18} style={{
                width: 30,
                height: '100%',
                padding: 0,
                marginLeft: 5
              }} name="home" backgroundColor={colors.backgroundColor} color={'#fff'} onPress={this.goHome.bind(this)}></Entypo.Button> :
              this.title !== 'Home' ? <FontAwesome.Button size={18} style={{
                width: 30,
                height: '100%',
                padding: 0,
                marginLeft: 5
              }} name="arrow-left" backgroundColor={colors.backgroundColor} color={'#fff'} onPress={this.goBackBack.bind(this)}>

              </FontAwesome.Button> : null}
            <Text numberOfLines={1} style={{
              color: '#fff',
              marginBottom: 2,
              maxWidth: '80%',
              fontSize: 20
            }}>{this.props.options.title === 'Video' ? this.context.state.currentVideoInfo?.snippet.title : this.props.t(this.props.options.title)}</Text>
            {/* {this.props.options.title !== 'Video' ?
              <View style={{
                flex: 1,

                justifyContent: 'center',
                alignItems: 'center',
              }}><Feather name="play-circle" style={{}} size={34} color={colors.orange}/></View> : null}*/}
          </View>


          <View style={[{ height: '100%', paddingRight: 10 }, style.row, style.vcenter, style.hright]}>


            <TouchableOpacity onPress={this.gotoProfile.bind(this)} style={[style.userButton, style.hright, { alignItems: 'center' }]}>
              {this.context.state.userPicture && this.context.state.user ?
                <Image style={{ resizeMode: 'cover', width: '100%', height: '100%' }} source={{
                  uri: this.context.state.userPicture
                }}></Image> : this.context.state.user ? <Text style={{
                    color: colors.textColor,
                    marginBottom: 6
                  }}>{this.context.state.user.firstName.charAt(0) + this.context.state.user.lastName.charAt(0)}</Text> :
                  <FontAwesome style={{ marginBottom: 5 }} size={20} color={colors.textColor} name='user'></FontAwesome>}
            </TouchableOpacity>
          </View>

        </View>

      </SafeAreaView>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.primaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default withTranslation()(Header);
