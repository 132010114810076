import Amplify, { API } from "aws-amplify";
import React from "react";
import { ActivityIndicator, View } from "react-native";
import config from './../aws-exports';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  HatenaIcon,
  HatenaShareButton,
  HatenaShareCount,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  OKShareCount,
  PinterestIcon,
  PinterestShareButton,
  PinterestShareCount,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TumblrShareCount,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  VKShareCount,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton
} from "react-share";
import style from "../constants/style";
import navigationService from "../services/navigation-service";

export interface Props {
  onShare: any
  lang?: string
  picture?: string
  title?: string
  videoId: string

}

export interface State {
  isBusy: boolean
}

class Share extends React.PureComponent<Props, State> {
  state = { isBusy: false }

  onSharePost(target?: string, url?: string, event?: any) {
    this.props.onShare();

    /* console.log("event", event)
    event.nativeEvent.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    switch (target) {
        case 'fb':

            var obj = {
                method: 'feed',
                link: url,
                picture: image,
                name: 'A Title for Feed Dialog',
                caption: 'Some Caption for the URL',
                description: 'A description for the URL which is to be displayed'
            };

            //Calling the Facebook API : Important
            window["FB"].ui(obj);

            break;
    } */
    switch (target) {
      case 'mess':
        window.open('fb-messenger://share?link=' + encodeURIComponent(url || ''));
        break;
    }

  }

  async setTitle() {
    /*  config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3000/"
     Amplify.configure(config); */
    let title

    /* if (this.props.lang && this.props.lang !== "en") {
        this.setState({ isBusy: true })
        const values = await Promise.all([API.get('ginierestapi', '/rest/translate-to-lang', {
            queryStringParameters: {
                text: this.props.title,
                lang: this.props.lang
            }
        }), API.get('ginierestapi', '/rest/translate-to-lang', {
            queryStringParameters: {
                text: this.props.description,
                lang: this.props.lang
            }
        })])
        title = values[0].result;
        const description = values[1].result;
        this.setState({ title, description, isBusy: false })

    } */

  }

  componentDidMount() {
    this.setTitle();
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.lang !== prevProps.lang) {
      this.setTitle();
    }
  }

  render() {
    /*  let title = this.state.description || this.props.description;
     let titleShort = this.state.title || this.props.title;

     const exampleImage = this.props.picture;
*/
    const title = this.props.title;
    //console.log("langu", this.props.lang)
    const exampleImage = this.props.picture;
    const lang = this.props.lang ? `&lang=${this.props.lang}` : '';
    const shareUrl = `https://preview.youvi.io/preview?vid=${this.props.videoId}${lang}`
    console.log("shareUrl", shareUrl)
    //return;
    const busyIndicator = () => {
      if (this.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color="#ffffff"/>
      }
    }
    return (
      <View style={style.fullSize}>
        {!this.state.isBusy ? <View style={[style.fullSize, {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
          direction: "ltr"
        }]}>
          <View style={style.shareButton}>

            {/*  <FacebookIcon onClick={this.onSharePost.bind(this, "fb", shareUrl)} size={40} round /> */}
            <FacebookShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                                 url={shareUrl}


            >
              <FacebookIcon size={40} round/>
            </FacebookShareButton>

          </View>

          <View style={style.shareButton}>
            {/* <FacebookMessengerShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                    url={shareUrl}
                    appId="521270401588372"
                    style={style.shareButtonIcon}
                >
                    <FacebookMessengerIcon size={40} round />
                </FacebookMessengerShareButton> */}
            <FacebookMessengerIcon onClick={this.onSharePost.bind(this, "mess", shareUrl)} size={40} round/>
          </View>

          <View style={style.shareButton}>
            <TwitterShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                                url={shareUrl}
                                title={title}
                                style={style.shareButtonIcon}
            >
              <TwitterIcon size={40} round/>
            </TwitterShareButton>


          </View>

          <View style={style.shareButton}>
            <TelegramShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                                 url={shareUrl}
                                 title={title}
                                 style={style.shareButtonIcon}
            >
              <TelegramIcon size={40} round/>
            </TelegramShareButton>


          </View>

          <View style={style.shareButton}>
            <WhatsappShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                                 url={shareUrl}
                                 title={title}
                                 separator=":: "
                                 style={style.shareButtonIcon}
            >
              <WhatsappIcon size={40} round/>
            </WhatsappShareButton>


          </View>

          <View style={style.shareButton}>
            <LinkedinShareButton title={title} source={shareUrl} onClick={this.onSharePost.bind(this, "", shareUrl)} url={shareUrl} style={style.shareButtonIcon}>
              <LinkedinIcon size={40} round/>
            </LinkedinShareButton>
          </View>

          <View style={style.shareButton}>
            <PinterestShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                                  url={String(window.location)}
                                  media={`${exampleImage}`}
                                  style={style.shareButtonIcon}
            >
              <PinterestIcon size={40} round/>
            </PinterestShareButton>

          </View>

          {/* <View style={style.shareButton}>
                        <VKShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                            url={shareUrl}
                            image={`${String(window.location)}/${exampleImage}`}
                            style={style.shareButtonIcon}
                        >
                            <VKIcon size={40} round />
                        </VKShareButton>


                    </View> */}

          <View style={style.shareButton}>
            <OKShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                           url={shareUrl}
                           image={`${String(window.location)}/${exampleImage}`}
                           style={style.shareButtonIcon}
            >
              <OKIcon size={40} round/>
            </OKShareButton>


          </View>

          <View style={style.shareButton}>
            <RedditShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                               url={shareUrl}
                               title={title}
                               windowWidth={660}
                               windowHeight={460}
                               style={style.shareButtonIcon}
            >
              <RedditIcon size={40} round/>
            </RedditShareButton>


          </View>

          <View style={style.shareButton}>
            <TumblrShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                               url={shareUrl}
                               title={title}
                               style={style.shareButtonIcon}
            >
              <TumblrIcon size={40} round/>
            </TumblrShareButton>


          </View>

          <View style={style.shareButton}>
            <LivejournalShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                                    url={shareUrl}
                                    title={title}
                                    description={shareUrl}
                                    style={style.shareButtonIcon}
            >
              <LivejournalIcon size={40} round/>
            </LivejournalShareButton>
          </View>

          {/* <View style={style.shareButton}>
                        <MailruShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                            url={shareUrl}
                            title={title}
                            style={style.shareButtonIcon}
                        >
                            <MailruIcon size={40} round />
                        </MailruShareButton>
                    </View> */}

          {/*  <View style={style.shareButton}>
                        <EmailShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                            url={shareUrl}
                            subject={title}
                            body={shareUrl}
                            style={style.shareButtonIcon}
                        >
                            <EmailIcon size={40} round />
                        </EmailShareButton>
                    </View> */}
          <View style={style.shareButton}>
            <ViberShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                              url={shareUrl}
                              title={title}
                              style={style.shareButtonIcon}
            >
              <ViberIcon size={40} round/>
            </ViberShareButton>
          </View>

          <View style={style.shareButton}>
            <WorkplaceShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                                  url={shareUrl}
                                  quote={title}
                                  style={style.shareButtonIcon}
            >
              <WorkplaceIcon size={40} round/>
            </WorkplaceShareButton>
          </View>

          <View style={style.shareButton}>
            <LineShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                             url={shareUrl}
                             title={title}
                             style={style.shareButtonIcon}
            >
              <LineIcon size={40} round/>
            </LineShareButton>
          </View>

          <View style={style.shareButton}>
            <WeiboShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                              url={shareUrl}
                              title={title}
                              image={`${String(window.location)}/${exampleImage}`}
                              style={style.shareButtonIcon}
            >
              <WeiboIcon size={40} round/>
            </WeiboShareButton>
          </View>

          <View style={style.shareButton}>
            <PocketShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                               url={shareUrl}
                               title={title}
                               style={style.shareButtonIcon}
            >
              <PocketIcon size={40} round/>
            </PocketShareButton>
          </View>

          <View style={style.shareButton}>
            <InstapaperShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                                   url={shareUrl}
                                   title={title}
                                   style={style.shareButtonIcon}
            >
              <InstapaperIcon size={40} round/>
            </InstapaperShareButton>
          </View>

          <View style={style.shareButton}>
            <HatenaShareButton onClick={this.onSharePost.bind(this, "", shareUrl)}
                               url={shareUrl}
                               title={title}
                               windowWidth={660}
                               windowHeight={460}
                               style={style.shareButtonIcon}
            >
              <HatenaIcon size={40} round/>
            </HatenaShareButton>


          </View>
        </View> : null}
        {busyIndicator()}
      </View>);
  }
}

export default Share;
