/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yya2fntovnhyvnfkej7gjj77uu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7oand2y3lrdpdg7eza7r2pjxbm",
    "aws_cloud_logic_custom": [
        {
            "name": "ginierestapi",
            "endpoint": "https://bqu4xsworg.execute-api.us-east-1.amazonaws.com/mainprod",
            "region": "us-east-1"
        },
        {
            "name": "previewapi",
            "endpoint": "https://rzwu930pxk.execute-api.us-east-1.amazonaws.com/mainprod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:bfee6a7c-a62e-49ed-a6c7-1828cb80e530",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_t8v7S2EnQ",
    "aws_user_pools_web_client_id": "7sternb0ik4nr6nfl1hldrd26h",
    "oauth": {
        "domain": "geniereactnative-mainprod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/,genie://,https://www.youvi.io/",
        "redirectSignOut": "http://localhost:19006/,genie://,genie://,genie://,https://www.youvi.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
