/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      picture
      channels
      email
      firstName
      lastName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      picture
      channels
      email
      firstName
      lastName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      picture
      channels
      email
      firstName
      lastName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserVideoTime = /* GraphQL */ `
  mutation CreateUserVideoTime(
    $input: CreateUserVideoTimeInput!
    $condition: ModelUserVideoTimeConditionInput
  ) {
    createUserVideoTime(input: $input, condition: $condition) {
      id
      videoId
      userEmail
      videoDuration
      currentTime
      updated
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserVideoTime = /* GraphQL */ `
  mutation UpdateUserVideoTime(
    $input: UpdateUserVideoTimeInput!
    $condition: ModelUserVideoTimeConditionInput
  ) {
    updateUserVideoTime(input: $input, condition: $condition) {
      id
      videoId
      userEmail
      videoDuration
      currentTime
      updated
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserVideoTime = /* GraphQL */ `
  mutation DeleteUserVideoTime(
    $input: DeleteUserVideoTimeInput!
    $condition: ModelUserVideoTimeConditionInput
  ) {
    deleteUserVideoTime(input: $input, condition: $condition) {
      id
      videoId
      userEmail
      videoDuration
      currentTime
      updated
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createShareClip = /* GraphQL */ `
  mutation CreateShareClip(
    $input: CreateShareClipInput!
    $condition: ModelShareClipConditionInput
  ) {
    createShareClip(input: $input, condition: $condition) {
      id
      videoId
      seenByIps
      createdAt
      updatedAt
    }
  }
`;
export const updateShareClip = /* GraphQL */ `
  mutation UpdateShareClip(
    $input: UpdateShareClipInput!
    $condition: ModelShareClipConditionInput
  ) {
    updateShareClip(input: $input, condition: $condition) {
      id
      videoId
      seenByIps
      createdAt
      updatedAt
    }
  }
`;
export const deleteShareClip = /* GraphQL */ `
  mutation DeleteShareClip(
    $input: DeleteShareClipInput!
    $condition: ModelShareClipConditionInput
  ) {
    deleteShareClip(input: $input, condition: $condition) {
      id
      videoId
      seenByIps
      createdAt
      updatedAt
    }
  }
`;
export const createFastClips = /* GraphQL */ `
  mutation CreateFastClips(
    $input: CreateFastClipsInput!
    $condition: ModelFastClipsConditionInput
  ) {
    createFastClips(input: $input, condition: $condition) {
      id
      videoUrl
      type
      previewUrl
      description
      commentCount
      playCount
      shareCount
      addedTime
      timeRef
      creationTime
      createdAt
      updatedAt
    }
  }
`;
export const updateFastClips = /* GraphQL */ `
  mutation UpdateFastClips(
    $input: UpdateFastClipsInput!
    $condition: ModelFastClipsConditionInput
  ) {
    updateFastClips(input: $input, condition: $condition) {
      id
      videoUrl
      type
      previewUrl
      description
      commentCount
      playCount
      shareCount
      addedTime
      timeRef
      creationTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteFastClips = /* GraphQL */ `
  mutation DeleteFastClips(
    $input: DeleteFastClipsInput!
    $condition: ModelFastClipsConditionInput
  ) {
    deleteFastClips(input: $input, condition: $condition) {
      id
      videoUrl
      type
      previewUrl
      description
      commentCount
      playCount
      shareCount
      addedTime
      timeRef
      creationTime
      createdAt
      updatedAt
    }
  }
`;
export const createUnsubcribedEmails = /* GraphQL */ `
  mutation CreateUnsubcribedEmails(
    $input: CreateUnsubcribedEmailsInput!
    $condition: ModelUnsubcribedEmailsConditionInput
  ) {
    createUnsubcribedEmails(input: $input, condition: $condition) {
      id
      emails
      createdAt
      updatedAt
    }
  }
`;
export const updateUnsubcribedEmails = /* GraphQL */ `
  mutation UpdateUnsubcribedEmails(
    $input: UpdateUnsubcribedEmailsInput!
    $condition: ModelUnsubcribedEmailsConditionInput
  ) {
    updateUnsubcribedEmails(input: $input, condition: $condition) {
      id
      emails
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnsubcribedEmails = /* GraphQL */ `
  mutation DeleteUnsubcribedEmails(
    $input: DeleteUnsubcribedEmailsInput!
    $condition: ModelUnsubcribedEmailsConditionInput
  ) {
    deleteUnsubcribedEmails(input: $input, condition: $condition) {
      id
      emails
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      score
      title
      type
      numViews
      numShares
      time
      videoImage
      creatorId
      lang
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      score
      title
      type
      numViews
      numShares
      time
      videoImage
      creatorId
      lang
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      score
      title
      type
      numViews
      numShares
      time
      videoImage
      creatorId
      lang
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTrendingList = /* GraphQL */ `
  mutation CreateTrendingList(
    $input: CreateTrendingListInput!
    $condition: ModelTrendingListConditionInput
  ) {
    createTrendingList(input: $input, condition: $condition) {
      id
      videoList
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTrendingList = /* GraphQL */ `
  mutation UpdateTrendingList(
    $input: UpdateTrendingListInput!
    $condition: ModelTrendingListConditionInput
  ) {
    updateTrendingList(input: $input, condition: $condition) {
      id
      videoList
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTrendingList = /* GraphQL */ `
  mutation DeleteTrendingList(
    $input: DeleteTrendingListInput!
    $condition: ModelTrendingListConditionInput
  ) {
    deleteTrendingList(input: $input, condition: $condition) {
      id
      videoList
      createdAt
      updatedAt
      owner
    }
  }
`;
