import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  ActivityIndicator,
  Platform,
  FlatList, RefreshControl
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from "../redux/store/app-provider";
import { AntDesign, Entypo, FontAwesome, FontAwesome5 } from "@expo/vector-icons";

interface State {

}

interface Props {
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

class SubscriptionsScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {
    this.context.getUserChannels();
  }

  onSelectChannel = (channel: any) => {
    //this.context.gotoVideo(videoItem)
    //this.props.onPress && this.props.onPress(videoItem);
    console.log("onSelectChannel", channel)
    this.context.setAppProps({ currentChannelId: channel.id })
    this.context.setScreen("Channel", {})
  }

  render() {
    const busyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator}/>
      }
    }
    const renderYoutubeVideoItem = ({ item, index }: any) => {
      return (

        <TouchableOpacity style={{ width: '100%' }} onPress={() => {
          this.onSelectChannel(item)
        }}>

          <View style={{
            width: '100%',
            marginBottom: 10,
            borderRadius: colors.borderRadius,
            borderWidth: 1,
            padding: 0,
            alignItems: 'center',
            overflow: 'hidden'
          }}>

            <Image blurRadius={0} style={{
              width: '100%',
              height: 260,
              marginTop: 0,
              marginBottom: 0
            }} source={{ uri: item.avatar.thumbnails[0].url }}/>

            <View style={{
              width: '100%',
              marginTop: 0,
              position: 'absolute',
              borderRadius: colors.borderRadius,
              borderTopEndRadius: 0,
              borderTopStartRadius: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
              left: 0,
              bottom: 0,
              padding: 15,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',

            }}>
              <Text style={[{
                color: 'white',
                fontSize: 14,
                fontWeight: 'bold',
                width: '100%',
                padding: 0,
                marginTop: 0,
                textAlign: 'left'
              }]}>{item.title}</Text>
              {/* Display Text component to show post createdAt date property as date and time formatted */}
              <View style={[style.row, style.fullWidth, style.vcenter, {
                marginTop: 5,
                padding: 5,
                paddingLeft: 0,
                paddingBottom: 0
              }]}>

                {/* <FontAwesome style={{
                  marginRight: 10,
                  display: item.viewCountText ? "flex" : "none"
                }} size={15} color={colors.textColor} name="eye"></FontAwesome>
                <Text style={{
                  color: colors.textColor,
                  fontWeight: 'bold',
                  marginRight: 10,
                  fontSize: 12,
                  display: item.viewCountText ? "flex" : "none"
                }}>{item.viewCountText}</Text>*/}

                <AntDesign name="like2" style={{
                  marginRight: 10,
                  display: item.subscriberCountText ? "flex" : "none"
                }} size={15} color={colors.textColor}/>
                <Text style={{
                  color: colors.textColor,
                  marginRight: 10,
                  fontWeight: 'bold',
                  fontSize: 12,
                  display: item.subscriberCountText ? "flex" : "none"
                }}>{item.subscriberCountText}</Text>


              </View>

            </View>

          </View>

        </TouchableOpacity>

      )
    }
    return (
      <View style={[style.container, { padding: 10, paddingBottom: 0 }]}>
        <View style={{ width: '100%', maxWidth: 400, height: '100%', display: 'flex', flexDirection: 'column' }}>
          {this.context.state.userChannels ? <FlatList horizontal={false} contentContainerStyle={{
            width: '100%',
            flexDirection: 'column'
          }} data={this.context.state.userChannels} style={{
            flex: 1,
            width: '100%',
            marginBottom: 10
          }} renderItem={renderYoutubeVideoItem} keyExtractor={(item) => item.title}
          /> : null}
        </View>
        {busyIndicator()}
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default SubscriptionsScreen;
