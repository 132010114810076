import { StyleSheet } from 'react-native';
import colors from './colors';

export default StyleSheet.create({
  busyIndicator: {
    position: 'absolute',
    marginLeft: -16,
    marginTop: -16,
    top: '50%',
    left: '50%'
  },
  absoluteCenter: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    textAlign: 'center'
  },
  blur: {
    color: "#fff0",
    textShadowColor: "rgba(255,255,255,0.5)",
    textShadowOffset: {
      width: 1,
      height: 1,
    },
    textShadowRadius: 8,
    fontSize: 16,
    fontWeight: "600",
  },
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  container: {
    flex: 1,
    height: '100%',
    padding: 20,
    backgroundColor: '#000000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  page: {
    flex: 1,
    padding: 5,
    width: '100%',
    maxWidth: 400,
    backgroundColor: colors.backgroundColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadow: {
    shadowColor: '#000000',
    shadowOffset: { width: 5, height: 5 },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 0.1
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  textInput: {
    backgroundColor: colors.grey,
    padding: 14,
    fontSize: 18,
    borderRadius: colors.borderRadius,
    color: '#000',
    borderWidth: 0,
  },
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  fullSize: {
    width: '100%',
    height: '100%'
  },
  shareButton: {
    width: 50,
    marginRight: 12,
    height: 50
  },
  shareButtonIcon: {
    width: 50,
    height: 50
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  shadowButton: {
    shadowColor: '#000000',
    shadowOffset: { width: 3, height: 9 },
    shadowRadius: 11,
    elevation: 11,
    shadowOpacity: 0.4
  },
  vcenter: {
    alignItems: 'center'
  },
  vcenterself: {
    alignSelf: 'center'
  },
  hcenter: {
    justifyContent: 'center'
  },
  hright: {
    justifyContent: 'flex-end'
  },
  hleft: {
    justifyContent: 'flex-start'
  },
  userButton: {
    overflow: 'hidden',
    width: 34,
    height: 34,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 34,
    borderColor: '#fff',
    borderWidth: 2,
    backgroundColor: colors.thirdColor,
  }
});
