import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Platform } from "react-native";
import env from "../constants/env";
import { getLocales } from 'react-native-localize';

//const labels = env.labels;

import { I18n } from 'aws-amplify';
import navigationService from "./navigation-service";

const lang17 = {
  "en": {
    "translation": {
      "Fast": "Fast",
      "Play 30 seconds Ad": "Play 30 seconds Ad",
      "This video can be played only on YouVi mobile app": "This video can be played only on YouVi mobile app"
    }
  },
  "lv": {
    "translation": {
      "Fast": "Ātri",
      "This video can be played only on YouVi mobile app": "Šo videoklipu var atskaņot tikai mobilajā lietotnē YouVi",
      "Play 30 seconds Ad": "Atskaņot 30 sekunžu reklāmu"
    }
  },
  "sl": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Ta video je mogoče predvajati samo v mobilni aplikaciji YouVi",
      "Play 30 seconds Ad": "Predvajaj 30 sekundni oglas",
      "Fast": "hitro"
    }
  },
  "af": {
    "translation": {
      "Play 30 seconds Ad": "Speel 30 sekondes Ad",
      "This video can be played only on YouVi mobile app": "Hierdie video kan slegs op YouVi-mobiele toepassing gespeel word",
      "Fast": "Vinnig"
    }
  },
  "pt": {
    "translation": {
      "Play 30 seconds Ad": "Reproduzir anúncio de 30 segundos",
      "Fast": "Velozes",
      "This video can be played only on YouVi mobile app": "Este vídeo pode ser reproduzido apenas no aplicativo móvel YouVi"
    }
  },
  "es": {
    "translation": {
      "Fast": "Rápido",
      "Play 30 seconds Ad": "Reproducir anuncio de 30 segundos",
      "This video can be played only on YouVi mobile app": "Este video solo se puede reproducir en la aplicación móvil YouVi"
    }
  },
  "uk": {
    "translation": {
      "Fast": "швидко",
      "Play 30 seconds Ad": "Відтворити рекламу 30 секунд",
      "This video can be played only on YouVi mobile app": "Це відео можна відтворити лише в мобільному додатку YouVi"
    }
  },
  "is": {
    "translation": {
      "Fast": "Hratt",
      "Play 30 seconds Ad": "Spilaðu 30 sekúndna auglýsingu",
      "This video can be played only on YouVi mobile app": "Þetta myndband er aðeins hægt að spila í YouVi farsímaforritinu"
    }
  },
  "id": {
    "translation": {
      "Play 30 seconds Ad": "Mainkan Iklan 30 detik",
      "Fast": "Cepat",
      "This video can be played only on YouVi mobile app": "Video ini hanya dapat diputar di aplikasi seluler YouVi"
    }
  },
  "zh": {
    "translation": {
      "Fast": "快速地",
      "Play 30 seconds Ad": "播放 30 秒广告",
      "This video can be played only on YouVi mobile app": "此视频只能在 YouVi 移动应用程序上播放"
    }
  },
  "nl": {
    "translation": {
      "Fast": "Snel",
      "Play 30 seconds Ad": "Speel 30 seconden advertentie",
      "This video can be played only on YouVi mobile app": "Deze video kan alleen worden afgespeeld op de YouVi mobiele app"
    }
  },
  "ro": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Acest videoclip poate fi redat numai pe aplicația mobilă YouVi",
      "Play 30 seconds Ad": "Redați reclamă de 30 de secunde",
      "Fast": "Rapid"
    }
  },
  "hi": {
    "translation": {
      "Fast": "तेज़",
      "Play 30 seconds Ad": "30 सेकंड का विज्ञापन चलाएं",
      "This video can be played only on YouVi mobile app": "यह वीडियो केवल यूवीआई मोबाइल एप पर चलाया जा सकता है"
    }
  },
  "mt": {
    "translation": {
      "Fast": "Fast",
      "Play 30 seconds Ad": "Play 30 sekonda Ad",
      "This video can be played only on YouVi mobile app": "Dan il-video jista' jintlagħab biss fuq l-app mobbli YouVi"
    }
  },
  "sq": {
    "translation": {
      "Fast": "Shpejt",
      "This video can be played only on YouVi mobile app": "Kjo video mund të luhet vetëm në aplikacionin celular YouVi",
      "Play 30 seconds Ad": "Luaj reklamën 30 sekonda"
    }
  },
  "ne": {
    "translation": {
      "Fast": "छिटो",
      "Play 30 seconds Ad": "३० सेकेन्डको विज्ञापन प्ले गर्नुहोस्",
      "This video can be played only on YouVi mobile app": "यो भिडियो YouVi मोबाइल एपमा मात्र प्ले गर्न सकिन्छ"
    }
  },
  "el": {
    "translation": {
      "Fast": "Γρήγορα",
      "This video can be played only on YouVi mobile app": "Αυτό το βίντεο μπορεί να αναπαραχθεί μόνο στην εφαρμογή YouVi για κινητά",
      "Play 30 seconds Ad": "Αναπαραγωγή διαφήμισης 30 δευτερολέπτων"
    }
  },
  "am": {
    "translation": {
      "Play 30 seconds Ad": "የ30 ሰከንድ ማስታወቂያ አጫውት።",
      "This video can be played only on YouVi mobile app": "ይህ ቪዲዮ በYouVi ሞባይል መተግበሪያ ላይ ብቻ መጫወት ይችላል።",
      "Fast": "ፈጣን"
    }
  },
  "ga": {
    "translation": {
      "Fast": "Go tapa",
      "Play 30 seconds Ad": "Seinn 30 soicind Ad",
      "This video can be played only on YouVi mobile app": "Ní féidir an físeán seo a imirt ach ar aip shoghluaiste YouVi"
    }
  },
  "mg": {
    "translation": {
      "Fast": "fifadian-kanina",
      "This video can be played only on YouVi mobile app": "Ity horonantsary ity dia tsy azo alefa amin'ny fampiharana finday YouVi ihany",
      "Play 30 seconds Ad": "Milalao 30 segondra Ad"
    }
  },
  "cs": {
    "translation": {
      "Fast": "Rychle",
      "Play 30 seconds Ad": "Přehrát 30 sekundovou reklamu",
      "This video can be played only on YouVi mobile app": "Toto video lze přehrát pouze v mobilní aplikaci YouVi"
    }
  },
  "bs": {
    "translation": {
      "Play 30 seconds Ad": "Pusti 30 sekundi Ad",
      "Fast": "Brzo",
      "This video can be played only on YouVi mobile app": "Ovaj video se može reproducirati samo na YouVi mobilnoj aplikaciji"
    }
  },
  "lt": {
    "translation": {
      "Play 30 seconds Ad": "Groti 30 sekundžių reklamą",
      "This video can be played only on YouVi mobile app": "Šį vaizdo įrašą galima leisti tik „YouVi“ programėlėje mobiliesiems",
      "Fast": "Greitas"
    }
  },
  "sr": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Овај видео се може пуштати само у мобилној апликацији ИоуВи",
      "Fast": "Фаст",
      "Play 30 seconds Ad": "Пусти оглас од 30 секунди"
    }
  },
  "et": {
    "translation": {
      "Fast": "Kiire",
      "Play 30 seconds Ad": "Esita 30 sekundit reklaami",
      "This video can be played only on YouVi mobile app": "Seda videot saab esitada ainult YouVi mobiilirakenduses"
    }
  },
  "ru": {
    "translation": {
      "Fast": "Быстро",
      "This video can be played only on YouVi mobile app": "Это видео можно воспроизвести только в мобильном приложении YouVi.",
      "Play 30 seconds Ad": "Воспроизвести 30-секундную рекламу"
    }
  },
  "it": {
    "translation": {
      "Fast": "Veloce",
      "Play 30 seconds Ad": "Riproduci annuncio di 30 secondi",
      "This video can be played only on YouVi mobile app": "Questo video può essere riprodotto solo sull'app mobile YouVi"
    }
  },
  "da": {
    "translation": {
      "Fast": "Hurtig",
      "Play 30 seconds Ad": "Afspil 30 sekunder Ad",
      "This video can be played only on YouVi mobile app": "Denne video kan kun afspilles på YouVi-mobilappen"
    }
  },
  "hr": {
    "translation": {
      "Fast": "Brzo",
      "This video can be played only on YouVi mobile app": "Ovaj video se može reproducirati samo na YouVi mobilnoj aplikaciji",
      "Play 30 seconds Ad": "Pusti oglas od 30 sekundi"
    }
  },
  "sm": {
    "translation": {
      "Fast": "Anapogi",
      "Play 30 seconds Ad": "Ta'alo 30 sekone Ad",
      "This video can be played only on YouVi mobile app": "O lenei vitio e mafai ona ta na'o le YouVi mobile app"
    }
  },
  "co": {
    "translation": {
      "Fast": "Rapidu",
      "This video can be played only on YouVi mobile app": "Stu video pò esse ghjucatu solu nantu à l'app mobile YouVi",
      "Play 30 seconds Ad": "Ghjucà 30 seconde Ad"
    }
  },
  "hy": {
    "translation": {
      "Play 30 seconds Ad": "Խաղալ 30 վայրկյան գովազդ",
      "Fast": "Արագ",
      "This video can be played only on YouVi mobile app": "Այս տեսանյութը կարելի է նվագարկել միայն YouVi բջջային հավելվածում"
    }
  },
  "sk": {
    "translation": {
      "Play 30 seconds Ad": "Prehrať 30-sekundovú reklamu",
      "Fast": "Rýchlo",
      "This video can be played only on YouVi mobile app": "Toto video je možné prehrať iba v mobilnej aplikácii YouVi"
    }
  },
  "pl": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Ten film można odtwarzać tylko w aplikacji mobilnej YouVi",
      "Fast": "Szybko",
      "Play 30 seconds Ad": "Odtwórz 30-sekundową reklamę"
    }
  },
  "tr": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Bu video yalnızca YouVi mobil uygulamasında oynatılabilir",
      "Fast": "Hızlı",
      "Play 30 seconds Ad": "30 saniye reklamı oynat"
    }
  },
  "haw": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Hiki ke hoʻokani ʻia kēia wikiō ma ka polokalamu kelepona ʻo YouVi wale nō",
      "Play 30 seconds Ad": "Pāʻani 30 kekona Hoʻolaha",
      "Fast": "wikiwiki"
    }
  },
  "fr": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Cette vidéo ne peut être lue que sur l'application mobile YouVi",
      "Fast": "Vite",
      "Play 30 seconds Ad": "Diffusez 30 secondes d'annonce"
    }
  },
  "mk": {
    "translation": {
      "Fast": "Брзо",
      "This video can be played only on YouVi mobile app": "Ова видео може да се репродуцира само на мобилната апликација YouVi",
      "Play 30 seconds Ad": "Пушти реклама од 30 секунди"
    }
  },
  "bg": {
    "translation": {
      "Play 30 seconds Ad": "Пусни 30 секунди реклама",
      "Fast": "Бърз",
      "This video can be played only on YouVi mobile app": "Това видео може да се възпроизвежда само в мобилното приложение YouVi"
    }
  },
  "de": {
    "translation": {
      "Fast": "Schnell",
      "Play 30 seconds Ad": "30 Sekunden Anzeige abspielen",
      "This video can be played only on YouVi mobile app": "Dieses Video kann nur auf der mobilen YouVi-App abgespielt werden"
    }
  },
  "ja": {
    "translation": {
      "Fast": "速い",
      "Play 30 seconds Ad": "広告を 30 秒間再生する",
      "This video can be played only on YouVi mobile app": "この動画はYouViモバイルアプリでのみ再生できます"
    }
  },
  "ml": {
    "translation": {
      "Fast": "വേഗം",
      "This video can be played only on YouVi mobile app": "YouVi മൊബൈൽ ആപ്പിൽ മാത്രമേ ഈ വീഡിയോ പ്ലേ ചെയ്യാനാകൂ",
      "Play 30 seconds Ad": "30 സെക്കൻഡ് പരസ്യം പ്ലേ ചെയ്യുക"
    }
  },
  "sv": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Den här videon kan endast spelas upp på YouVis mobilapp",
      "Play 30 seconds Ad": "Spela 30 sekunders annons",
      "Fast": "Snabb"
    }
  },
  "vi": {
    "translation": {
      "Play 30 seconds Ad": "Phát quảng cáo 30 giây",
      "Fast": "Nhanh",
      "This video can be played only on YouVi mobile app": "Video này chỉ có thể phát trên ứng dụng di động YouVi"
    }
  },
  "ar": {
    "translation": {
      "Fast": "سريع",
      "This video can be played only on YouVi mobile app": "يمكن تشغيل هذا الفيديو على تطبيق YouVi للهاتف المحمول فقط",
      "Play 30 seconds Ad": "العب 30 ثانية Ad"
    }
  },
  "ko": {
    "translation": {
      "This video can be played only on YouVi mobile app": "이 동영상은 YouVi 모바일 앱에서만 재생할 수 있습니다.",
      "Play 30 seconds Ad": "30초 광고 재생",
      "Fast": "빠른"
    }
  },
  "iw": {
    "translation": {
      "Fast": "מָהִיר",
      "This video can be played only on YouVi mobile app": "ניתן להפעיל את הסרטון הזה רק באפליקציית YouVi לנייד",
      "Play 30 seconds Ad": "הפעל מודעה של 30 שניות"
    }
  },
  "no": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Denne videoen kan bare spilles av på YouVi-mobilappen",
      "Fast": "Rask",
      "Play 30 seconds Ad": "Spill 30 sekunders annonse"
    }
  },
  "fi": {
    "translation": {
      "Fast": "Nopeasti",
      "Play 30 seconds Ad": "Toista 30 sekunnin mainos",
      "This video can be played only on YouVi mobile app": "Tätä videota voi toistaa vain YouVi-mobiilisovelluksessa"
    }
  },
  "ca": {
    "translation": {
      "This video can be played only on YouVi mobile app": "Aquest vídeo només es pot reproduir a l'aplicació mòbil YouVi",
      "Play 30 seconds Ad": "Juga l'anunci de 30 segons",
      "Fast": "Ràpid"
    }
  },
  "hu": {
    "translation": {
      "Play 30 seconds Ad": "30 másodperces hirdetés lejátszása",
      "This video can be played only on YouVi mobile app": "Ez a videó csak a YouVi mobilalkalmazáson játszható le",
      "Fast": "Gyors"
    }
  }
}
const lang16 = {
  "en": {
    "translation": {
      "I have a suggestion": "I have a suggestion",
      "Something is not working": "Something is not working",
      "Other": "Other"
    }
  },
  "mk": {
    "translation": {
      "I have a suggestion": "Имам предлог",
      "Other": "Друго",
      "Something is not working": "Нешто не функционира"
    }
  },
  "fr": {
    "translation": {
      "Other": "Autre",
      "I have a suggestion": "J'ai une suggestion",
      "Something is not working": "Quelque chose ne fonctionne pas"
    }
  },
  "pl": {
    "translation": {
      "Something is not working": "Coś nie działa",
      "I have a suggestion": "Mam propozycję",
      "Other": "Inny"
    }
  },
  "hu": {
    "translation": {
      "Other": "Egyéb",
      "I have a suggestion": "Van egy javaslatom",
      "Something is not working": "Valami nem működik"
    }
  },
  "mg": {
    "translation": {
      "Other": "Hafa",
      "I have a suggestion": "Manana soso-kevitra aho",
      "Something is not working": "Misy zavatra tsy mandeha"
    }
  },
  "sk": {
    "translation": {
      "Other": "Iné",
      "I have a suggestion": "Mám návrh",
      "Something is not working": "Niečo nefunguje"
    }
  },
  "haw": {
    "translation": {
      "Other": "'ē aʻe",
      "Something is not working": "ʻAʻole hana kekahi mea",
      "I have a suggestion": "He manaʻo koʻu"
    }
  },
  "id": {
    "translation": {
      "I have a suggestion": "Saya punya saran",
      "Other": "Lainnya",
      "Something is not working": "Ada yang tidak berfungsi"
    }
  },
  "ga": {
    "translation": {
      "I have a suggestion": "Tá moladh agam",
      "Something is not working": "Níl rud éigin ag obair",
      "Other": "Eile"
    }
  },
  "sm": {
    "translation": {
      "Something is not working": "E i ai se mea e le o galue",
      "I have a suggestion": "E i ai sa'u fautuaga",
      "Other": "Isi"
    }
  },
  "sq": {
    "translation": {
      "Something is not working": "Diçka nuk po funksionon",
      "I have a suggestion": "Unë kam një sugjerim",
      "Other": "Të tjera"
    }
  },
  "lt": {
    "translation": {
      "I have a suggestion": "Turiu pasiūlymą",
      "Other": "Kita",
      "Something is not working": "Kažkas neveikia"
    }
  },
  "af": {
    "translation": {
      "Something is not working": "Iets werk nie",
      "Other": "Ander",
      "I have a suggestion": "Ek het 'n voorstel"
    }
  },
  "bs": {
    "translation": {
      "Something is not working": "Nešto ne radi",
      "Other": "Ostalo",
      "I have a suggestion": "Imam prijedlog"
    }
  },
  "ca": {
    "translation": {
      "Other": "Altres",
      "Something is not working": "Alguna cosa no funciona",
      "I have a suggestion": "Tinc un suggeriment"
    }
  },
  "iw": {
    "translation": {
      "I have a suggestion": "יש לי הצעה",
      "Other": "אַחֵר",
      "Something is not working": "משהו לא עובד"
    }
  },
  "da": {
    "translation": {
      "I have a suggestion": "Jeg har et forslag",
      "Something is not working": "Noget virker ikke",
      "Other": "Andet"
    }
  },
  "fi": {
    "translation": {
      "Other": "Muut",
      "Something is not working": "Jokin ei toimi",
      "I have a suggestion": "minulla on ehdotus"
    }
  },
  "nl": {
    "translation": {
      "Something is not working": "Er werkt iets niet",
      "I have a suggestion": "ik heb een suggestie",
      "Other": "Ander"
    }
  },
  "vi": {
    "translation": {
      "Other": "Khác",
      "Something is not working": "Một cái gì đó không hoạt động",
      "I have a suggestion": "tôi có một gợi ý"
    }
  },
  "ru": {
    "translation": {
      "I have a suggestion": "У меня есть предложение",
      "Other": "Другой",
      "Something is not working": "Что-то не работает"
    }
  },
  "el": {
    "translation": {
      "I have a suggestion": "Έχω μία πρόταση",
      "Other": "Αλλα",
      "Something is not working": "Κάτι δεν λειτουργεί"
    }
  },
  "pt": {
    "translation": {
      "Something is not working": "Algo não está funcionando",
      "I have a suggestion": "Eu tenho uma sugestão",
      "Other": "Outro"
    }
  },
  "lv": {
    "translation": {
      "Something is not working": "Kaut kas nedarbojas",
      "I have a suggestion": "Man ir ieteikums",
      "Other": "Cits"
    }
  },
  "bg": {
    "translation": {
      "I have a suggestion": "Имам едно предложение",
      "Other": "други",
      "Something is not working": "Нещо не работи"
    }
  },
  "ar": {
    "translation": {
      "Other": "آخر",
      "I have a suggestion": "لدي اقتراح",
      "Something is not working": "شيء ما لا يعمل"
    }
  },
  "ro": {
    "translation": {
      "Other": "Alte",
      "I have a suggestion": "Am o sugestie",
      "Something is not working": "Ceva nu funcționează"
    }
  },
  "de": {
    "translation": {
      "Other": "Sonstiges",
      "I have a suggestion": "Ich habe einen Vorschlag",
      "Something is not working": "Etwas funktioniert nicht"
    }
  },
  "ne": {
    "translation": {
      "Other": "अन्य",
      "Something is not working": "केहि काम गरिरहेको छैन",
      "I have a suggestion": "मेरो एउटा सुझाव छ"
    }
  },
  "sv": {
    "translation": {
      "Other": "Övrig",
      "I have a suggestion": "jag har ett förslag",
      "Something is not working": "Något fungerar inte"
    }
  },
  "hy": {
    "translation": {
      "Something is not working": "Ինչ-որ բան չի աշխատում",
      "I have a suggestion": "Ես մի առաջարկ ունեմ",
      "Other": "Այլ"
    }
  },
  "is": {
    "translation": {
      "Something is not working": "Eitthvað er ekki að virka",
      "I have a suggestion": "Ég er með tillögu",
      "Other": "Annað"
    }
  },
  "am": {
    "translation": {
      "Something is not working": "የሆነ ነገር እየሰራ አይደለም።",
      "Other": "ሌላ",
      "I have a suggestion": "ሀሳብ አለኝ"
    }
  },
  "it": {
    "translation": {
      "I have a suggestion": "ho un suggerimento",
      "Something is not working": "Qualcosa non funziona",
      "Other": "Altro"
    }
  },
  "no": {
    "translation": {
      "I have a suggestion": "jeg har et forslag",
      "Other": "Annen",
      "Something is not working": "Noe fungerer ikke"
    }
  },
  "uk": {
    "translation": {
      "Other": "Інший",
      "Something is not working": "Щось не працює",
      "I have a suggestion": "У мене є пропозиція"
    }
  },
  "cs": {
    "translation": {
      "Something is not working": "Něco nefunguje",
      "I have a suggestion": "mám návrh",
      "Other": "jiný"
    }
  },
  "ml": {
    "translation": {
      "Something is not working": "എന്തോ പ്രവർത്തിക്കുന്നില്ല",
      "I have a suggestion": "എനിക്ക് ഒരു നിർദ്ദേശമുണ്ട്",
      "Other": "മറ്റുള്ളവ"
    }
  },
  "hi": {
    "translation": {
      "Something is not working": "कुछ काम नहीं कर रहा है",
      "Other": "अन्य",
      "I have a suggestion": "मेरे पास एक सुझाव है"
    }
  },
  "zh": {
    "translation": {
      "Other": "其他",
      "I have a suggestion": "我有个建议",
      "Something is not working": "有些东西不工作"
    }
  },
  "ko": {
    "translation": {
      "Other": "다른",
      "I have a suggestion": "나는 제안이있다",
      "Something is not working": "뭔가 작동하지 않습니다"
    }
  },
  "et": {
    "translation": {
      "Other": "muud",
      "I have a suggestion": "Mul on ettepanek",
      "Something is not working": "Midagi ei tööta"
    }
  },
  "sl": {
    "translation": {
      "Other": "drugo",
      "I have a suggestion": "Imam predlog",
      "Something is not working": "Nekaj ne deluje"
    }
  },
  "mt": {
    "translation": {
      "I have a suggestion": "Għandi suġġeriment",
      "Other": "Oħrajn",
      "Something is not working": "Xi ħaġa mhix qed taħdem"
    }
  },
  "co": {
    "translation": {
      "Something is not working": "Qualcosa ùn hè micca travagliatu",
      "I have a suggestion": "Aghju un suggerimentu",
      "Other": "Altru"
    }
  },
  "sr": {
    "translation": {
      "Other": "Остало",
      "Something is not working": "Нешто не ради",
      "I have a suggestion": "Имам предлог"
    }
  },
  "tr": {
    "translation": {
      "I have a suggestion": "bir önerim var",
      "Something is not working": "Bir şey çalışmıyor",
      "Other": "Başka"
    }
  },
  "es": {
    "translation": {
      "Something is not working": "algo no funciona",
      "Other": "Otro",
      "I have a suggestion": "tengo una sugerencia"
    }
  },
  "ja": {
    "translation": {
      "I have a suggestion": "提案があります",
      "Other": "他の",
      "Something is not working": "何かが機能していません"
    }
  },
  "hr": {
    "translation": {
      "Other": "ostalo",
      "Something is not working": "Nešto ne radi",
      "I have a suggestion": "Imam prijedlog"
    }
  }
}
const lang13 = {
  "en": {
    "translation": {
      "Feedback subject": "Feedback subject",
      "Feedback sent": "Feedback sent",
      "Feedback message": "Feedback message",
      "Send": "Send",
      "Feedback": "Feedback"
    }
  },
  "sv": {
    "translation": {
      "Send": "Skicka",
      "Feedback sent": "Feedback har skickats",
      "Feedback subject": "Feedback ämne",
      "Feedback": "Respons",
      "Feedback message": "Feedbackmeddelande"
    }
  },
  "bs": {
    "translation": {
      "Feedback subject": "Predmet povratne informacije",
      "Feedback message": "Povratna poruka",
      "Send": "Pošalji",
      "Feedback sent": "Povratne informacije su poslane",
      "Feedback": "Povratne informacije"
    }
  },
  "co": {
    "translation": {
      "Feedback sent": "Feedback mandatu",
      "Send": "Mandate",
      "Feedback subject": "Sugettu di feedback",
      "Feedback": "Feedback",
      "Feedback message": "Missaghju di feedback"
    }
  },
  "af": {
    "translation": {
      "Feedback": "Terugvoer",
      "Feedback subject": "Terugvoer onderwerp",
      "Feedback message": "Terugvoerboodskap",
      "Feedback sent": "Terugvoer gestuur",
      "Send": "Stuur"
    }
  },
  "cs": {
    "translation": {
      "Feedback message": "Zpětná vazba",
      "Feedback sent": "Zpětná vazba byla odeslána",
      "Feedback subject": "Předmět zpětné vazby",
      "Send": "Poslat",
      "Feedback": "Zpětná vazba"
    }
  },
  "no": {
    "translation": {
      "Feedback subject": "Tilbakemeldingsemne",
      "Send": "Sende",
      "Feedback sent": "Tilbakemelding sendt",
      "Feedback": "Tilbakemelding",
      "Feedback message": "Tilbakemeldingsmelding"
    }
  },
  "ca": {
    "translation": {
      "Feedback": "Feedback",
      "Feedback message": "Missatge de comentaris",
      "Send": "Enviar",
      "Feedback sent": "Comentaris enviats",
      "Feedback subject": "Tema de comentaris"
    }
  },
  "pt": {
    "translation": {
      "Send": "Mandar",
      "Feedback": "Comentários",
      "Feedback sent": "Feedback enviado",
      "Feedback subject": "Assunto do feedback",
      "Feedback message": "Mensagem de feedback"
    }
  },
  "bg": {
    "translation": {
      "Send": "Изпратете",
      "Feedback message": "Съобщение за обратна връзка",
      "Feedback": "Обратна връзка",
      "Feedback subject": "Тема на обратната връзка",
      "Feedback sent": "Отзивите са изпратени"
    }
  },
  "vi": {
    "translation": {
      "Feedback": "Nhận xét",
      "Feedback sent": "Đã gửi phản hồi",
      "Feedback message": "Thông báo phản hồi",
      "Feedback subject": "chủ đề phản hồi",
      "Send": "Gửi"
    }
  },
  "de": {
    "translation": {
      "Feedback subject": "Feedback-Thema",
      "Feedback": "Feedback",
      "Send": "Senden",
      "Feedback sent": "Rückmeldung gesendet",
      "Feedback message": "Feedback-Nachricht"
    }
  },
  "ml": {
    "translation": {
      "Send": "അയക്കുക",
      "Feedback subject": "ഫീഡ്ബാക്ക് വിഷയം",
      "Feedback sent": "ഫീഡ്ബാക്ക് അയച്ചു",
      "Feedback": "പ്രതികരണം",
      "Feedback message": "പ്രതികരണ സന്ദേശം"
    }
  },
  "mg": {
    "translation": {
      "Feedback message": "Hafatra tamberina",
      "Feedback subject": "Lohahevitra fanehoan-kevitra",
      "Feedback sent": "Feedback nalefa",
      "Send": "Alefaso",
      "Feedback": "Feedback"
    }
  },
  "haw": {
    "translation": {
      "Feedback subject": "Kumuhana pane",
      "Send": "Hoʻouna",
      "Feedback message": "Memo manaʻo",
      "Feedback": "Manaʻo manaʻo",
      "Feedback sent": "Hoʻouna ʻia nā manaʻo"
    }
  },
  "pl": {
    "translation": {
      "Feedback sent": "Wysłano opinię",
      "Feedback message": "Wiadomość zwrotna",
      "Feedback subject": "Temat opinii",
      "Send": "Wysłać",
      "Feedback": "Informacja zwrotna"
    }
  },
  "et": {
    "translation": {
      "Feedback subject": "Tagasiside teema",
      "Feedback sent": "Tagasiside saadetud",
      "Send": "Saada",
      "Feedback message": "Tagasiside sõnum",
      "Feedback": "Tagasiside"
    }
  },
  "hu": {
    "translation": {
      "Feedback message": "Visszajelzési üzenet",
      "Feedback": "Visszacsatolás",
      "Feedback subject": "Visszajelzés tárgya",
      "Send": "Küld",
      "Feedback sent": "Visszajelzés elküldve"
    }
  },
  "lv": {
    "translation": {
      "Send": "Sūtīt",
      "Feedback": "Atsauksmes",
      "Feedback message": "Atsauksmes ziņojums",
      "Feedback sent": "Atsauksmes nosūtītas",
      "Feedback subject": "Atsauksmes priekšmets"
    }
  },
  "sk": {
    "translation": {
      "Feedback sent": "Spätná väzba bola odoslaná",
      "Feedback": "Spätná väzba",
      "Feedback message": "Správa spätnej väzby",
      "Send": "Odoslať",
      "Feedback subject": "Predmet spätnej väzby"
    }
  },
  "el": {
    "translation": {
      "Feedback subject": "Θέμα ανατροφοδότησης",
      "Feedback": "Ανατροφοδότηση",
      "Send": "Στείλετε",
      "Feedback message": "Μήνυμα ανατροφοδότησης",
      "Feedback sent": "Τα σχόλια στάλθηκαν"
    }
  },
  "sq": {
    "translation": {
      "Send": "Dërgo",
      "Feedback subject": "Tema e komenteve",
      "Feedback": "Feedback",
      "Feedback message": "Mesazh reagimi",
      "Feedback sent": "Komentet u dërguan"
    }
  },
  "iw": {
    "translation": {
      "Feedback sent": "נשלח משוב",
      "Send": "לִשְׁלוֹחַ",
      "Feedback subject": "נושא המשוב",
      "Feedback": "מָשׁוֹב",
      "Feedback message": "הודעת משוב"
    }
  },
  "sl": {
    "translation": {
      "Feedback message": "Povratno sporočilo",
      "Send": "Pošlji",
      "Feedback sent": "Povratne informacije so poslane",
      "Feedback subject": "Predmet povratne informacije",
      "Feedback": "Povratne informacije"
    }
  },
  "lt": {
    "translation": {
      "Feedback sent": "Atsiliepimai išsiųsti",
      "Feedback message": "Atsiliepimo žinutė",
      "Feedback subject": "Atsiliepimo tema",
      "Feedback": "Atsiliepimas",
      "Send": "Siųsti"
    }
  },
  "hr": {
    "translation": {
      "Feedback": "Povratne informacije",
      "Send": "Poslati",
      "Feedback sent": "Povratne informacije su poslane",
      "Feedback message": "Poruka povratne informacije",
      "Feedback subject": "Predmet povratne informacije"
    }
  },
  "fi": {
    "translation": {
      "Feedback sent": "Palaute lähetetty",
      "Send": "Lähettää",
      "Feedback message": "Palauteviesti",
      "Feedback": "Palaute",
      "Feedback subject": "Palautteen aihe"
    }
  },
  "ja": {
    "translation": {
      "Feedback": "フィードバック",
      "Feedback sent": "フィードバックを送信しました",
      "Send": "送信",
      "Feedback message": "フィードバック メッセージ",
      "Feedback subject": "フィードバックの件名"
    }
  },
  "id": {
    "translation": {
      "Send": "Kirim",
      "Feedback message": "Pesan umpan balik",
      "Feedback": "Masukan",
      "Feedback subject": "Subjek umpan balik",
      "Feedback sent": "Umpan balik dikirim"
    }
  },
  "ar": {
    "translation": {
      "Feedback": "استجابة",
      "Feedback subject": "موضوع الملاحظات",
      "Send": "إرسال",
      "Feedback sent": "تم إرسال التعليقات",
      "Feedback message": "رسالة ملاحظات"
    }
  },
  "am": {
    "translation": {
      "Feedback sent": "ግብረ መልስ ተልኳል።",
      "Feedback": "ግብረ መልስ",
      "Send": "ላክ",
      "Feedback subject": "የግብረመልስ ርዕሰ ጉዳይ",
      "Feedback message": "የግብረመልስ መልእክት"
    }
  },
  "fr": {
    "translation": {
      "Feedback": "Retour d'information",
      "Feedback message": "Message de rétroaction",
      "Feedback sent": "Commentaires envoyés",
      "Send": "Envoyer",
      "Feedback subject": "Objet du commentaire"
    }
  },
  "zh": {
    "translation": {
      "Feedback sent": "已发送反馈",
      "Feedback message": "反馈留言",
      "Send": "发送",
      "Feedback": "反馈",
      "Feedback subject": "反馈主题"
    }
  },
  "hi": {
    "translation": {
      "Feedback": "प्रतिपुष्टि",
      "Feedback message": "प्रतिक्रिया संदेश",
      "Feedback subject": "प्रतिक्रिया विषय",
      "Send": "भेजना",
      "Feedback sent": "प्रतिक्रिया भेजी गई"
    }
  },
  "tr": {
    "translation": {
      "Send": "Göndermek",
      "Feedback subject": "Geri bildirim konusu",
      "Feedback message": "Geri bildirim mesajı",
      "Feedback": "Geri bildirim",
      "Feedback sent": "Geri bildirim gönderildi"
    }
  },
  "ga": {
    "translation": {
      "Feedback subject": "Ábhar aiseolais",
      "Feedback sent": "Aiseolas seolta",
      "Feedback": "Aiseolas",
      "Send": "Seol",
      "Feedback message": "Teachtaireacht aiseolais"
    }
  },
  "is": {
    "translation": {
      "Feedback sent": "Viðbrögð send",
      "Feedback": "Endurgjöf",
      "Feedback message": "Ábending skilaboð",
      "Send": "Senda",
      "Feedback subject": "Umsagnarefni"
    }
  },
  "ne": {
    "translation": {
      "Feedback subject": "प्रतिक्रिया विषय",
      "Send": "पठाउनुहोस्",
      "Feedback sent": "प्रतिक्रिया पठाइयो",
      "Feedback": "प्रतिक्रिया",
      "Feedback message": "प्रतिक्रिया सन्देश"
    }
  },
  "ro": {
    "translation": {
      "Feedback subject": "Subiect de feedback",
      "Send": "Trimite",
      "Feedback message": "Mesaj de feedback",
      "Feedback sent": "Feedback trimis",
      "Feedback": "Părere"
    }
  },
  "hy": {
    "translation": {
      "Feedback sent": "Հետադարձ կապ ուղարկվեց",
      "Feedback subject": "Հետադարձ կապի թեմա",
      "Feedback message": "Հետադարձ հաղորդագրություն",
      "Feedback": "Հետադարձ կապ",
      "Send": "Ուղարկել"
    }
  },
  "sm": {
    "translation": {
      "Feedback message": "Fe'au manatu faaalia",
      "Send": "Auina atu",
      "Feedback subject": "Fa'amatalaga mataupu",
      "Feedback sent": "Fa'amatalaga lafo",
      "Feedback": "Manatu faaalia"
    }
  },
  "ko": {
    "translation": {
      "Feedback": "피드백",
      "Feedback subject": "피드백 주제",
      "Feedback sent": "피드백 전송됨",
      "Send": "보내다",
      "Feedback message": "피드백 메시지"
    }
  },
  "ru": {
    "translation": {
      "Feedback sent": "Отзыв отправлен",
      "Feedback": "Обратная связь",
      "Feedback subject": "Тема отзыва",
      "Feedback message": "Сообщение обратной связи",
      "Send": "Отправлять"
    }
  },
  "es": {
    "translation": {
      "Feedback": "Retroalimentación",
      "Feedback message": "Mensaje de comentarios",
      "Feedback sent": "Comentarios enviados",
      "Send": "Enviar",
      "Feedback subject": "Tema de comentarios"
    }
  },
  "nl": {
    "translation": {
      "Send": "Versturen",
      "Feedback sent": "Feedback verzonden",
      "Feedback": "Feedback",
      "Feedback subject": "Feedback onderwerp",
      "Feedback message": "Feedback bericht"
    }
  },
  "sr": {
    "translation": {
      "Feedback message": "Повратна порука",
      "Feedback subject": "Предмет повратне информације",
      "Send": "Пошаљи",
      "Feedback sent": "Повратне информације су послате",
      "Feedback": "Повратна информација"
    }
  },
  "da": {
    "translation": {
      "Feedback sent": "Feedback sendt",
      "Feedback": "Feedback",
      "Send": "Sende",
      "Feedback subject": "Feedback emne",
      "Feedback message": "Feedback besked"
    }
  },
  "it": {
    "translation": {
      "Send": "Inviare",
      "Feedback": "Feedback",
      "Feedback message": "Messaggio di feedback",
      "Feedback subject": "Oggetto del feedback",
      "Feedback sent": "Feedback inviato"
    }
  },
  "uk": {
    "translation": {
      "Feedback sent": "Відгук надіслано",
      "Feedback subject": "Тема відгуку",
      "Send": "Надіслати",
      "Feedback": "Зворотній зв'язок",
      "Feedback message": "Повідомлення зворотного зв'язку"
    }
  },
  "mt": {
    "translation": {
      "Feedback message": "Messaġġ ta' feedback",
      "Feedback": "Feedback",
      "Send": "Ibgħat",
      "Feedback subject": "Suġġett ta' feedback",
      "Feedback sent": "Feedback mibgħut"
    }
  },
  "mk": {
    "translation": {
      "Feedback message": "Повратна порака",
      "Feedback sent": "Повратните информации се испратени",
      "Feedback": "Повратни информации",
      "Feedback subject": "Тема на повратни информации",
      "Send": "Испрати"
    }
  }
}
const lang12 = {
  "en": {
    "translation": {
      "Nothing to show here": "Nothing to show here",
      "YouVi is a simple app for watching videos with translated captions": "YouVi is a simple app for watching videos with translated captions",
      "Trending": "Trending",
      "Terms and Conditions": "Terms and Conditions",
      "Sign In": "Sign In",
      "Sign Out": "Sign Out",
      "Privacy Policy": "Privacy Policy",
      "You don't have any channels yet. Please create one!": "You don't have any channels yet. Please create one!"
    }
  },
  "cs": {
    "translation": {
      "Sign Out": "Odhlásit se",
      "YouVi is a simple app for watching videos with translated captions": "YouVi je jednoduchá aplikace pro sledování videí s přeloženými titulky",
      "You don't have any channels yet. Please create one!": "Zatím nemáte žádné kanály. Vytvořte si ji prosím!",
      "Nothing to show here": "Není zde nic k zobrazení",
      "Trending": "Trendy",
      "Sign In": "Přihlásit se",
      "Terms and Conditions": "Pravidla a podmínky",
      "Privacy Policy": "Zásady ochrany osobních údajů"
    }
  },
  "hy": {
    "translation": {
      "YouVi is a simple app for watching videos with translated captions": "YouVi-ն պարզ հավելված է թարգմանված ենթագրերով տեսանյութեր դիտելու համար",
      "Trending": "Թրենդային",
      "Privacy Policy": "Գաղտնիության քաղաքականություն",
      "Sign In": "Մուտք գործել",
      "Nothing to show here": "Այստեղ ցուցադրելու ոչինչ չկա",
      "You don't have any channels yet. Please create one!": "Դուք դեռ չունեք ալիքներ: Խնդրում ենք ստեղծել մեկը:",
      "Terms and Conditions": "Ընդհանուր դրույթներր եւ պայմանները",
      "Sign Out": "Դուրս գալ"
    }
  },
  "ar": {
    "translation": {
      "Privacy Policy": "سياسة الخصوصية",
      "YouVi is a simple app for watching videos with translated captions": "YouVi هو تطبيق بسيط لمشاهدة مقاطع الفيديو ذات التسميات التوضيحية المترجمة",
      "You don't have any channels yet. Please create one!": "ليس لديك أي قنوات حتى الآن. الرجاء إنشاء واحدة!",
      "Nothing to show here": "لا يوجد شيء لعرضه هنا",
      "Trending": "الشائع",
      "Terms and Conditions": "الأحكام والشروط",
      "Sign Out": "خروج",
      "Sign In": "تسجيل الدخول"
    }
  },
  "am": {
    "translation": {
      "Trending": "በመታየት ላይ ያለ",
      "YouVi is a simple app for watching videos with translated captions": "YouVi ከተተረጎመ መግለጫ ፅሁፎች ጋር ቪዲዮዎችን ለመመልከት ቀላል መተግበሪያ ነው።",
      "Sign In": "ስግን እን",
      "Nothing to show here": "እዚህ ምንም የሚታይ ነገር የለም።",
      "Terms and Conditions": "አተገባበሩና መመሪያው",
      "You don't have any channels yet. Please create one!": "እስካሁን ምንም ቻናል የሎትም። እባክዎን አንድ ይፍጠሩ!",
      "Privacy Policy": "የ ግል የሆነ",
      "Sign Out": "ዛግተ ውጣ"
    }
  },
  "da": {
    "translation": {
      "Terms and Conditions": "Vilkår og betingelser",
      "You don't have any channels yet. Please create one!": "Du har ingen kanaler endnu. Opret venligst en!",
      "Sign Out": "Log ud",
      "Nothing to show here": "Intet at vise her",
      "YouVi is a simple app for watching videos with translated captions": "YouVi er en simpel app til at se videoer med oversatte billedtekster",
      "Privacy Policy": "Fortrolighedspolitik",
      "Sign In": "Log ind",
      "Trending": "Trending"
    }
  },
  "mk": {
    "translation": {
      "Nothing to show here": "Нема што да се прикаже овде",
      "Trending": "Во тренд",
      "You don't have any channels yet. Please create one!": "Сè уште немате канали. Ве молиме создадете еден!",
      "Privacy Policy": "Политика за приватност",
      "Terms and Conditions": "Правила и услови",
      "YouVi is a simple app for watching videos with translated captions": "YouVi е едноставна апликација за гледање видеа со преведени титли",
      "Sign Out": "Одјави се",
      "Sign In": "Најави се"
    }
  },
  "lv": {
    "translation": {
      "Trending": "Tendences",
      "You don't have any channels yet. Please create one!": "Jums vēl nav neviena kanāla. Lūdzu, izveidojiet vienu!",
      "Terms and Conditions": "Noteikumi un nosacījumi",
      "Sign Out": "Izrakstīties",
      "YouVi is a simple app for watching videos with translated captions": "YouVi ir vienkārša lietotne videoklipu skatīšanai ar tulkotiem parakstiem",
      "Sign In": "Ielogoties",
      "Privacy Policy": "Privātuma politika",
      "Nothing to show here": "Šeit nav ko rādīt"
    }
  },
  "ne": {
    "translation": {
      "Sign Out": "साइन आउट गर्नुहोस्",
      "Nothing to show here": "यहाँ देखाउन को लागी केहि छैन",
      "YouVi is a simple app for watching videos with translated captions": "YouVi अनुवादित क्याप्शनहरू सहित भिडियोहरू हेर्नको लागि एक सरल अनुप्रयोग हो",
      "You don't have any channels yet. Please create one!": "तपाईंसँग अहिलेसम्म कुनै च्यानलहरू छैनन्। कृपया एउटा सिर्जना गर्नुहोस्!",
      "Sign In": "साइन इन गर्नुहोस्",
      "Privacy Policy": "गोपनीयता नीति",
      "Trending": "ट्रेन्डिङ",
      "Terms and Conditions": "नियम र सर्तहरू"
    }
  },
  "ml": {
    "translation": {
      "Terms and Conditions": "ഉപാധികളും നിബന്ധനകളും",
      "Nothing to show here": "ഇവിടെ ഒന്നും കാണിക്കാനില്ല",
      "YouVi is a simple app for watching videos with translated captions": "വിവർത്തനം ചെയ്‌ത അടിക്കുറിപ്പുകളുള്ള വീഡിയോകൾ കാണുന്നതിനുള്ള ഒരു ലളിതമായ ആപ്പാണ് YouVi",
      "Privacy Policy": "സ്വകാര്യതാനയം",
      "Sign Out": "സൈൻ ഔട്ട്",
      "You don't have any channels yet. Please create one!": "നിങ്ങൾക്ക് ഇതുവരെ ചാനലുകളൊന്നുമില്ല. ദയവായി ഒരെണ്ണം സൃഷ്‌ടിക്കുക!",
      "Trending": "ട്രെൻഡിംഗ്",
      "Sign In": "സൈൻ ഇൻ"
    }
  },
  "mt": {
    "translation": {
      "Sign In": "Idħol",
      "Privacy Policy": "Regoli tal-privatezza",
      "Nothing to show here": "Xejn x'juri hawn",
      "Trending": "Trending",
      "Sign Out": "Sign Out",
      "Terms and Conditions": "Termini u Kundizzjonijiet",
      "YouVi is a simple app for watching videos with translated captions": "YouVi hija app sempliċi biex tara vidjows b'titoli tradotti",
      "You don't have any channels yet. Please create one!": "Għad m'għandekx kanali. Jekk jogħġbok oħloq waħda!"
    }
  },
  "uk": {
    "translation": {
      "Terms and Conditions": "Правила та умови",
      "YouVi is a simple app for watching videos with translated captions": "YouVi — це проста програма для перегляду відео з перекладеними субтитрами",
      "You don't have any channels yet. Please create one!": "У вас ще немає каналів. Будь ласка, створіть його!",
      "Sign Out": "Вийти з аккаунта",
      "Sign In": "Увійти",
      "Nothing to show here": "Тут нічого не показувати",
      "Privacy Policy": "Політика конфіденційності",
      "Trending": "В тренді"
    }
  },
  "af": {
    "translation": {
      "Nothing to show here": "Niks om hier te wys nie",
      "Privacy Policy": "Privaatheidsbeleid",
      "Terms and Conditions": "Bepalings en voorwaardes",
      "Sign Out": "Teken uit",
      "Sign In": "Meld aan",
      "You don't have any channels yet. Please create one!": "Jy het nog geen kanale nie. Skep asseblief een!",
      "Trending": "Trending",
      "YouVi is a simple app for watching videos with translated captions": "YouVi is 'n eenvoudige toepassing om video's met vertaalde onderskrifte te kyk"
    }
  },
  "lt": {
    "translation": {
      "Nothing to show here": "Nėra čia ką parodyti",
      "Terms and Conditions": "Taisyklės ir sąlygos",
      "You don't have any channels yet. Please create one!": "Dar neturite jokių kanalų. Prašome sukurti vieną!",
      "YouVi is a simple app for watching videos with translated captions": "„YouVi“ yra paprasta programa, skirta žiūrėti vaizdo įrašus su išverstais subtitrais",
      "Trending": "Tendencijos",
      "Privacy Policy": "Privatumo politika",
      "Sign In": "Prisijungti",
      "Sign Out": "Atsijungti"
    }
  },
  "is": {
    "translation": {
      "Nothing to show here": "Ekkert að sýna hér",
      "You don't have any channels yet. Please create one!": "Þú ert ekki með neinar rásir ennþá. Vinsamlegast búðu til einn!",
      "Terms and Conditions": "Skilmálar og skilyrði",
      "Sign In": "Skráðu þig inn",
      "Sign Out": "Útskrá",
      "YouVi is a simple app for watching videos with translated captions": "YouVi er einfalt app til að horfa á myndbönd með þýddum texta",
      "Trending": "Vinsælt",
      "Privacy Policy": "Friðhelgisstefna"
    }
  },
  "hr": {
    "translation": {
      "Sign Out": "Odjavi se",
      "Nothing to show here": "Ovdje se nema što pokazati",
      "Sign In": "Prijaviti se",
      "You don't have any channels yet. Please create one!": "Još nemate nijedan kanal. Molimo stvorite jedan!",
      "Privacy Policy": "Politika privatnosti",
      "Trending": "U trendu",
      "YouVi is a simple app for watching videos with translated captions": "YouVi je jednostavna aplikacija za gledanje videa s prevedenim titlovima",
      "Terms and Conditions": "Odredbe i uvjeti"
    }
  },
  "id": {
    "translation": {
      "YouVi is a simple app for watching videos with translated captions": "YouVi adalah aplikasi sederhana untuk menonton video dengan teks terjemahan",
      "Terms and Conditions": "Syarat dan ketentuan",
      "Trending": "Sedang tren",
      "Privacy Policy": "Kebijakan pribadi",
      "Sign Out": "Keluar",
      "Nothing to show here": "Tidak ada yang bisa ditampilkan di sini",
      "Sign In": "Masuk",
      "You don't have any channels yet. Please create one!": "Anda belum memiliki saluran. Silakan buat satu!"
    }
  },
  "sv": {
    "translation": {
      "Terms and Conditions": "Villkor",
      "Privacy Policy": "Integritetspolicy",
      "Nothing to show here": "Inget att visa här",
      "Sign Out": "Logga ut",
      "YouVi is a simple app for watching videos with translated captions": "YouVi är en enkel app för att titta på videor med översatta bildtexter",
      "Trending": "Trendigt",
      "You don't have any channels yet. Please create one!": "Du har inga kanaler än. Vänligen skapa en!",
      "Sign In": "Logga in"
    }
  },
  "ja": {
    "translation": {
      "YouVi is a simple app for watching videos with translated captions": "YouVi は、翻訳されたキャプション付きのビデオを見るためのシンプルなアプリです",
      "Sign In": "ログイン",
      "Trending": "トレンド",
      "Terms and Conditions": "規約と条件",
      "Sign Out": "サインアウト",
      "You don't have any channels yet. Please create one!": "まだチャンネルがありません。作成してください！",
      "Privacy Policy": "プライバシーポリシー",
      "Nothing to show here": "ここに表示するものはありません"
    }
  },
  "sl": {
    "translation": {
      "You don't have any channels yet. Please create one!": "Nimate še nobenega kanala. Prosimo, ustvarite ga!",
      "Privacy Policy": "Politika zasebnosti",
      "YouVi is a simple app for watching videos with translated captions": "YouVi je preprosta aplikacija za gledanje videoposnetkov s prevedenimi napisi",
      "Sign Out": "Odjava",
      "Nothing to show here": "Tukaj ni ničesar za pokazati",
      "Trending": "V trendu",
      "Terms and Conditions": "Pogoji",
      "Sign In": "Vpis"
    }
  },
  "bg": {
    "translation": {
      "Sign Out": "Отписване",
      "Privacy Policy": "Политика за поверителност",
      "YouVi is a simple app for watching videos with translated captions": "YouVi е просто приложение за гледане на видеоклипове с преведени надписи",
      "Trending": "Тенденция",
      "Sign In": "Впиши се",
      "Nothing to show here": "Няма нищо за показване тук",
      "You don't have any channels yet. Please create one!": "Все още нямате канали. Моля, създайте такъв!",
      "Terms and Conditions": "Правила и условия"
    }
  },
  "fr": {
    "translation": {
      "Sign Out": "Se déconnecter",
      "YouVi is a simple app for watching videos with translated captions": "YouVi est une application simple pour regarder des vidéos avec des sous-titres traduits",
      "Sign In": "S'identifier",
      "You don't have any channels yet. Please create one!": "Vous n'avez pas encore de chaînes. Veuillez en créer un !",
      "Trending": "Tendance",
      "Nothing to show here": "Rien à montrer ici",
      "Privacy Policy": "Politique de confidentialité",
      "Terms and Conditions": "Termes et conditions"
    }
  },
  "hi": {
    "translation": {
      "Trending": "रुझान",
      "Privacy Policy": "गोपनीयता नीति",
      "You don't have any channels yet. Please create one!": "आपके पास अभी तक कोई चैनल नहीं है। कृपया एक बनाएं!",
      "Sign In": "साइन इन करें",
      "Nothing to show here": "यहां दिखाने के लिए कुछ नहीं है",
      "Terms and Conditions": "नियम और शर्तें",
      "YouVi is a simple app for watching videos with translated captions": "YouVi अनुदित अनुशीर्षकों के साथ वीडियो देखने के लिए एक सरल ऐप है",
      "Sign Out": "साइन आउट"
    }
  },
  "no": {
    "translation": {
      "Terms and Conditions": "Vilkår og betingelser",
      "YouVi is a simple app for watching videos with translated captions": "YouVi er en enkel app for å se videoer med oversatt bildetekst",
      "Privacy Policy": "Personvernerklæring",
      "You don't have any channels yet. Please create one!": "Du har ingen kanaler ennå. Opprett en!",
      "Sign Out": "Logg ut",
      "Sign In": "Logg inn",
      "Nothing to show here": "Ingenting å vise her",
      "Trending": "Trender"
    }
  },
  "tr": {
    "translation": {
      "Nothing to show here": "Burada gösterilecek bir şey yok",
      "Terms and Conditions": "Şartlar ve koşullar",
      "Trending": "trend",
      "YouVi is a simple app for watching videos with translated captions": "YouVi, çevrilmiş altyazılı videoları izlemek için basit bir uygulamadır",
      "Privacy Policy": "Gizlilik Politikası",
      "Sign Out": "Oturumu Kapat",
      "You don't have any channels yet. Please create one!": "Henüz kanalınız yok. Lütfen bir tane oluşturun!",
      "Sign In": "Kayıt olmak"
    }
  },
  "ko": {
    "translation": {
      "Sign Out": "로그아웃",
      "Trending": "트렌드",
      "Privacy Policy": "개인 정보 정책",
      "Terms and Conditions": "이용약관",
      "Nothing to show here": "여기에 표시할 항목이 없습니다.",
      "Sign In": "로그인",
      "You don't have any channels yet. Please create one!": "아직 채널이 없습니다. 하나 만들어주세요!",
      "YouVi is a simple app for watching videos with translated captions": "YouVi는 자막이 번역된 동영상을 시청할 수 있는 간단한 앱입니다."
    }
  },
  "es": {
    "translation": {
      "You don't have any channels yet. Please create one!": "Aún no tienes ningún canal. ¡Por favor crea uno!",
      "Terms and Conditions": "Términos y condiciones",
      "YouVi is a simple app for watching videos with translated captions": "YouVi es una aplicación simple para ver videos con subtítulos traducidos",
      "Nothing to show here": "Nada que mostrar aquí",
      "Sign In": "Registrarse",
      "Privacy Policy": "Política de privacidad",
      "Sign Out": "Desconectar",
      "Trending": "Tendencias"
    }
  },
  "vi": {
    "translation": {
      "You don't have any channels yet. Please create one!": "Bạn chưa có bất kỳ kênh nào. Hãy tạo một!",
      "Sign In": "Đăng nhập",
      "YouVi is a simple app for watching videos with translated captions": "YouVi là một ứng dụng đơn giản để xem video có phụ đề đã dịch",
      "Trending": "xu hướng",
      "Terms and Conditions": "Các điều khoản và điều kiện",
      "Privacy Policy": "Chính sách bảo mật",
      "Nothing to show here": "Không có gì để hiển thị ở đây",
      "Sign Out": "Đăng xuất"
    }
  },
  "ca": {
    "translation": {
      "Terms and Conditions": "Termes i condicions",
      "Sign Out": "Tanca sessió",
      "Trending": "Tendència",
      "Nothing to show here": "No hi ha res a mostrar aquí",
      "YouVi is a simple app for watching videos with translated captions": "YouVi és una aplicació senzilla per veure vídeos amb subtítols traduïts",
      "Sign In": "Inicieu la sessió",
      "Privacy Policy": "Política de privacitat",
      "You don't have any channels yet. Please create one!": "Encara no tens cap canal. Si us plau, creeu-ne un!"
    }
  },
  "pl": {
    "translation": {
      "Trending": "Popularne",
      "Sign Out": "Wyloguj się",
      "YouVi is a simple app for watching videos with translated captions": "YouVi to prosta aplikacja do oglądania filmów z przetłumaczonymi napisami",
      "Sign In": "Zaloguj się",
      "Nothing to show here": "Nie ma tu nic do pokazania",
      "You don't have any channels yet. Please create one!": "Nie masz jeszcze żadnych kanałów. Utwórz go!",
      "Privacy Policy": "Polityka prywatności",
      "Terms and Conditions": "Zasady i warunki"
    }
  },
  "fi": {
    "translation": {
      "Sign Out": "Kirjaudu ulos",
      "Privacy Policy": "Tietosuojakäytäntö",
      "You don't have any channels yet. Please create one!": "Sinulla ei ole vielä yhtään kanavaa. Ole hyvä ja luo sellainen!",
      "YouVi is a simple app for watching videos with translated captions": "YouVi on yksinkertainen sovellus videoiden katseluun käännetyillä tekstityksillä",
      "Trending": "Trendaavat",
      "Terms and Conditions": "Käyttöehdot",
      "Sign In": "Kirjaudu sisään",
      "Nothing to show here": "Ei tässä mitään näytettävää"
    }
  },
  "nl": {
    "translation": {
      "Sign Out": "Afmelden",
      "Sign In": "Log in",
      "YouVi is a simple app for watching videos with translated captions": "YouVi is een eenvoudige app voor het bekijken van video's met vertaalde bijschriften",
      "You don't have any channels yet. Please create one!": "Je hebt nog geen zenders. Maak er een aan!",
      "Privacy Policy": "Privacybeleid",
      "Nothing to show here": "Hier is niets te zien",
      "Trending": "Populair",
      "Terms and Conditions": "Voorwaarden"
    }
  },
  "mg": {
    "translation": {
      "Nothing to show here": "Tsy misy haseho eto",
      "Terms and Conditions": "Fepetra",
      "You don't have any channels yet. Please create one!": "Tsy mbola manana fantsona ianao. Mamorona iray azafady!",
      "YouVi is a simple app for watching videos with translated captions": "YouVi dia fampiharana tsotra hijerena horonan-tsary misy lohateny voadika",
      "Privacy Policy": "politika fiarovana fiainan'olona",
      "Sign Out": "Hivoaka",
      "Trending": "malaza",
      "Sign In": "Hiditra"
    }
  },
  "ga": {
    "translation": {
      "Terms and Conditions": "Téarmaí agus Coinníollacha",
      "Privacy Policy": "Beartas Príobháideachais",
      "You don't have any channels yet. Please create one!": "Níl aon chainéil agat fós. Cruthaigh ceann le do thoil!",
      "Trending": "Treocht",
      "YouVi is a simple app for watching videos with translated captions": "Is aip shimplí é YouVi chun féachaint ar fhíseáin le fotheidil aistrithe",
      "Sign Out": "Logáil Amach",
      "Sign In": "Sínigh isteach",
      "Nothing to show here": "Ní dhéanfaidh aon ní a thaispeáint anseo"
    }
  },
  "iw": {
    "translation": {
      "Sign In": "להתחבר",
      "Terms and Conditions": "תנאים",
      "Privacy Policy": "מדיניות פרטיות",
      "You don't have any channels yet. Please create one!": "עדיין אין לך ערוצים. נא ליצור אחד!",
      "Nothing to show here": "אין מה להראות כאן",
      "Trending": "מגמות",
      "YouVi is a simple app for watching videos with translated captions": "YouVi היא אפליקציה פשוטה לצפייה בסרטונים עם כיתובים מתורגמים",
      "Sign Out": "התנתק"
    }
  },
  "pt": {
    "translation": {
      "Privacy Policy": "Política de Privacidade",
      "You don't have any channels yet. Please create one!": "Você ainda não tem canais. Por favor, crie um!",
      "Trending": "Tendendo",
      "Sign In": "Entrar",
      "YouVi is a simple app for watching videos with translated captions": "YouVi é um aplicativo simples para assistir vídeos com legendas traduzidas",
      "Sign Out": "Sair",
      "Nothing to show here": "Nada para mostrar aqui",
      "Terms and Conditions": "Termos e Condições"
    }
  },
  "haw": {
    "translation": {
      "Nothing to show here": "ʻAʻohe mea e hōʻike ʻia ma ʻaneʻi",
      "Trending": "Kaulana",
      "You don't have any channels yet. Please create one!": "ʻAʻohe āu ala i kēia manawa. E ʻoluʻolu e hana i hoʻokahi!",
      "Sign In": "ʻeʻe",
      "Privacy Policy": "Kulekele pilikino",
      "YouVi is a simple app for watching videos with translated captions": "He polokalamu maʻalahi ʻo YouVi no ka nānā ʻana i nā wikiō me nā huaʻōlelo unuhi",
      "Terms and Conditions": "Nā Kūlana a me nā Kūlana",
      "Sign Out": "Lele"
    }
  },
  "it": {
    "translation": {
      "Nothing to show here": "Niente da mostrare qui",
      "Trending": "Di tendenza",
      "YouVi is a simple app for watching videos with translated captions": "YouVi è una semplice app per guardare video con sottotitoli tradotti",
      "Sign Out": "Disconnessione",
      "You don't have any channels yet. Please create one!": "Non hai ancora alcun canale. Si prega di crearne uno!",
      "Sign In": "Registrazione",
      "Terms and Conditions": "Termini e Condizioni",
      "Privacy Policy": "politica sulla riservatezza"
    }
  },
  "ro": {
    "translation": {
      "You don't have any channels yet. Please create one!": "Nu aveți încă niciun canal. Vă rugăm să creați unul!",
      "YouVi is a simple app for watching videos with translated captions": "YouVi este o aplicație simplă pentru vizionarea videoclipurilor cu subtitrări traduse",
      "Nothing to show here": "Nimic de arătat aici",
      "Sign In": "Conectare",
      "Terms and Conditions": "Termeni si conditii",
      "Trending": "Trending",
      "Privacy Policy": "Politica de Confidențialitate",
      "Sign Out": "Sign out"
    }
  },
  "co": {
    "translation": {
      "Sign Out": "Sorte",
      "Nothing to show here": "Nunda da mustrà quì",
      "Terms and Conditions": "Termini è Cundizioni",
      "You don't have any channels yet. Please create one!": "Ùn avete micca ancu canali. Per piacè creane unu!",
      "Privacy Policy": "Pulitica di privacy",
      "YouVi is a simple app for watching videos with translated captions": "YouVi hè una app simplice per vede video cù didascalie tradutte",
      "Trending": "Trending",
      "Sign In": "Firmà lu"
    }
  },
  "sk": {
    "translation": {
      "Sign In": "Prihlásiť sa",
      "Privacy Policy": "Zásady ochrany osobných údajov",
      "YouVi is a simple app for watching videos with translated captions": "YouVi je jednoduchá aplikácia na sledovanie videí s preloženými titulkami",
      "Trending": "Trendy",
      "Sign Out": "Odhlásiť Sa",
      "You don't have any channels yet. Please create one!": "Zatiaľ nemáte žiadne kanály. Prosím vytvorte si ho!",
      "Terms and Conditions": "Podmienky",
      "Nothing to show here": "Nie je tu nič na zobrazenie"
    }
  },
  "et": {
    "translation": {
      "YouVi is a simple app for watching videos with translated captions": "YouVi on lihtne rakendus tõlgitud subtiitritega videote vaatamiseks",
      "Nothing to show here": "Siin pole midagi näidata",
      "You don't have any channels yet. Please create one!": "Teil pole veel ühtegi kanalit. Palun looge see!",
      "Sign Out": "Logi välja",
      "Trending": "Trendikas",
      "Terms and Conditions": "Tingimused",
      "Privacy Policy": "Privaatsuspoliitika",
      "Sign In": "Logi sisse"
    }
  },
  "sq": {
    "translation": {
      "Privacy Policy": "Politika e privatësisë",
      "You don't have any channels yet. Please create one!": "Nuk ke ende asnjë kanal. Ju lutemi krijoni një!",
      "Terms and Conditions": "Termat dhe Kushtet",
      "Sign In": "Hyni",
      "Nothing to show here": "Asgjë për të treguar këtu",
      "Trending": "Në trend",
      "YouVi is a simple app for watching videos with translated captions": "YouVi është një aplikacion i thjeshtë për të parë video me titra të përkthyera",
      "Sign Out": "Dilni"
    }
  },
  "ru": {
    "translation": {
      "Nothing to show here": "Здесь нечего показывать",
      "YouVi is a simple app for watching videos with translated captions": "YouVi — простое приложение для просмотра видео с переведенными субтитрами.",
      "Trending": "В тренде",
      "Privacy Policy": "Политика конфиденциальности",
      "Terms and Conditions": "Условия и положения",
      "Sign Out": "Выход",
      "You don't have any channels yet. Please create one!": "У вас пока нет каналов. Пожалуйста, создайте его!",
      "Sign In": "Войти"
    }
  },
  "bs": {
    "translation": {
      "Sign In": "Prijavite se",
      "You don't have any channels yet. Please create one!": "Još uvijek nemate nijedan kanal. Molimo kreirajte jedan!",
      "YouVi is a simple app for watching videos with translated captions": "YouVi je jednostavna aplikacija za gledanje videa s prevedenim titlovima",
      "Terms and Conditions": "Odredbe i uslovi",
      "Nothing to show here": "Ovdje nema šta da se pokaže",
      "Trending": "U trendu",
      "Sign Out": "Odjaviti se",
      "Privacy Policy": "Politika privatnosti"
    }
  },
  "de": {
    "translation": {
      "Nothing to show here": "Hier gibt es nichts zu zeigen",
      "Terms and Conditions": "Geschäftsbedingungen",
      "YouVi is a simple app for watching videos with translated captions": "YouVi ist eine einfache App zum Ansehen von Videos mit übersetzten Untertiteln",
      "Sign In": "Einloggen",
      "Trending": "Im Trend",
      "Sign Out": "Austragen",
      "You don't have any channels yet. Please create one!": "Du hast noch keine Kanäle. Bitte erstellen Sie eine!",
      "Privacy Policy": "Datenschutz-Bestimmungen"
    }
  },
  "hu": {
    "translation": {
      "Terms and Conditions": "Felhasználási feltételek",
      "Sign Out": "Kijelentkezés",
      "Trending": "Felkapott",
      "Nothing to show here": "Itt nincs mit mutatni",
      "YouVi is a simple app for watching videos with translated captions": "A YouVi egy egyszerű alkalmazás lefordított feliratokkal ellátott videók megtekintéséhez",
      "Sign In": "Bejelentkezés",
      "You don't have any channels yet. Please create one!": "Még nincsenek csatornáid. Kérjük, hozzon létre egyet!",
      "Privacy Policy": "Adatvédelmi irányelvek"
    }
  },
  "el": {
    "translation": {
      "Privacy Policy": "Πολιτική Απορρήτου",
      "Sign Out": "Αποσύνδεση",
      "Nothing to show here": "Δεν υπάρχει τίποτα να δείξουμε εδώ",
      "Terms and Conditions": "Οροι και Προϋποθέσεις",
      "YouVi is a simple app for watching videos with translated captions": "Το YouVi είναι μια απλή εφαρμογή για την παρακολούθηση βίντεο με μεταφρασμένους λεζάντες",
      "Trending": "Τάσεις",
      "Sign In": "Συνδεθείτε",
      "You don't have any channels yet. Please create one!": "Δεν έχετε ακόμη κανάλια. Δημιουργήστε ένα!"
    }
  },
  "zh": {
    "translation": {
      "Sign In": "登入",
      "Privacy Policy": "隐私政策",
      "Sign Out": "登出",
      "Nothing to show here": "这里没有什么可展示的",
      "YouVi is a simple app for watching videos with translated captions": "YouVi 是一个简单的应用程序，用于观看带有翻译字幕的视频",
      "You don't have any channels yet. Please create one!": "您还没有任何频道。请创建一个！",
      "Trending": "热门",
      "Terms and Conditions": "条款和条件"
    }
  },
  "sm": {
    "translation": {
      "Terms and Conditions": "Tulaga ma Tulaga",
      "Sign Out": "Saini ese",
      "Trending": "Fa'asolo",
      "Sign In": "Saini ese j",
      "YouVi is a simple app for watching videos with translated captions": "O le YouVi ose polokalama faigofie e matamata ai i vitio ma faʻamatalaga faʻaliliu",
      "Nothing to show here": "E leai se mea e fa'aalia iinei",
      "Privacy Policy": "Faiga Fa'alilolilo",
      "You don't have any channels yet. Please create one!": "E le'i iai ni au auala. Fa'amolemole fatu se tasi!"
    }
  },
  "sr": {
    "translation": {
      "Sign Out": "Одјава",
      "Nothing to show here": "Овде нема шта да се покаже",
      "You don't have any channels yet. Please create one!": "Још увек немате ниједан канал. Молимо креирајте један!",
      "Sign In": "Пријавите се",
      "YouVi is a simple app for watching videos with translated captions": "ИоуВи је једноставна апликација за гледање видео записа са преведеним титловима",
      "Trending": "У тренду",
      "Terms and Conditions": "Услови",
      "Privacy Policy": "Правила о приватности"
    }
  }
}
const lang11 = {
  "en": { "translation": { "Friends": "Friends" } },
  "hy": { "translation": { "Friends": "Ընկերներ" } },
  "mk": { "translation": { "Friends": "Пријатели" } },
  "am": { "translation": { "Friends": "ጓደኞች" } },
  "fi": { "translation": { "Friends": "Ystävät" } },
  "co": { "translation": { "Friends": "Amici" } },
  "nl": { "translation": { "Friends": "Vrienden" } },
  "sr": { "translation": { "Friends": "Пријатељи" } },
  "zh": { "translation": { "Friends": "朋友们" } },
  "no": { "translation": { "Friends": "Venner" } },
  "bg": { "translation": { "Friends": "приятели" } },
  "hi": { "translation": { "Friends": "मित्र" } },
  "ru": { "translation": { "Friends": "Друзья" } },
  "tr": { "translation": { "Friends": "Arkadaşlar" } },
  "ar": { "translation": { "Friends": "أصدقاء" } },
  "es": { "translation": { "Friends": "Amigos" } },
  "lv": { "translation": { "Friends": "Draugi" } },
  "da": { "translation": { "Friends": "Venner" } },
  "ca": { "translation": { "Friends": "Amics" } },
  "ml": { "translation": { "Friends": "സുഹൃത്തുക്കൾ" } },
  "ja": { "translation": { "Friends": "友達" } },
  "sq": { "translation": { "Friends": "Miqtë" } },
  "mg": { "translation": { "Friends": "NAMANA" } },
  "iw": { "translation": { "Friends": "חברים" } },
  "is": { "translation": { "Friends": "Vinir" } },
  "de": { "translation": { "Friends": "Freunde" } },
  "id": { "translation": { "Friends": "Teman-teman" } },
  "uk": { "translation": { "Friends": "друзі" } },
  "hu": { "translation": { "Friends": "Barátok" } },
  "ko": { "translation": { "Friends": "친구들" } },
  "cs": { "translation": { "Friends": "Přátelé" } },
  "pt": { "translation": { "Friends": "Amigos" } },
  "bs": { "translation": { "Friends": "Prijatelji" } },
  "lt": { "translation": { "Friends": "Draugai" } },
  "ne": { "translation": { "Friends": "साथीहरू" } },
  "ga": { "translation": { "Friends": "Cairde" } },
  "haw": { "translation": { "Friends": "E na hoa" } },
  "vi": { "translation": { "Friends": "Bạn bè" } },
  "el": { "translation": { "Friends": "Οι φιλοι" } },
  "ro": { "translation": { "Friends": "Prieteni" } },
  "fr": { "translation": { "Friends": "Amis" } },
  "pl": { "translation": { "Friends": "Przyjaciele" } },
  "it": { "translation": { "Friends": "Gli amici" } },
  "mt": { "translation": { "Friends": "Ħbieb" } },
  "af": { "translation": { "Friends": "Vriende" } },
  "sv": { "translation": { "Friends": "Vänner" } },
  "hr": { "translation": { "Friends": "Prijatelji" } },
  "sl": { "translation": { "Friends": "prijatelji" } },
  "et": { "translation": { "Friends": "Sõbrad" } },
  "sm": { "translation": { "Friends": "Uo" } },
  "sk": { "translation": { "Friends": "Priatelia" } }
}
const lang10 = {
  "en": {
    "translation": {
      "Seen shared video": "Seen shared video",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Congratulations, 15 friends have seen your share video and you are now a premium user!",
      "friends have seen your shared video!": "friends have seen your shared video!",
      "Don't show this again": "Don't show this again",
      "Continue": "Continue",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!",
      "Share": "Share"
    }
  },
  "sl": {
    "translation": {
      "Seen shared video": "Videli ste skupni video",
      "Share": "Deliti",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Delite ta video s prijatelji in če si ga ogleda 15 ljudi, dobite dostop do vseh vrhunskih funkcij in brez oglasnih pasic!",
      "friends have seen your shared video!": "prijatelji so videli vaš skupni video!",
      "Continue": "Nadaljuj",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Čestitamo, 15 prijateljev je videlo vaš videoposnetek v skupni rabi in zdaj ste vrhunski uporabnik!",
      "Don't show this again": "Ne pokaži več tega"
    }
  },
  "lv": {
    "translation": {
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Apsveicam, 15 draugi ir redzējuši jūsu kopīgoto videoklipu, un jūs tagad esat premium klases lietotājs!",
      "Continue": "Turpināt",
      "Seen shared video": "Redzēts kopīgots video",
      "Don't show this again": "Nerādīt šo vēlreiz",
      "friends have seen your shared video!": "draugi ir redzējuši jūsu kopīgoto video!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Kopīgojiet šo videoklipu ar draugiem, un, ja to redz 15 cilvēki, jūs iegūsit piekļuvi visām premium funkcijām un bez reklāmkarogu reklāmām!",
      "Share": "Dalīties"
    }
  },
  "ro": {
    "translation": {
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Felicitări, 15 prieteni ți-au văzut videoclipul distribuit și acum ești un utilizator premium!",
      "Continue": "Continua",
      "friends have seen your shared video!": "prietenii au văzut videoclipul tău partajat!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Distribuiți acest videoclip prietenilor dvs. și dacă îl văd 15 persoane, veți avea acces la toate funcțiile premium și fără anunțuri banner!",
      "Seen shared video": "S-a văzut videoclipul partajat",
      "Don't show this again": "Nu mai arăta asta",
      "Share": "Acțiune"
    }
  },
  "hi": {
    "translation": {
      "friends have seen your shared video!": "मित्रों ने आपका साझा किया हुआ वीडियो देखा है!",
      "Seen shared video": "शेयर किया वीडियो देखा",
      "Continue": "जारी रखना",
      "Share": "शेयर करना",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "बधाई हो, 15 दोस्तों ने आपका वीडियो साझा किया है और अब आप एक प्रीमियम उपयोगकर्ता हैं!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "इस वीडियो को अपने दोस्तों के साथ साझा करें और यदि 15 लोग इसे देखते हैं, तो आपको सभी प्रीमियम सुविधाओं तक पहुंच प्राप्त होती है और कोई बैनर विज्ञापन नहीं!",
      "Don't show this again": "इसे दोबारा न दिखाएं"
    }
  },
  "co": {
    "translation": {
      "Seen shared video": "Videtu video spartutu",
      "Don't show this again": "Ùn mostra micca questu novu",
      "Continue": "Cuntinuà",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Condividi stu video cù i vostri amichi è se 15 persone u vedenu, avete accessu à tutte e funzioni premium è senza banner!",
      "Share": "sparte",
      "friends have seen your shared video!": "l'amichi anu vistu u vostru video spartutu!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Felicitazioni, 15 amichi anu vistu u vostru video di sparte è site avà un utilizatore premium!"
    }
  },
  "lt": {
    "translation": {
      "Share": "Dalintis",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Pasidalykite šiuo vaizdo įrašu su draugais ir, jei jį pamatys 15 žmonių, gausite prieigą prie visų aukščiausios kokybės funkcijų ir jokių reklamjuosčių!",
      "Continue": "Tęsti",
      "Don't show this again": "Daugiau to nerodyti",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Sveikiname, 15 draugų pamatė jūsų bendrinamą vaizdo įrašą ir dabar esate aukščiausios kokybės naudotojas!",
      "friends have seen your shared video!": "draugai pamatė jūsų bendrinamą vaizdo įrašą!",
      "Seen shared video": "Pamatytas bendrinamas vaizdo įrašas"
    }
  },
  "vi": {
    "translation": {
      "Continue": "Tiếp tục",
      "Seen shared video": "Đã xem video được chia sẻ",
      "Don't show this again": "Không hiển thị điều này một lần nữa",
      "Share": "Đăng lại",
      "friends have seen your shared video!": "bạn bè đã xem video chia sẻ của bạn!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Xin chúc mừng, 15 người bạn đã xem video chia sẻ của bạn và bạn hiện là người dùng cao cấp!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Chia sẻ video này với bạn bè của bạn và nếu 15 người xem video, bạn sẽ có quyền truy cập vào tất cả các tính năng cao cấp và không có quảng cáo biểu ngữ!"
    }
  },
  "cs": {
    "translation": {
      "Don't show this again": "Už to nezobrazovat",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Gratulujeme, vaše sdílené video vidělo 15 přátel a nyní jste prémiovým uživatelem!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Sdílejte toto video se svými přáteli, a pokud ho uvidí 15 lidí, získáte přístup ke všem prémiovým funkcím a žádné bannerové reklamy!",
      "Continue": "Pokračovat",
      "Seen shared video": "Viděno sdílené video",
      "friends have seen your shared video!": "přátelé viděli vaše sdílené video!",
      "Share": "Podíl"
    }
  },
  "af": {
    "translation": {
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Deel hierdie video met jou vriende en as 15 mense dit sien, kry jy toegang tot alle premium kenmerke en geen banieradvertensies nie!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Baie geluk, 15 vriende het jou deelvideo gesien en jy is nou 'n premium gebruiker!",
      "Don't show this again": "Moenie dit weer wys nie",
      "Seen shared video": "Gedeelde video gesien",
      "Continue": "Aanhou",
      "friends have seen your shared video!": "vriende het jou gedeelde video gesien!",
      "Share": "Deel"
    }
  },
  "tr": {
    "translation": {
      "Seen shared video": "Paylaşılan video görüldü",
      "Continue": "Devam et",
      "Don't show this again": "Bunu bir daha gösterme",
      "Share": "Paylaşmak",
      "friends have seen your shared video!": "arkadaşlar paylaştığınız videoyu gördü!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Tebrikler, paylaşım videonuzu 15 arkadaşınız gördü ve artık premium kullanıcısınız!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Bu videoyu arkadaşlarınızla paylaşın ve 15 kişi görürse, tüm premium özelliklere erişin ve banner reklamlar olmadan!"
    }
  },
  "ar": {
    "translation": {
      "Don't show this again": "لا تظهر هذا مرة أخرى",
      "Share": "يشارك",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "شارك هذا الفيديو مع أصدقائك وإذا شاهده 15 شخصًا ، يمكنك الوصول إلى جميع الميزات المتميزة وبدون إعلانات لافتة!",
      "Continue": "يكمل",
      "Seen shared video": "رأيت فيديو مشترك",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "تهانينا ، شاهد 15 صديقًا مقطع الفيديو الخاص بك وأنت الآن مستخدم متميز!",
      "friends have seen your shared video!": "شاهد الأصدقاء الفيديو المشترك الخاص بك!"
    }
  },
  "pt": {
    "translation": {
      "Seen shared video": "Viu o vídeo compartilhado",
      "Don't show this again": "Não mostrar isso novamente",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Parabéns, 15 amigos viram seu vídeo compartilhado e agora você é um usuário premium!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Compartilhe este vídeo com seus amigos e se 15 pessoas o virem, você terá acesso a todos os recursos premium e sem banners!",
      "friends have seen your shared video!": "amigos viram seu vídeo compartilhado!",
      "Share": "Compartilhar",
      "Continue": "Continuar"
    }
  },
  "fr": {
    "translation": {
      "Don't show this again": "Ne plus afficher ça",
      "friends have seen your shared video!": "amis ont vu votre vidéo partagée !",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Partagez cette vidéo avec vos amis et si 15 personnes la voient, vous aurez accès à toutes les fonctionnalités premium et à aucune bannière publicitaire !",
      "Seen shared video": "Vidéo partagée vue",
      "Share": "Partager",
      "Continue": "Continuer",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Félicitations, 15 amis ont vu votre vidéo partagée et vous êtes maintenant un utilisateur premium !"
    }
  },
  "pl": {
    "translation": {
      "Don't show this again": "Nie pokazuj tego więcej",
      "Seen shared video": "Widziane udostępnione wideo",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Udostępnij ten film znajomym, a jeśli obejrzy go 15 osób, uzyskasz dostęp do wszystkich funkcji premium i żadnych banerów reklamowych!",
      "Continue": "Kontynuować",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Gratulacje, 15 znajomych obejrzało Twój udostępniony film i jesteś teraz użytkownikiem premium!",
      "friends have seen your shared video!": "znajomych widziało Twój udostępniony film!",
      "Share": "Dzielić"
    }
  },
  "ko": {
    "translation": {
      "friends have seen your shared video!": "친구가 귀하의 공유 동영상을 보았습니다!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "이 영상을 친구들과 공유하고 15명이 본다면 배너 광고 없이 모든 프리미엄 기능을 이용할 수 있습니다!",
      "Seen shared video": "공유된 동영상을 보았습니다.",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "축하합니다. 15명의 친구가 귀하의 공유 비디오를 보았고 귀하는 이제 프리미엄 사용자입니다!",
      "Don't show this again": "다시 표시하지 않음",
      "Continue": "계속하다",
      "Share": "공유하다"
    }
  },
  "de": {
    "translation": {
      "Don't show this again": "Zeige das nicht noch einmal",
      "friends have seen your shared video!": "Freunde haben Ihr geteiltes Video gesehen!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Herzlichen Glückwunsch, 15 Freunde haben Ihr geteiltes Video gesehen und Sie sind jetzt ein Premium-Benutzer!",
      "Continue": "Fortsetzen",
      "Seen shared video": "Geteiltes Video gesehen",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Teilen Sie dieses Video mit Ihren Freunden und wenn 15 Personen es sehen, erhalten Sie Zugriff auf alle Premium-Funktionen und keine Bannerwerbung!",
      "Share": "Teilen"
    }
  },
  "mk": {
    "translation": {
      "Share": "Споделете",
      "friends have seen your shared video!": "пријателите го видоа вашето споделено видео!",
      "Don't show this again": "Не покажувај го ова повторно",
      "Continue": "Продолжи",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Честитки, 15 пријатели го видоа вашето споделено видео и сега сте премиум корисник!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Споделете го ова видео со вашите пријатели и ако го видат 15 луѓе, ќе добиете пристап до сите премиум функции и без реклами!",
      "Seen shared video": "Видено споделено видео"
    }
  },
  "bs": {
    "translation": {
      "friends have seen your shared video!": "prijatelji su vidjeli vaš zajednički video!",
      "Don't show this again": "Ne pokazuj ovo ponovo",
      "Continue": "Nastavi",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Podijelite ovaj video sa svojim prijateljima i ako ga vidi 15 ljudi, dobićete pristup svim premium funkcijama i bez baner reklama!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Čestitamo, 15 prijatelja je vidjelo vaš zajednički video i sada ste premium korisnik!",
      "Share": "Dijeli",
      "Seen shared video": "Pogledano podijeljeni video"
    }
  },
  "mg": {
    "translation": {
      "Seen shared video": "Nijery video nozaraina",
      "Share": "anjara",
      "Continue": "hanohy",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Zarao amin'ny namanao ity horonan-tsary ity ary raha olona 15 no mahita azy dia mahazo miditra amin'ny endri-javatra premium rehetra ianao ary tsy misy doka sora-baventy!",
      "Don't show this again": "Aza aseho indray izany",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Arahabaina, namana 15 no nahita ny lahatsarin'ny fizaranao ary ianao izao dia mpampiasa premium!",
      "friends have seen your shared video!": "efa hitan'ny namana ny vidéo zarainao!"
    }
  },
  "id": {
    "translation": {
      "Seen shared video": "Melihat video yang dibagikan",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Selamat, 15 teman telah melihat video yang Anda bagikan dan sekarang Anda adalah pengguna premium!",
      "friends have seen your shared video!": "teman telah melihat video yang Anda bagikan!",
      "Don't show this again": "Jangan tampilkan ini lagi",
      "Share": "Membagikan",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Bagikan video ini dengan teman Anda dan jika 15 orang melihatnya, Anda mendapatkan akses ke semua fitur premium dan tanpa iklan banner!",
      "Continue": "Melanjutkan"
    }
  },
  "hu": {
    "translation": {
      "Seen shared video": "Megosztott videót láttam",
      "Don't show this again": "Ne mutasd ezt újra",
      "Share": "Részvény",
      "friends have seen your shared video!": "ismerősei látták a megosztott videódat!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Oszd meg ezt a videót barátaiddal, és ha 15 ember látja, minden prémium funkcióhoz hozzáférhetsz, szalaghirdetések nélkül!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Gratulálunk, 15 barát látta a megosztott videódat, és mostantól prémium felhasználó vagy!",
      "Continue": "Folytatni"
    }
  },
  "nl": {
    "translation": {
      "Share": "Deel",
      "Seen shared video": "Gedeelde video gezien",
      "Continue": "Doorgaan",
      "Don't show this again": "Laat dit niet nog een keer zien",
      "friends have seen your shared video!": "vrienden hebben je gedeelde video gezien!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Gefeliciteerd, 15 vrienden hebben je gedeelde video gezien en je bent nu een premium gebruiker!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Deel deze video met je vrienden en als 15 mensen hem zien, krijg je toegang tot alle premiumfuncties en geen banneradvertenties!"
    }
  },
  "is": {
    "translation": {
      "Continue": "Halda áfram",
      "Don't show this again": "Ekki sýna þetta aftur",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Deildu þessu myndbandi með vinum þínum og ef 15 manns sjá það færðu aðgang að öllum úrvalsaðgerðum og engar borðaauglýsingar!",
      "Share": "Deila",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Til hamingju, 15 vinir hafa séð deilingarvídeóið þitt og þú ert nú úrvalsnotandi!",
      "Seen shared video": "Sá samnýtt myndband",
      "friends have seen your shared video!": "vinir hafa séð deilda myndbandið þitt!"
    }
  },
  "es": {
    "translation": {
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Comparta este video con sus amigos y si 15 personas lo ven, ¡obtendrá acceso a todas las funciones premium y sin anuncios publicitarios!",
      "Seen shared video": "Visto video compartido",
      "Continue": "Continuar",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "¡Felicitaciones, 15 amigos han visto tu video compartido y ahora eres un usuario premium!",
      "Don't show this again": "No mostrar esto de nuevo",
      "Share": "Cuota",
      "friends have seen your shared video!": "¡Tus amigos han visto tu video compartido!"
    }
  },
  "uk": {
    "translation": {
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Поділіться цим відео з друзями, і якщо його переглянуть 15 людей, ви отримаєте доступ до всіх преміум-функцій і без рекламних банерів!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Вітаємо, 15 друзів переглянули ваше відео, і тепер ви преміум-користувач!",
      "Continue": "Продовжити",
      "Share": "Поділіться",
      "Seen shared video": "Переглянув спільне відео",
      "friends have seen your shared video!": "друзі бачили ваше спільне відео!",
      "Don't show this again": "Не показувати це знову"
    }
  },
  "ru": {
    "translation": {
      "Don't show this again": "Не показывать это снова",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Поздравляем, 15 друзей посмотрели ваше видео, и теперь вы являетесь премиум-пользователем!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Поделитесь этим видео с друзьями, и если его увидят 15 человек, вы получите доступ ко всем премиум-функциям и никакой баннерной рекламы!",
      "Continue": "Продолжать",
      "friends have seen your shared video!": "друзья увидели ваше совместное видео!",
      "Share": "Делиться",
      "Seen shared video": "Просмотрено совместное видео"
    }
  },
  "sm": {
    "translation": {
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Faasoa atu lenei vitio i au uo ma afai e 15 tagata e vaʻai i ai, e te maua avanoa i mea faʻapitoa uma ma leai ni faʻasalalauga fuʻa!",
      "friends have seen your shared video!": "ua vaai uo i lau vitio faasoa!",
      "Seen shared video": "Va'aia le vitio fa'asoa",
      "Share": "Faasoa",
      "Continue": "Fa'aauau",
      "Don't show this again": "Aua le toe fa'aalia lenei mea",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Fa'afetai, 15 uo ua va'ai i lau fa'asoa vitio ma ua avea nei oe ma tagata fa'aoga moni!"
    }
  },
  "ca": {
    "translation": {
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Enhorabona, 15 amics han vist el teu vídeo compartit i ara ets un usuari premium!",
      "Continue": "Continua",
      "Share": "Compartir",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Comparteix aquest vídeo amb els teus amics i si el veuen 15 persones, tindreu accés a totes les funcions premium i sense anuncis de bàner!",
      "friends have seen your shared video!": "els amics han vist el teu vídeo compartit!",
      "Seen shared video": "S'ha vist el vídeo compartit",
      "Don't show this again": "No tornis a mostrar això"
    }
  },
  "ja": {
    "translation": {
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "おめでとうございます。15 人の友達があなたのシェア動画を見て、あなたはプレミアム ユーザーになりました。",
      "Seen shared video": "共有ビデオを見た",
      "Continue": "継続する",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "このビデオを友達と共有し、15 人が見たら、すべてのプレミアム機能にアクセスでき、バナー広告は表示されません!",
      "Share": "シェア",
      "Don't show this again": "今後表示しない",
      "friends have seen your shared video!": "友達があなたの共有ビデオを見ました！"
    }
  },
  "bg": {
    "translation": {
      "friends have seen your shared video!": "приятели са видели вашето споделено видео!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Споделете това видео с приятелите си и ако 15 души го видят, ще получите достъп до всички първокласни функции и без рекламни банери!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Поздравления, 15 приятели са видели вашето споделено видео и вече сте премиум потребител!",
      "Seen shared video": "Видяно споделено видео",
      "Share": "Дял",
      "Don't show this again": "Не показвай това отново",
      "Continue": "продължи"
    }
  },
  "fi": {
    "translation": {
      "Continue": "Jatkaa",
      "Share": "Jaa",
      "Don't show this again": "Älä näytä tätä uudelleen",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Jaa tämä video ystävillesi ja jos 15 ihmistä näkee sen, saat käyttöösi kaikki premium-ominaisuudet ilman bannerimainoksia!",
      "Seen shared video": "Jaettu video nähty",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Onnittelut, 15 ystävääsi on nähnyt jaetun videosi ja olet nyt premium-käyttäjä!",
      "friends have seen your shared video!": "ystävät ovat nähneet jaetun videosi!"
    }
  },
  "no": {
    "translation": {
      "friends have seen your shared video!": "venner har sett din delte video!",
      "Seen shared video": "Har sett delt video",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Gratulerer, 15 venner har sett delevideoen din, og du er nå en premiumbruker!",
      "Continue": "Fortsette",
      "Don't show this again": "Ikke vis dette igjen",
      "Share": "Dele",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Del denne videoen med vennene dine, og hvis 15 personer ser den, får du tilgang til alle premium-funksjoner og ingen bannerannonser!"
    }
  },
  "da": {
    "translation": {
      "Continue": "Blive ved",
      "Don't show this again": "Vis ikke dette igen",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Tillykke, 15 venner har set din delevideo, og du er nu en premium-bruger!",
      "Seen shared video": "Har set delt video",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Del denne video med dine venner, og hvis 15 personer ser den, får du adgang til alle premium funktioner og ingen bannerreklamer!",
      "Share": "Del",
      "friends have seen your shared video!": "venner har set din delte video!"
    }
  },
  "hr": {
    "translation": {
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Čestitamo, 15 prijatelja je vidjelo vaš dijeljeni video i sada ste premium korisnik!",
      "Seen shared video": "Vidio zajednički video",
      "Don't show this again": "Ne prikazuj ovo više",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Podijelite ovaj video sa svojim prijateljima i ako ga vidi 15 ljudi, dobit ćete pristup svim premium značajkama i bez banner oglasa!",
      "friends have seen your shared video!": "prijatelji su vidjeli vaš zajednički video!",
      "Continue": "Nastaviti",
      "Share": "Udio"
    }
  },
  "mt": {
    "translation": {
      "Seen shared video": "Rajt video kondiviż",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Prosit, 15-il ħbieb raw is-sehem tal-video tiegħek u issa int utent premium!",
      "Continue": "Kompli",
      "Share": "Aqsam",
      "Don't show this again": "Terġax turi dan",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Aqsam dan il-video ma' sħabek u jekk jarawh 15-il persuna, ikollok aċċess għall-karatteristiċi premium kollha u l-ebda banner ads!",
      "friends have seen your shared video!": "ħbieb raw il-video kondiviż tiegħek!"
    }
  },
  "am": {
    "translation": {
      "Continue": "ቀጥል",
      "friends have seen your shared video!": "ጓደኞች የጋራ ቪዲዮዎን አይተዋል!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "እንኳን ደስ አለህ፣ 15 ጓደኞች የማጋራት ቪዲዮህን አይተዋል እና አሁን ፕሪሚየም ተጠቃሚ ነህ!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "ይህንን ቪዲዮ ለጓደኞችዎ ያካፍሉ እና 15 ሰዎች ካዩት ሁሉንም ዋና ዋና ባህሪያትን ያገኛሉ እና ምንም የሰንደቅ ማስታወቂያዎች የሉም!",
      "Seen shared video": "የተጋራ ቪዲዮ ታይቷል።",
      "Share": "አጋራ",
      "Don't show this again": "ይህንን እንደገና አታሳይ"
    }
  },
  "haw": {
    "translation": {
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Hoʻomaikaʻi, ua ʻike nā hoaaloha 15 i kāu kaʻana wikiō a he mea hoʻohana premium ʻoe!",
      "Seen shared video": "Ua ʻike ʻia ke wikiō kaʻana like",
      "Don't show this again": "Mai hōʻike hou i kēia",
      "Continue": "E hoʻomau",
      "Share": "Kaʻana like",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Kaʻana like i kēia wikiō me kāu mau hoaaloha a inā ʻike ka poʻe he 15, hiki iā ʻoe ke komo i nā hiʻohiʻona premium āpau a ʻaʻohe hoʻolaha hae!",
      "friends have seen your shared video!": "ua ʻike nā hoaaloha i kāu wikiō i kaʻana like!"
    }
  },
  "sq": {
    "translation": {
      "friends have seen your shared video!": "miqtë e kanë parë videon tuaj të përbashkët!",
      "Seen shared video": "Është parë video e ndarë",
      "Continue": "Vazhdoni",
      "Share": "Shpërndaje",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Ndani këtë video me miqtë tuaj dhe nëse e shohin 15 persona, do të keni akses në të gjitha veçoritë premium dhe pa reklama banerash!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Urime, 15 miq e kanë parë videon tënde të përbashkët dhe tani je një përdorues premium!",
      "Don't show this again": "Mos e shfaq më këtë"
    }
  },
  "zh": {
    "translation": {
      "Seen shared video": "看过分享的视频",
      "friends have seen your shared video!": "朋友看过你分享的视频！",
      "Don't show this again": "不再显示",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "与您的朋友分享此视频，如果有 15 人看到它，您就可以使用所有高级功能，并且没有横幅广告！",
      "Share": "分享",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "恭喜，已有15位好友看过您的分享视频，您已成为高级用户！",
      "Continue": "继续"
    }
  },
  "sv": {
    "translation": {
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Dela den här videon med dina vänner och om 15 personer ser den får du tillgång till alla premiumfunktioner och inga bannerannonser!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Grattis, 15 vänner har sett din dela video och du är nu en premiumanvändare!",
      "Share": "Dela med sig",
      "friends have seen your shared video!": "vänner har sett din delade video!",
      "Continue": "Fortsätta",
      "Don't show this again": "Visa inte detta igen",
      "Seen shared video": "Har sett delad video"
    }
  },
  "el": {
    "translation": {
      "Continue": "Να συνεχίσει",
      "Seen shared video": "Εμφανίστηκε το κοινόχρηστο βίντεο",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Μοιραστείτε αυτό το βίντεο με τους φίλους σας και αν το δουν 15 άτομα, θα έχετε πρόσβαση σε όλες τις premium λειτουργίες και όχι σε διαφημίσεις banner!",
      "Share": "Μερίδιο",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Συγχαρητήρια, 15 φίλοι είδαν το κοινόχρηστο βίντεο και είστε πλέον premium χρήστης!",
      "Don't show this again": "Μην το ξαναδείξετε αυτό",
      "friends have seen your shared video!": "φίλοι είδαν το κοινό σας βίντεο!"
    }
  },
  "et": {
    "translation": {
      "Seen shared video": "Jagatud videot nähtud",
      "Don't show this again": "Ära seda enam näita",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Õnnitleme, 15 sõpra on näinud teie jagatud videot ja olete nüüd esmaklassiline kasutaja!",
      "friends have seen your shared video!": "sõbrad on teie jagatud videot näinud!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Jagage seda videot oma sõpradega ja kui seda näeb 15 inimest, saate juurdepääsu kõigile esmaklassilistele funktsioonidele ja ilma bännerreklaamideta!",
      "Share": "Jaga",
      "Continue": "Jätka"
    }
  },
  "iw": {
    "translation": {
      "Continue": "לְהַמשִׁיך",
      "Seen shared video": "ראה סרטון משותף",
      "Share": "לַחֲלוֹק",
      "friends have seen your shared video!": "חברים ראו את הסרטון המשותף שלך!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "מזל טוב, 15 חברים ראו את סרטון השיתוף שלך ואתה עכשיו משתמש פרימיום!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "שתף את הסרטון הזה עם החברים שלך ואם 15 אנשים רואים אותו, תקבל גישה לכל תכונות הפרימיום וללא מודעות באנר!",
      "Don't show this again": "אל תראה את זה שוב"
    }
  },
  "it": {
    "translation": {
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Condividi questo video con i tuoi amici e se 15 persone lo vedono, avrai accesso a tutte le funzionalità premium e nessun banner pubblicitario!",
      "Share": "Condividere",
      "friends have seen your shared video!": "gli amici hanno visto il tuo video condiviso!",
      "Seen shared video": "Visto il video condiviso",
      "Don't show this again": "Non mostrarlo più",
      "Continue": "Continua",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Congratulazioni, 15 amici hanno visto il tuo video condiviso e ora sei un utente premium!"
    }
  },
  "ga": {
    "translation": {
      "Seen shared video": "Chonaic físeán roinnte",
      "Continue": "Leanúint ar aghaidh",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Comhghairdeachas, tá 15 cara tar éis do chuid físeán a roinnt a fheiceáil agus is úsáideoir den scoth thú anois!",
      "Don't show this again": "Ná taispeáin é seo arís",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Roinn an físeán seo le do chairde agus má fheiceann 15 duine é, gheobhaidh tú rochtain ar na gnéithe préimhe go léir agus gan aon fhógraí meirgí!",
      "Share": "Comhroinn",
      "friends have seen your shared video!": "tá d'fhíseán roinnte feicthe ag cairde!"
    }
  },
  "hy": {
    "translation": {
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Շնորհավորում ենք, 15 ընկերներ տեսել են ձեր կիսվելու տեսանյութը, և դուք այժմ պրեմիում օգտատեր եք:",
      "Continue": "Շարունակել",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Կիսվեք այս տեսանյութով ձեր ընկերների հետ և եթե այն տեսնեն 15 հոգի, դուք կստանաք մուտք դեպի բոլոր պրեմիում գործառույթները և ոչ մի բաններային գովազդ:",
      "Don't show this again": "Սա այլևս մի ցուցադրեք",
      "Share": "Կիսվել",
      "friends have seen your shared video!": "ընկերները տեսել են ձեր ընդհանուր տեսանյութը:",
      "Seen shared video": "Դիտվել է համօգտագործված տեսանյութ"
    }
  },
  "ml": {
    "translation": {
      "Share": "പങ്കിടുക",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "അഭിനന്ദനങ്ങൾ, 15 സുഹൃത്തുക്കൾ നിങ്ങളുടെ പങ്കിടൽ വീഡിയോ കണ്ടു, നിങ്ങൾ ഇപ്പോൾ ഒരു പ്രീമിയം ഉപയോക്താവാണ്!",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "ഈ വീഡിയോ നിങ്ങളുടെ സുഹൃത്തുക്കളുമായി പങ്കിടുക, 15 ആളുകൾ ഇത് കാണുകയാണെങ്കിൽ, എല്ലാ പ്രീമിയം ഫീച്ചറുകളിലേക്കും നിങ്ങൾക്ക് ആക്‌സസ് ലഭിക്കും, കൂടാതെ ബാനർ പരസ്യങ്ങളൊന്നുമില്ല!",
      "friends have seen your shared video!": "സുഹൃത്തുക്കൾ നിങ്ങളുടെ പങ്കിട്ട വീഡിയോ കണ്ടു!",
      "Continue": "തുടരുക",
      "Seen shared video": "ഷെയർ ചെയ്ത വീഡിയോ കണ്ടു",
      "Don't show this again": "ഇത് വീണ്ടും കാണിക്കരുത്"
    }
  },
  "sr": {
    "translation": {
      "friends have seen your shared video!": "пријатељи су видели ваш дељени видео!",
      "Continue": "Настави",
      "Don't show this again": "Не показуј ово поново",
      "Seen shared video": "Видели дељени видео",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Поделите овај видео са својим пријатељима и ако га види 15 људи, добићете приступ свим премиум функцијама и без реклама на банеру!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Честитамо, 15 пријатеља је видело ваш дељени видео и сада сте премиум корисник!",
      "Share": "Објави"
    }
  },
  "sk": {
    "translation": {
      "Seen shared video": "Videné zdieľané video",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "Gratulujeme, 15 priateľov videlo vaše zdieľané video a teraz ste prémiovým používateľom!",
      "Don't show this again": "Toto už nezobrazovať",
      "friends have seen your shared video!": "priatelia videli vaše zdieľané video!",
      "Share": "zdieľam",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "Zdieľajte toto video so svojimi priateľmi a ak ho uvidí 15 ľudí, získate prístup ku všetkým prémiovým funkciám a žiadne bannerové reklamy!",
      "Continue": "ďalej"
    }
  },
  "ne": {
    "translation": {
      "Don't show this again": "यसलाई फेरि नदेखाउनुहोस्",
      "friends have seen your shared video!": "साथीहरूले तपाइँको साझा भिडियो हेर्नुभयो!",
      "Congratulations, 15 friends have seen your share video and you are now a premium user!": "बधाई छ, 15 जना साथीहरूले तपाइँको साझा भिडियो हेर्नुभयो र तपाइँ अब एक प्रिमियम प्रयोगकर्ता हुनुहुन्छ!",
      "Seen shared video": "सेयर गरिएको भिडियो हेर्नुभयो",
      "Continue": "जारी राख्नुहोस्",
      "Share": "सेयर गर्नुहोस्",
      "Share this video with your friends and if 15 people see it, you get access to all premium features and no banner ads!": "यो भिडियो आफ्ना साथीहरूसँग साझा गर्नुहोस् र यदि 15 व्यक्तिहरूले यसलाई देख्नुभयो भने, तपाईंले सबै प्रिमियम सुविधाहरूमा पहुँच पाउनुहुनेछ र कुनै ब्यानर विज्ञापनहरू छैनन्!"
    }
  }
}
const lang9 = {
  "en": { "translation": { "Recommended": "Recommended", "Hide Ads": "Hide Ads" } },
  "et": { "translation": { "Recommended": "Soovitatav", "Hide Ads": "Peida reklaamid" } },
  "ga": { "translation": { "Hide Ads": "Folaigh Fógraí", "Recommended": "Molta" } },
  "bs": { "translation": { "Hide Ads": "Sakrij oglase", "Recommended": "Preporučeno" } },
  "sv": { "translation": { "Recommended": "Rekommenderad", "Hide Ads": "Dölj annonser" } },
  "vi": { "translation": { "Recommended": "Khuyến khích", "Hide Ads": "Ẩn quảng cáo" } },
  "sr": { "translation": { "Recommended": "Препоручено", "Hide Ads": "Сакриј огласе" } },
  "ja": { "translation": { "Recommended": "おすすめされた", "Hide Ads": "広告を非表示" } },
  "el": { "translation": { "Hide Ads": "Απόκρυψη διαφημίσεων", "Recommended": "Συνιστάται" } },
  "mk": { "translation": { "Hide Ads": "Скриј реклами", "Recommended": "Препорачано" } },
  "pl": { "translation": { "Recommended": "Zalecana", "Hide Ads": "Ukryj reklamy" } },
  "tr": { "translation": { "Recommended": "Önerilen", "Hide Ads": "Reklamları Gizle" } },
  "sq": { "translation": { "Recommended": "Rekomanduar", "Hide Ads": "Fshih reklamat" } },
  "am": { "translation": { "Recommended": "የሚመከር", "Hide Ads": "ማስታወቂያዎችን ደብቅ" } },
  "pt": { "translation": { "Hide Ads": "Ocultar anúncios", "Recommended": "Recomendado" } },
  "ro": { "translation": { "Recommended": "Recomandat", "Hide Ads": "Ascundeți anunțuri" } },
  "ne": { "translation": { "Hide Ads": "विज्ञापनहरू लुकाउनुहोस्", "Recommended": "सिफारिस गर्नुभयो" } },
  "ko": { "translation": { "Recommended": "추천", "Hide Ads": "광고 숨기기" } },
  "co": { "translation": { "Recommended": "Cunsigliatu", "Hide Ads": "Oculta l'annunzii" } },
  "it": { "translation": { "Recommended": "Consigliato", "Hide Ads": "Nascondi annunci" } },
  "fi": { "translation": { "Hide Ads": "Piilota mainokset", "Recommended": "Suositeltava" } },
  "ca": { "translation": { "Recommended": "Recomanat", "Hide Ads": "Amaga anuncis" } },
  "mg": { "translation": { "Recommended": "soso-kevitra", "Hide Ads": "Afeno doka" } },
  "af": { "translation": { "Recommended": "Aanbeveel", "Hide Ads": "Versteek advertensies" } },
  "zh": { "translation": { "Hide Ads": "隐藏广告", "Recommended": "推荐的" } },
  "ml": { "translation": { "Hide Ads": "പരസ്യങ്ങൾ മറയ്ക്കുക", "Recommended": "ശുപാർശ ചെയ്ത" } },
  "de": { "translation": { "Hide Ads": "Anzeigen ausblenden", "Recommended": "Empfohlen" } },
  "da": { "translation": { "Hide Ads": "Skjul annoncer", "Recommended": "Anbefalede" } },
  "es": { "translation": { "Recommended": "Recomendado", "Hide Ads": "Ocultar anuncios" } },
  "ru": { "translation": { "Recommended": "рекомендуемые", "Hide Ads": "Скрыть рекламу" } },
  "lt": { "translation": { "Hide Ads": "Slėpti skelbimus", "Recommended": "Rekomenduojamas" } },
  "mt": { "translation": { "Recommended": "Irrakkomandat", "Hide Ads": "Aħbi Reklami" } },
  "id": { "translation": { "Hide Ads": "Sembunyikan Iklan", "Recommended": "Direkomendasikan" } },
  "cs": { "translation": { "Hide Ads": "Skrýt reklamy", "Recommended": "Doporučeno" } },
  "fr": { "translation": { "Recommended": "Recommandé", "Hide Ads": "Masquer les annonces" } },
  "sm": { "translation": { "Recommended": "Fautuaina", "Hide Ads": "Natia Fa'asalalauga" } },
  "bg": { "translation": { "Hide Ads": "Скриване на рекламите", "Recommended": "Препоръчва се" } },
  "lv": { "translation": { "Hide Ads": "Slēpt reklāmas", "Recommended": "Ieteicams" } },
  "ar": { "translation": { "Recommended": "مُستَحسَن", "Hide Ads": "إخفاء الإعلانات" } },
  "sk": { "translation": { "Hide Ads": "Skryť reklamy", "Recommended": "Odporúčané" } },
  "iw": { "translation": { "Hide Ads": "הסתר מודעות", "Recommended": "מוּמלָץ" } },
  "hy": { "translation": { "Recommended": "Խորհուրդ է տրվում", "Hide Ads": "Թաքցնել գովազդները" } },
  "haw": { "translation": { "Hide Ads": "Huna Hoolaha", "Recommended": "Paipai ʻia" } },
  "hi": { "translation": { "Recommended": "अनुशंसित", "Hide Ads": "विज्ञापन छुपाएं" } },
  "is": { "translation": { "Recommended": "Mælt er með", "Hide Ads": "Fela auglýsingar" } },
  "sl": { "translation": { "Recommended": "Priporočeno", "Hide Ads": "Skrij oglase" } },
  "no": { "translation": { "Recommended": "Anbefalt", "Hide Ads": "Skjul annonser" } },
  "hr": { "translation": { "Hide Ads": "Sakrij oglase", "Recommended": "Preporučeno" } },
  "uk": { "translation": { "Hide Ads": "Приховати рекламу", "Recommended": "Рекомендовано" } },
  "nl": { "translation": { "Recommended": "Aanbevolen", "Hide Ads": "Advertenties verbergen" } },
  "hu": { "translation": { "Hide Ads": "Hirdetések elrejtése", "Recommended": "Ajánlott" } }
}
const lang8 = {
  "en": {
    "translation": {
      "You don't have any subscriptions": "You don't have any subscriptions",
      "Buy the Premium package and be free of Banner Ads": "Buy the Premium package and be free of Banner Ads",
      "Buy Premium": "Buy Premium",
      "Premium": "Premium",
      "Restore": "Restore"
    }
  },
  "am": {
    "translation": {
      "Restore": "እነበረበት መልስ",
      "Buy Premium": "ፕሪሚየም ይግዙ",
      "Premium": "ፕሪሚየም",
      "Buy the Premium package and be free of Banner Ads": "የፕሪሚየም ፓኬጁን ይግዙ እና ከባነር ማስታወቂያዎች ነጻ ይሁኑ",
      "You don't have any subscriptions": "ምንም የደንበኝነት ምዝገባዎች የሉዎትም።"
    }
  },
  "el": {
    "translation": {
      "Restore": "Επαναφέρω",
      "You don't have any subscriptions": "Δεν έχετε συνδρομές",
      "Buy the Premium package and be free of Banner Ads": "Αγοράστε το πακέτο Premium και απαλλαγείτε από διαφημίσεις Banner",
      "Buy Premium": "Αγοράστε Premium",
      "Premium": "Ασφάλιστρο"
    }
  },
  "fi": {
    "translation": {
      "You don't have any subscriptions": "Sinulla ei ole tilauksia",
      "Buy the Premium package and be free of Banner Ads": "Osta Premium-paketti ilman bannerimainoksia",
      "Restore": "Palauttaa",
      "Premium": "Premium",
      "Buy Premium": "Osta Premium"
    }
  },
  "sl": {
    "translation": {
      "Premium": "Premium",
      "Buy the Premium package and be free of Banner Ads": "Kupite paket Premium in se osvobodite oglasnih pasic",
      "You don't have any subscriptions": "Nimate nobenih naročnin",
      "Buy Premium": "Kupi Premium",
      "Restore": "Obnovi"
    }
  },
  "af": {
    "translation": {
      "Buy the Premium package and be free of Banner Ads": "Koop die Premium-pakket en wees vry van banieradvertensies",
      "You don't have any subscriptions": "Jy het geen intekeninge nie",
      "Premium": "Premie",
      "Buy Premium": "Koop Premium",
      "Restore": "Herstel"
    }
  },
  "bs": {
    "translation": {
      "You don't have any subscriptions": "Nemate nikakve pretplate",
      "Restore": "Vrati",
      "Buy the Premium package and be free of Banner Ads": "Kupite Premium paket i oslobodite se baner oglasa",
      "Premium": "Premium",
      "Buy Premium": "Kupite Premium"
    }
  },
  "ca": {
    "translation": {
      "You don't have any subscriptions": "No tens cap subscripció",
      "Premium": "Premium",
      "Buy Premium": "Compra Premium",
      "Buy the Premium package and be free of Banner Ads": "Compreu el paquet Premium i no tingueu anuncis de bàner",
      "Restore": "Restaurar"
    }
  },
  "es": {
    "translation": {
      "Buy the Premium package and be free of Banner Ads": "Compre el paquete Premium y libérese de los anuncios publicitarios",
      "You don't have any subscriptions": "No tienes suscripciones",
      "Buy Premium": "Comprar Premium",
      "Premium": "De primera calidad",
      "Restore": "Restaurar"
    }
  },
  "cs": {
    "translation": {
      "Restore": "Obnovit",
      "Buy the Premium package and be free of Banner Ads": "Kupte si prémiový balíček a zbavte se bannerových reklam",
      "Buy Premium": "Koupit Premium",
      "You don't have any subscriptions": "Nemáte žádné odběry",
      "Premium": "Pojistné"
    }
  },
  "ga": {
    "translation": {
      "Restore": "Athchóirigh",
      "Buy Premium": "Ceannaigh Préimh",
      "You don't have any subscriptions": "Níl aon síntiús agat",
      "Premium": "Préimh",
      "Buy the Premium package and be free of Banner Ads": "Ceannaigh an pacáiste Préimh agus a bheith saor ó Fógraí Meirge"
    }
  },
  "vi": {
    "translation": {
      "Restore": "Khôi phục",
      "Premium": "Phần thưởng",
      "You don't have any subscriptions": "Bạn không có bất kỳ đăng ký nào",
      "Buy the Premium package and be free of Banner Ads": "Mua gói Cao cấp và không có Quảng cáo biểu ngữ",
      "Buy Premium": "Mua cao cấp"
    }
  },
  "co": {
    "translation": {
      "Premium": "Premium",
      "Restore": "Risturà",
      "Buy Premium": "Cumprate Premium",
      "Buy the Premium package and be free of Banner Ads": "Cumprate u pacchettu Premium è esse liberu di Banner Ads",
      "You don't have any subscriptions": "Ùn avete micca abbonamenti"
    }
  },
  "de": {
    "translation": {
      "Buy Premium": "Prämie kaufen",
      "Buy the Premium package and be free of Banner Ads": "Kaufen Sie das Premium-Paket und seien Sie frei von Bannerwerbung",
      "Restore": "Wiederherstellen",
      "You don't have any subscriptions": "Sie haben keine Abonnements",
      "Premium": "Prämie"
    }
  },
  "et": {
    "translation": {
      "You don't have any subscriptions": "Teil pole tellimusi",
      "Buy Premium": "Osta Premium",
      "Buy the Premium package and be free of Banner Ads": "Ostke Premium pakett ja olge bännerreklaamidest vaba",
      "Restore": "Taastama",
      "Premium": "Premium"
    }
  },
  "lv": {
    "translation": {
      "Premium": "Premium",
      "Restore": "Atjaunot",
      "You don't have any subscriptions": "Jums nav neviena abonementa",
      "Buy Premium": "Pērciet Premium",
      "Buy the Premium package and be free of Banner Ads": "Iegādājieties Premium paketi un atbrīvojieties no reklāmkarogu reklāmām"
    }
  },
  "hi": {
    "translation": {
      "Buy Premium": "प्रीमियम खरीदें",
      "Restore": "पुनर्स्थापित करना",
      "Premium": "बीमा किस्त",
      "You don't have any subscriptions": "आपके पास कोई सदस्यता नहीं है",
      "Buy the Premium package and be free of Banner Ads": "प्रीमियम पैकेज खरीदें और बैनर विज्ञापनों से मुक्त रहें"
    }
  },
  "pt": {
    "translation": {
      "Buy Premium": "Comprar Premium",
      "Premium": "Prêmio",
      "Restore": "Restaurar",
      "You don't have any subscriptions": "Você não tem nenhuma assinatura",
      "Buy the Premium package and be free of Banner Ads": "Compre o pacote Premium e fique livre de Banners"
    }
  },
  "hu": {
    "translation": {
      "Buy the Premium package and be free of Banner Ads": "Vásárolja meg a prémium csomagot, és mentes a szalaghirdetésektől",
      "Restore": "visszaállítás",
      "Buy Premium": "Prémium vásárlás",
      "You don't have any subscriptions": "Nincsenek előfizetései",
      "Premium": "Prémium"
    }
  },
  "lt": {
    "translation": {
      "Restore": "Atkurti",
      "Premium": "Premija",
      "You don't have any subscriptions": "Neturite jokių prenumeratų",
      "Buy the Premium package and be free of Banner Ads": "Įsigykite „Premium“ paketą ir būkite be reklamjuosčių",
      "Buy Premium": "Pirkite Premium"
    }
  },
  "zh": {
    "translation": {
      "Buy the Premium package and be free of Banner Ads": "购买高级套餐并免于横幅广告",
      "Buy Premium": "购买溢价",
      "Restore": "恢复",
      "You don't have any subscriptions": "您没有任何订阅",
      "Premium": "优质的"
    }
  },
  "is": {
    "translation": {
      "Premium": "Premium",
      "Buy Premium": "Kaupa Premium",
      "You don't have any subscriptions": "Þú ert ekki með neinar áskriftir",
      "Restore": "Endurheimta",
      "Buy the Premium package and be free of Banner Ads": "Kauptu Premium pakkann og vertu laus við auglýsingaborða"
    }
  },
  "hy": {
    "translation": {
      "You don't have any subscriptions": "Դուք բաժանորդագրություններ չունեք",
      "Buy the Premium package and be free of Banner Ads": "Գնեք Պրեմիում փաթեթը և զերծ մնացեք Բաններային գովազդից",
      "Restore": "Վերականգնել",
      "Buy Premium": "Գնել Պրեմիում",
      "Premium": "Հավելավճար"
    }
  },
  "tr": {
    "translation": {
      "You don't have any subscriptions": "Hiç aboneliğiniz yok",
      "Premium": "Ödül",
      "Restore": "Eski haline getirmek",
      "Buy Premium": "Premium Satın Al",
      "Buy the Premium package and be free of Banner Ads": "Premium paketi satın alın ve Banner Reklamlardan kurtulun"
    }
  },
  "sm": {
    "translation": {
      "Buy the Premium package and be free of Banner Ads": "Fa'atau le afifi Premium ma ia sa'oloto mai Fa'asalalauga Fa'asalalau",
      "You don't have any subscriptions": "E leai sau lesitala",
      "Restore": "Toe fa'afo'isia",
      "Premium": "Premium",
      "Buy Premium": "Fa'atau Premium"
    }
  },
  "da": {
    "translation": {
      "Buy Premium": "Køb Premium",
      "Restore": "Gendan",
      "You don't have any subscriptions": "Du har ingen abonnementer",
      "Premium": "Præmie",
      "Buy the Premium package and be free of Banner Ads": "Køb Premium-pakken og vær fri for bannerannoncer"
    }
  },
  "ar": {
    "translation": {
      "Premium": "الممتازة",
      "You don't have any subscriptions": "ليس لديك أي اشتراكات",
      "Buy the Premium package and be free of Banner Ads": "اشترِ الحزمة المميزة وكن خاليًا من إعلانات البانر",
      "Buy Premium": "شراء قسط",
      "Restore": "يعيد"
    }
  },
  "fr": {
    "translation": {
      "Premium": "Prime",
      "You don't have any subscriptions": "Vous n'avez aucun abonnement",
      "Buy the Premium package and be free of Banner Ads": "Achetez le forfait Premium et soyez libre de bannières publicitaires",
      "Restore": "Restaurer",
      "Buy Premium": "Acheter Premium"
    }
  },
  "nl": {
    "translation": {
      "Buy Premium": "Koop Premium",
      "Premium": "Premie",
      "You don't have any subscriptions": "Je hebt geen abonnementen",
      "Buy the Premium package and be free of Banner Ads": "Koop het Premium-pakket en wees vrij van banneradvertenties",
      "Restore": "Herstellen"
    }
  },
  "ne": {
    "translation": {
      "Buy the Premium package and be free of Banner Ads": "प्रिमियम प्याकेज किन्नुहोस् र ब्यानर विज्ञापनहरूबाट मुक्त हुनुहोस्",
      "Buy Premium": "प्रिमियम किन्नुहोस्",
      "Premium": "प्रिमियम",
      "Restore": "पुनर्स्थापना गर्नुहोस्",
      "You don't have any subscriptions": "तपाईंसँग कुनै पनि सदस्यता छैन"
    }
  },
  "ro": {
    "translation": {
      "Restore": "Restabili",
      "You don't have any subscriptions": "Nu aveți niciun abonament",
      "Buy the Premium package and be free of Banner Ads": "Cumpărați pachetul Premium și nu mai aveți reclame banner",
      "Buy Premium": "Cumpărați Premium",
      "Premium": "Premium"
    }
  },
  "mk": {
    "translation": {
      "You don't have any subscriptions": "Немате претплати",
      "Restore": "Врати",
      "Buy Premium": "Купете Premium",
      "Premium": "Премиум",
      "Buy the Premium package and be free of Banner Ads": "Купете го Premium пакетот и ослободете се од реклами за банер"
    }
  },
  "mg": {
    "translation": {
      "Premium": "Premium",
      "You don't have any subscriptions": "Tsy manana famandrihana ianao",
      "Buy Premium": "Mividiana Premium",
      "Buy the Premium package and be free of Banner Ads": "Vidio ny fonosana Premium ary afaka amin'ny doka Banner",
      "Restore": "Ampodio"
    }
  },
  "ru": {
    "translation": {
      "You don't have any subscriptions": "У вас нет подписок",
      "Buy the Premium package and be free of Banner Ads": "Купите пакет Премиум и будьте свободны от баннерной рекламы",
      "Buy Premium": "Купить Премиум",
      "Premium": "Премиум",
      "Restore": "Восстановить"
    }
  },
  "ml": {
    "translation": {
      "Buy the Premium package and be free of Banner Ads": "പ്രീമിയം പാക്കേജ് വാങ്ങുക, ബാനർ പരസ്യങ്ങൾ ഒഴിവാക്കുക",
      "Buy Premium": "പ്രീമിയം വാങ്ങുക",
      "Restore": "പുനഃസ്ഥാപിക്കുക",
      "You don't have any subscriptions": "നിങ്ങൾക്ക് സബ്‌സ്‌ക്രിപ്‌ഷനുകളൊന്നുമില്ല",
      "Premium": "പ്രീമിയം"
    }
  },
  "sr": {
    "translation": {
      "Restore": "Ресторе",
      "You don't have any subscriptions": "Немате ниједну претплату",
      "Buy Premium": "Купите Премиум",
      "Premium": "Премиум",
      "Buy the Premium package and be free of Banner Ads": "Купите Премиум пакет и ослободите се банер огласа"
    }
  },
  "uk": {
    "translation": {
      "You don't have any subscriptions": "У вас немає жодної підписки",
      "Buy the Premium package and be free of Banner Ads": "Придбайте преміум-пакет і звільніться від рекламних банерів",
      "Buy Premium": "Купуйте Premium",
      "Restore": "Відновлення",
      "Premium": "Преміум"
    }
  },
  "it": {
    "translation": {
      "Restore": "Ristabilire",
      "You don't have any subscriptions": "Non hai abbonamenti",
      "Buy the Premium package and be free of Banner Ads": "Acquista il pacchetto Premium e liberati dai Banner Ads",
      "Premium": "Premium",
      "Buy Premium": "Acquista Premium"
    }
  },
  "hr": {
    "translation": {
      "You don't have any subscriptions": "Nemate nijednu pretplatu",
      "Buy Premium": "Kupite Premium",
      "Buy the Premium package and be free of Banner Ads": "Kupite Premium paket i oslobodite se banner oglasa",
      "Restore": "Vratiti",
      "Premium": "Premija"
    }
  },
  "no": {
    "translation": {
      "Buy Premium": "Kjøp Premium",
      "You don't have any subscriptions": "Du har ingen abonnementer",
      "Buy the Premium package and be free of Banner Ads": "Kjøp Premium-pakken og bli fri for bannerannonser",
      "Restore": "Restaurere",
      "Premium": "Premium"
    }
  },
  "ja": {
    "translation": {
      "Restore": "戻す",
      "Buy the Premium package and be free of Banner Ads": "プレミアム パッケージを購入すると、バナー広告が表示されなくなります",
      "Premium": "プレミアム",
      "Buy Premium": "プレミアムを購入",
      "You don't have any subscriptions": "サブスクリプションはありません"
    }
  },
  "ko": {
    "translation": {
      "Restore": "복원하다",
      "Buy Premium": "프리미엄 구매",
      "You don't have any subscriptions": "구독이 없습니다.",
      "Buy the Premium package and be free of Banner Ads": "프리미엄 패키지를 구매하고 배너 광고를 무료로 이용하세요",
      "Premium": "프리미엄"
    }
  },
  "pl": {
    "translation": {
      "Restore": "Przywrócić",
      "Buy Premium": "Kup Premium",
      "Premium": "Premia",
      "Buy the Premium package and be free of Banner Ads": "Kup pakiet Premium i uwolnij się od banerów reklamowych",
      "You don't have any subscriptions": "Nie masz żadnych subskrypcji"
    }
  },
  "sk": {
    "translation": {
      "Buy Premium": "Kúpiť Premium",
      "Buy the Premium package and be free of Banner Ads": "Kúpte si balík Premium a zbavte sa bannerových reklám",
      "You don't have any subscriptions": "Nemáte žiadne odbery",
      "Premium": "Premium",
      "Restore": "Obnoviť"
    }
  },
  "mt": {
    "translation": {
      "Premium": "Premium",
      "You don't have any subscriptions": "M'għandekx abbonamenti",
      "Buy Premium": "Ixtri Premium",
      "Restore": "Irrestawra",
      "Buy the Premium package and be free of Banner Ads": "Ixtri l-pakkett Premium u tkun ħielsa minn Banner Ads"
    }
  },
  "id": {
    "translation": {
      "Restore": "Memulihkan",
      "Buy the Premium package and be free of Banner Ads": "Beli paket Premium dan bebas dari Iklan Banner",
      "Premium": "Premium",
      "Buy Premium": "Beli Premium",
      "You don't have any subscriptions": "Anda tidak memiliki langganan apa pun"
    }
  },
  "haw": {
    "translation": {
      "You don't have any subscriptions": "ʻAʻohe āu kau inoa",
      "Buy Premium": "Kūʻai Premium",
      "Premium": "Premium",
      "Restore": "Hoʻihoʻi",
      "Buy the Premium package and be free of Banner Ads": "E kūʻai i ka pūʻolo Premium a e manuahi i nā hoʻolaha Banner"
    }
  },
  "iw": {
    "translation": {
      "Buy Premium": "קנה פרימיום",
      "Premium": "פּרֶמיָה",
      "Buy the Premium package and be free of Banner Ads": "קנה את חבילת הפרימיום והיה חופשי ממודעות באנר",
      "Restore": "לשחזר",
      "You don't have any subscriptions": "אין לך מנויים"
    }
  },
  "bg": {
    "translation": {
      "You don't have any subscriptions": "Нямате никакви абонаменти",
      "Buy Premium": "Купете Premium",
      "Restore": "Възстанови",
      "Premium": "Премиум",
      "Buy the Premium package and be free of Banner Ads": "Купете Premium пакета и се освободете от банерни реклами"
    }
  },
  "sv": {
    "translation": {
      "Premium": "Premie",
      "You don't have any subscriptions": "Du har inga prenumerationer",
      "Buy the Premium package and be free of Banner Ads": "Köp Premium-paketet och bli fri från bannerannonser",
      "Buy Premium": "Köp Premium",
      "Restore": "Återställ"
    }
  },
  "sq": {
    "translation": {
      "Buy the Premium package and be free of Banner Ads": "Blini paketën Premium dhe jini pa reklama me baner",
      "You don't have any subscriptions": "Nuk ke asnjë abonim",
      "Restore": "Rivendos",
      "Buy Premium": "Blini Premium",
      "Premium": "Premium"
    }
  }
}
const lang7 = {
  "en": {
    "translation": {
      "History": "History",
      "Subscriptions": "Subscriptions",
      "Channel": "Channel",
      "Related": "Related",
      "Subscribe": "Subscribe"
    }
  },
  "af": {
    "translation": {
      "Channel": "Kanaal",
      "Subscriptions": "Subskripsies",
      "Subscribe": "Teken in",
      "Related": "Verwante",
      "History": "Geskiedenis"
    }
  },
  "mk": {
    "translation": {
      "Subscriptions": "Претплати",
      "History": "Историја",
      "Channel": "Канал",
      "Subscribe": "Претплатете се",
      "Related": "Поврзани"
    }
  },
  "iw": {
    "translation": {
      "Subscriptions": "מנויים",
      "History": "הִיסטוֹרִיָה",
      "Subscribe": "הירשם",
      "Related": "קָשׁוּר",
      "Channel": "עָרוּץ"
    }
  },
  "fi": {
    "translation": {
      "Related": "Liittyvät",
      "Subscriptions": "Tilaukset",
      "Channel": "kanava",
      "Subscribe": "Tilaa",
      "History": "Historia"
    }
  },
  "uk": {
    "translation": {
      "Channel": "Канал",
      "Subscriptions": "Підписки",
      "History": "історія",
      "Related": "Пов'язані",
      "Subscribe": "Підпишіться"
    }
  },
  "pl": {
    "translation": {
      "Related": "Związane z",
      "Subscriptions": "Subskrypcje",
      "Channel": "Kanał",
      "Subscribe": "Subskrybuj",
      "History": "Historia"
    }
  },
  "da": {
    "translation": {
      "Subscribe": "Abonner",
      "Related": "Relaterede",
      "History": "Historie",
      "Channel": "Kanal",
      "Subscriptions": "Abonnementer"
    }
  },
  "bg": {
    "translation": {
      "Subscriptions": "Абонаменти",
      "Subscribe": "Абонирай се",
      "Related": "Свързани",
      "Channel": "канал",
      "History": "История"
    }
  },
  "ne": {
    "translation": {
      "History": "इतिहास",
      "Related": "सम्बन्धित",
      "Subscribe": "सदस्यता लिनुहोस्",
      "Subscriptions": "सदस्यताहरू",
      "Channel": "च्यानल"
    }
  },
  "el": {
    "translation": {
      "History": "Ιστορία",
      "Subscribe": "Εγγραφείτε",
      "Channel": "Κανάλι",
      "Related": "Σχετίζεται με",
      "Subscriptions": "Συνδρομές"
    }
  },
  "sk": {
    "translation": {
      "History": "História",
      "Channel": "kanál",
      "Related": "Súvisiace",
      "Subscriptions": "Predplatné",
      "Subscribe": "Prihlásiť sa na odber"
    }
  },
  "mt": {
    "translation": {
      "Channel": "Kanal",
      "History": "Storja",
      "Subscriptions": "Abbonamenti",
      "Subscribe": "Abbona",
      "Related": "Relatati"
    }
  },
  "ro": {
    "translation": {
      "Subscribe": "Abonati-va",
      "History": "Istorie",
      "Channel": "Canal",
      "Related": "Legate de",
      "Subscriptions": "Abonamente"
    }
  },
  "lv": {
    "translation": {
      "History": "Vēsture",
      "Subscriptions": "Abonementi",
      "Related": "Saistīts",
      "Channel": "Kanāls",
      "Subscribe": "Abonēt"
    }
  },
  "it": {
    "translation": {
      "Channel": "Canale",
      "History": "Storia",
      "Subscribe": "sottoscrivi",
      "Related": "Imparentato",
      "Subscriptions": "Abbonamenti"
    }
  },
  "ml": {
    "translation": {
      "History": "ചരിത്രം",
      "Related": "ബന്ധപ്പെട്ട",
      "Subscriptions": "സബ്സ്ക്രിപ്ഷനുകൾ",
      "Subscribe": "സബ്സ്ക്രൈബ് ചെയ്യുക",
      "Channel": "ചാനൽ"
    }
  },
  "ga": {
    "translation": {
      "History": "Stair",
      "Subscriptions": "Síntiúis",
      "Subscribe": "Liostáil",
      "Related": "Gaolmhar",
      "Channel": "Cainéal"
    }
  },
  "lt": {
    "translation": {
      "History": "Istorija",
      "Subscriptions": "Prenumeratos",
      "Related": "Susijęs",
      "Subscribe": "Prenumeruoti",
      "Channel": "Kanalas"
    }
  },
  "bs": {
    "translation": {
      "Related": "Povezano",
      "Subscribe": "Pretplatite se",
      "Channel": "Kanal",
      "Subscriptions": "Pretplate",
      "History": "istorija"
    }
  },
  "hr": {
    "translation": {
      "Subscriptions": "Pretplate",
      "Channel": "Kanal",
      "Subscribe": "Pretplatite se",
      "Related": "Povezano",
      "History": "Povijest"
    }
  },
  "sv": {
    "translation": {
      "History": "Historia",
      "Related": "Relaterad",
      "Channel": "Kanal",
      "Subscribe": "Prenumerera",
      "Subscriptions": "Prenumerationer"
    }
  },
  "hu": {
    "translation": {
      "Channel": "Csatorna",
      "Subscriptions": "Előfizetések",
      "Subscribe": "Iratkozz fel",
      "Related": "Összefüggő",
      "History": "Történelem"
    }
  },
  "fr": {
    "translation": {
      "Subscriptions": "Abonnements",
      "Channel": "Canaliser",
      "Subscribe": "S'abonner",
      "History": "Histoire",
      "Related": "Lié"
    }
  },
  "ca": {
    "translation": {
      "History": "Història",
      "Subscriptions": "Subscripcions",
      "Related": "Relacionats",
      "Subscribe": "Subscriu-te",
      "Channel": "Canal"
    }
  },
  "cs": {
    "translation": {
      "Subscribe": "předplatit",
      "Related": "Příbuzný",
      "History": "Dějiny",
      "Subscriptions": "Předplatné",
      "Channel": "Kanál"
    }
  },
  "tr": {
    "translation": {
      "Subscriptions": "abonelikler",
      "History": "Tarih",
      "Subscribe": "Abone olmak",
      "Channel": "Kanal",
      "Related": "İlişkili"
    }
  },
  "sl": {
    "translation": {
      "History": "Zgodovina",
      "Channel": "Kanal",
      "Related": "Povezano",
      "Subscribe": "Naročite se",
      "Subscriptions": "Naročnine"
    }
  },
  "co": {
    "translation": {
      "Channel": "Canale",
      "Related": "Relativu",
      "Subscribe": "Abbonate",
      "Subscriptions": "Abbonamenti",
      "History": "Storia"
    }
  },
  "am": {
    "translation": {
      "History": "ታሪክ",
      "Subscribe": "ሰብስክራይብ ያድርጉ",
      "Related": "ተዛማጅ",
      "Subscriptions": "የደንበኝነት ምዝገባዎች",
      "Channel": "ቻናል"
    }
  },
  "hy": {
    "translation": {
      "Channel": "Ալիք",
      "Subscriptions": "Բաժանորդագրություններ",
      "History": "Պատմություն",
      "Subscribe": "Բաժանորդագրվել",
      "Related": "Առնչվող"
    }
  },
  "ja": {
    "translation": {
      "History": "歴史",
      "Channel": "チャネル",
      "Subscriptions": "サブスクリプション",
      "Related": "関連している",
      "Subscribe": "申し込む"
    }
  },
  "sq": {
    "translation": {
      "History": "Historia",
      "Channel": "Kanali",
      "Subscriptions": "Abonimet",
      "Related": "Të lidhura",
      "Subscribe": "Abonohu"
    }
  },
  "de": {
    "translation": {
      "Subscribe": "Abonnieren",
      "Subscriptions": "Abonnements",
      "Related": "Verwandt",
      "History": "Geschichte",
      "Channel": "Kanal"
    }
  },
  "haw": {
    "translation": {
      "Subscribe": "Kau inoa",
      "History": "Moolelo",
      "Channel": "Kanal",
      "Subscriptions": "Kau inoa",
      "Related": "Pili"
    }
  },
  "hi": {
    "translation": {
      "Subscribe": "सदस्यता लेने के",
      "Subscriptions": "सदस्यता",
      "History": "इतिहास",
      "Related": "सम्बंधित",
      "Channel": "चैनल"
    }
  },
  "et": {
    "translation": {
      "Channel": "Kanal",
      "Subscribe": "Telli",
      "Subscriptions": "Tellimused",
      "History": "Ajalugu",
      "Related": "Seotud"
    }
  },
  "ko": {
    "translation": {
      "History": "역사",
      "Subscribe": "구독하다",
      "Related": "관련된",
      "Subscriptions": "구독",
      "Channel": "채널"
    }
  },
  "zh": {
    "translation": {
      "History": "历史",
      "Subscriptions": "订阅",
      "Channel": "渠道",
      "Related": "有关的",
      "Subscribe": "订阅"
    }
  },
  "id": {
    "translation": {
      "Subscribe": "Langganan",
      "Channel": "Saluran",
      "Subscriptions": "Langganan",
      "Related": "Terkait",
      "History": "Sejarah"
    }
  },
  "vi": {
    "translation": {
      "Subscriptions": "Đăng ký",
      "Related": "Có liên quan",
      "Channel": "Kênh truyền hình",
      "Subscribe": "Đặt mua",
      "History": "Lịch sử"
    }
  },
  "no": {
    "translation": {
      "Channel": "Kanal",
      "Related": "I slekt",
      "Subscriptions": "Abonnementer",
      "History": "Historie",
      "Subscribe": "Abonnere"
    }
  },
  "mg": {
    "translation": {
      "Subscribe": "Raiso amin ny",
      "Subscriptions": "Famandrihana",
      "Related": "mifandraika",
      "History": "FIAINANY TALOHA",
      "Channel": "fantsona"
    }
  },
  "es": {
    "translation": {
      "History": "Historia",
      "Subscriptions": "Suscripciones",
      "Channel": "Canal",
      "Subscribe": "Suscribir",
      "Related": "Relacionado"
    }
  },
  "nl": {
    "translation": {
      "History": "Geschiedenis",
      "Subscriptions": "Abonnementen",
      "Subscribe": "Abonneren",
      "Related": "Verwant",
      "Channel": "Kanaal"
    }
  },
  "sr": {
    "translation": {
      "Related": "Повезан",
      "History": "Историја",
      "Channel": "Канал",
      "Subscriptions": "Претплате",
      "Subscribe": "претплатити се"
    }
  },
  "ru": {
    "translation": {
      "History": "История",
      "Related": "Связанный",
      "Subscriptions": "Подписки",
      "Channel": "Канал",
      "Subscribe": "Подписывайся"
    }
  },
  "sm": {
    "translation": {
      "Channel": "Auala",
      "History": "Tala'aga",
      "Related": "Fa'atatau",
      "Subscriptions": "Fa'atauga",
      "Subscribe": "Fa'asoa"
    }
  },
  "ar": {
    "translation": {
      "History": "تاريخ",
      "Subscribe": "الإشتراك",
      "Channel": "قناة",
      "Subscriptions": "الاشتراكات",
      "Related": "متعلق ب"
    }
  },
  "pt": {
    "translation": {
      "Subscribe": "Se inscrever",
      "Channel": "Canal",
      "History": "História",
      "Subscriptions": "Assinaturas",
      "Related": "Relacionado"
    }
  },
  "is": {
    "translation": {
      "History": "Saga",
      "Channel": "Rás",
      "Subscriptions": "Áskriftir",
      "Subscribe": "Gerast áskrifandi",
      "Related": "Tengt"
    }
  }
}
const lang6 = {
  "en": {
    "translation": {
      "You must be signed in to subscribe": "You must be signed in to subscribe",
      "Successfully subscribed": "Successfully subscribed"
    }
  },
  "it": {
    "translation": {
      "Successfully subscribed": "Iscritto con successo",
      "You must be signed in to subscribe": "Devi essere registrato per iscriverti"
    }
  },
  "is": {
    "translation": {
      "Successfully subscribed": "Tókst áskrifandi",
      "You must be signed in to subscribe": "Þú verður að vera skráður inn til að gerast áskrifandi"
    }
  },
  "no": {
    "translation": {
      "Successfully subscribed": "Vellykket abonnement",
      "You must be signed in to subscribe": "Du må være pålogget for å abonnere"
    }
  },
  "ja": {
    "translation": {
      "You must be signed in to subscribe": "購読するにはサインインする必要があります",
      "Successfully subscribed": "正常にサブスクライブしました"
    }
  },
  "am": {
    "translation": {
      "You must be signed in to subscribe": "ለመመዝገብ መግባት አለብህ",
      "Successfully subscribed": "በተሳካ ሁኔታ ተመዝግቧል"
    }
  },
  "ro": {
    "translation": {
      "You must be signed in to subscribe": "Trebuie să fiți conectat pentru a vă abona",
      "Successfully subscribed": "S-a abonat cu succes"
    }
  },
  "pl": {
    "translation": {
      "Successfully subscribed": "Pomyślnie zasubskrybowano",
      "You must be signed in to subscribe": "Aby subskrybować, musisz się zalogować"
    }
  },
  "nl": {
    "translation": {
      "You must be signed in to subscribe": "Je moet ingelogd zijn om je te abonneren",
      "Successfully subscribed": "Succesvol geabonneerd"
    }
  },
  "fr": {
    "translation": {
      "Successfully subscribed": "Abonné avec succès",
      "You must be signed in to subscribe": "Vous devez être connecté pour vous inscrire"
    }
  },
  "haw": {
    "translation": {
      "Successfully subscribed": "Ua hoʻopaʻa inoa maikaʻi",
      "You must be signed in to subscribe": "Pono ʻoe e hoʻopaʻa inoa"
    }
  },
  "lv": {
    "translation": {
      "You must be signed in to subscribe": "Lai abonētu, jums ir jāpierakstās",
      "Successfully subscribed": "Veiksmīgi abonēts"
    }
  },
  "sk": {
    "translation": {
      "Successfully subscribed": "Úspešne prihlásení na odber",
      "You must be signed in to subscribe": "Na odber musíte byť prihlásený"
    }
  },
  "pt": {
    "translation": {
      "You must be signed in to subscribe": "Você deve estar conectado para se inscrever",
      "Successfully subscribed": "Inscrito com sucesso"
    }
  },
  "hy": {
    "translation": {
      "Successfully subscribed": "Բաժանորդագրվել է",
      "You must be signed in to subscribe": "Բաժանորդագրվելու համար դուք պետք է մուտք գործեք"
    }
  },
  "uk": {
    "translation": {
      "You must be signed in to subscribe": "Ви повинні увійти, щоб підписатися",
      "Successfully subscribed": "Успішно підписаний"
    }
  },
  "cs": {
    "translation": {
      "Successfully subscribed": "Úspěšně přihlášeni",
      "You must be signed in to subscribe": "K odběru musíte být přihlášeni"
    }
  },
  "mk": {
    "translation": {
      "You must be signed in to subscribe": "Мора да бидете најавени за да се претплатите",
      "Successfully subscribed": "Успешно претплатен"
    }
  },
  "el": {
    "translation": {
      "You must be signed in to subscribe": "Πρέπει να είστε συνδεδεμένοι για να εγγραφείτε",
      "Successfully subscribed": "Έγινε επιτυχής εγγραφή"
    }
  },
  "ar": {
    "translation": {
      "You must be signed in to subscribe": "يجب عليك تسجيل الدخول للاشتراك",
      "Successfully subscribed": "تم الاشتراك بنجاح"
    }
  },
  "tr": {
    "translation": {
      "Successfully subscribed": "Başarıyla Abone Oldunuz",
      "You must be signed in to subscribe": "Abone olmak için oturum açmalısınız"
    }
  },
  "ru": {
    "translation": {
      "You must be signed in to subscribe": "Вы должны войти в систему, чтобы подписаться",
      "Successfully subscribed": "Успешная подписка"
    }
  },
  "id": {
    "translation": {
      "You must be signed in to subscribe": "Anda harus masuk untuk berlangganan",
      "Successfully subscribed": "Berhasil berlangganan"
    }
  },
  "vi": {
    "translation": {
      "You must be signed in to subscribe": "Bạn phải đăng nhập để đăng ký",
      "Successfully subscribed": "Đã đăng ký thành công"
    }
  },
  "fi": {
    "translation": {
      "Successfully subscribed": "Tilaus onnistui",
      "You must be signed in to subscribe": "Sinun on kirjauduttava sisään tilataksesi"
    }
  },
  "iw": {
    "translation": {
      "Successfully subscribed": "נרשם בהצלחה",
      "You must be signed in to subscribe": "עליך להיות מחובר כדי להירשם"
    }
  },
  "mg": {
    "translation": {
      "Successfully subscribed": "Nisoratra anarana soa aman-tsara",
      "You must be signed in to subscribe": "Tsy maintsy miditra ianao raha te hisoratra anarana"
    }
  },
  "bg": {
    "translation": {
      "Successfully subscribed": "Абонирахте се успешно",
      "You must be signed in to subscribe": "Трябва да сте влезли, за да се абонирате"
    }
  },
  "hi": {
    "translation": {
      "You must be signed in to subscribe": "सदस्यता लेने के लिए आपको साइन इन करना होगा",
      "Successfully subscribed": "सफलतापूर्वक सब्सक्राइब किया गया"
    }
  },
  "hu": {
    "translation": {
      "Successfully subscribed": "Sikeres előfizetés",
      "You must be signed in to subscribe": "Az előfizetéshez be kell jelentkeznie"
    }
  },
  "af": {
    "translation": {
      "You must be signed in to subscribe": "Jy moet aangemeld wees om in te teken",
      "Successfully subscribed": "Suksesvol ingeteken"
    }
  },
  "bs": {
    "translation": {
      "You must be signed in to subscribe": "Morate biti prijavljeni da biste se pretplatili",
      "Successfully subscribed": "Uspješno pretplaćen"
    }
  },
  "lt": {
    "translation": {
      "Successfully subscribed": "Sėkmingai užsiprenumeravote",
      "You must be signed in to subscribe": "Norėdami užsiprenumeruoti, turite būti prisijungę"
    }
  },
  "co": {
    "translation": {
      "Successfully subscribed": "Abbonatu successu",
      "You must be signed in to subscribe": "Duvete esse cunnessu per abbunà"
    }
  },
  "sl": {
    "translation": {
      "You must be signed in to subscribe": "Če se želite naročiti, morate biti prijavljeni",
      "Successfully subscribed": "Uspešno naročen"
    }
  },
  "ml": {
    "translation": {
      "You must be signed in to subscribe": "സബ്‌സ്‌ക്രൈബുചെയ്യാൻ നിങ്ങൾ സൈൻ ഇൻ ചെയ്‌തിരിക്കണം",
      "Successfully subscribed": "സബ്‌സ്‌ക്രൈബ് ചെയ്തു"
    }
  },
  "sr": {
    "translation": {
      "Successfully subscribed": "Успешно сте пријављени",
      "You must be signed in to subscribe": "Морате бити пријављени да бисте се претплатили"
    }
  },
  "sq": {
    "translation": {
      "You must be signed in to subscribe": "Duhet të identifikohesh për t'u abonuar",
      "Successfully subscribed": "U abonua me sukses"
    }
  },
  "hr": {
    "translation": {
      "You must be signed in to subscribe": "Morate biti prijavljeni da biste se pretplatili",
      "Successfully subscribed": "Uspješno ste pretplaćeni"
    }
  },
  "ca": {
    "translation": {
      "You must be signed in to subscribe": "Heu d'iniciar la sessió per subscriure-us",
      "Successfully subscribed": "S'ha subscrit correctament"
    }
  },
  "de": {
    "translation": {
      "Successfully subscribed": "Erfolgreich abonniert",
      "You must be signed in to subscribe": "Sie müssen angemeldet sein, um sich anzumelden"
    }
  },
  "sm": {
    "translation": {
      "Successfully subscribed": "Manuia le lesitala",
      "You must be signed in to subscribe": "E tatau ona e saini e te lesitala"
    }
  },
  "zh": {
    "translation": {
      "Successfully subscribed": "订阅成功",
      "You must be signed in to subscribe": "您必须登录才能订阅"
    }
  },
  "ko": {
    "translation": {
      "Successfully subscribed": "성공적으로 구독",
      "You must be signed in to subscribe": "구독하려면 로그인해야 합니다."
    }
  },
  "sv": {
    "translation": {
      "You must be signed in to subscribe": "Du måste vara inloggad för att prenumerera",
      "Successfully subscribed": "Prenumererade framgångsrikt"
    }
  },
  "es": {
    "translation": {
      "You must be signed in to subscribe": "Debes iniciar sesión para suscribirte",
      "Successfully subscribed": "Suscrito correctamente"
    }
  },
  "ne": {
    "translation": {
      "You must be signed in to subscribe": "तपाईंले सदस्यता लिन साइन इन गर्नुपर्छ",
      "Successfully subscribed": "सफलतापूर्वक सदस्यता लिइयो"
    }
  },
  "et": {
    "translation": {
      "You must be signed in to subscribe": "Tellimiseks peate olema sisse logitud",
      "Successfully subscribed": "Tellimine õnnestus"
    }
  },
  "ga": {
    "translation": {
      "Successfully subscribed": "D'éirigh leis an suibscríobh",
      "You must be signed in to subscribe": "Ní mór duit a bheith sínithe isteach chun síntiús a íoc"
    }
  },
  "da": {
    "translation": {
      "Successfully subscribed": "Tilmeldt",
      "You must be signed in to subscribe": "Du skal være logget ind for at abonnere"
    }
  },
  "mt": {
    "translation": {
      "You must be signed in to subscribe": "Trid tkun iffirmat biex tabbona",
      "Successfully subscribed": "Abbonat b'suċċess"
    }
  }
}
const lang5 = {
  "en": {
    "translation": {
      "Related videos": "Related videos",
      "Channel videos": "Channel videos",
      "Subscribe to channel": "Subscribe to channel"
    }
  },
  "no": {
    "translation": {
      "Channel videos": "Kanalvideoer",
      "Related videos": "Relaterte videoer",
      "Subscribe to channel": "Abonner på kanalen"
    }
  },
  "hr": {
    "translation": {
      "Related videos": "Slični Videi",
      "Channel videos": "Videozapisi kanala",
      "Subscribe to channel": "Pretplatite se na kanal"
    }
  },
  "sr": {
    "translation": {
      "Subscribe to channel": "Претплатите се на канал",
      "Channel videos": "Видео снимци канала",
      "Related videos": "Повезани видео снимци"
    }
  },
  "ar": {
    "translation": {
      "Subscribe to channel": "اشترك في القناة",
      "Related videos": "فيديوهات ذات علاقة",
      "Channel videos": "فيديوهات القناة"
    }
  },
  "nl": {
    "translation": {
      "Related videos": "Gerelateerde video's",
      "Subscribe to channel": "Abonneer op kanaal",
      "Channel videos": "Kanaalvideo's"
    }
  },
  "ga": {
    "translation": {
      "Subscribe to channel": "Liostáil le cainéal",
      "Related videos": "Físeáin ghaolmhara",
      "Channel videos": "Físeáin cainéal"
    }
  },
  "et": {
    "translation": {
      "Subscribe to channel": "Telli kanal",
      "Related videos": "Seotud videod",
      "Channel videos": "Kanali videod"
    }
  },
  "es": {
    "translation": {
      "Channel videos": "Vídeos del canal",
      "Related videos": "Videos relacionados",
      "Subscribe to channel": "Suscríbete al canal"
    }
  },
  "vi": {
    "translation": {
      "Related videos": "Các video liên quan",
      "Channel videos": "Video của kênh",
      "Subscribe to channel": "Đăng ký kênh"
    }
  },
  "tr": {
    "translation": {
      "Channel videos": "Kanal videoları",
      "Related videos": "İlgili videolar",
      "Subscribe to channel": "kanala abone ol"
    }
  },
  "is": {
    "translation": {
      "Related videos": "Tengd myndbönd",
      "Channel videos": "Rásar myndbönd",
      "Subscribe to channel": "Gerast áskrifandi að rás"
    }
  },
  "bs": {
    "translation": {
      "Related videos": "Povezani video zapisi",
      "Channel videos": "Video zapisi kanala",
      "Subscribe to channel": "Pretplatite se na kanal"
    }
  },
  "id": {
    "translation": {
      "Channel videos": "Video saluran",
      "Related videos": "Video Terkait",
      "Subscribe to channel": "Berlangganan saluran"
    }
  },
  "it": {
    "translation": {
      "Subscribe to channel": "Iscriviti al canale",
      "Related videos": "Video collegati",
      "Channel videos": "Video del canale"
    }
  },
  "de": {
    "translation": {
      "Subscribe to channel": "Kanal abonnieren",
      "Channel videos": "Kanalvideos",
      "Related videos": "Ähnliche Videos"
    }
  },
  "mt": {
    "translation": {
      "Channel videos": "Filmati tal-kanal",
      "Subscribe to channel": "Abbona għall-kanal",
      "Related videos": "Filmati relatati"
    }
  },
  "sq": {
    "translation": {
      "Channel videos": "Videot e kanalit",
      "Related videos": "Video të ngjashme",
      "Subscribe to channel": "Abonohuni në kanal"
    }
  },
  "mk": {
    "translation": {
      "Subscribe to channel": "Претплатете се на каналот",
      "Channel videos": "Видеа на каналот",
      "Related videos": "Поврзани видеа"
    }
  },
  "ml": {
    "translation": {
      "Channel videos": "ചാനൽ വീഡിയോകൾ",
      "Related videos": "ബന്ധപ്പെട്ട വീഡിയോകൾ",
      "Subscribe to channel": "ചാനൽ സബ്സ്ക്രൈബ് ചെയ്യുക"
    }
  },
  "sl": {
    "translation": {
      "Related videos": "Povezani videoposnetki",
      "Channel videos": "Videoposnetki kanala",
      "Subscribe to channel": "Naročite se na kanal"
    }
  },
  "hi": {
    "translation": {
      "Subscribe to channel": "चैनल को सब्सक्राइब करें",
      "Related videos": "संबंधित वीडियो",
      "Channel videos": "चैनल वीडियो"
    }
  },
  "ja": {
    "translation": {
      "Subscribe to channel": "チャネルを購読する",
      "Related videos": "関連動画",
      "Channel videos": "チャンネル動画"
    }
  },
  "iw": {
    "translation": {
      "Subscribe to channel": "הירשמו לערוץ",
      "Channel videos": "סרטוני ערוץ",
      "Related videos": "סרטונים קשורים"
    }
  },
  "am": {
    "translation": {
      "Subscribe to channel": "ለሰርጥ ይመዝገቡ",
      "Related videos": "ተዛማጅ ቪዲዮዎች",
      "Channel videos": "የሰርጥ ቪዲዮዎች"
    }
  },
  "sk": {
    "translation": {
      "Subscribe to channel": "Prihláste sa na odber kanála",
      "Channel videos": "Videá kanála",
      "Related videos": "Podobné videá"
    }
  },
  "fi": {
    "translation": {
      "Related videos": "Liittyvät videot",
      "Channel videos": "Kanavan videoita",
      "Subscribe to channel": "Tilaa kanava"
    }
  },
  "hu": {
    "translation": {
      "Related videos": "Kapcsolódó videók",
      "Subscribe to channel": "Iratkozz fel a csatornára",
      "Channel videos": "Csatorna videók"
    }
  },
  "fr": {
    "translation": {
      "Subscribe to channel": "Abonnez-vous à la chaîne",
      "Channel videos": "Vidéos de la chaîne",
      "Related videos": "Vidéos connexes"
    }
  },
  "ne": {
    "translation": {
      "Subscribe to channel": "च्यानल सब्सक्राइब गर्नुहोस्",
      "Channel videos": "च्यानल भिडियोहरू",
      "Related videos": "सम्बन्धित भिडियोहरू"
    }
  },
  "bg": {
    "translation": {
      "Related videos": "Подобни видеа",
      "Channel videos": "Видеоклипове на канала",
      "Subscribe to channel": "Абонирайте се за канала"
    }
  },
  "ro": {
    "translation": {
      "Channel videos": "Videoclipuri pe canal",
      "Subscribe to channel": "Abonați-vă la canal",
      "Related videos": "Videoclipuri asemănătoare"
    }
  },
  "cs": {
    "translation": {
      "Subscribe to channel": "Přihlásit se k odběru kanálu",
      "Related videos": "Související videa",
      "Channel videos": "Videa kanálu"
    }
  },
  "mg": {
    "translation": {
      "Subscribe to channel": "Misoratra anarana amin'ny fantsona",
      "Channel videos": "Horonantsary fantsona",
      "Related videos": "Lahatsary mifandraika amin'izany"
    }
  },
  "ca": {
    "translation": {
      "Channel videos": "Vídeos del canal",
      "Related videos": "Vídeos relacionats",
      "Subscribe to channel": "Subscriu-te al canal"
    }
  },
  "co": {
    "translation": {
      "Channel videos": "Video di u canali",
      "Related videos": "Video cunnessi",
      "Subscribe to channel": "Abbonate à u canali"
    }
  },
  "ko": { "translation": { "Related videos": "관련 동영상", "Channel videos": "채널 비디오", "Subscribe to channel": "채널 구독" } },
  "sv": {
    "translation": {
      "Related videos": "Relaterade videoklipp",
      "Channel videos": "Kanalvideor",
      "Subscribe to channel": "Prenumerera på kanalen"
    }
  },
  "lt": {
    "translation": {
      "Related videos": "Susiję vaizdo įrašai",
      "Channel videos": "Kanalo vaizdo įrašai",
      "Subscribe to channel": "Prenumeruokite kanalą"
    }
  },
  "haw": {
    "translation": {
      "Related videos": "Nā wikiō pili",
      "Channel videos": "Nā wikiō channel",
      "Subscribe to channel": "E kau inoa i ke kahawai"
    }
  },
  "pl": {
    "translation": {
      "Subscribe to channel": "Subskrybuj kanał",
      "Channel videos": "Filmy z kanału",
      "Related videos": "Powiązane wideo"
    }
  },
  "uk": {
    "translation": {
      "Channel videos": "Відео каналу",
      "Related videos": "Пов'язані відео",
      "Subscribe to channel": "Підписуйтесь на канал"
    }
  },
  "hy": {
    "translation": {
      "Related videos": "Առնչվող տեսանյութեր",
      "Subscribe to channel": "Բաժանորդագրվեք ալիքին",
      "Channel videos": "Ալիքի տեսանյութեր"
    }
  },
  "el": {
    "translation": {
      "Related videos": "Σχετικά βίντεο",
      "Channel videos": "Βίντεο καναλιών",
      "Subscribe to channel": "Εγγραφείτε στο κανάλι"
    }
  },
  "da": {
    "translation": {
      "Related videos": "Lignende videoer",
      "Channel videos": "Kanalvideoer",
      "Subscribe to channel": "Abonner på kanalen"
    }
  },
  "sm": {
    "translation": {
      "Channel videos": "Ala vitiō",
      "Related videos": "Vitio fa'atatau",
      "Subscribe to channel": "Fa'asoa ile alaleo"
    }
  },
  "zh": {
    "translation": {
      "Subscribe to channel": "订阅频道",
      "Channel videos": "频道视频",
      "Related videos": "有关的影片"
    }
  },
  "lv": {
    "translation": {
      "Related videos": "Saistītie video",
      "Channel videos": "Kanāla video",
      "Subscribe to channel": "Abonēt kanālu"
    }
  },
  "pt": {
    "translation": {
      "Related videos": "Vídeos relacionados",
      "Subscribe to channel": "Inscreva-se no canal",
      "Channel videos": "Vídeos do canal"
    }
  },
  "af": {
    "translation": {
      "Related videos": "Verwante video's",
      "Channel videos": "Kanaalvideo's",
      "Subscribe to channel": "Teken in op die kanaal"
    }
  },
  "ru": {
    "translation": {
      "Related videos": "Похожие видео",
      "Channel videos": "Видео канала",
      "Subscribe to channel": "Подписаться на канал"
    }
  }
}
const lang4 = {
  "en": {
    "translation": {
      "Download the mobile app for a better experience": "Download the mobile app for a better experience",
      "You need to be signed in and have a premium subscription to access this feature": "You need to be signed in and have a premium subscription to access this feature",
      "Make me premium": "Make me premium"
    }
  },
  "ko": {
    "translation": {
      "Make me premium": "나를 프리미엄으로 만들어줘",
      "You need to be signed in and have a premium subscription to access this feature": "이 기능에 액세스하려면 로그인하고 프리미엄 구독이 필요합니다.",
      "Download the mobile app for a better experience": "더 나은 경험을 위해 모바일 앱을 다운로드하세요"
    }
  },
  "hr": {
    "translation": {
      "Make me premium": "Učini me premium",
      "Download the mobile app for a better experience": "Preuzmite mobilnu aplikaciju za bolje iskustvo",
      "You need to be signed in and have a premium subscription to access this feature": "Morate biti prijavljeni i imati premium pretplatu da biste pristupili ovoj značajci"
    }
  },
  "nl": {
    "translation": {
      "Make me premium": "Maak me premium",
      "You need to be signed in and have a premium subscription to access this feature": "Je moet ingelogd zijn en een premium abonnement hebben om toegang te krijgen tot deze functie",
      "Download the mobile app for a better experience": "Download de mobiele app voor een betere ervaring"
    }
  },
  "fi": {
    "translation": {
      "Make me premium": "Tee minusta premium",
      "Download the mobile app for a better experience": "Lataa mobiilisovellus saadaksesi paremman käyttökokemuksen",
      "You need to be signed in and have a premium subscription to access this feature": "Sinun on oltava kirjautuneena sisään ja sinulla on oltava premium-tilaus käyttääksesi tätä ominaisuutta"
    }
  },
  "no": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Du må være pålogget og ha et premium-abonnement for å få tilgang til denne funksjonen",
      "Make me premium": "Gjør meg premium",
      "Download the mobile app for a better experience": "Last ned mobilappen for en bedre opplevelse"
    }
  },
  "lv": {
    "translation": {
      "Download the mobile app for a better experience": "Lejupielādējiet mobilo lietotni, lai iegūtu labāku pieredzi",
      "You need to be signed in and have a premium subscription to access this feature": "Lai piekļūtu šai funkcijai, jums ir jāpierakstās un jābūt maksas abonementam",
      "Make me premium": "Padariet man premium"
    }
  },
  "co": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Avete bisognu à esse firmatu è avè un abbonamentu premium per accede à sta funzione",
      "Download the mobile app for a better experience": "Scaricate l'app mobile per una sperienza megliu",
      "Make me premium": "Fatemi premium"
    }
  },
  "sq": {
    "translation": {
      "Make me premium": "Më bëj premium",
      "You need to be signed in and have a premium subscription to access this feature": "Duhet të jeni i identifikuar dhe të keni një abonim premium për të hyrë në këtë funksion",
      "Download the mobile app for a better experience": "Shkarkoni aplikacionin celular për një përvojë më të mirë"
    }
  },
  "hi": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "इस सुविधा को एक्सेस करने के लिए आपको साइन इन करना होगा और प्रीमियम सदस्यता लेनी होगी",
      "Make me premium": "मुझे प्रीमियम बनाओ",
      "Download the mobile app for a better experience": "बेहतर अनुभव के लिए मोबाइल ऐप डाउनलोड करें"
    }
  },
  "sl": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Za dostop do te funkcije morate biti prijavljeni in imeti premium naročnino",
      "Download the mobile app for a better experience": "Prenesite mobilno aplikacijo za boljšo izkušnjo",
      "Make me premium": "Naredi me premium"
    }
  },
  "mt": {
    "translation": {
      "Download the mobile app for a better experience": "Niżżel l-app mobbli għal esperjenza aħjar",
      "You need to be signed in and have a premium subscription to access this feature": "Jeħtieġ li tkun iffirmat u jkollok abbonament premium biex taċċessa din il-karatteristika",
      "Make me premium": "Agħmilni premium"
    }
  },
  "tr": {
    "translation": {
      "Download the mobile app for a better experience": "Daha iyi bir deneyim için mobil uygulamayı indirin",
      "Make me premium": "beni premium yap",
      "You need to be signed in and have a premium subscription to access this feature": "Bu özelliğe erişmek için oturum açmanız ve premium aboneliğinizin olması gerekir"
    }
  },
  "pt": {
    "translation": {
      "Make me premium": "Faça-me premium",
      "You need to be signed in and have a premium subscription to access this feature": "Você precisa estar conectado e ter uma assinatura premium para acessar este recurso",
      "Download the mobile app for a better experience": "Baixe o aplicativo móvel para uma melhor experiência"
    }
  },
  "et": {
    "translation": {
      "Make me premium": "Tee mulle lisatasu",
      "Download the mobile app for a better experience": "Parema kasutuskogemuse saamiseks laadige alla mobiilirakendus",
      "You need to be signed in and have a premium subscription to access this feature": "Selle funktsiooni kasutamiseks peate olema sisse logitud ja omama tasulist tellimust"
    }
  },
  "bs": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Morate biti prijavljeni i imati premium pretplatu da biste pristupili ovoj funkciji",
      "Make me premium": "Učini me premium",
      "Download the mobile app for a better experience": "Preuzmite mobilnu aplikaciju za bolje iskustvo"
    }
  },
  "hu": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "A funkció eléréséhez be kell jelentkeznie, és prémium előfizetéssel kell rendelkeznie",
      "Make me premium": "Legyen prémium",
      "Download the mobile app for a better experience": "Töltse le a mobilalkalmazást a jobb élmény érdekében"
    }
  },
  "zh": {
    "translation": {
      "Make me premium": "让我溢价",
      "Download the mobile app for a better experience": "下载移动应用程序以获得更好的体验",
      "You need to be signed in and have a premium subscription to access this feature": "您需要登录并拥有高级订阅才能访问此功能"
    }
  },
  "haw": {
    "translation": {
      "Download the mobile app for a better experience": "Hoʻoiho i ka polokalamu kelepona no kahi ʻike maikaʻi aʻe",
      "You need to be signed in and have a premium subscription to access this feature": "Pono ʻoe e hoʻopaʻa inoa a loaʻa kahi kau inoa premium e komo i kēia hiʻohiʻona",
      "Make me premium": "E hoʻolilo iaʻu i ka uku"
    }
  },
  "ga": {
    "translation": {
      "Download the mobile app for a better experience": "Íoslódáil an app soghluaiste le haghaidh taithí níos fearr",
      "Make me premium": "Déan préimh dom",
      "You need to be signed in and have a premium subscription to access this feature": "Ní mór duit a bheith sínithe isteach agus síntiús préimhe a bheith agat chun rochtain a fháil ar an ngné seo"
    }
  },
  "sk": {
    "translation": {
      "Make me premium": "Urobte zo mňa prémiu",
      "You need to be signed in and have a premium subscription to access this feature": "Na prístup k tejto funkcii musíte byť prihlásení a mať prémiové predplatné",
      "Download the mobile app for a better experience": "Stiahnite si mobilnú aplikáciu pre lepší zážitok"
    }
  },
  "ru": {
    "translation": {
      "Download the mobile app for a better experience": "Скачайте мобильное приложение для большего удобства",
      "You need to be signed in and have a premium subscription to access this feature": "Вы должны войти в систему и иметь премиум-подписку, чтобы получить доступ к этой функции.",
      "Make me premium": "Сделай меня премиальным"
    }
  },
  "de": {
    "translation": {
      "Download the mobile app for a better experience": "Laden Sie die mobile App für ein besseres Erlebnis herunter",
      "You need to be signed in and have a premium subscription to access this feature": "Sie müssen angemeldet sein und über ein Premium-Abonnement verfügen, um auf diese Funktion zugreifen zu können",
      "Make me premium": "Machen Sie mich Premium"
    }
  },
  "lt": {
    "translation": {
      "Download the mobile app for a better experience": "Atsisiųskite programą mobiliesiems, kad galėtumėte mėgautis geresne patirtimi",
      "Make me premium": "Padaryk man aukščiausios kokybės",
      "You need to be signed in and have a premium subscription to access this feature": "Jei norite pasiekti šią funkciją, turite būti prisijungę ir turėti aukščiausios kokybės prenumeratą"
    }
  },
  "af": {
    "translation": {
      "Download the mobile app for a better experience": "Laai die mobiele toepassing af vir 'n beter ervaring",
      "You need to be signed in and have a premium subscription to access this feature": "Jy moet aangemeld wees en 'n premium-intekening hê om toegang tot hierdie kenmerk te kry",
      "Make me premium": "Maak my premium"
    }
  },
  "mg": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Mila miditra ianao ary manana famandrihana premium mba hidirana amin'ity endri-javatra ity",
      "Make me premium": "Ataovy premium aho",
      "Download the mobile app for a better experience": "Ampidino ny fampiharana finday hahazoana traikefa tsara kokoa"
    }
  },
  "is": {
    "translation": {
      "Download the mobile app for a better experience": "Sæktu farsímaforritið til að fá betri upplifun",
      "You need to be signed in and have a premium subscription to access this feature": "Þú þarft að vera skráður inn og vera með úrvalsáskrift til að fá aðgang að þessum eiginleika",
      "Make me premium": "Gerðu mér úrvals"
    }
  },
  "sm": {
    "translation": {
      "Download the mobile app for a better experience": "La'u mai le polokalama feavea'i mo se poto masani lelei",
      "Make me premium": "Fai mai ia te a'u le tupe sili",
      "You need to be signed in and have a premium subscription to access this feature": "E tatau ona e saini i totonu ma maua sau saofaga fa'apitoa e maua ai lenei fa'aoga"
    }
  },
  "ar": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "تحتاج إلى تسجيل الدخول ولديك اشتراك مميز للوصول إلى هذه الميزة",
      "Make me premium": "اجعلني قسط",
      "Download the mobile app for a better experience": "قم بتنزيل تطبيق الهاتف لتجربة أفضل"
    }
  },
  "da": {
    "translation": {
      "Make me premium": "Gør mig premium",
      "You need to be signed in and have a premium subscription to access this feature": "Du skal være logget ind og have et premium-abonnement for at få adgang til denne funktion",
      "Download the mobile app for a better experience": "Download mobilappen for en bedre oplevelse"
    }
  },
  "el": {
    "translation": {
      "Download the mobile app for a better experience": "Κατεβάστε την εφαρμογή για κινητά για καλύτερη εμπειρία",
      "You need to be signed in and have a premium subscription to access this feature": "Πρέπει να είστε συνδεδεμένοι και να έχετε μια premium συνδρομή για να αποκτήσετε πρόσβαση σε αυτήν τη λειτουργία",
      "Make me premium": "Κάντε με premium"
    }
  },
  "es": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Debe iniciar sesión y tener una suscripción premium para acceder a esta función",
      "Download the mobile app for a better experience": "Descarga la aplicación móvil para una mejor experiencia",
      "Make me premium": "hazme premium"
    }
  },
  "bg": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Трябва да сте влезли и да имате първокласен абонамент, за да получите достъп до тази функция",
      "Download the mobile app for a better experience": "Изтеглете мобилното приложение за по-добро изживяване",
      "Make me premium": "Направи ме премиум"
    }
  },
  "uk": {
    "translation": {
      "Download the mobile app for a better experience": "Завантажте мобільний додаток для кращого досвіду",
      "Make me premium": "Зробіть мене преміальним",
      "You need to be signed in and have a premium subscription to access this feature": "Щоб отримати доступ до цієї функції, вам потрібно ввійти та мати преміальну підписку"
    }
  },
  "pl": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Aby uzyskać dostęp do tej funkcji, musisz się zalogować i mieć subskrypcję premium",
      "Download the mobile app for a better experience": "Pobierz aplikację mobilną, aby uzyskać lepsze wrażenia",
      "Make me premium": "Zrób mi premium"
    }
  },
  "sv": {
    "translation": {
      "Download the mobile app for a better experience": "Ladda ner mobilappen för en bättre upplevelse",
      "You need to be signed in and have a premium subscription to access this feature": "Du måste vara inloggad och ha ett premiumabonnemang för att få tillgång till den här funktionen",
      "Make me premium": "Gör mig premium"
    }
  },
  "it": {
    "translation": {
      "Download the mobile app for a better experience": "Scarica l'app mobile per un'esperienza migliore",
      "You need to be signed in and have a premium subscription to access this feature": "Devi essere registrato e avere un abbonamento premium per accedere a questa funzione",
      "Make me premium": "Rendimi premium"
    }
  },
  "cs": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Pro přístup k této funkci musíte být přihlášeni a mít prémiové předplatné",
      "Make me premium": "Udělej mě prémiovým",
      "Download the mobile app for a better experience": "Stáhněte si mobilní aplikaci pro lepší zážitek"
    }
  },
  "fr": {
    "translation": {
      "Download the mobile app for a better experience": "Téléchargez l'application mobile pour une meilleure expérience",
      "You need to be signed in and have a premium subscription to access this feature": "Vous devez être connecté et avoir un abonnement premium pour accéder à cette fonctionnalité",
      "Make me premium": "Fais-moi prime"
    }
  },
  "hy": {
    "translation": {
      "Download the mobile app for a better experience": "Ներբեռնեք բջջային հավելվածը ավելի լավ փորձի համար",
      "You need to be signed in and have a premium subscription to access this feature": "Դուք պետք է մուտք գործեք և ունենաք պրեմիում բաժանորդագրություն՝ այս գործառույթից օգտվելու համար",
      "Make me premium": "Դարձրեք ինձ պրեմիում"
    }
  },
  "iw": {
    "translation": {
      "Make me premium": "תעשה לי פרימיום",
      "Download the mobile app for a better experience": "הורד את האפליקציה לנייד לחוויה טובה יותר",
      "You need to be signed in and have a premium subscription to access this feature": "אתה צריך להיות מחובר ולהצטייד במנוי פרימיום כדי לגשת לתכונה זו"
    }
  },
  "sr": {
    "translation": {
      "Make me premium": "Учини ме премиум",
      "Download the mobile app for a better experience": "Преузмите мобилну апликацију за боље искуство",
      "You need to be signed in and have a premium subscription to access this feature": "Морате бити пријављени и имати премиум претплату да бисте приступили овој функцији"
    }
  },
  "ne": {
    "translation": {
      "Make me premium": "मलाई प्रिमियम बनाउनुहोस्",
      "Download the mobile app for a better experience": "राम्रो अनुभवको लागि मोबाइल एप डाउनलोड गर्नुहोस्",
      "You need to be signed in and have a premium subscription to access this feature": "तपाईं साइन इन हुनु पर्छ र यो सुविधा पहुँच गर्न एक प्रीमियम सदस्यता छ"
    }
  },
  "am": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "ይህንን ባህሪ ለማግኘት በመለያ መግባት እና ፕሪሚየም ምዝገባ ሊኖርዎት ይገባል።",
      "Download the mobile app for a better experience": "ለተሻለ ተሞክሮ የሞባይል መተግበሪያን ያውርዱ",
      "Make me premium": "ፕሪሚየም አድርጊኝ።"
    }
  },
  "id": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Anda harus masuk dan memiliki langganan premium untuk mengakses fitur ini",
      "Download the mobile app for a better experience": "Unduh aplikasi seluler untuk pengalaman yang lebih baik",
      "Make me premium": "Jadikan saya premium"
    }
  },
  "vi": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Bạn cần đăng nhập và có đăng ký trả phí để truy cập tính năng này",
      "Make me premium": "Làm cho tôi cao cấp",
      "Download the mobile app for a better experience": "Tải xuống ứng dụng di động để có trải nghiệm tốt hơn"
    }
  },
  "mk": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Треба да сте најавени и да имате премиум претплата за да пристапите до оваа функција",
      "Download the mobile app for a better experience": "Преземете ја мобилната апликација за подобро искуство",
      "Make me premium": "Направи ме премиум"
    }
  },
  "ml": {
    "translation": {
      "Download the mobile app for a better experience": "മികച്ച അനുഭവത്തിനായി മൊബൈൽ ആപ്പ് ഡൗൺലോഡ് ചെയ്യുക",
      "Make me premium": "എന്നെ പ്രീമിയം ആക്കുക",
      "You need to be signed in and have a premium subscription to access this feature": "ഈ ഫീച്ചർ ആക്‌സസ് ചെയ്യുന്നതിന് നിങ്ങൾ സൈൻ ഇൻ ചെയ്യുകയും പ്രീമിയം സബ്‌സ്‌ക്രിപ്‌ഷൻ ഉണ്ടായിരിക്കുകയും വേണം"
    }
  },
  "ca": {
    "translation": {
      "You need to be signed in and have a premium subscription to access this feature": "Heu d'iniciar la sessió i tenir una subscripció premium per accedir a aquesta funció",
      "Download the mobile app for a better experience": "Descarrega l'aplicació mòbil per a una millor experiència",
      "Make me premium": "Fes-me premium"
    }
  },
  "ro": {
    "translation": {
      "Make me premium": "Fă-mă premium",
      "Download the mobile app for a better experience": "Descărcați aplicația mobilă pentru o experiență mai bună",
      "You need to be signed in and have a premium subscription to access this feature": "Trebuie să fiți conectat și să aveți un abonament premium pentru a accesa această funcție"
    }
  },
  "ja": {
    "translation": {
      "Download the mobile app for a better experience": "より良い体験のためにモバイルアプリをダウンロードしてください",
      "Make me premium": "私をプレミアムにする",
      "You need to be signed in and have a premium subscription to access this feature": "この機能にアクセスするには、サインインしてプレミアムサブスクリプションを持っている必要があります"
    }
  }
}
const lang3 = {
  "en": { "translation": { "Get more videos like this": "Get more videos like this" } },
  "de": { "translation": { "Get more videos like this": "Holen Sie sich mehr Videos wie dieses" } },
  "is": { "translation": { "Get more videos like this": "Fáðu fleiri svona myndbönd" } },
  "vi": { "translation": { "Get more videos like this": "Nhận nhiều video như thế này" } },
  "ru": { "translation": { "Get more videos like this": "Побольше таких видео" } },
  "et": { "translation": { "Get more videos like this": "Hankige rohkem selliseid videoid" } },
  "es": { "translation": { "Get more videos like this": "Obtén más videos como este" } },
  "fr": { "translation": { "Get more videos like this": "Obtenez plus de vidéos comme celle-ci" } },
  "ko": { "translation": { "Get more videos like this": "이와 같은 동영상을 더 많이 받으세요" } },
  "co": { "translation": { "Get more videos like this": "Ottene più video cum'è questu" } },
  "bg": { "translation": { "Get more videos like this": "Вземете още такива видеоклипове" } },
  "ml": { "translation": { "Get more videos like this": "ഇതുപോലുള്ള കൂടുതൽ വീഡിയോകൾ നേടൂ" } },
  "da": { "translation": { "Get more videos like this": "Få flere videoer som denne" } },
  "sl": { "translation": { "Get more videos like this": "Pridobite več takšnih videoposnetkov" } },
  "mg": { "translation": { "Get more videos like this": "Mahazoa horonan-tsary bebe kokoa tahaka izao" } },
  "ca": { "translation": { "Get more videos like this": "Obtén més vídeos com aquest" } },
  "sr": { "translation": { "Get more videos like this": "Набавите још оваквих видео снимака" } },
  "el": { "translation": { "Get more videos like this": "Λάβετε περισσότερα βίντεο σαν αυτό" } },
  "iw": { "translation": { "Get more videos like this": "קבל עוד סרטונים כמו זה" } },
  "sv": { "translation": { "Get more videos like this": "Få fler sådana här videor" } },
  "ro": { "translation": { "Get more videos like this": "Obțineți mai multe videoclipuri ca acesta" } },
  "mt": { "translation": { "Get more videos like this": "Ikseb aktar vidjows bħal dan" } },
  "am": { "translation": { "Get more videos like this": "እንደዚህ ያሉ ተጨማሪ ቪዲዮዎችን ያግኙ" } },
  "hu": { "translation": { "Get more videos like this": "Szerezz még több ilyen videót" } },
  "ja": { "translation": { "Get more videos like this": "このようなビデオをもっと入手する" } },
  "lt": { "translation": { "Get more videos like this": "Gaukite daugiau tokių vaizdo įrašų" } },
  "it": { "translation": { "Get more videos like this": "Ottieni più video come questo" } },
  "hr": { "translation": { "Get more videos like this": "Nabavite još ovakvih videozapisa" } },
  "lv": { "translation": { "Get more videos like this": "Iegūstiet vairāk šādu videoklipu" } },
  "uk": { "translation": { "Get more videos like this": "Отримайте більше таких відео" } },
  "hy": { "translation": { "Get more videos like this": "Ստացեք ավելի շատ նման տեսանյութեր" } },
  "tr": { "translation": { "Get more videos like this": "Bunun gibi daha fazla video alın" } },
  "ne": { "translation": { "Get more videos like this": "यस्तै अन्य भिडियोहरू प्राप्त गर्नुहोस्" } },
  "ga": { "translation": { "Get more videos like this": "Faigh níos mó físeáin mar seo" } },
  "no": { "translation": { "Get more videos like this": "Få flere videoer som dette" } },
  "id": { "translation": { "Get more videos like this": "Dapatkan lebih banyak video seperti ini" } },
  "bs": { "translation": { "Get more videos like this": "Nabavite još ovakvih videozapisa" } },
  "sq": { "translation": { "Get more videos like this": "Merr më shumë video si kjo" } },
  "ar": { "translation": { "Get more videos like this": "الحصول على المزيد من أشرطة الفيديو مثل هذا" } },
  "fi": { "translation": { "Get more videos like this": "Hanki lisää tällaisia videoita" } },
  "zh": { "translation": { "Get more videos like this": "获取更多这样的视频" } },
  "pl": { "translation": { "Get more videos like this": "Zobacz więcej takich filmów" } },
  "sm": { "translation": { "Get more videos like this": "Maua nisi vitiō fa'apenei" } },
  "pt": { "translation": { "Get more videos like this": "Receba mais vídeos como este" } },
  "hi": { "translation": { "Get more videos like this": "इस तरह के और वीडियो प्राप्त करें" } },
  "sk": { "translation": { "Get more videos like this": "Získajte viac takýchto videí" } },
  "cs": { "translation": { "Get more videos like this": "Získejte více takových videí" } },
  "nl": { "translation": { "Get more videos like this": "Krijg meer van dit soort video's" } },
  "mk": { "translation": { "Get more videos like this": "Добијте повеќе вакви видеа" } },
  "haw": { "translation": { "Get more videos like this": "E kiʻi i nā wikiō hou aʻe e like me kēia" } },
  "af": { "translation": { "Get more videos like this": "Kry meer video's soos hierdie" } }
}
const lang1 = {
  "en": {
    "translation": {
      "Search": "Search",
      "Email Address": "Email Address",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Password": "Password",
      "No seen videos yet": "No seen videos yet",
      "Sign Out": "Sign Out",
      "Search or paste link": "Search or paste link",
      "Language": "Language",
      "Donate": "Donate",
      "Default": "Default",
      "translated clips": "translated clips",
      "Could not find any subtitles": "Could not find any subtitles"
    }
  },
  "hy": {
    "translation": {
      "Donate": "Նվիրաբերել",
      "Search": "Որոնել",
      "No seen videos yet": "Դեռ տեսած տեսանյութեր չկան",
      "Email Address": "Էլեկտրոնային հասցե",
      "Language": "Լեզու",
      "Password": "Գաղտնաբառ",
      "Sign Out": "Դուրս գալ",
      "Last Name": "Ազգանուն",
      "translated clips": "թարգմանված հոլովակներ",
      "Search or paste link": "Որոնեք կամ տեղադրեք հղումը",
      "First Name": "Անուն",
      "Default": "Կանխադրված",
      "Could not find any subtitles": "Չհաջողվեց գտնել ենթագրեր"
    }
  },
  "zh-Hant": {
    "translation": {
      "First Name": "名",
      "No seen videos yet": "還沒有看過的視頻",
      "Email Address": "電子郵件地址",
      "Donate": "捐",
      "Last Name": "姓",
      "Password": "密碼",
      "Sign Out": "登出",
      "Default": "默認",
      "Language": "語言",
      "Could not find any subtitles": "找不到任何字幕",
      "Search": "搜索",
      "translated clips": "翻譯剪輯",
      "Search or paste link": "搜索或粘貼鏈接"
    }
  },
  "el": {
    "translation": {
      "Language": "Γλώσσα",
      "Search or paste link": "Αναζήτηση ή επικόλληση συνδέσμου",
      "Email Address": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
      "Could not find any subtitles": "Δεν ήταν δυνατή η εύρεση υπότιτλων",
      "Password": "Κωδικός πρόσβασης",
      "Default": "Προκαθορισμένο",
      "Donate": "Προσφέρω",
      "Last Name": "Επίθετο",
      "No seen videos yet": "Δεν έχετε δει βίντεο ακόμα",
      "translated clips": "μεταφρασμένα κλιπ",
      "First Name": "Ονομα",
      "Search": "Αναζήτηση",
      "Sign Out": "Αποσύνδεση"
    }
  },
  "no": {
    "translation": {
      "Search or paste link": "Søk eller lim inn lenken",
      "Language": "Språk",
      "translated clips": "oversatte klipp",
      "Email Address": "Epostadresse",
      "Password": "Passord",
      "Donate": "Donere",
      "Could not find any subtitles": "Fant ingen undertekster",
      "First Name": "Fornavn",
      "Last Name": "Etternavn",
      "Default": "Misligholde",
      "No seen videos yet": "Ingen sett videoer ennå",
      "Search": "Søk",
      "Sign Out": "Logg ut"
    }
  },
  "it": {
    "translation": {
      "Search or paste link": "Cerca o incolla il link",
      "Default": "Predefinito",
      "No seen videos yet": "Nessun video ancora visto",
      "Email Address": "Indirizzo e-mail",
      "translated clips": "clip tradotte",
      "Language": "Lingua",
      "Could not find any subtitles": "Impossibile trovare i sottotitoli",
      "Password": "Parola d'ordine",
      "Last Name": "Cognome",
      "Search": "Ricerca",
      "First Name": "Nome di battesimo",
      "Donate": "Donare",
      "Sign Out": "Disconnessione"
    }
  },
  "co": {
    "translation": {
      "Sign Out": "Sorte",
      "Search": "Ricerca",
      "translated clips": "clips tradotti",
      "Default": "Default",
      "Password": "Codice",
      "Last Name": "Casata",
      "No seen videos yet": "Nisun video vistu ancora",
      "Email Address": "Indirizzu mail",
      "Language": "Lingua",
      "Donate": "Donate",
      "First Name": "Nomu",
      "Search or paste link": "Cerca o incolla ligame",
      "Could not find any subtitles": "Ùn pudia truvà micca sottutituli"
    }
  },
  "vi": {
    "translation": {
      "First Name": "Tên đầu tiên",
      "No seen videos yet": "Chưa thấy video nào",
      "Sign Out": "Đăng xuất",
      "Last Name": "Họ",
      "Donate": "Quyên tặng",
      "Language": "Ngôn ngữ",
      "Search": "Tìm kiếm",
      "translated clips": "clip đã dịch",
      "Search or paste link": "Tìm kiếm hoặc dán liên kết",
      "Password": "Mật khẩu",
      "Default": "Mặc định",
      "Could not find any subtitles": "Không thể tìm thấy bất kỳ phụ đề nào",
      "Email Address": "Địa chỉ email"
    }
  },
  "bs": {
    "translation": {
      "Email Address": "E-mail adresa",
      "Sign Out": "Odjaviti se",
      "Password": "Lozinka",
      "Donate": "Donirati",
      "Language": "Jezik",
      "Search": "Traži",
      "Search or paste link": "Pretražite ili zalijepite link",
      "Could not find any subtitles": "Nije moguće pronaći titlove",
      "No seen videos yet": "Još nema viđenih videozapisa",
      "Last Name": "Prezime",
      "Default": "Default",
      "translated clips": "prevedeni klipovi",
      "First Name": "Ime"
    }
  },
  "pt": {
    "translation": {
      "Last Name": "Último nome",
      "Search or paste link": "Pesquisar ou colar link",
      "translated clips": "clipes traduzidos",
      "No seen videos yet": "Nenhum vídeo visto ainda",
      "Language": "Linguagem",
      "First Name": "Primeiro nome",
      "Email Address": "Endereço de e-mail",
      "Search": "Procurar",
      "Could not find any subtitles": "Não foi possível encontrar legendas",
      "Donate": "Doar",
      "Password": "Senha",
      "Default": "Predefinição",
      "Sign Out": "Sair"
    }
  },
  "et": {
    "translation": {
      "Sign Out": "Logi välja",
      "First Name": "Eesnimi",
      "Password": "Parool",
      "Search": "Otsing",
      "Search or paste link": "Otsige või kleepige link",
      "translated clips": "tõlgitud klipid",
      "Language": "Keel",
      "Could not find any subtitles": "Subtiitreid ei leitud",
      "Email Address": "E-posti aadress",
      "No seen videos yet": "Pole veel videoid näinud",
      "Donate": "Anneta",
      "Default": "Vaikimisi",
      "Last Name": "Perekonnanimi"
    }
  },
  "sl": {
    "translation": {
      "Last Name": "Priimek",
      "translated clips": "prevedeni posnetki",
      "Password": "Geslo",
      "Language": "Jezik",
      "Could not find any subtitles": "Podnapisov ni bilo mogoče najti",
      "No seen videos yet": "Ni še videnih videoposnetkov",
      "Sign Out": "Odjava",
      "First Name": "Ime",
      "Search": "Iskanje",
      "Donate": "Donirajte",
      "Search or paste link": "Iščite ali prilepite povezavo",
      "Default": "Privzeto",
      "Email Address": "Email naslov"
    }
  },
  "sk": {
    "translation": {
      "Password": "heslo",
      "Sign Out": "Odhlásiť Sa",
      "Search": "Vyhľadávanie",
      "Default": "Predvolené",
      "Language": "Jazyk",
      "Search or paste link": "Vyhľadajte alebo vložte odkaz",
      "Email Address": "Emailová adresa",
      "No seen videos yet": "Zatiaľ žiadne videné videá",
      "Donate": "darovať",
      "Last Name": "Priezvisko",
      "Could not find any subtitles": "Nenašli sa žiadne titulky",
      "translated clips": "preložené klipy",
      "First Name": "Krstné meno"
    }
  },
  "fi": {
    "translation": {
      "Search or paste link": "Etsi tai liitä linkki",
      "First Name": "Etunimi",
      "Last Name": "Sukunimi",
      "Search": "Hae",
      "translated clips": "käännetyt leikkeet",
      "Language": "Kieli",
      "Password": "Salasana",
      "No seen videos yet": "Videoita ei ole vielä näkynyt",
      "Email Address": "Sähköpostiosoite",
      "Donate": "Lahjoittaa",
      "Could not find any subtitles": "Tekstityksiä ei löytynyt",
      "Default": "Oletus",
      "Sign Out": "Kirjaudu ulos"
    }
  },
  "mk": {
    "translation": {
      "translated clips": "преведени клипови",
      "No seen videos yet": "Сè уште нема видени видеа",
      "Last Name": "Презиме",
      "Password": "Лозинка",
      "Language": "Јазик",
      "Email Address": "И-мејл адреса",
      "Search or paste link": "Пребарајте или залепете врска",
      "Sign Out": "Одјави се",
      "Donate": "Донирајте",
      "Search": "Пребарување",
      "First Name": "Име",
      "Could not find any subtitles": "Не можев да најдам преводи",
      "Default": "Стандардно"
    }
  },
  "ro": {
    "translation": {
      "Email Address": "Adresa de e-mail",
      "Search or paste link": "Căutați sau inserați linkul",
      "Language": "Limba",
      "Search": "Căutare",
      "No seen videos yet": "Niciun videoclip văzut încă",
      "Password": "Parola",
      "First Name": "Nume",
      "Donate": "Donează",
      "Default": "Mod implicit",
      "Sign Out": "Sign out",
      "Could not find any subtitles": "Nu am putut găsi nicio subtitrare",
      "Last Name": "Numele de familie",
      "translated clips": "clipuri traduse"
    }
  },
  "iw": {
    "translation": {
      "Password": "סיסמה",
      "Search": "לחפש",
      "Search or paste link": "חפש או הדבק קישור",
      "Last Name": "שם משפחה",
      "Donate": "לִתְרוֹם",
      "Email Address": "כתובת דוא\"ל",
      "Sign Out": "התנתק",
      "No seen videos yet": "עדיין לא נראו סרטונים",
      "translated clips": "קליפים מתורגמים",
      "Default": "בְּרִירַת מֶחדָל",
      "Could not find any subtitles": "לא הצליח למצוא כתוביות",
      "Language": "שפה",
      "First Name": "שם פרטי"
    }
  },
  "am": {
    "translation": {
      "Last Name": "ያባት ስም",
      "Could not find any subtitles": "የትርጉም ጽሑፎችን ማግኘት አልተቻለም",
      "Sign Out": "ዛግተ ውጣ",
      "Search": "ፈልግ",
      "Password": "ፕስወርድ",
      "Default": "ነባሪ",
      "First Name": "የመጀመሪያ ስም",
      "Donate": "ለገሱ",
      "No seen videos yet": "እስካሁን ምንም ቪዲዮዎች አልታዩም።",
      "translated clips": "የተተረጎሙ ቅንጥቦች",
      "Search or paste link": "አገናኝ ይፈልጉ ወይም ይለጥፉ",
      "Email Address": "የ ኢሜል አድራሻ",
      "Language": "ቋንቋ"
    }
  },
  "mg": {
    "translation": {
      "Search": "karohy",
      "translated clips": "clips nadika",
      "Password": "Password",
      "Donate": "Manomeza",
      "First Name": "Fanampin'anarana",
      "Language": "Fiteny",
      "Default": "toerana misy anao",
      "Email Address": "Adiresy mailaka",
      "Sign Out": "Hivoaka",
      "Could not find any subtitles": "Tsy nahita dikanteny",
      "Search or paste link": "Karohy na apetaho rohy",
      "Last Name": "Anarana",
      "No seen videos yet": "Mbola tsy nisy horonan-tsary hita"
    }
  },
  "fil": {
    "translation": {
      "No seen videos yet": "Wala pang nakikitang mga video",
      "Could not find any subtitles": "Hindi makahanap ng anumang mga subtitle",
      "First Name": "Pangalan",
      "Donate": "Mag-donate",
      "Search or paste link": "Maghanap o mag-paste ng link",
      "Email Address": "Email Address",
      "Password": "Password",
      "translated clips": "isinalin na mga clip",
      "Default": "Default",
      "Last Name": "Huling pangalan",
      "Search": "Maghanap",
      "Sign Out": "Mag-sign Out",
      "Language": "Wika"
    }
  },
  "de": {
    "translation": {
      "No seen videos yet": "Noch keine Videos gesehen",
      "First Name": "Vorname",
      "Sign Out": "Austragen",
      "Language": "Sprache",
      "Default": "Standard",
      "Password": "Passwort",
      "Search": "Suche",
      "translated clips": "übersetzte Clips",
      "Search or paste link": "Link suchen oder einfügen",
      "Email Address": "E-Mail-Addresse",
      "Donate": "Spenden",
      "Could not find any subtitles": "Konnte keine Untertitel finden",
      "Last Name": "Familienname, Nachname"
    }
  },
  "ar": {
    "translation": {
      "Sign Out": "خروج",
      "Search": "بحث",
      "Last Name": "الكنية",
      "First Name": "الاسم الأول",
      "Could not find any subtitles": "لا يمكن العثور على أي ترجمات",
      "Email Address": "عنوان بريد الكتروني",
      "Password": "كلمة المرور",
      "Donate": "يتبرع",
      "Search or paste link": "ابحث أو الصق الرابط",
      "Default": "تقصير",
      "translated clips": "مقاطع مترجمة",
      "Language": "لغة",
      "No seen videos yet": "لم تتم مشاهدة مقاطع الفيديو حتى الآن"
    }
  },
  "ru": {
    "translation": {
      "Password": "Пароль",
      "Last Name": "Фамилия",
      "Search or paste link": "Найдите или вставьте ссылку",
      "Email Address": "Адрес электронной почты",
      "Sign Out": "Выход",
      "translated clips": "переведенные клипы",
      "Language": "Язык",
      "Could not find any subtitles": "Не удалось найти субтитры",
      "First Name": "Имя",
      "Default": "По умолчанию",
      "Search": "Поиск",
      "No seen videos yet": "Пока нет просмотренных видео",
      "Donate": "Пожертвовать"
    }
  },
  "af": {
    "translation": {
      "Search": "Soek",
      "Donate": "skenk",
      "Last Name": "Van",
      "Password": "Wagwoord",
      "No seen videos yet": "Nog geen gesiene video's nie",
      "Search or paste link": "Soek of plak skakel",
      "First Name": "Eerste naam",
      "Sign Out": "Teken uit",
      "translated clips": "vertaalde snitte",
      "Email Address": "E-pos adres",
      "Default": "Verstek",
      "Language": "Taal",
      "Could not find any subtitles": "Kon geen onderskrifte vind nie"
    }
  },
  "ca": {
    "translation": {
      "Sign Out": "Tanca sessió",
      "Search": "Cerca",
      "Last Name": "Cognom",
      "Search or paste link": "Cerca o enganxa l'enllaç",
      "Email Address": "Correu electrònic",
      "Donate": "Donar",
      "Password": "Contrasenya",
      "Language": "Llenguatge",
      "translated clips": "clips traduïts",
      "First Name": "Nom",
      "Could not find any subtitles": "No s'ha pogut trobar cap subtítol",
      "No seen videos yet": "Encara no s'ha vist cap vídeo",
      "Default": "Per defecte"
    }
  },
  "sm": {
    "translation": {
      "Search or paste link": "Su'e pe faapipii so'oga",
      "Password": "Numera e le iloa e sesi",
      "Language": "Gagana",
      "Last Name": "Faʻaiu",
      "Default": "Fa'atonu",
      "translated clips": "fa'aliliuina kilipa",
      "Donate": "Foai",
      "First Name": "Igoa muamua",
      "Sign Out": "Saini ese",
      "Could not find any subtitles": "Le mafai ona maua so'o se ulutala",
      "No seen videos yet": "E le'i va'aia ni vitio",
      "Email Address": "Imeli tuatusi",
      "Search": "Saili"
    }
  },
  "da": {
    "translation": {
      "Donate": "Doner",
      "First Name": "Fornavn",
      "Password": "Adgangskode",
      "translated clips": "oversatte klip",
      "Search": "Søg",
      "Language": "Sprog",
      "Search or paste link": "Søg eller indsæt link",
      "Email Address": "Email adresse",
      "No seen videos yet": "Ingen sete videoer endnu",
      "Last Name": "Efternavn",
      "Default": "Standard",
      "Could not find any subtitles": "Kunne ikke finde nogen undertekster",
      "Sign Out": "Log ud"
    }
  },
  "hi": {
    "translation": {
      "translated clips": "अनुवादित क्लिप",
      "First Name": "पहला नाम",
      "No seen videos yet": "अभी तक कोई वीडियो नहीं देखा है",
      "Search": "खोज",
      "Language": "भाषा",
      "Last Name": "अंतिम नाम",
      "Password": "कुंजिका",
      "Could not find any subtitles": "कोई उपशीर्षक नहीं मिला",
      "Search or paste link": "लिंक खोजें या पेस्ट करें",
      "Sign Out": "साइन आउट",
      "Default": "चूक जाना",
      "Email Address": "ईमेल पता",
      "Donate": "दान करना"
    }
  },
  "sr": {
    "translation": {
      "Search": "Претрага",
      "Search or paste link": "Претражите или налепите везу",
      "Sign Out": "Одјава",
      "First Name": "Име",
      "Email Address": "Адреса Е-поште",
      "No seen videos yet": "Још нема виђених видео снимака",
      "Donate": "Донате",
      "Language": "Језик",
      "Default": "Уобичајено",
      "Password": "Лозинка",
      "Could not find any subtitles": "Није могуће пронаћи титлове",
      "translated clips": "преведени клипови",
      "Last Name": "Презиме"
    }
  },
  "fr": {
    "translation": {
      "Donate": "Faire un don",
      "Could not find any subtitles": "Impossible de trouver des sous-titres",
      "Email Address": "Adresse e-mail",
      "Default": "Défaut",
      "No seen videos yet": "Pas encore de vidéos vues",
      "Sign Out": "Se déconnecter",
      "Language": "Langue",
      "Last Name": "Nom de famille",
      "translated clips": "extraits traduits",
      "Search": "Rechercher",
      "Search or paste link": "Rechercher ou coller le lien",
      "First Name": "Prénom",
      "Password": "Mot de passe"
    }
  },
  "ml": {
    "translation": {
      "Email Address": "ഇമെയിൽ വിലാസം",
      "Sign Out": "സൈൻ ഔട്ട്",
      "First Name": "പേരിന്റെ ആദ്യഭാഗം",
      "Donate": "സംഭാവനചെയ്യുക",
      "Default": "സ്ഥിരസ്ഥിതി",
      "Search": "തിരയുക",
      "Password": "Password",
      "No seen videos yet": "ഇതുവരെ വീഡിയോകളൊന്നും കണ്ടിട്ടില്ല",
      "Could not find any subtitles": "സബ്‌ടൈറ്റിലുകളൊന്നും കണ്ടെത്താൻ കഴിഞ്ഞില്ല",
      "Last Name": "പേരിന്റെ അവസാന ഭാഗം",
      "Language": "ഭാഷ",
      "translated clips": "വിവർത്തനം ചെയ്ത ക്ലിപ്പുകൾ",
      "Search or paste link": "ലിങ്ക് തിരയുക അല്ലെങ്കിൽ ഒട്ടിക്കുക"
    }
  },
  "sq": {
    "translation": {
      "First Name": "Emri",
      "Language": "Gjuhe",
      "translated clips": "klipe të përkthyera",
      "Default": "E paracaktuar",
      "Last Name": "Mbiemri",
      "No seen videos yet": "Asnjë video e parë ende",
      "Donate": "Dhuroni",
      "Sign Out": "Dilni",
      "Could not find any subtitles": "Nuk mund të gjeja asnjë titra",
      "Email Address": "Adresa e emailit",
      "Search": "Kërko",
      "Password": "Fjalëkalimi",
      "Search or paste link": "Kërko ose ngjit lidhjen"
    }
  },
  "uk": {
    "translation": {
      "Language": "Мову",
      "Search": "Пошук",
      "Default": "За замовчуванням",
      "Could not find any subtitles": "Не вдалося знайти жодних субтитрів",
      "Search or paste link": "Знайдіть або вставте посилання",
      "Donate": "Пожертвуйте",
      "translated clips": "перекладені кліпи",
      "No seen videos yet": "Відео ще не переглянуто",
      "Password": "Пароль",
      "Last Name": "Прізвище",
      "Sign Out": "Вийти з аккаунта",
      "Email Address": "Адреса електронної пошти",
      "First Name": "Ім'я"
    }
  },
  "es": {
    "translation": {
      "Default": "Por defecto",
      "First Name": "Primer nombre",
      "Password": "Clave",
      "Language": "Idioma",
      "Email Address": "Dirección de correo electrónico",
      "Last Name": "Apellido",
      "Search": "Búsqueda",
      "Sign Out": "Desconectar",
      "No seen videos yet": "No hay videos vistos todavía",
      "Search or paste link": "Buscar o pegar enlace",
      "Donate": "Donar",
      "Could not find any subtitles": "No se pudo encontrar ningún subtítulo",
      "translated clips": "clips traducidos"
    }
  },
  "ne": {
    "translation": {
      "Last Name": "थर",
      "Language": "भाषा",
      "Search or paste link": "लिङ्क खोज्नुहोस् वा टाँस्नुहोस्",
      "Email Address": "इ - मेल ठेगाना",
      "Search": "खोज्नुहोस्",
      "No seen videos yet": "अहिलेसम्म कुनै पनि भिडियो हेरिएको छैन",
      "First Name": "पहिलो नाम",
      "Default": "पूर्वनिर्धारित",
      "Could not find any subtitles": "कुनै पनि उपशीर्षकहरू फेला पार्न सकेन",
      "Sign Out": "साइन आउट गर्नुहोस्",
      "Donate": "दान गर्नुहोस्",
      "Password": "पासवर्ड",
      "translated clips": "अनुवादित क्लिपहरू"
    }
  },
  "ja": {
    "translation": {
      "No seen videos yet": "まだ見たビデオはありません",
      "Sign Out": "サインアウト",
      "Language": "言語",
      "Last Name": "苗字",
      "Search or paste link": "リンクを検索または貼り付けます",
      "Donate": "寄付",
      "Search": "検索",
      "Default": "ディフォルト",
      "translated clips": "翻訳されたクリップ",
      "Could not find any subtitles": "字幕が見つかりませんでした",
      "Email Address": "電子メールアドレス",
      "Password": "パスワード",
      "First Name": "ファーストネーム"
    }
  },
  "cs": {
    "translation": {
      "Search": "Vyhledávání",
      "Language": "Jazyk",
      "No seen videos yet": "Zatím žádná viděná videa",
      "Email Address": "Emailová adresa",
      "translated clips": "přeložené klipy",
      "Sign Out": "Odhlásit se",
      "Default": "Výchozí",
      "Last Name": "Příjmení",
      "First Name": "Jméno",
      "Password": "Heslo",
      "Could not find any subtitles": "Nebyly nalezeny žádné titulky",
      "Donate": "Darovat",
      "Search or paste link": "Vyhledejte nebo vložte odkaz"
    }
  },
  "zh-Hans": {
    "translation": {
      "Search or paste link": "搜索或粘贴链接",
      "Search": "搜索",
      "Last Name": "姓",
      "No seen videos yet": "还没有看过的视频",
      "Donate": "捐",
      "Language": "语言",
      "First Name": "名",
      "Password": "密码",
      "Email Address": "电子邮件地址",
      "Sign Out": "登出",
      "translated clips": "翻译剪辑",
      "Default": "默认",
      "Could not find any subtitles": "找不到任何字幕"
    }
  },
  "ga": {
    "translation": {
      "Language": "Teanga",
      "Could not find any subtitles": "Níorbh fhéidir aon fhotheidil a aimsiú",
      "Last Name": "Sloinne",
      "Email Address": "Seoladh ríomhphoist",
      "Password": "Pasfhocal",
      "No seen videos yet": "Níl físeáin le feiceáil go fóill",
      "Search or paste link": "Cuardaigh nó greamaigh nasc",
      "Default": "Réamhshocrú",
      "Donate": "Síntiúis",
      "Search": "Cuardach",
      "Sign Out": "Logáil Amach",
      "First Name": "Ainm",
      "translated clips": "clips aistrithe"
    }
  },
  "lt": {
    "translation": {
      "First Name": "Pirmas vardas",
      "Language": "Kalba",
      "Could not find any subtitles": "Nepavyko rasti jokių subtitrų",
      "Sign Out": "Atsijungti",
      "Search": "Paieška",
      "Email Address": "Elektroninio pašto adresas",
      "Password": "Slaptažodis",
      "No seen videos yet": "Dar nematytų vaizdo įrašų",
      "translated clips": "išversti klipai",
      "Search or paste link": "Ieškokite arba įklijuokite nuorodą",
      "Last Name": "Pavardė",
      "Donate": "Paaukoti",
      "Default": "Numatytas"
    }
  },
  "tr": {
    "translation": {
      "Email Address": "E",
      "Search": "Arama",
      "First Name": "İlk adı",
      "No seen videos yet": "Henüz video görülmedi",
      "Password": "Şifre",
      "Language": "Dilim",
      "Sign Out": "Oturumu Kapat",
      "Last Name": "Soy isim",
      "translated clips": "çevrilmiş klipler",
      "Donate": "Bağış yapmak",
      "Search or paste link": "Bağlantıyı arayın veya yapıştırın",
      "Default": "Varsayılan",
      "Could not find any subtitles": "Altyazı bulunamadı"
    }
  },
  "pl": {
    "translation": {
      "Last Name": "Nazwisko",
      "Search": "Szukaj",
      "Donate": "Podarować",
      "First Name": "Imię",
      "Default": "Domyślna",
      "Could not find any subtitles": "Nie udało się znaleźć żadnych napisów",
      "Search or paste link": "Wyszukaj lub wklej link",
      "Email Address": "Adres e-mail",
      "No seen videos yet": "Nie widziałem jeszcze filmów",
      "Sign Out": "Wyloguj się",
      "Password": "Hasło",
      "Language": "Język",
      "translated clips": "przetłumaczone klipy"
    }
  },
  "hr": {
    "translation": {
      "Sign Out": "Odjavi se",
      "No seen videos yet": "Još nema viđenih videozapisa",
      "Default": "Zadano",
      "Password": "Zaporka",
      "Search or paste link": "Pretražite ili zalijepite vezu",
      "Email Address": "Email adresa",
      "First Name": "Ime",
      "Last Name": "Prezime",
      "Language": "Jezik",
      "Donate": "Donirajte",
      "translated clips": "prevedeni isječci",
      "Could not find any subtitles": "Nije moguće pronaći titlove",
      "Search": "traži"
    }
  },
  "hu": {
    "translation": {
      "Email Address": "Email cím",
      "No seen videos yet": "Még nem látott videók",
      "translated clips": "lefordított klipek",
      "Could not find any subtitles": "Nem található felirat",
      "Search or paste link": "Keressen vagy illesszen be linket",
      "First Name": "Keresztnév",
      "Sign Out": "Kijelentkezés",
      "Last Name": "Vezetéknév",
      "Donate": "Adományoz",
      "Language": "Nyelv",
      "Search": "Keresés",
      "Password": "Jelszó",
      "Default": "Alapértelmezett"
    }
  },
  "lv": {
    "translation": {
      "First Name": "Vārds",
      "Search": "Meklēt",
      "Language": "Valoda",
      "Donate": "Ziedot",
      "Email Address": "Epasta adrese",
      "translated clips": "tulkotie klipi",
      "No seen videos yet": "Vēl nav redzēts video",
      "Sign Out": "Izrakstīties",
      "Last Name": "Uzvārds",
      "Password": "Parole",
      "Default": "Noklusējums",
      "Search or paste link": "Meklējiet vai ielīmējiet saiti",
      "Could not find any subtitles": "Nevarēja atrast subtitrus"
    }
  },
  "is": {
    "translation": {
      "Sign Out": "Útskrá",
      "Search": "Leita",
      "No seen videos yet": "Engin myndbönd hafa sést ennþá",
      "Could not find any subtitles": "Fann enga texta",
      "Donate": "Gefðu",
      "Search or paste link": "Leitaðu eða límdu tengil",
      "Email Address": "Netfang",
      "Password": "Lykilorð",
      "Language": "Tungumál",
      "Last Name": "Eftirnafn",
      "First Name": "Fyrsta nafn",
      "translated clips": "þýddar klippur",
      "Default": "Sjálfgefið"
    }
  },
  "ko": {
    "translation": {
      "Search": "검색",
      "translated clips": "번역된 클립",
      "Sign Out": "로그아웃",
      "Could not find any subtitles": "자막을 찾을 수 없습니다",
      "Search or paste link": "링크 검색 또는 붙여넣기",
      "First Name": "이름",
      "Password": "비밀번호",
      "No seen videos yet": "아직 본 동영상이 없습니다.",
      "Default": "기본",
      "Last Name": "성",
      "Donate": "기부",
      "Email Address": "이메일 주소",
      "Language": "언어"
    }
  },
  "id": {
    "translation": {
      "Search or paste link": "Cari atau tempel tautan",
      "Could not find any subtitles": "Tidak dapat menemukan subtitle",
      "Last Name": "Nama keluarga",
      "Password": "Kata sandi",
      "translated clips": "klip terjemahan",
      "Default": "Bawaan",
      "Sign Out": "Keluar",
      "Email Address": "Alamat email",
      "Language": "Bahasa",
      "No seen videos yet": "Belum ada video yang dilihat",
      "First Name": "Nama depan",
      "Donate": "Menyumbangkan",
      "Search": "Mencari"
    }
  },
  "nl": {
    "translation": {
      "First Name": "Voornaam",
      "Email Address": "E-mailadres",
      "Donate": "Doneren",
      "Password": "Wachtwoord",
      "Language": "Taal",
      "Sign Out": "Afmelden",
      "Search": "Zoekopdracht",
      "Search or paste link": "Zoek of plak link",
      "translated clips": "vertaalde clips",
      "No seen videos yet": "Nog geen video's gezien",
      "Last Name": "Achternaam",
      "Default": "Standaard",
      "Could not find any subtitles": "Kon geen ondertiteling vinden"
    }
  },
  "tk": {
    "translation": {
      "Email Address": "Email adres",
      "First Name": "Ady",
      "Language": "Dil",
      "Donate": "Bagyşlaň",
      "Sign Out": "Çykyň",
      "Could not find any subtitles": "Subtitr tapyp bilmedim",
      "Last Name": "Familiýasy",
      "Search": "Gözlemek",
      "No seen videos yet": "Entek görlen wideo ýok",
      "translated clips": "terjime edilen klipler",
      "Search or paste link": "Baglanyşygy gözläň ýa-da goýuň",
      "Password": "Parol",
      "Default": "Bellenen"
    }
  },
  "sv": {
    "translation": {
      "Donate": "Donera",
      "Default": "Standard",
      "Language": "Språk",
      "Last Name": "Efternamn",
      "First Name": "Förnamn",
      "Password": "Lösenord",
      "Search or paste link": "Sök eller klistra in länken",
      "Search": "Sök",
      "Could not find any subtitles": "Det gick inte att hitta några undertexter",
      "Email Address": "E-postadress",
      "translated clips": "översatta klipp",
      "No seen videos yet": "Inga sedda videor ännu",
      "Sign Out": "Logga ut"
    }
  },
  "bg": {
    "translation": {
      "Sign Out": "Отписване",
      "Search or paste link": "Търсете или поставете връзка",
      "Language": "език",
      "translated clips": "преведени клипове",
      "Donate": "Дарете",
      "First Name": "Първо име",
      "Could not find any subtitles": "Не можах да намеря никакви субтитри",
      "Email Address": "Имейл адрес",
      "No seen videos yet": "Все още няма видени видеоклипове",
      "Password": "парола",
      "Search": "Търсене",
      "Default": "По подразбиране",
      "Last Name": "Фамилия"
    }
  },
  "haw": {
    "translation": {
      "Search or paste link": "Huli a hoʻopili i ka loulou",
      "Search": "Huli",
      "Sign Out": "Lele",
      "Password": "Hua huna",
      "First Name": "Inoa mua",
      "Donate": "Hāʻawi",
      "Could not find any subtitles": "ʻAʻole hiki ke loaʻa nā huaʻōlelo",
      "translated clips": "nā kiʻi unuhi",
      "Last Name": "Inoa hope",
      "Email Address": "Helu leka uila",
      "No seen videos yet": "ʻAʻohe wikiō i ʻike ʻia",
      "Default": "Default",
      "Language": "ʻŌlelo"
    }
  },
  "mt": {
    "translation": {
      "Donate": "Agħti donazzjoni",
      "No seen videos yet": "Għadu ma dehru l-ebda vidjows",
      "First Name": "Isem",
      "Search": "Fittex",
      "Language": "Lingwa",
      "Password": "Password",
      "Sign Out": "Sign Out",
      "Last Name": "Kunjom",
      "Could not find any subtitles": "Ma setgħetx issib sottotitli",
      "Search or paste link": "Fittex jew paste link",
      "Default": "Default",
      "Email Address": "Indirizz elettroniku",
      "translated clips": "klipps tradotti"
    }
  }
}
const lang2 = {
  "en": {
    "translation": {
      "Signing in is available only on the mobile app": "Signing in is available only on the mobile app",
      "You have no channel subscriptions": "You have no channel subscriptions",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Sign in with your Google account to view the latest on your channel subscriptions",
      "Trending": "Trending",
      "News": "News",
      "Entertainment": "Entertainment",
      "Comedy": "Comedy",
      "Music": "Music",
      "Film": "Film",
      "Science&Tech": "Science&Tech",
      "Gaming": "Gaming",
      "Sports": "Sports",
      "Education": "Education",
      "Autos": "Autos",
      "Pets": "Pets",
      "People": "People",
      "Style": "Style"
    }
  },
  "is": {
    "translation": {
      "People": "Fólk",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Skráðu þig inn með Google reikningnum þínum til að skoða það nýjasta um rásaráskriftirnar þínar",
      "Entertainment": "Skemmtun",
      "Autos": "Bílar",
      "Comedy": "Gamanleikur",
      "News": "Fréttir",
      "Science&Tech": "Vísindi og tækni",
      "Gaming": "Spilamennska",
      "Trending": "Vinsælt",
      "Signing in is available only on the mobile app": "Innskráning er aðeins í boði í farsímaappinu",
      "Style": "Stíll",
      "You have no channel subscriptions": "Þú ert ekki með rásaráskrift",
      "Film": "Kvikmynd",
      "Pets": "Gæludýr",
      "Education": "Menntun",
      "Music": "Tónlist",
      "Sports": "Íþróttir"
    }
  },
  "fr": {
    "translation": {
      "Music": "La musique",
      "You have no channel subscriptions": "Vous n'êtes pas abonné à une chaîne",
      "Trending": "Tendance",
      "Signing in is available only on the mobile app": "La connexion est disponible uniquement sur l'application mobile",
      "Comedy": "La comédie",
      "Entertainment": "Divertissement",
      "Autos": "voitures",
      "Sports": "Des sports",
      "People": "Gens",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Connectez-vous avec votre compte Google pour voir les dernières actualités sur vos abonnements aux chaînes",
      "Film": "Film",
      "Education": "Éducation",
      "Style": "Style",
      "Gaming": "Jeux",
      "News": "Nouvelles",
      "Science&Tech": "Sciences et technologies",
      "Pets": "Animaux domestiques"
    }
  },
  "ca": {
    "translation": {
      "Trending": "Tendència",
      "People": "Gent",
      "News": "Notícies",
      "Gaming": "Jocs",
      "Education": "Educació",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Inicieu la sessió amb el vostre compte de Google per veure les últimes subscripcions al vostre canal",
      "Pets": "Animals de companyia",
      "Comedy": "Comèdia",
      "Style": "Estil",
      "Sports": "Esports",
      "Science&Tech": "Ciència i tecnologia",
      "Film": "Pel·lícula",
      "Music": "Música",
      "Entertainment": "Entreteniment",
      "You have no channel subscriptions": "No tens subscripcions al canal",
      "Autos": "Autos",
      "Signing in is available only on the mobile app": "L'inici de sessió només està disponible a l'aplicació mòbil"
    }
  },
  "ja": {
    "translation": {
      "News": "ニュース",
      "You have no channel subscriptions": "チャンネルサブスクリプションはありません",
      "Sports": "スポーツ",
      "Music": "音楽",
      "Style": "スタイル",
      "Film": "映画",
      "Entertainment": "エンターテイメント",
      "Science&Tech": "科学技術",
      "Gaming": "ゲーム",
      "Pets": "ペット",
      "Signing in is available only on the mobile app": "サインインはモバイルアプリでのみ利用可能です",
      "Autos": "車",
      "Comedy": "コメディ",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Googleアカウントでサインインして、チャンネルサブスクリプションの最新情報を表示します",
      "Trending": "トレンド",
      "Education": "教育",
      "People": "人々"
    }
  },
  "bg": {
    "translation": {
      "Comedy": "Комедия",
      "Sports": "Спорт",
      "You have no channel subscriptions": "Нямате абонамент за канали",
      "Film": "филм",
      "Pets": "Домашни любимци",
      "Style": "стил",
      "Science&Tech": "Наука и технологии",
      "Gaming": "игри",
      "Autos": "автомобили",
      "People": "хора",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Влезте с вашия акаунт в Google, за да видите най-новото за абонаментите си за канали",
      "Signing in is available only on the mobile app": "Влизането е достъпно само в мобилното приложение",
      "Entertainment": "Забавление",
      "News": "Новини",
      "Education": "Образование",
      "Music": "Музика",
      "Trending": "Набиращи популярност"
    }
  },
  "pt": {
    "translation": {
      "Education": "Educação",
      "Comedy": "Comédia",
      "Signing in is available only on the mobile app": "O login está disponível apenas no aplicativo para dispositivos móveis",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Faça login com sua conta do Google para ver as últimas informações sobre suas inscrições de canal",
      "Autos": "carros",
      "Style": "Estilo",
      "Film": "Filme",
      "Entertainment": "Entretenimento",
      "People": "Pessoas",
      "You have no channel subscriptions": "Você não tem inscrições no canal",
      "Trending": "Tendendo",
      "Gaming": "Jogos",
      "Music": "Música",
      "Science&Tech": "Ciência e Tecnologia",
      "News": "Notícias",
      "Sports": "Esportes",
      "Pets": "Animais de estimação"
    }
  },
  "hu": {
    "translation": {
      "Entertainment": "Szórakozás",
      "Comedy": "Komédia",
      "Pets": "Háziállatok",
      "Science&Tech": "Science&Tech",
      "Gaming": "Szerencsejáték",
      "Sports": "Sport",
      "Trending": "Felkapott",
      "Education": "Oktatás",
      "People": "Emberek",
      "Music": "Zene",
      "Film": "Film",
      "Signing in is available only on the mobile app": "A bejelentkezés csak a mobilalkalmazásban lehetséges",
      "Style": "Stílus",
      "News": "hírek",
      "Autos": "autók",
      "You have no channel subscriptions": "Nincs csatorna-előfizetésed",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Jelentkezz be Google-fiókoddal, hogy megtekintsd a legfrissebb híreket csatornáiddal kapcsolatos feliratkozásaidról"
    }
  },
  "ml": {
    "translation": {
      "Sign in with your Google account to view the latest on your channel subscriptions": "നിങ്ങളുടെ ചാനൽ സബ്‌സ്‌ക്രിപ്‌ഷനുകളിൽ ഏറ്റവും പുതിയത് കാണുന്നതിന് നിങ്ങളുടെ Google അക്കൗണ്ട് ഉപയോഗിച്ച് സൈൻ ഇൻ ചെയ്യുക",
      "Entertainment": "വിനോദം",
      "Autos": "കാറുകൾ",
      "Film": "ഫിലിം",
      "Signing in is available only on the mobile app": "സൈൻ ഇൻ ചെയ്യുന്നത് മൊബൈൽ ആപ്പിൽ മാത്രമേ ലഭ്യമാകൂ",
      "Gaming": "ഗെയിമിംഗ്",
      "You have no channel subscriptions": "നിങ്ങൾക്ക് ചാനൽ സബ്‌സ്‌ക്രിപ്‌ഷനുകളൊന്നുമില്ല",
      "Music": "സംഗീതം",
      "News": "വാർത്ത",
      "Style": "ശൈലി",
      "Science&Tech": "സയൻസ് & ടെക്",
      "Education": "വിദ്യാഭ്യാസം",
      "Trending": "ട്രെൻഡിംഗ്",
      "People": "ആളുകൾ",
      "Sports": "കായികം",
      "Comedy": "കോമഡി",
      "Pets": "വളർത്തുമൃഗങ്ങൾ"
    }
  },
  "sl": {
    "translation": {
      "News": "novice",
      "Autos": "avtomobili",
      "Entertainment": "Zabava",
      "Pets": "Hišni ljubljenčki",
      "You have no channel subscriptions": "Nimate naročnin na kanal",
      "Film": "Film",
      "Music": "Glasba",
      "Trending": "V trendu",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Prijavite se s svojim Google Računom, če si želite ogledati najnovejše naročnine na svoje kanale",
      "Gaming": "Igranje iger",
      "Sports": "Šport",
      "Signing in is available only on the mobile app": "Prijava je na voljo samo v mobilni aplikaciji",
      "Comedy": "Komedija",
      "Science&Tech": "znanost in tehnika",
      "Education": "Izobraževanje",
      "Style": "Slog",
      "People": "Ljudje"
    }
  },
  "nl": {
    "translation": {
      "Gaming": "Gamen",
      "You have no channel subscriptions": "Je hebt geen kanaalabonnementen",
      "Signing in is available only on the mobile app": "Aanmelden is alleen beschikbaar in de mobiele app",
      "Trending": "Trending",
      "Music": "Muziek",
      "Film": "Film",
      "Autos": "auto's",
      "Comedy": "Komedie",
      "Style": "Stijl",
      "Science&Tech": "Wetenschap&Tech",
      "Pets": "Huisdieren",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Log in met je Google-account om het laatste nieuws over je kanaalabonnementen te bekijken",
      "Education": "Opleiding",
      "News": "Nieuws",
      "Sports": "Sport",
      "People": "Mensen",
      "Entertainment": "Amusement"
    }
  },
  "it": {
    "translation": {
      "Autos": "macchine",
      "Pets": "Animali domestici",
      "Trending": "Tendenza",
      "Science&Tech": "Scienza&Tecnologia",
      "Sports": "Gli sport",
      "Gaming": "Gioco",
      "Education": "Formazione scolastica",
      "Music": "Musica",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Accedi con il tuo account Google per visualizzare le ultime novità sulle iscrizioni ai tuoi canali",
      "News": "Notizia",
      "Entertainment": "Divertimento",
      "Film": "Film",
      "Comedy": "Commedia",
      "Style": "Stile",
      "You have no channel subscriptions": "Non hai iscrizioni al canale",
      "Signing in is available only on the mobile app": "L'accesso è disponibile solo sull'app mobile",
      "People": "Le persone"
    }
  },
  "ga": {
    "translation": {
      "Science&Tech": "Eolaíocht & Teicneolaíocht",
      "Signing in is available only on the mobile app": "Níl síniú isteach ar fáil ach ar an aip shoghluaiste",
      "News": "Nuacht",
      "Gaming": "Cearrbhachas",
      "Sports": "Spóirt",
      "Education": "Oideachas",
      "Music": "Ceol",
      "Autos": "gluaisteáin",
      "People": "Daoine",
      "You have no channel subscriptions": "Níl aon síntiús cainéal agat",
      "Trending": "Treocht",
      "Film": "Scannán",
      "Comedy": "Coiméide",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Sínigh isteach le do Chuntas Google chun féachaint ar na cinn is déanaí de do shíntiúis chainéil",
      "Pets": "Peataí",
      "Style": "Stíl",
      "Entertainment": "Siamsaíocht"
    }
  },
  "am": {
    "translation": {
      "Pets": "የቤት እንስሳት",
      "Style": "ቅጥ",
      "Science&Tech": "ሳይንስ እና ቴክኖሎጂ",
      "Entertainment": "መዝናኛ",
      "People": "ሰዎች",
      "Education": "ትምህርት",
      "Music": "ሙዚቃ",
      "Trending": "በመታየት ላይ ያለ",
      "Film": "ፊልም",
      "You have no channel subscriptions": "ምንም የሰርጥ ምዝገባዎች የሎትም።",
      "Signing in is available only on the mobile app": "መግባት በሞባይል መተግበሪያ ላይ ብቻ ይገኛል።",
      "Sports": "ስፖርት",
      "Gaming": "ጨዋታ",
      "Autos": "መኪኖች",
      "News": "ዜና",
      "Sign in with your Google account to view the latest on your channel subscriptions": "በሰርጥ ምዝገባዎችዎ ላይ የቅርብ ጊዜውን ለማየት በጉግል መለያዎ ይግቡ",
      "Comedy": "አስቂኝ"
    }
  },
  "sv": {
    "translation": {
      "Sports": "sporter",
      "Education": "Utbildning",
      "Entertainment": "Underhållning",
      "Science&Tech": "Science&Tech",
      "Comedy": "Komedi",
      "People": "människor",
      "Pets": "Husdjur",
      "Signing in is available only on the mobile app": "Inloggning är endast tillgängligt i mobilappen",
      "Music": "musik",
      "News": "Nyheter",
      "Autos": "bilar",
      "Trending": "Trendigt",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Logga in med ditt Google-konto för att se det senaste om dina kanalprenumerationer",
      "Style": "Stil",
      "Film": "Filma",
      "Gaming": "Spelande",
      "You have no channel subscriptions": "Du har inga kanalprenumerationer"
    }
  },
  "ar": {
    "translation": {
      "Sign in with your Google account to view the latest on your channel subscriptions": "قم بتسجيل الدخول باستخدام حساب Google الخاص بك لعرض أحدث ما تم بشأن اشتراكات قناتك",
      "Film": "فيلم",
      "Education": "تعليم",
      "Signing in is available only on the mobile app": "تسجيل الدخول متاح فقط على تطبيق الهاتف",
      "Pets": "حيوانات أليفة",
      "Music": "موسيقى",
      "Autos": "سيارات",
      "Science&Tech": "العلوم والتكنولوجيا",
      "News": "أخبار",
      "Comedy": "كوميديا",
      "Sports": "رياضات",
      "Entertainment": "وسائل الترفيه",
      "You have no channel subscriptions": "ليس لديك اشتراكات في القناة",
      "Style": "أسلوب",
      "Trending": "الشائع",
      "Gaming": "الألعاب",
      "People": "الناس"
    }
  },
  "pl": {
    "translation": {
      "Sports": "Sporty",
      "People": "Ludzie",
      "Film": "Film",
      "Science&Tech": "Nauka i technika",
      "Trending": "Trendy",
      "You have no channel subscriptions": "Nie masz subskrypcji kanałów",
      "Education": "Edukacja",
      "Gaming": "Hazard",
      "Style": "Styl",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Zaloguj się na swoje konto Google, aby zobaczyć najnowsze informacje o swoich subskrypcjach kanałów",
      "Entertainment": "Rozrywka",
      "Autos": "samochody",
      "Pets": "Zwierzęta",
      "News": "Aktualności",
      "Comedy": "Komedia",
      "Signing in is available only on the mobile app": "Logowanie jest dostępne tylko w aplikacji mobilnej",
      "Music": "Muzyka"
    }
  },
  "cs": {
    "translation": {
      "Autos": "auta",
      "Gaming": "Hraní",
      "Music": "Hudba",
      "You have no channel subscriptions": "Nemáte žádné odběry kanálů",
      "Entertainment": "Zábava",
      "Science&Tech": "Science&Tech",
      "Comedy": "Komedie",
      "News": "Zprávy",
      "Education": "Vzdělání",
      "Style": "Styl",
      "People": "Lidé",
      "Trending": "Trendy",
      "Signing in is available only on the mobile app": "Přihlášení je dostupné pouze v mobilní aplikaci",
      "Sports": "Sportovní",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Přihlaste se pomocí svého účtu Google a prohlédněte si nejnovější informace o odběrech svých kanálů",
      "Film": "Film",
      "Pets": "Domácí mazlíčci"
    }
  },
  "sm": {
    "translation": {
      "News": "Tala Fou",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Saini i totonu i lau Google account e matamata ai i tala fou i luga o au fa'asalalauga",
      "Signing in is available only on the mobile app": "E maua na'o le saini i totonu ile telefoni feavea'i",
      "Trending": "Ta'amilosaga",
      "Comedy": "Maliu",
      "Autos": "taavale",
      "People": "Tagata",
      "Pets": "Fagafao",
      "Film": "Ata tifaga",
      "Gaming": "Taaloga",
      "Sports": "Ta'aloga",
      "Music": "Musika",
      "Education": "A'oga",
      "You have no channel subscriptions": "E leai sau fa'auluga o alaleo",
      "Science&Tech": "Saienisi & Tekinolosi",
      "Entertainment": "Fa'afiafiaga",
      "Style": "Sitaili"
    }
  },
  "af": {
    "translation": {
      "Entertainment": "Vermaak",
      "Education": "Onderwys",
      "You have no channel subscriptions": "Jy het geen kanaalintekeninge nie",
      "Music": "Musiek",
      "Signing in is available only on the mobile app": "Aanmelding is slegs op die mobiele toepassing beskikbaar",
      "Pets": "Troeteldiere",
      "Gaming": "Speletjies",
      "Science&Tech": "Wetenskap en Tegnologie",
      "News": "Nuus",
      "Comedy": "Komedie",
      "Trending": "Trending",
      "Film": "Film",
      "Autos": "motors",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Meld aan met jou Google-rekening om die nuutste op jou kanaalintekeninge te sien",
      "People": "Mense",
      "Sports": "Sport",
      "Style": "Styl"
    }
  },
  "ru": {
    "translation": {
      "Entertainment": "Развлечение",
      "Science&Tech": "Наука и техника",
      "Comedy": "Комедия",
      "You have no channel subscriptions": "У вас нет подписки на канал",
      "Music": "Музыка",
      "Trending": "В тренде",
      "People": "Люди",
      "News": "Новости",
      "Gaming": "Игры",
      "Education": "Образование",
      "Signing in is available only on the mobile app": "Вход доступен только в мобильном приложении",
      "Autos": "легковые автомобили",
      "Style": "Стиль",
      "Film": "фильм",
      "Pets": "Домашние питомцы",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Войдите в свою учетную запись Google, чтобы просмотреть последние подписки на ваш канал.",
      "Sports": "Спортивный"
    }
  },
  "el": {
    "translation": {
      "Sign in with your Google account to view the latest on your channel subscriptions": "Συνδεθείτε με τον Λογαριασμό σας Google για να δείτε τα πιο πρόσφατα σχετικά με τις συνδρομές του καναλιού σας",
      "Signing in is available only on the mobile app": "Η σύνδεση είναι διαθέσιμη μόνο στην εφαρμογή για κινητά",
      "News": "Νέα",
      "Pets": "Κατοικίδια",
      "Entertainment": "Ψυχαγωγία",
      "Film": "Ταινία",
      "Trending": "Τάσεις",
      "Gaming": "Παιχνίδι",
      "Style": "Στυλ",
      "Comedy": "Κωμωδία",
      "You have no channel subscriptions": "Δεν έχετε συνδρομές καναλιού",
      "Education": "Εκπαίδευση",
      "Science&Tech": "Επιστήμη & Τεχνολογία",
      "Sports": "Αθλητισμός",
      "Autos": "αυτοκίνητα",
      "Music": "ΜΟΥΣΙΚΗ",
      "People": "Ανθρωποι"
    }
  },
  "lv": {
    "translation": {
      "You have no channel subscriptions": "Jums nav kanālu abonementu",
      "People": "Cilvēki",
      "Film": "Filma",
      "Autos": "automašīnas",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Pierakstieties savā Google kontā, lai skatītu jaunāko informāciju par kanālu abonementiem",
      "Style": "Stils",
      "Comedy": "Komēdija",
      "Trending": "Tendences",
      "Education": "Izglītība",
      "Pets": "Mājdzīvnieki",
      "Sports": "Sports",
      "Entertainment": "Izklaide",
      "Signing in is available only on the mobile app": "Pierakstīšanās ir pieejama tikai mobilajā lietotnē",
      "News": "Jaunumi",
      "Science&Tech": "Zinātne&Tech",
      "Music": "Mūzika",
      "Gaming": "Spēles"
    }
  },
  "mt": {
    "translation": {
      "Music": "Mużika",
      "People": "Nies",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Idħol mal-kont tal-Google tiegħek biex tara l-aħħar fuq l-abbonamenti tal-kanali tiegħek",
      "Entertainment": "Divertiment",
      "Autos": "karozzi",
      "Gaming": "Logħob",
      "Science&Tech": "Xjenza u Teknoloġija",
      "News": "Aħbarijiet",
      "Pets": "Pets",
      "Signing in is available only on the mobile app": "L-iffirmar huwa disponibbli biss fuq l-app mobbli",
      "Comedy": "Comedy",
      "You have no channel subscriptions": "M'għandekx abbonamenti tal-kanali",
      "Trending": "Trending",
      "Style": "Stil",
      "Education": "Edukazzjoni",
      "Film": "Film",
      "Sports": "Sports"
    }
  },
  "hr": {
    "translation": {
      "News": "Vijesti",
      "Trending": "U trendu",
      "Style": "Stil",
      "You have no channel subscriptions": "Nemate pretplate na kanale",
      "Music": "glazba, muzika",
      "Gaming": "Igranje igara",
      "Comedy": "Komedija",
      "Science&Tech": "znanost i tehnologija",
      "Pets": "Kućni ljubimci",
      "Entertainment": "Zabava",
      "Autos": "automobili",
      "Film": "Film",
      "Sports": "Sportski",
      "Education": "Obrazovanje",
      "People": "narod",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Prijavite se svojim Google računom da biste vidjeli najnovije pretplate na kanale",
      "Signing in is available only on the mobile app": "Prijava je dostupna samo na mobilnoj aplikaciji"
    }
  },
  "id": {
    "translation": {
      "Music": "Musik",
      "You have no channel subscriptions": "Anda tidak memiliki langganan saluran",
      "News": "Berita",
      "Style": "Gaya",
      "Sports": "Olahraga",
      "Autos": "mobil",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Masuk dengan akun Google Anda untuk melihat yang terbaru tentang langganan saluran Anda",
      "People": "Orang-orang",
      "Science&Tech": "Sains & Teknologi",
      "Comedy": "Komedi",
      "Trending": "Sedang tren",
      "Entertainment": "Hiburan",
      "Education": "Pendidikan",
      "Pets": "hewan peliharaan",
      "Signing in is available only on the mobile app": "Masuk hanya tersedia di aplikasi seluler",
      "Film": "Film",
      "Gaming": "Game"
    }
  },
  "lt": {
    "translation": {
      "People": "Žmonės",
      "Sports": "Sportas",
      "Signing in is available only on the mobile app": "Prisijungti galima tik mobiliojoje programėlėje",
      "Trending": "Tendencijos",
      "News": "žinios",
      "Music": "Muzika",
      "Autos": "automobiliai",
      "Entertainment": "Pramogos",
      "Film": "Filmas",
      "Comedy": "Komedija",
      "Pets": "Augintiniai",
      "Education": "Išsilavinimas",
      "Style": "Stilius",
      "Gaming": "Žaidimas",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Prisijunkite naudodami „Google“ paskyrą, kad peržiūrėtumėte naujausią informaciją apie kanalų prenumeratas",
      "You have no channel subscriptions": "Neturite kanalų prenumeratų",
      "Science&Tech": "Mokslas ir technologijos"
    }
  },
  "sq": {
    "translation": {
      "Education": "Arsimi",
      "Film": "Film",
      "Music": "Muzikë",
      "News": "Lajme",
      "Gaming": "Lojëra",
      "You have no channel subscriptions": "Nuk keni abonime në kanal",
      "People": "Njerëzit",
      "Comedy": "Komedi",
      "Sports": "Sportive",
      "Pets": "Kafshët shtëpiake",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Identifikohu me llogarinë tënde të Google për të parë më të fundit në abonimet e kanalit tënd",
      "Entertainment": "Argëtim",
      "Science&Tech": "Shkencë & Teknikë",
      "Autos": "makina",
      "Trending": "Në trend",
      "Style": "Stili",
      "Signing in is available only on the mobile app": "Identifikimi është i disponueshëm vetëm në aplikacionin celular"
    }
  },
  "co": {
    "translation": {
      "Sports": "Sports",
      "Pets": "Animali",
      "Comedy": "Cumedia",
      "Gaming": "Lusinghi",
      "Entertainment": "Divertimentu",
      "Music": "Musica",
      "You have no channel subscriptions": "Ùn avete micca abbonamenti di canali",
      "Trending": "Trending",
      "Autos": "vitture",
      "Signing in is available only on the mobile app": "A registrazione hè dispunibule solu nantu à l'app mobile",
      "Film": "Film",
      "People": "People",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Accedi cù u vostru contu Google per vede l'ultime nantu à i vostri abbonamenti di canali",
      "Science&Tech": "Scienza è Tecnulugia",
      "News": "News",
      "Style": "Stile",
      "Education": "Educazione"
    }
  },
  "fi": {
    "translation": {
      "Trending": "Trendaavat",
      "Comedy": "Komedia",
      "Science&Tech": "Tiede ja tekniikka",
      "Education": "koulutus",
      "Pets": "Lemmikit",
      "People": "Ihmiset",
      "Autos": "autoja",
      "Style": "Tyyli",
      "Signing in is available only on the mobile app": "Kirjautuminen on mahdollista vain mobiilisovelluksella",
      "Gaming": "Pelaaminen",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Kirjaudu sisään Google-tililläsi nähdäksesi uusimmat kanavatilauksesi",
      "Music": "Musiikki",
      "You have no channel subscriptions": "Sinulla ei ole kanavatilauksia",
      "Film": "Elokuva",
      "Entertainment": "Viihde",
      "News": "Uutiset",
      "Sports": "Urheilu"
    }
  },
  "no": {
    "translation": {
      "You have no channel subscriptions": "Du har ingen kanalabonnementer",
      "Autos": "biler",
      "Science&Tech": "Vitenskap og teknologi",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Logg på med Google-kontoen din for å se siste nytt om kanalabonnementene dine",
      "Sports": "Sport",
      "Music": "Musikk",
      "Education": "utdanning",
      "Film": "Film",
      "People": "Mennesker",
      "Comedy": "Komedie",
      "Gaming": "Gaming",
      "Signing in is available only on the mobile app": "Pålogging er kun tilgjengelig på mobilappen",
      "Trending": "Trender",
      "Entertainment": "Underholdning",
      "Pets": "Kjæledyr",
      "News": "Nyheter",
      "Style": "Stil"
    }
  },
  "bs": {
    "translation": {
      "Gaming": "Gaming",
      "You have no channel subscriptions": "Nemate pretplate na kanale",
      "Entertainment": "Zabava",
      "Pets": "Kućni ljubimci",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Prijavite se sa svojim Google računom da vidite najnovije pretplate na kanale",
      "Trending": "U trendu",
      "Sports": "Sport",
      "Music": "Muzika",
      "Education": "Obrazovanje",
      "Science&Tech": "Nauka i tehnologija",
      "News": "Vijesti",
      "Signing in is available only on the mobile app": "Prijava je dostupna samo na mobilnoj aplikaciji",
      "Film": "Film",
      "Style": "Stil",
      "Comedy": "Komedija",
      "Autos": "automobili",
      "People": "Ljudi"
    }
  },
  "es": {
    "translation": {
      "Entertainment": "Entretenimiento",
      "Comedy": "Comedia",
      "You have no channel subscriptions": "No tienes suscripciones a canales.",
      "News": "Noticias",
      "Style": "Estilo",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Inicie sesión con su cuenta de Google para ver lo último en las suscripciones de su canal",
      "Pets": "Mascotas",
      "Sports": "Deportes",
      "Science&Tech": "Ciencia y Tecnología",
      "Trending": "Tendencias",
      "Gaming": "Juego de azar",
      "Autos": "Autos",
      "People": "Gente",
      "Film": "Película",
      "Music": "Música",
      "Signing in is available only on the mobile app": "El inicio de sesión solo está disponible en la aplicación móvil",
      "Education": "Educación"
    }
  },
  "ko": {
    "translation": {
      "Entertainment": "오락",
      "Film": "필름",
      "Autos": "자동차",
      "Education": "교육",
      "You have no channel subscriptions": "채널 구독이 없습니다.",
      "Sports": "스포츠",
      "Sign in with your Google account to view the latest on your channel subscriptions": "채널 구독에 대한 최신 정보를 보려면 Google 계정으로 로그인하세요.",
      "Comedy": "코메디",
      "Music": "음악",
      "Science&Tech": "과학기술",
      "Gaming": "노름",
      "Trending": "트렌드",
      "Style": "스타일",
      "Signing in is available only on the mobile app": "로그인은 모바일 앱에서만 가능합니다.",
      "People": "사람들",
      "News": "소식",
      "Pets": "애완동물"
    }
  },
  "mg": {
    "translation": {
      "People": "People",
      "Gaming": "lalao",
      "Education": "fampianarana",
      "Sports": "fanatanjahan-tena",
      "Film": "horonan-tsary",
      "Signing in is available only on the mobile app": "Ny fidirana dia tsy misy afa-tsy amin'ny fampiharana finday",
      "Trending": "malaza",
      "Entertainment": "Fialam-boly",
      "Autos": "FIARAKODIA",
      "Comedy": "mampihomehy",
      "You have no channel subscriptions": "Tsy manana famandrihana fantsona ianao",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Midira amin'ny kaontinao Google raha hijery ny vaovao farany amin'ny famandrihana fantsona anao",
      "News": "NEWS",
      "Science&Tech": "Siansa & Teknolojia",
      "Music": "MOZIKA",
      "Pets": "Pets",
      "Style": "Style"
    }
  },
  "da": {
    "translation": {
      "Signing in is available only on the mobile app": "Det er kun muligt at logge ind på mobilappen",
      "Autos": "biler",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Log ind med din Google-konto for at se det seneste om dine kanalabonnementer",
      "Entertainment": "Underholdning",
      "Gaming": "Spil",
      "Science&Tech": "Videnskab & Teknologi",
      "You have no channel subscriptions": "Du har ingen kanalabonnementer",
      "Comedy": "Komedie",
      "Style": "Stil",
      "Education": "Uddannelse",
      "Trending": "Trending",
      "News": "Nyheder",
      "Pets": "Kæledyr",
      "Music": "musik",
      "Film": "Film",
      "Sports": "Sport",
      "People": "Mennesker"
    }
  },
  "et": {
    "translation": {
      "Trending": "Trendikas",
      "Autos": "autod",
      "Gaming": "Mängimine",
      "Pets": "Lemmikloomad",
      "Music": "Muusika",
      "Sports": "Sport",
      "Entertainment": "Meelelahutus",
      "Film": "Film",
      "Signing in is available only on the mobile app": "Sisselogimine on saadaval ainult mobiilirakenduses",
      "Education": "Haridus",
      "Science&Tech": "Teadus ja tehnika",
      "News": "Uudised",
      "Comedy": "Komöödia",
      "People": "Inimesed",
      "Style": "Stiil",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Logige sisse oma Google'i kontoga, et vaadata uusimat kanalite tellimuste kohta",
      "You have no channel subscriptions": "Teil pole kanalitellimusi"
    }
  },
  "de": {
    "translation": {
      "Entertainment": "Unterhaltung",
      "Autos": "Autos",
      "Signing in is available only on the mobile app": "Die Anmeldung ist nur in der mobilen App möglich",
      "Sports": "Sport",
      "Style": "Stil",
      "Pets": "Haustiere",
      "Film": "Film",
      "People": "Menschen",
      "Trending": "Im Trend",
      "Gaming": "Spielen",
      "Comedy": "Komödie",
      "Education": "Bildung",
      "News": "Nachrichten",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Melden Sie sich mit Ihrem Google-Konto an, um die neuesten Informationen zu Ihren Kanalabonnements anzuzeigen",
      "You have no channel subscriptions": "Du hast keine Kanalabonnements",
      "Science&Tech": "Wissenschaft & Technik",
      "Music": "Musik"
    }
  },
  "zh": {
    "translation": {
      "Style": "风格",
      "You have no channel subscriptions": "您没有频道订阅",
      "News": "消息",
      "Sign in with your Google account to view the latest on your channel subscriptions": "使用您的 Google 帐户登录以查看您的频道订阅的最新消息",
      "Trending": "趋势",
      "Entertainment": "娱乐",
      "Autos": "汽车",
      "Comedy": "喜剧",
      "Science&Tech": "科技",
      "Film": "电影",
      "Music": "音乐",
      "Signing in is available only on the mobile app": "仅可在移动应用上登录",
      "Gaming": "赌博",
      "Sports": "运动的",
      "Pets": "宠物",
      "Education": "教育",
      "People": "人们"
    }
  },
  "iw": {
    "translation": {
      "Sports": "ספורט",
      "Sign in with your Google account to view the latest on your channel subscriptions": "היכנס עם חשבון Google שלך כדי לראות את העדכונים העדכניים ביותר לגבי ההרשמות לערוץ שלך",
      "Music": "מוּסִיקָה",
      "Pets": "חיות מחמד",
      "News": "חֲדָשׁוֹת",
      "Entertainment": "בידור",
      "Comedy": "קוֹמֶדִיָה",
      "Trending": "מגמות",
      "Science&Tech": "מדע וטכנולוגיה",
      "People": "אֲנָשִׁים",
      "Signing in is available only on the mobile app": "הכניסה זמינה רק באפליקציה לנייד",
      "Autos": "מכוניות",
      "Style": "סִגְנוֹן",
      "Film": "סרט צילום",
      "Education": "חינוך",
      "You have no channel subscriptions": "אין לך מנויים לערוץ",
      "Gaming": "משחקים"
    }
  },
  "hi": {
    "translation": {
      "Education": "शिक्षा",
      "Signing in is available only on the mobile app": "साइन इन करना केवल मोबाइल ऐप पर उपलब्ध है",
      "Music": "संगीत",
      "Trending": "रुझान",
      "Film": "फ़िल्म",
      "Sign in with your Google account to view the latest on your channel subscriptions": "अपने चैनल सदस्यता पर नवीनतम देखने के लिए अपने Google खाते से साइन इन करें",
      "Pets": "पालतू जानवर",
      "Autos": "कारों",
      "You have no channel subscriptions": "आपके पास कोई चैनल सदस्यता नहीं है",
      "Entertainment": "मनोरंजन",
      "People": "लोग",
      "Sports": "खेल",
      "News": "समाचार",
      "Gaming": "जुआ",
      "Style": "अंदाज",
      "Comedy": "कॉमेडी",
      "Science&Tech": "विज्ञान और तकनीक"
    }
  },
  "sr": {
    "translation": {
      "News": "Вести",
      "Science&Tech": "Наука и технологија",
      "Film": "Филм",
      "Education": "образовање",
      "Comedy": "Комедија",
      "People": "Људи",
      "Pets": "Кућни љубимци",
      "Gaming": "Гаминг",
      "Entertainment": "Забава",
      "Style": "Стил",
      "Signing in is available only on the mobile app": "Пријављивање је доступно само на мобилној апликацији",
      "You have no channel subscriptions": "Немате претплате на канале",
      "Music": "Музика",
      "Sports": "Спортс",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Пријавите се помоћу свог Гоогле налога да бисте видели најновије претплате на канале",
      "Autos": "аутомобили",
      "Trending": "У тренду"
    }
  },
  "sk": {
    "translation": {
      "Pets": "Domáce zvieratá",
      "Autos": "autá",
      "Film": "Film",
      "Style": "Štýl",
      "Education": "Vzdelávanie",
      "Music": "Hudba",
      "Gaming": "Hranie",
      "Sports": "Šport",
      "Trending": "Trendy",
      "You have no channel subscriptions": "Nemáte žiadne odbery kanálov",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Ak chcete zobraziť najnovšie informácie o odberoch svojich kanálov, prihláste sa pomocou svojho účtu Google",
      "Science&Tech": "Science&Tech",
      "Comedy": "Komédia",
      "People": "Ľudia",
      "Entertainment": "Zábava",
      "News": "Správy",
      "Signing in is available only on the mobile app": "Prihlásenie je možné iba v mobilnej aplikácii"
    }
  },
  "ro": {
    "translation": {
      "Entertainment": "Divertisment",
      "People": "oameni",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Conectați-vă cu contul dvs. Google pentru a vedea cele mai recente abonamente la canal",
      "Style": "Stil",
      "News": "Știri",
      "Trending": "Trending",
      "Film": "Film",
      "Sports": "Sport",
      "Education": "Educaţie",
      "Signing in is available only on the mobile app": "Conectarea este disponibilă numai în aplicația mobilă",
      "Gaming": "Jocuri",
      "Autos": "mașini",
      "Pets": "Animale de companie",
      "Science&Tech": "Știință și tehnologie",
      "Comedy": "Comedie",
      "Music": "Muzică",
      "You have no channel subscriptions": "Nu aveți abonamente la canal"
    }
  },
  "tr": {
    "translation": {
      "Autos": "arabalar",
      "Education": "Eğitim",
      "Entertainment": "Eğlence",
      "Pets": "Evcil Hayvanlar",
      "You have no channel subscriptions": "Kanal aboneliğiniz yok",
      "Sports": "Spor Dalları",
      "Film": "Film",
      "Signing in is available only on the mobile app": "Oturum açma yalnızca mobil uygulamada kullanılabilir",
      "Trending": "trend",
      "Music": "Müzik",
      "People": "İnsanlar",
      "News": "Haberler",
      "Style": "stil",
      "Science&Tech": "Bilim ve Teknoloji",
      "Gaming": "oyun",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Kanal aboneliklerinizle ilgili en son bilgileri görüntülemek için Google hesabınızla oturum açın",
      "Comedy": "Komedi"
    }
  },
  "uk": {
    "translation": {
      "Comedy": "Комедія",
      "News": "Новини",
      "Music": "Музика",
      "Gaming": "Ігри",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Увійдіть у свій обліковий запис Google, щоб переглянути останні підписки на канал",
      "You have no channel subscriptions": "У вас немає підписок на канал",
      "Sports": "Спорт",
      "Education": "Освіта",
      "Film": "Фільм",
      "Trending": "У тренді",
      "Entertainment": "Розваги",
      "People": "Люди",
      "Autos": "автомобілів",
      "Signing in is available only on the mobile app": "Вхід доступний лише в мобільному додатку",
      "Science&Tech": "Наука і техніка",
      "Pets": "Домашні тварини",
      "Style": "Стиль"
    }
  },
  "mk": {
    "translation": {
      "You have no channel subscriptions": "Немате претплати на канали",
      "Science&Tech": "Наука и технологија",
      "Comedy": "Комедија",
      "Style": "Стил",
      "Pets": "Домашни миленици",
      "Entertainment": "Забава",
      "People": "Луѓе",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Пријавете се со вашата сметка на Google за да ги видите најновите на вашите претплати на канали",
      "Film": "Филм",
      "Gaming": "Играње",
      "Trending": "Во тренд",
      "Autos": "Автомобили",
      "Signing in is available only on the mobile app": "Најавувањето е достапно само на мобилната апликација",
      "Sports": "Спорт",
      "Education": "Образование",
      "News": "Вести",
      "Music": "Музика"
    }
  },
  "ne": {
    "translation": {
      "Film": "चलचित्र",
      "Pets": "घरपालुवा जनावर",
      "Entertainment": "मनोरञ्जन",
      "Gaming": "गेमिङ",
      "Comedy": "कमेडी",
      "Education": "शिक्षा",
      "Style": "शैली",
      "People": "मानिसहरू",
      "Autos": "कारहरू",
      "Sports": "खेलकुद",
      "News": "समाचार",
      "Signing in is available only on the mobile app": "साइन इन मोबाइल एपमा मात्र उपलब्ध छ",
      "Sign in with your Google account to view the latest on your channel subscriptions": "आफ्नो च्यानल सदस्यता मा नवीनतम हेर्न आफ्नो Google खाता संग साइन इन गर्नुहोस्",
      "You have no channel subscriptions": "तपाईंसँग कुनै च्यानल सदस्यता छैन",
      "Music": "संगीत",
      "Trending": "ट्रेन्डिङ",
      "Science&Tech": "विज्ञान र प्रविधि"
    }
  },
  "vi": {
    "translation": {
      "Entertainment": "Giải trí",
      "Style": "Phong cách",
      "Pets": "Vật nuôi",
      "You have no channel subscriptions": "Bạn không có đăng ký kênh nào",
      "Education": "Giáo dục",
      "Sports": "Các môn thể thao",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Đăng nhập bằng tài khoản Google của bạn để xem thông tin mới nhất về đăng ký kênh của bạn",
      "People": "Mọi người",
      "Autos": "ô tô",
      "Film": "Phim ảnh",
      "News": "Tin tức",
      "Gaming": "Chơi game",
      "Science&Tech": "Khoa học & Công nghệ",
      "Signing in is available only on the mobile app": "Đăng nhập chỉ khả dụng trên ứng dụng dành cho thiết bị di động",
      "Trending": "Xu hướng",
      "Music": "Âm nhạc",
      "Comedy": "Hài kịch"
    }
  },
  "hy": {
    "translation": {
      "People": "Ժողովուրդ",
      "You have no channel subscriptions": "Դուք չունեք ալիքի բաժանորդագրություններ",
      "Signing in is available only on the mobile app": "Մուտք գործելը հասանելի է միայն բջջային հավելվածում",
      "Autos": "մեքենաներ",
      "Gaming": "Մոլեխաղեր",
      "Education": "Կրթություն",
      "Entertainment": "Ժամանց",
      "Trending": "Թրենդային",
      "Film": "Ֆիլմ",
      "News": "Նորություններ",
      "Sign in with your Google account to view the latest on your channel subscriptions": "Մուտք գործեք ձեր Google հաշիվ՝ ձեր ալիքի բաժանորդագրությունների վերջին նորությունները դիտելու համար",
      "Sports": "Սպորտաձեւեր",
      "Comedy": "Կատակերգություն",
      "Style": "Ոճ",
      "Pets": "Կենդանիներ",
      "Science&Tech": "Գիտություն և տեխնիկա",
      "Music": "Երաժշտություն"
    }
  },
  "haw": {
    "translation": {
      "Film": "Kiʻiʻoniʻoni",
      "You have no channel subscriptions": "ʻAʻohe ou kau inoa inoa",
      "Pets": "Pets",
      "Signing in is available only on the mobile app": "Loaʻa ka ʻeʻe ʻana ma ka polokalamu kelepona wale nō",
      "Trending": "Kaulana",
      "Entertainment": "Lealea",
      "Music": "Mele",
      "Sports": "Haʻuki",
      "People": "Kanaka",
      "News": "Nūhou",
      "Gaming": "Pāʻani pāʻani",
      "Style": "Kaila",
      "Comedy": "Komedy",
      "Education": "Hoʻonaʻauao",
      "Autos": "nā kaʻa",
      "Science&Tech": "ʻEpekema&ʻenehana",
      "Sign in with your Google account to view the latest on your channel subscriptions": "E ʻeʻe me kāu moʻokāki Google no ka ʻike ʻana i nā mea hou loa ma kāu kau inoa channel"
    }
  }
}
const lang18 = {
  "en": {
    "translation": {
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model",
      "Account deleted": "Account deleted",
      "My Account": "My Account",
      "Are you sure you want to delete your account? This action is irreversible!": "Are you sure you want to delete your account? This action is irreversible!",
      "Delete": "Delete",
      "Delete Account": "Delete Account"
    }
  },
  "hu": {
    "translation": {
      "My Account": "Az én fiókom",
      "Delete": "Töröl",
      "Account deleted": "Fiók törölve",
      "Are you sure you want to delete your account? This action is irreversible!": "Biztos benne, hogy törölni akarja fiókját? Ez a művelet visszafordíthatatlan!",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Az alkalmazás magas fenntartási költségei miatt már nem jelenítünk meg hirdetéseket, hanem előfizetéses modellre váltunk.",
      "Delete Account": "Fiók törlése"
    }
  },
  "fr": {
    "translation": {
      "My Account": "Mon compte",
      "Are you sure you want to delete your account? This action is irreversible!": "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible !",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "En raison des coûts élevés de maintenance de cette application, nous ne diffusons plus d'annonces mais sommes passés à un modèle basé sur un abonnement",
      "Account deleted": "Compte supprimé",
      "Delete": "Effacer",
      "Delete Account": "Supprimer le compte"
    }
  },
  "lt": {
    "translation": {
      "My Account": "Mano sąskaita",
      "Account deleted": "Paskyra ištrinta",
      "Are you sure you want to delete your account? This action is irreversible!": "Ar tikrai norite ištrinti savo paskyrą? Šis veiksmas yra negrįžtamas!",
      "Delete": "Ištrinti",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Dėl didelių šios programos priežiūros išlaidų nebeteikiame skelbimų, bet perėjome prie prenumerata pagrįsto modelio",
      "Delete Account": "Ištrinti paskyrą"
    }
  },
  "ko": {
    "translation": {
      "My Account": "내 계정",
      "Are you sure you want to delete your account? This action is irreversible!": "계정을 삭제하시겠습니까? 이 행동은 돌이킬 수 없습니다!",
      "Delete": "삭제",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "이 앱을 유지하는 데 드는 높은 비용으로 인해 더 이상 광고를 게재하지 않고 구독 기반 모델로 전환했습니다.",
      "Delete Account": "계정 삭제",
      "Account deleted": "계정 삭제됨"
    }
  },
  "ca": {
    "translation": {
      "Are you sure you want to delete your account? This action is irreversible!": "Esteu segur que voleu suprimir el vostre compte? Aquesta acció és irreversible!",
      "Delete": "Suprimeix",
      "My Account": "El meu compte",
      "Account deleted": "Compte suprimit",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "A causa dels alts costos de manteniment d'aquesta aplicació, ja no publiquem anuncis, sinó que hem canviat a un model basat en subscripció",
      "Delete Account": "Esborrar compte"
    }
  },
  "et": {
    "translation": {
      "Are you sure you want to delete your account? This action is irreversible!": "Kas olete kindel, et soovite oma konto kustutada? See toiming on pöördumatu!",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Selle rakenduse kõrgete ülalpidamiskulude tõttu ei esita me enam reklaame, vaid läksime üle tellimusepõhisele mudelile",
      "Delete Account": "Kustuta konto",
      "My Account": "Minu konto",
      "Account deleted": "Konto kustutatud",
      "Delete": "Kustuta"
    }
  },
  "lv": {
    "translation": {
      "Are you sure you want to delete your account? This action is irreversible!": "Vai tiešām vēlaties dzēst savu kontu? Šī darbība ir neatgriezeniska!",
      "My Account": "Mans Konts",
      "Delete Account": "Dzēst kontu",
      "Delete": "Dzēst",
      "Account deleted": "Konts dzēsts",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Šīs lietotnes uzturēšanas augsto izmaksu dēļ reklāmas vairs netiek rādītas, bet tiek pāriets uz modeli, kura pamatā ir abonements."
    }
  },
  "hr": {
    "translation": {
      "My Account": "Moj račun",
      "Delete": "Izbrisati",
      "Account deleted": "Račun izbrisan",
      "Are you sure you want to delete your account? This action is irreversible!": "Jeste li sigurni da želite izbrisati svoj račun? Ova radnja je nepovratna!",
      "Delete Account": "Izbriši račun",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Zbog visokih troškova održavanja ove aplikacije, više ne poslužujemo oglase već smo prešli na model temeljen na pretplati"
    }
  },
  "af": {
    "translation": {
      "Account deleted": "Rekening is uitgevee",
      "Delete Account": "Verwyder rekening",
      "Delete": "Vee uit",
      "Are you sure you want to delete your account? This action is irreversible!": "Is jy seker jy wil jou rekening uitvee? Hierdie aksie is onomkeerbaar!",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "As gevolg van die hoë koste van die instandhouding van hierdie toepassing, dien ons nie meer advertensies nie, maar het oorgeskakel na 'n intekeninggebaseerde model",
      "My Account": "My rekening"
    }
  },
  "co": {
    "translation": {
      "Delete Account": "Sguassà u contu",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "A causa di l'alti costi di mantenimentu di sta app, ùn servemu più annunzii, ma avemu cambiatu à un mudellu basatu in abbonamentu.",
      "Delete": "Sguassà",
      "Are you sure you want to delete your account? This action is irreversible!": "Sò sicuru chì vulete sguassà u vostru contu ? Questa azione hè irreversibile!",
      "Account deleted": "U contu sguassatu",
      "My Account": "U mo contu"
    }
  },
  "sv": {
    "translation": {
      "Delete Account": "Radera konto",
      "My Account": "Mitt konto",
      "Account deleted": "Kontot raderat",
      "Are you sure you want to delete your account? This action is irreversible!": "Är du säker på att du vill ta bort ditt konto? Denna åtgärd är oåterkallelig!",
      "Delete": "Radera",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "På grund av höga kostnader för att underhålla denna app, visar vi inte längre annonser utan bytte till en prenumerationsbaserad modell"
    }
  },
  "hy": {
    "translation": {
      "My Account": "Իմ հաշիվը",
      "Delete Account": "Հաշիվը ջնջել",
      "Delete": "Ջնջել",
      "Account deleted": "Հաշիվը ջնջված է",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Այս հավելվածի պահպանման բարձր ծախսերի պատճառով մենք այլևս գովազդ չենք ցուցադրում, այլ անցնում ենք բաժանորդագրության վրա հիմնված մոդելի",
      "Are you sure you want to delete your account? This action is irreversible!": "Իսկապե՞ս ուզում եք ջնջել ձեր հաշիվը: Այս գործողությունն անշրջելի է:"
    }
  },
  "sr": {
    "translation": {
      "Delete": "Избриши",
      "Are you sure you want to delete your account? This action is irreversible!": "Да ли сте сигурни да желите да избришете свој налог? Ова акција је неповратна!",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Због високих трошкова одржавања ове апликације, више не приказујемо огласе, већ смо прешли на модел заснован на претплати",
      "Delete Account": "Обрисати налог",
      "Account deleted": "Налог је избрисан",
      "My Account": "Мој налог"
    }
  },
  "mk": {
    "translation": {
      "Account deleted": "Сметката е избришана",
      "Are you sure you want to delete your account? This action is irreversible!": "Дали сте сигурни дека сакате да ја избришете вашата сметка? Оваа акција е неповратна!",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Поради високите трошоци за одржување на оваа апликација, повеќе не прикажуваме реклами, туку се префрливме на модел базиран на претплата",
      "My Account": "Мојот акаунт",
      "Delete Account": "Избриши сметка",
      "Delete": "Избриши"
    }
  },
  "ru": {
    "translation": {
      "Delete Account": "Удалить аккаунт",
      "Delete": "Удалить",
      "My Account": "Мой счет",
      "Account deleted": "Аккаунт удален",
      "Are you sure you want to delete your account? This action is irreversible!": "Вы уверены, что хотите удалить свой аккаунт? Это действие необратимо!",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Из-за высоких затрат на обслуживание этого приложения мы больше не размещаем рекламу, а перешли на модель на основе подписки."
    }
  },
  "bg": {
    "translation": {
      "Are you sure you want to delete your account? This action is irreversible!": "Сигурни ли сте, че искате да изтриете акаунта си? Това действие е необратимо!",
      "Delete": "Изтрий",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Поради високите разходи за поддръжка на това приложение, вече не показваме реклами, а преминахме към модел, базиран на абонамент",
      "My Account": "Моята сметка",
      "Account deleted": "Акаунтът е изтрит",
      "Delete Account": "Изтриване на акаунт"
    }
  },
  "ga": {
    "translation": {
      "Delete": "Scrios",
      "Are you sure you want to delete your account? This action is irreversible!": "An bhfuil tú cinnte gur mhaith leat do chuntas a scriosadh? Tá an gníomh seo do-aisiompaithe!",
      "My Account": "Mo chuntas",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Mar gheall ar na costais arda a bhaineann leis an aip seo a chothabháil, nílimid ag freastal ar fhógraí a thuilleadh ach d’athraigh muid go múnla bunaithe ar shíntiús",
      "Account deleted": "Scriosadh an cuntas",
      "Delete Account": "Scrios Cuntas"
    }
  },
  "el": {
    "translation": {
      "Account deleted": "Ο λογαριασμός διαγράφηκε",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Λόγω του υψηλού κόστους συντήρησης αυτής της εφαρμογής, δεν προβάλλουμε πλέον διαφημίσεις, αλλά μεταβήκαμε σε ένα μοντέλο που βασίζεται σε συνδρομή",
      "Are you sure you want to delete your account? This action is irreversible!": "Είστε βέβαιοι ότι θέλετε να διαγράψετε τον λογαριασμό σας; Αυτή η ενέργεια είναι μη αναστρέψιμη!",
      "My Account": "Ο λογαριασμός μου",
      "Delete Account": "Διαγραφή λογαριασμού",
      "Delete": "Διαγράφω"
    }
  },
  "am": {
    "translation": {
      "Delete": "ሰርዝ",
      "Delete Account": "መለያ ሰርዝ",
      "Are you sure you want to delete your account? This action is irreversible!": "እርግጠኛ ነህ መለያህን መሰረዝ ትፈልጋለህ? ይህ እርምጃ የማይቀለበስ ነው!",
      "Account deleted": "መለያ ተሰርዟል።",
      "My Account": "አካውንቴ",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "ይህን መተግበሪያ ለማቆየት በሚያስፈልገን ከፍተኛ ወጪ ምክንያት ማስታወቂያዎችን እያገለገልን አይደለም ነገር ግን ወደ የደንበኝነት ምዝገባ ሞዴል ቀይረናል"
    }
  },
  "uk": {
    "translation": {
      "My Account": "Мій рахунок",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Через високі витрати на підтримку цієї програми ми більше не розміщуємо рекламу, але перейшли на модель на основі підписки",
      "Are you sure you want to delete your account? This action is irreversible!": "Ви впевнені, що хочете видалити свій обліковий запис? Ця дія незворотна!",
      "Account deleted": "Обліковий запис видалено",
      "Delete": "Видалити",
      "Delete Account": "Видалити аккаунт"
    }
  },
  "ne": {
    "translation": {
      "Account deleted": "खाता मेटाइयो",
      "My Account": "मेरो खाता",
      "Delete Account": "खाता मेटाउनुहोस्",
      "Delete": "मेटाउन",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "यस एपलाई कायम राख्नको लागि उच्च लागतको कारणले गर्दा, हामी अब विज्ञापनहरू प्रदान गर्दैनौं तर सदस्यतामा आधारित मोडेलमा स्विच गरेका छौं।",
      "Are you sure you want to delete your account? This action is irreversible!": "के तपाइँ तपाइँको खाता मेटाउन निश्चित हुनुहुन्छ? यो कार्य अपरिवर्तनीय छ!"
    }
  },
  "sm": {
    "translation": {
      "My Account": "La'u Tala",
      "Account deleted": "Ua tapeina tala",
      "Delete Account": "Ave'ese Fa'amatalaga",
      "Are you sure you want to delete your account? This action is irreversible!": "E te mautinoa e te mana'o e tape lau tala? O lenei gaioiga e le mafai ona toe suia!",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Ona o le maualuga o tau o le tausia o lenei app, matou te le o toe tuʻuina atu faʻasalalauga ae sui i se faʻataʻitaʻiga faʻavae",
      "Delete": "Aveese"
    }
  },
  "cs": {
    "translation": {
      "Delete": "Vymazat",
      "Delete Account": "Smazat účet",
      "Are you sure you want to delete your account? This action is irreversible!": "Opravdu chcete smazat svůj účet? Tato akce je nevratná!",
      "Account deleted": "Účet smazán",
      "My Account": "Můj účet",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Kvůli vysokým nákladům na údržbu této aplikace již nezobrazujeme reklamy, ale přecházíme na model založený na předplatném"
    }
  },
  "vi": {
    "translation": {
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Do chi phí duy trì ứng dụng này cao, chúng tôi không còn phân phát quảng cáo nữa mà chuyển sang mô hình dựa trên đăng ký",
      "Delete Account": "Xóa tài khoản",
      "Delete": "Xóa bỏ",
      "My Account": "Tài khoản của tôi",
      "Account deleted": "Tài khoản đã bị xóa",
      "Are you sure you want to delete your account? This action is irreversible!": "Bạn có chắc rằng bạn muốn xóa tài khoản của bạn? Hành động này là không thể đảo ngược!"
    }
  },
  "ar": {
    "translation": {
      "Delete": "حذف",
      "Account deleted": "تم حذف الحساب",
      "Delete Account": "حذف الحساب",
      "Are you sure you want to delete your account? This action is irreversible!": "هل انت متأكد انك تريد حذف حسابك؟ هذا العمل لا رجوع فيه!",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "نظرًا لارتفاع تكاليف صيانة هذا التطبيق ، لم نعد نعرض الإعلانات ولكننا تحولنا إلى نموذج قائم على الاشتراك",
      "My Account": "حسابي"
    }
  },
  "fi": {
    "translation": {
      "Delete Account": "Poista tili",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Tämän sovelluksen korkeiden ylläpitokustannusten vuoksi emme enää näytä mainoksia, vaan olemme siirtyneet tilauspohjaiseen malliin",
      "Account deleted": "Tili poistettu",
      "Are you sure you want to delete your account? This action is irreversible!": "Haluatko varmasti poistaa tilisi? Tämä toimenpide on peruuttamaton!",
      "Delete": "Poistaa",
      "My Account": "Tilini"
    }
  },
  "it": {
    "translation": {
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "A causa degli elevati costi di manutenzione di questa app, non pubblichiamo più annunci ma siamo passati a un modello basato su abbonamento",
      "Delete": "Elimina",
      "Are you sure you want to delete your account? This action is irreversible!": "Sei sicuro di voler eliminare il tuo account? Questa azione è irreversibile!",
      "Account deleted": "Account cancellato",
      "Delete Account": "Eliminare l'account",
      "My Account": "Il mio account"
    }
  },
  "sl": {
    "translation": {
      "Are you sure you want to delete your account? This action is irreversible!": "Ali ste prepričani, da želite izbrisati svoj račun? To dejanje je nepovratno!",
      "Account deleted": "Račun izbrisan",
      "Delete Account": "Izbriši račun",
      "My Account": "Moj račun",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Zaradi visokih stroškov vzdrževanja te aplikacije ne prikazujemo več oglasov, temveč smo prešli na naročniški model",
      "Delete": "Izbriši"
    }
  },
  "mg": {
    "translation": {
      "Delete Account": "Fafao ny kaonty",
      "My Account": "Ny kaontiko",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Noho ny vola lany amin'ny fikojakojana ity fampiharana ity dia tsy manolotra doka intsony izahay fa novaina ho modely mifototra amin'ny famandrihana",
      "Delete": "Mamafa",
      "Are you sure you want to delete your account? This action is irreversible!": "Tena te-hamafa ny kaontinao tokoa ve ianao? Ity hetsika ity dia tsy azo ovaina!",
      "Account deleted": "Kaonty voafafa"
    }
  },
  "sq": {
    "translation": {
      "Account deleted": "Llogaria u fshi",
      "Delete Account": "Fshij llogarine",
      "Are you sure you want to delete your account? This action is irreversible!": "Jeni i sigurt që dëshironi të fshini llogarinë tuaj? Ky veprim është i pakthyeshëm!",
      "Delete": "Fshije",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Për shkak të kostove të larta të mirëmbajtjes së këtij aplikacioni, ne nuk po ofrojmë më reklama, por kalojmë në një model të bazuar në abonim",
      "My Account": "Llogaria ime"
    }
  },
  "id": {
    "translation": {
      "Are you sure you want to delete your account? This action is irreversible!": "Apakah Anda yakin ingin menghapus akun Anda? Tindakan ini tidak dapat diubah!",
      "Delete Account": "Hapus akun",
      "My Account": "Akun saya",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Karena tingginya biaya pemeliharaan aplikasi ini, kami tidak lagi menayangkan iklan tetapi beralih ke model berbasis langganan",
      "Account deleted": "Akun dihapus",
      "Delete": "Menghapus"
    }
  },
  "pl": {
    "translation": {
      "Are you sure you want to delete your account? This action is irreversible!": "Czy na pewno chcesz usunąć swoje konto? Ta czynność jest nieodwracalna!",
      "Delete Account": "Usuń konto",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Ze względu na wysokie koszty utrzymania tej aplikacji nie wyświetlamy już reklam, ale przeszliśmy na model abonamentowy",
      "Account deleted": "Konto usunięte",
      "Delete": "Usuwać",
      "My Account": "Moje konto"
    }
  },
  "mt": {
    "translation": {
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Minħabba l-ispejjeż għoljin taż-żamma ta’ din l-app, m’għadniex inservu reklami iżda qlibna għal mudell ibbażat fuq abbonament",
      "My Account": "Il-Kont Tiegħi",
      "Delete Account": "Ħassar Kont",
      "Are you sure you want to delete your account? This action is irreversible!": "Int żgur li trid tħassar il-kont tiegħek? Din l-azzjoni hija irriversibbli!",
      "Delete": "Ħassar",
      "Account deleted": "Kont imħassar"
    }
  },
  "pt": {
    "translation": {
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Devido aos altos custos de manutenção deste aplicativo, não estamos mais veiculando anúncios, mas mudamos para um modelo baseado em assinatura",
      "Account deleted": "Conta deletada",
      "Delete": "Excluir",
      "My Account": "Minha conta",
      "Are you sure you want to delete your account? This action is irreversible!": "Tem certeza de que deseja excluir sua conta? Esta ação é irreversível!",
      "Delete Account": "Deletar conta"
    }
  },
  "iw": {
    "translation": {
      "Account deleted": "החשבון נמחק",
      "Are you sure you want to delete your account? This action is irreversible!": "האם אתה בטוח שברצונך למחוק את חשבונך? פעולה זו היא בלתי הפיכה!",
      "My Account": "החשבון שלי",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "עקב עלויות גבוהות של תחזוקת אפליקציה זו, איננו מציגים עוד מודעות אלא עברנו למודל מבוסס מנוי",
      "Delete Account": "מחק חשבון",
      "Delete": "לִמְחוֹק"
    }
  },
  "ml": {
    "translation": {
      "Delete Account": "അക്കൗണ്ട് ഇല്ലാതാക്കുക",
      "My Account": "എന്റെ അക്കൗണ്ട്",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "ഈ ആപ്പ് പരിപാലിക്കുന്നതിനുള്ള ഉയർന്ന ചിലവ് കാരണം, ഞങ്ങൾ പരസ്യങ്ങൾ നൽകുന്നില്ല, സബ്‌സ്‌ക്രിപ്‌ഷൻ അടിസ്ഥാനമാക്കിയുള്ള മോഡലിലേക്ക് മാറി",
      "Account deleted": "അക്കൗണ്ട് ഇല്ലാതാക്കി",
      "Delete": "ഇല്ലാതാക്കുക",
      "Are you sure you want to delete your account? This action is irreversible!": "നിങ്ങളുടെ അക്കൗണ്ട് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ? ഈ പ്രവർത്തനം മാറ്റാനാവാത്തതാണ്!"
    }
  },
  "is": {
    "translation": {
      "Delete Account": "Eyða reikningi",
      "My Account": "Minn reikningur",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Vegna mikils kostnaðar við að viðhalda þessu forriti erum við ekki lengur að birta auglýsingar heldur skiptum yfir í áskriftargerð",
      "Delete": "Eyða",
      "Account deleted": "Reikningi eytt",
      "Are you sure you want to delete your account? This action is irreversible!": "Ertu viss um að þú viljir eyða reikningnum þínum? Þessi aðgerð er óafturkræf!"
    }
  },
  "no": {
    "translation": {
      "Delete Account": "Slett konto",
      "Delete": "Slett",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "På grunn av høye kostnader ved å vedlikeholde denne appen, viser vi ikke lenger annonser, men byttet til en abonnementsbasert modell",
      "Are you sure you want to delete your account? This action is irreversible!": "Er du sikker på at du vil slette kontoen din? Denne handlingen er irreversibel!",
      "My Account": "Min konto",
      "Account deleted": "Konto slettet"
    }
  },
  "tr": {
    "translation": {
      "My Account": "Hesabım",
      "Delete": "Silmek",
      "Account deleted": "Hesap silindi",
      "Delete Account": "Hesabı sil",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Bu uygulamayı sürdürmenin yüksek maliyetleri nedeniyle, artık reklam sunmuyoruz, aboneliğe dayalı bir modele geçtik",
      "Are you sure you want to delete your account? This action is irreversible!": "Hesabınızı silmek istediğinizden emin misiniz? Bu eylem geri alınamaz!"
    }
  },
  "haw": {
    "translation": {
      "Delete": "Holoi",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Ma muli o nā kumukūʻai kiʻekiʻe o ka mālama ʻana i kēia app, ʻaʻole mākou e lawelawe hou ana i nā hoʻolaha akā ua hoʻololi ʻia i kahi kumu hoʻohālikelike",
      "My Account": "Kaʻu moʻokāki",
      "Delete Account": "Holoi moʻokāki",
      "Are you sure you want to delete your account? This action is irreversible!": "Makemake ʻoe e holoi i kāu moʻokāki? ʻAʻole hiki ke hoʻohuli ʻia kēia hana!",
      "Account deleted": "Holoi ʻia ka moʻokāki"
    }
  },
  "hi": {
    "translation": {
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "इस ऐप को बनाए रखने की उच्च लागत के कारण, हम अब विज्ञापन नहीं दिखा रहे हैं बल्कि सदस्यता आधारित मॉडल पर स्विच कर रहे हैं",
      "Delete": "मिटाना",
      "Are you sure you want to delete your account? This action is irreversible!": "क्या आप इस खाते को हटाने के लिए सुनिश्चित हैं? यह क्रिया अपरिवर्तनीय है!",
      "My Account": "मेरा खाता",
      "Delete Account": "खाता हटा दो",
      "Account deleted": "खाता हटा दिया गया"
    }
  },
  "da": {
    "translation": {
      "Are you sure you want to delete your account? This action is irreversible!": "Er du sikker på, at du vil slette din konto? Denne handling er irreversibel!",
      "Delete Account": "Slet konto",
      "My Account": "Min konto",
      "Delete": "Slet",
      "Account deleted": "Konto slettet",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "På grund af høje omkostninger ved at vedligeholde denne app, viser vi ikke længere annoncer, men skiftede til en abonnementsbaseret model"
    }
  },
  "sk": {
    "translation": {
      "My Account": "Môj účet",
      "Account deleted": "Účet bol odstránený",
      "Delete Account": "Zmazať účet",
      "Are you sure you want to delete your account? This action is irreversible!": "Naozaj chcete odstrániť svoj účet? Táto akcia je nezvratná!",
      "Delete": "Odstrániť",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Kvôli vysokým nákladom na údržbu tejto aplikácie už nezobrazujeme reklamy, ale prejdeme na model založený na predplatnom"
    }
  },
  "de": {
    "translation": {
      "Delete": "Löschen",
      "Account deleted": "Konto gelöscht",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Aufgrund der hohen Kosten für die Wartung dieser App schalten wir keine Anzeigen mehr, sondern wechseln zu einem abonnementbasierten Modell",
      "Delete Account": "Konto löschen",
      "My Account": "Mein Konto",
      "Are you sure you want to delete your account? This action is irreversible!": "Möchten Sie Ihr Konto wirklich löschen? Diese Aktion ist irreversibel!"
    }
  },
  "ja": {
    "translation": {
      "Account deleted": "アカウントが削除されました",
      "Delete Account": "アカウントを削除する",
      "My Account": "マイアカウント",
      "Are you sure you want to delete your account? This action is irreversible!": "アカウントを削除してもよろしいですか？このアクションは元に戻せません。",
      "Delete": "消去",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "このアプリの維持費が高いため、広告の配信を停止し、サブスクリプション ベースのモデルに切り替えました"
    }
  },
  "zh": {
    "translation": {
      "My Account": "我的帐户",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "由于维护此应用程序的成本很高，我们不再投放广告，而是切换到基于订阅的模式",
      "Account deleted": "帐户已删除",
      "Are you sure you want to delete your account? This action is irreversible!": "您确定要删除您的帐户吗？这个动作是不可逆的！",
      "Delete Account": "删除帐户",
      "Delete": "删除"
    }
  },
  "bs": {
    "translation": {
      "My Account": "Moj račun",
      "Are you sure you want to delete your account? This action is irreversible!": "Jeste li sigurni da želite izbrisati svoj račun? Ova akcija je nepovratna!",
      "Delete": "Izbriši",
      "Account deleted": "Račun je izbrisan",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Zbog visokih troškova održavanja ove aplikacije, više ne prikazujemo oglase već smo prešli na model zasnovan na pretplati",
      "Delete Account": "Obriši račun"
    }
  },
  "nl": {
    "translation": {
      "Account deleted": "Account verwijderd",
      "Delete": "Verwijderen",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Vanwege de hoge onderhoudskosten van deze app geven we geen advertenties meer weer, maar zijn we overgestapt op een abonnementsmodel",
      "My Account": "Mijn rekening",
      "Are you sure you want to delete your account? This action is irreversible!": "Weet je zeker dat je je account wilt verwijderen? Deze actie is onomkeerbaar!",
      "Delete Account": "Account verwijderen"
    }
  },
  "ro": {
    "translation": {
      "Delete Account": "Șterge cont",
      "Are you sure you want to delete your account? This action is irreversible!": "Sigur doriți să vă ștergeți contul? Această acțiune este ireversibilă!",
      "Account deleted": "Contul a fost șters",
      "My Account": "Contul meu",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Din cauza costurilor ridicate de întreținere a acestei aplicații, nu mai difuzăm reclame, ci am trecut la un model bazat pe abonament.",
      "Delete": "Șterge"
    }
  },
  "es": {
    "translation": {
      "Delete": "Borrar",
      "Due to high costs of maintaining this app, we are no longer serving ads but switched to a subscription based model": "Debido a los altos costos de mantenimiento de esta aplicación, ya no publicamos anuncios sino que cambiamos a un modelo basado en suscripción.",
      "Are you sure you want to delete your account? This action is irreversible!": "¿Está seguro de que desea eliminar su cuenta? ¡Esta acción es irreversible!",
      "Account deleted": "Cuenta borrada",
      "My Account": "Mi cuenta",
      "Delete Account": "Borrar cuenta"
    }
  }
}
const langs = [lang1, lang2, lang3, lang4, lang5, lang6, lang7, lang8, lang9, lang10, lang11, lang12, lang13, lang16, lang17, lang18]
let allLang: any = {};
//merge lang1 and lang2 to allLang
/* langs.forEach((lang: any) => {
    Object.keys(lang).forEach(key => {
        allLang[key] = lang[key];
    })
}) */
for (let code in lang1) {
  if (!allLang[code]) {
    allLang[code] = { translation: {} };
  }

  langs.forEach((lang: any) => {

    allLang[code].translation = { ...allLang[code]?.translation, ...lang[code]?.translation }
  })
}
//console.log("locales", getLocales())
//navigationService.props.lang = getLocales().filter(l => l.languageCode.indexOf("en") === -1)[0]?.languageCode || "en"
let lang = ""
const nativeLang = getLocales().filter(l => l.languageCode.indexOf("en") === -1)[0]?.languageCode
const exists = env.languages.find(l => l.symbol === nativeLang);
if (nativeLang?.indexOf("en") === -1 && exists && !lang) {
  lang = nativeLang;
}
if (!lang) {
  lang = "en";
}
navigationService.props.lang = lang;
//navigationService.props.lang = 'en';
//console.log("navigationService.props.lang", navigationService.props.lang)
const dictionary = allLang
i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources: dictionary,
    lng: navigationService.props.lang,
    fallbackLng: "en",
  });
/* I18n.setLanguage(getLocales().filter(l => l.languageCode.indexOf("en") === -1)[0]?.languageCode || "en");
I18n.putVocabularies(dictionary); */
export default i18n;
