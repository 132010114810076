import { NavigationContainer } from "@react-navigation/native";
import React from "react";
import navigationService from "../services/navigation-service";
import HomeScreen from './../screens/home';
import PrivacyScreen from './../screens/privacy';
import SubscriptionsScreen from './../screens/subscriptions';
import TermsScreen from './../screens/terms';
import HistoryClipsScreen from './../screens/history-clips';
import LoginScreen from './../screens/login';
import MyAccountScreen from './../screens/my-account';

import Header from './../components/header';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Auth, Hub } from "aws-amplify";
import { AppContext, IContext } from "../redux/store/app-provider";
import NavigationService from "../services/navigation-service";
import VideoListScreen from "../screens/video-list";
import ChannelListScreen from "../screens/channel-video-list";

import GeoScreen from "../screens/geo";
import { Platform } from "react-native";

interface State {

}

interface Props {
  PlayerView: any
  FastClipsScreen: any
}

const link: LinkingOptions<any> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {

      Home: {

        path: '/',
      },
      Privacy: {
        path: 'privacy',
      },
      Videos: {
        path: 'videos',
      },
      Terms: {

        path: 'terms',
      },
      Channel: {
        path: 'channel',
      },
      Fast: {
        path: 'fast',
      },
      Geo: {
        path: 'geo',
      },
      History: {
        path: 'history',
      },
      Subscriptions: {
        path: 'subscriptions',
      },
      MyAccount: {
        path: 'account',
      },
      'Sign In': {
        path: 'login',
      },
      MobileVideo: {
        path: 'video',
      },
      Related: {
        path: 'related',
      }
    },

  },
}

class AppNavigator extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}
    this.getUserCredentials();
    Hub.listen('auth', ({ payload: { event, data } }) => {

      switch (event) {
        case 'signIn':
          //console.log('auth state signIn', event);
          this.getUserCredentials();
          break;
        case 'signOut':

          break;
        case 'customOAuthState':

      }
    });
  }

  async getUserCredentials() {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      this.context.setUser(user);
      //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(checkUser(user))
      console.log("Cognito has a user")
    } catch (e) {
      console.log("Cognito has no user")
      //(store.dispatch as ThunkDispatch<User, void, ActionTypes>)(checkUser({ attributes: { "email": "yonodo10@gmail.com", "sub": "920a4dd7-6212-48a8-b873-b7f1a72a2901" } }))

    }

  }

  navigationStateChange = (state: any) => {
    const screen = state?.routes?.[state.routes?.length - 1]?.name;
    const params = state?.routes?.[state.routes?.length - 1]?.params;
    this.context.setScreen(screen, params, true);
    console.log("navigationStateChange", screen, params);
    //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen(screen, params, true));

  }
  readyNav = () => {

    this.navigationStateChange(navigationService.getNavigation().getState())

  }

  render() {

    return (

      <NavigationContainer linking={link} onReady={this.readyNav.bind(this)} onStateChange={this.navigationStateChange.bind(this)} ref={navigatorRef => {
        NavigationService.setTopLevelNavigator(navigatorRef);
      }}>
        <Stack.Navigator screenOptions={{
          animation: 'simple_push',
          gestureEnabled: true,
          animationTypeForReplace: 'push'

        }}>
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{ headerTitleAlign: 'center', title: "Trending", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Privacy"
            component={PrivacyScreen}
            options={{ headerTitleAlign: 'center', title: "Privacy", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Videos"
            component={VideoListScreen}
            options={{ headerTitleAlign: 'center', title: "Search", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="History"
            component={HistoryClipsScreen}
            options={{ headerTitleAlign: 'center', title: "History", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Subscriptions"
            component={SubscriptionsScreen}
            options={{ headerTitleAlign: 'center', title: "Subscriptions", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Channel"
            component={ChannelListScreen}
            options={{ headerTitleAlign: 'center', title: "Channel", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Sign In"
            component={LoginScreen}
            options={{ headerTitleAlign: 'center', title: "Sign In", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Terms"
            component={TermsScreen}
            options={{
              headerTitleAlign: 'center',
              title: "Terms and Conditions",
              header: (props) => <Header {...props} />
            }}
          />
          <Stack.Screen
            name="Geo"
            component={GeoScreen}
            options={{ headerTitleAlign: 'center', title: "YouVi", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyAccount"
            component={MyAccountScreen}
            options={{ headerTitleAlign: 'center', title: "My Account", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MobileVideo"
            component={this.props.PlayerView}
            options={{ headerTitleAlign: 'center', title: "Video", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Fast"
            component={this.props.FastClipsScreen}
            options={{ headerTitleAlign: 'center', title: "Fast", header: (props) => <Header {...props} /> }}
          />
        </Stack.Navigator>

      </NavigationContainer>
    )
  }
}

export default AppNavigator;
