/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      picture
      channels
      email
      firstName
      lastName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        picture
        channels
        email
        firstName
        lastName
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserVideoTime = /* GraphQL */ `
  query GetUserVideoTime($id: ID!) {
    getUserVideoTime(id: $id) {
      id
      videoId
      userEmail
      videoDuration
      currentTime
      updated
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserVideoTimes = /* GraphQL */ `
  query ListUserVideoTimes(
    $filter: ModelUserVideoTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserVideoTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoId
        userEmail
        videoDuration
        currentTime
        updated
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getHistoryByTime = /* GraphQL */ `
  query GetHistoryByTime(
    $userEmail: String!
    $updated: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserVideoTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHistoryByTime(
      userEmail: $userEmail
      updated: $updated
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoId
        userEmail
        videoDuration
        currentTime
        updated
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getShareClip = /* GraphQL */ `
  query GetShareClip($id: ID!) {
    getShareClip(id: $id) {
      id
      videoId
      seenByIps
      createdAt
      updatedAt
    }
  }
`;
export const listShareClips = /* GraphQL */ `
  query ListShareClips(
    $filter: ModelShareClipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareClips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoId
        seenByIps
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFastClips = /* GraphQL */ `
  query GetFastClips($id: ID!) {
    getFastClips(id: $id) {
      id
      videoUrl
      type
      previewUrl
      description
      commentCount
      playCount
      shareCount
      addedTime
      timeRef
      creationTime
      createdAt
      updatedAt
    }
  }
`;
export const listFastClips = /* GraphQL */ `
  query ListFastClips(
    $filter: ModelFastClipsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFastClips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoUrl
        type
        previewUrl
        description
        commentCount
        playCount
        shareCount
        addedTime
        timeRef
        creationTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFastByType = /* GraphQL */ `
  query GetFastByType(
    $type: String!
    $addedTime: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFastClipsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFastByType(
      type: $type
      addedTime: $addedTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoUrl
        type
        previewUrl
        description
        commentCount
        playCount
        shareCount
        addedTime
        timeRef
        creationTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnsubcribedEmails = /* GraphQL */ `
  query GetUnsubcribedEmails($id: ID!) {
    getUnsubcribedEmails(id: $id) {
      id
      emails
      createdAt
      updatedAt
    }
  }
`;
export const listUnsubcribedEmails = /* GraphQL */ `
  query ListUnsubcribedEmails(
    $filter: ModelUnsubcribedEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnsubcribedEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emails
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      score
      title
      type
      numViews
      numShares
      time
      videoImage
      creatorId
      lang
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        score
        title
        type
        numViews
        numShares
        time
        videoImage
        creatorId
        lang
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPostsByTime = /* GraphQL */ `
  query GetPostsByTime(
    $type: String!
    $time: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByTime(
      type: $type
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        score
        title
        type
        numViews
        numShares
        time
        videoImage
        creatorId
        lang
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPostsByUser = /* GraphQL */ `
  query GetPostsByUser(
    $creatorId: String!
    $time: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByUser(
      creatorId: $creatorId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        score
        title
        type
        numViews
        numShares
        time
        videoImage
        creatorId
        lang
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTrendingList = /* GraphQL */ `
  query GetTrendingList($id: ID!) {
    getTrendingList(id: $id) {
      id
      videoList
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTrendingLists = /* GraphQL */ `
  query ListTrendingLists(
    $filter: ModelTrendingListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrendingLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoList
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
