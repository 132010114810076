import { Feather } from '@expo/vector-icons';
import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, ViewStyle, Image } from 'react-native';
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import colors from '../constants/colors';
import style from '../constants/style';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

interface State {

}

interface Props {
  hasStar: boolean
  language: string,
  t: any,
  onAction: any
  style: ViewStyle
}

class ButtonBar extends React.Component<Props, State> {

  actions: any[] = [{ label: this.props.t('Language'), icon: "language", action: 'language' }, {
    label: this.props.t('Related'),
    action: 'related',

    icon: "command"
  }, { label: this.props.t('Channel'), action: 'channel', icon: "archive" }, {
    label: this.props.t('Share'),
    action: 'share',
    icon: "share-2"
  }, {
    label: this.props.t('Friends'),
    action: 'share-friends',
    icon: "star"
  }]

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  action(actionLabel: string) {
    this.props.onAction(actionLabel);

  }

  componentDidMount() {

  }

  setLayout(type: any) {
    let meme = { ...this.context.state.currentMeme };
    meme.layout = type;
    this.context.setCurrentMeme(meme);
  }

  render() {

    return (
      <View style={[this.props.style, style.row, {
        borderTopWidth: 0,
        borderRadius: colors.borderRadius,
        width: '100%',
        borderWidth: 0,
        padding: 0,
        borderColor: colors.primaryColor,
        backgroundColor: 'rgba(255, 116, 46, 0)',
        justifyContent: 'space-around',
        alignItems: 'center'
      }]}>
        {this.actions.map((action: any, index: number) => {
          return action.icon !== 'star' || (action.icon === 'star' && this.props.hasStar) ? (
            <TouchableOpacity key={index} onPress={this.action.bind(this, action.action)} style={{}}>

              <View style={[{
                justifyContent: 'center',
                padding: 0,

                borderRadius: colors.borderRadius,
                alignItems: 'center',
                marginLeft: 0,
                marginRight: 0
              }]}>
                <View style={[style.row, style.hcenter, style.vcenter, {
                  backgroundColor: 'transparent',

                  width: 30,

                }]}>
                  {action.icon === 'language' ?
                    <Text style={{
                      color: colors.textColor,

                      fontSize: 16
                    }}>{this.props.language?.toUpperCase()}</Text> :
                    <Feather name={action.icon} size={18} color={action.icon !== 'star' ? colors.textColor : 'yellow'}/>}
                </View>

                <Text style={{
                  color: colors.textColor,
                  marginTop: 5,
                  fontSize: 12,
                  fontWeight: 'bold'
                }}>{action.label}</Text>
              </View>
            </TouchableOpacity>
          ) : null
        })}

      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',

  }
});
export default (withTranslation()(ButtonBar) as any);

