import { AsyncStorage, BackHandler, StatusBar, TextInput, TouchableOpacity } from 'react-native';
import { Animated, StyleSheet, Text, View, Platform, AppState, SafeAreaView } from 'react-native';
import { ToastProvider, useToast } from 'react-native-toast-notifications'
import Toast from 'react-native-toast-notifications';
import { Amplify } from 'aws-amplify';
import config from './src/aws-exports';
import "./src/services/i18n";
import React, { useEffect, useState } from 'react';
import navigationService from './src/services/navigation-service';
import style from "./src/constants/style";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import colors from "./src/constants/colors";
import i18next from "i18next";
import WebVideoScreen from "./src/screens/web-player";
import FastClipsWebScreen from './src/screens/fast-clips-web';
import ReactGA from "react-ga4";
import { AppContext, AppProvider } from "./src/redux/store/app-provider";
import AppNavigator from "./src/components/navigator";
import PrimaryButton from "./src/components/primary-button";
import IntroComponent from "./src/components/intro-component";

ReactGA.initialize("G-QLXW4RRD92");
declare var global: any;
export default function App() {
  const [feedbackMessage, setfeedbackMessage] = useState('');
  const [feedbackSubject, setffeedbackSubject] = useState('');
  const [isBusyFeedback, setisBusyFeedback] = useState(false);
  const menu = [{
    label: "Terms and Conditions",
    icon: "file-alt"
  }, {
    label: "Privacy Policy",
    icon: "file-alt"
  }]
  const user_menu = [
    { label: "History", icon: "history", screen: "History" },
    {
      label: "Subscriptions",
      icon: "stack-exchange",
      screen: "Subscriptions"
    }, {
      label: "Feedback",
      icon: "inbox",
      screen: "Feedback"
    }, {
      label: "My Account",
      icon: "user-circle",
      screen: "MyAccount"
    }]
  const gotoMyScreen = (context: any, item: any) => {
    SheetManager.hide(`user_menu`);
    if (item.screen !== 'Feedback') {
      context.setScreen(item.screen, {})
    } else {
      setTimeout(() => {
        SheetManager.show(`feedback`);
        //this.context.showToast(`Feedback is not available on the mobile app`, 'success');
      }, 500);

    }
  }
  const sendMessage = async (context: any) => {
    if (feedbackSubject && feedbackMessage) {
      setisBusyFeedback(true);
      await context.sendFeedback(feedbackSubject, feedbackMessage);
      setisBusyFeedback(false);
      setfeedbackMessage('');
      setffeedbackSubject('');
      SheetManager.hide(`feedback`);

    } else {
      context.showToast("Please fill all the fields", 'error');
    }
  }
  console.disableYellowBox = true;
  //config.aws_appsync_authenticationType = "AWS_IAM"
  config.oauth.redirectSignOut = config.oauth.redirectSignIn = "http://localhost:19006/"
  if (Platform.OS === 'web') {
    //console.log("window.location.href", window.location.href)
    config.oauth.redirectSignOut = config.oauth.redirectSignIn = window.location.href.indexOf("youvi") !== -1 ? "https://www.youvi.io/" : "http://localhost:19006/"
  } else {
    config.oauth.redirectSignOut = config.oauth.redirectSignIn = "genie://"
    const backAction = () => {
      if (!navigationService.props.setHome) {
        //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
        BackHandler.removeEventListener('hardwareBackPress', backAction);
        return true;
      }
      return true;
    }
    BackHandler.addEventListener('hardwareBackPress', backAction);
    StatusBar.setBackgroundColor("#000000");
    StatusBar.setBarStyle('dark-content', true);
    StatusBar.setTranslucent(true);
    config.oauth.redirectSignOut = config.oauth.redirectSignIn = "genie://";
    //SplashScreen.hide();
  }
  Amplify.configure({
    ...config,
    Analytics: {
      disabled: true,
    },
  });

  return (

    <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
      <AppProvider>
        <ToastProvider>

          <AppNavigator FastClipsScreen={FastClipsWebScreen} PlayerView={WebVideoScreen}></AppNavigator>
          <AppContext.Consumer>
            {context => context.state.isIntroVisible ?
              <IntroComponent></IntroComponent> : null}
          </AppContext.Consumer>
          <AppContext.Consumer>
            {context => <ActionSheet containerStyle={{
              maxWidth: 400, borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderRadius: colors.borderRadius
            }} defaultOverlayOpacity={0.5} id={`feedback`}>
              <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20 }]}>
                <TextInput placeholder={i18next.t('Feedback subject')} multiline={true} numberOfLines={1} onChangeText={(text: string) => {

                  setffeedbackSubject(text);
                }} style={[style.fullWidth, style.textInput, {

                  marginBottom: 15,

                }]}></TextInput>
                <TextInput placeholder={i18next.t('Feedback message')} multiline={true} numberOfLines={3} onChangeText={(text: string) => {

                  setfeedbackMessage(text);
                }} style={[style.fullWidth, style.textInput, {
                  height: 100,
                  marginBottom: 15,

                }]}></TextInput>
                <PrimaryButton isBusy={isBusyFeedback} iconPlacement={'right'} align={'left'} onPress={() => {
                  sendMessage(context)
                }} icon={
                  <FontAwesome style={{
                    position: 'absolute',
                    right: 15
                  }} size={20} color={colors.textColor} name='send'></FontAwesome>} label={i18next.t('Send')} style={{
                    width: '100%',

                  }} />
              </View>
            </ActionSheet>}</AppContext.Consumer>
          <AppContext.Consumer>
            {context => <ActionSheet id="menu_sheet">
              <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 10 }]}>
                {menu.map((item: any, index: any) => {
                  return <TouchableOpacity key={index} onPress={() => {
                    switch (item.label) {
                      case "Terms and Conditions":
                        SheetManager.hide("menu_sheet");
                        context.setScreen('Terms', {});
                        break;
                      case "Privacy Policy":
                        SheetManager.hide("menu_sheet");
                        context.setScreen('Privacy', {});
                        break;

                    }
                  }} style={[style.fullWidth]}>
                    <View style={[style.row, style.fullWidth, { marginTop: 10, padding: 10 }]}>
                      <FontAwesome5 name={item.icon} size={24} style={{ marginTop: 0 }} color="black" />
                      <Text style={{
                        marginLeft: 20,
                        flex: 1,
                        fontSize: 18,
                        fontWeight: 'bold'
                      }}>{i18next.t(item.label)}</Text>
                    </View>
                  </TouchableOpacity>
                })}
              </View>
            </ActionSheet>}</AppContext.Consumer>
          <AppContext.Consumer>
            {context => <ActionSheet containerStyle={{
              maxWidth: 500, borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderRadius: colors.borderRadius
            }} defaultOverlayOpacity={0.5} id={`user_menu`}>
              <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20 }]}>
                {user_menu.map((item: any, index: any) => {
                  return <TouchableOpacity key={index} onPress={() => {

                    gotoMyScreen(context, item)
                  }} style={[style.fullWidth]}>
                    <View style={[style.row, style.fullWidth, { marginTop: 10, padding: 10 }]}>
                      <FontAwesome5 name={item.icon} size={24} style={{ marginTop: 0 }} color="black" />
                      <Text style={{
                        marginLeft: 20,
                        flex: 1,
                        fontSize: 18,
                        fontWeight: 'bold'
                      }}>{i18next.t(item.label)}</Text>
                    </View>
                  </TouchableOpacity>

                })}

                <PrimaryButton align={'right'} isBusy={isBusyFeedback} onPress={() => {
                  context.signOut();
                }} iconPlacement={'left'} icon={
                  <FontAwesome style={{}} size={20} color={colors.textColor} name='sign-out'></FontAwesome>} label={i18next.t('Sign Out')} style={{
                    width: '100%',
                    marginTop: 10, marginBottom: 10
                  }} />
              </View>
            </ActionSheet>}</AppContext.Consumer>
          <Toast ref={(ref: any) => global['toast'] = ref} />
        </ToastProvider>
      </AppProvider>
    </SafeAreaView>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
