import * as React from 'react';
import { TouchableOpacity, StyleSheet, View, Text, ViewStyle } from 'react-native';
import colors from '../constants/colors';

interface Props {
  time: number
  style?: ViewStyle
  allText: { text: string, start: number, duration: number }[]
}

interface State {
  lastIndex: number
  sub: string
  allText: { text: string, start: number, duration: number }[]
}

class Subtitles extends React.Component<Props, State> {
  //allText: { text: string, start: number, duration: number }[] = [];
  constructor(props: Props) {
    super(props);
    this.state = { lastIndex: -1, sub: "", allText: [] };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps: any) {
    //  console.log("playing", this.props.time)
    if (prevProps.allText !== this.props.allText && this.props.allText?.length) {
      const text: any = [...this.props.allText]
      const allText = [];
      /* for (let i = 0; i <= text.length - 1; i += 3) {

          const first = text?.[i] || ({} as any)
          const second = text?.[i + 1] || ({} as any)
          const third = text?.[i + 2] || ({} as any)
          //console.log("i", i, `${first.text} ${second.text || ''} ${third.text || ''}`)
          allText.push({ start: first.start, duration: second.start + second.duration, text: (`${first.text} ${second.text || ''} ${third.text || ''}`).replace(/\s+/g, ' ').trim() })
      } */
      for (let i = 0; i <= text.length - 1; i += 2) {

        const first = text?.[i] || ({} as any)
        const second = text?.[i + 1] || ({} as any)

        //console.log("i", i, `${first.text} ${second.text || ''} ${third.text || ''}`)
        allText.push({
          start: first.start,
          duration: second.start + second.duration,
          text: (`${first.text} ${second.text || ''}`).replace(/\s+/g, ' ').trim()
        })
      }

      //this.allText = allText
      this.setState({ allText })

    } else if (prevProps.allText !== this.props.allText && !this.props.allText?.length) {
      this.setState({ allText: [] })
    }
  }

  getTimeIndex(time: number) {
    return this.state.allText?.findIndex((txt, i) => this.state.allText[i + 1]?.start >= time) || 0;
  }

  getSub = (time: number) => {
    //console.log("time", time)
    const index = this.getTimeIndex(time);
    let str = `${this.state.allText?.[index]?.text || ''}`;
    //console.log("index", index, str);
    return str;
  }

  render() {

    return <View style={this.props.style}>
      <Text style={{
        color: colors.textColor,
        fontSize: 14,
        width: "100%",
        padding: 5,
        textAlign: "center"
      }}>{this.getSub(this.props.time)}</Text>
    </View>
  }
}

export default Subtitles;
const styles = StyleSheet.create({})
