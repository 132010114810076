import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';

import { FontAwesome5 } from "@expo/vector-icons";
import PrimaryButton from "./primary-button";

interface State {

}

interface Props {
  message: string
  onAccept?: any
  onCancel?: any
  icon?: any
  cancelIcon?: any
  acceptLabel: string | 'Ok'
  cancelLabel?: string
  style?: ViewStyle
}

class Alert extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (
      <View style={[this.props.style, { padding: 10 }]}>
        <Text style={{ width: '100%', fontSize: 20 }}>{this.props.message}</Text>
        {this.props.cancelLabel ?
          <PrimaryButton isSecondary={true} align={'left'} icon={this.props.cancelIcon} onPress={() => {
            this.props.onCancel()
          }} label={this.props.cancelLabel} style={{
            width: '100%',
            marginTop: 30
          }}/> : null}
        <PrimaryButton align={'left'} iconPlacement={'left'} icon={this.props.icon} onPress={() => {
          this.props.onAccept()
        }} label={this.props.acceptLabel} style={{
          width: '100%',
          marginTop: 10
        }}/>
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default Alert;
