import { Entypo, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  View,
  Modal,
  FlatList,
  TextInput,
  ActivityIndicator,
  Platform
} from 'react-native';

import Subtitles from '../components/subtitles';
import colors from '../constants/colors';
import style from '../constants/style';
import env from '../constants/env';
import YouTube from 'react-youtube';
import Share from '../components/share';
import { Post } from '../models';
import { withTranslation } from 'react-i18next';
import ButtonBar from "../components/button-bar";
import { AppContext, IContext } from "../redux/store/app-provider";
import RelatedClipsScreen from "./related-clips";
import ChannelListScreen from "./channel-video-list";
import ChannelClipsScreen from "./channel-clips";
import NavigationService from "../services/navigation-service";

interface Props {
  t: any
}

interface State {

}

class WebVideoScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  tm: any = null;
  player: any;

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

    setTimeout(async () => {
      if (!this.context.state.currentVideoInfo) {
        await this.context.getVideoInfo(this.context.state.routeParams?.videoId, true)
        this.context.setAppProps({ relatedVideoId: this.context.state.routeParams?.videoId })
        this.context.getSubtitles(this.context.state.routeParams?.videoId);
      }
      //this.context.getSubtitles(this.context.state.routeParams?.videoId);
      this.onPlay()
    }, 10)

  }

  onRelatedPress = (videoInfo: any) => {
    this.context.setAppProps({ currentVideoInfo: videoInfo, relatedModalVisible: false, channelModalVisible: false })
    this.context.getSubtitles(videoInfo.videoId);
    this.onPlay()
  }
  onPlay = () => {
    if (this.context.state.routeParams?.shareCode) {
      this.context.registerSeenVideoIp(this.context.state.routeParams?.shareCode);
    }
    let playCount = parseInt(localStorage.getItem("webPlayCount") || "0") || 0;
    playCount++;
    localStorage.setItem("webPlayCount", playCount.toString());
    if (playCount >= parseFloat(this.context.state.appConfig?.webMax) && this.context.state.os !== "kaka") {
      this.context.setAppProps({ showDownloadAppModal: true })
      return
    }
  }

  componentWillUnmount() {

    if (this.tm) clearInterval(this.tm)
  }

  onPlayerError(error: any) {
    console.log("player error", error)
  }

  setParam(key: string, value: string) {
    var url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, '', url.href);
  }

  async subscribeToChannel() {
    this.context.setAppProps({
      channelModalVisible: false
    });
    this.context.subscribeToChannel(this.context.state.currentChannelId);
  }

  onPlayerState(event?: any) {
    if (this.tm) clearInterval(this.tm)
    if (event.data === 1) {
      this.tm = setInterval(async () => {
        const currentTime = await event.target.getCurrentTime();
        this.context.setAppProps({ currentPlayingTime: currentTime });
      }, 1000)
    }
  }

  onAction(action: string) {
    switch (action) {
      case "language":
        this.context.setAppProps({ languageModalVisible: true, filterLang: '' })
        break;
      case "share":
        this.context.setAppProps({ shareModalVisible: true })
        break;
      case "related":
        this.context.setAppProps({
          relatedModalVisible: true
        });
        this.context.logEvent("related_videos");
        break;
      case "channel":
        this.context.setAppProps({
          channelModalVisible: true
        });
        this.context.logEvent("channel_videos");
        break;
    }
  }

  downloadApp(os: string) {

    if (os === 'ios') {
      window.open(env.iOSApp)

    } else {
      window.open(env.androidApp)
    }
  }

  render() {
    const busyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator}/>
      }
    }

    const renderLangItem = ({ item }: any) => {
      return (
        <TouchableOpacity style={{ width: '100%' }} onPress={() => {
          this.context.setAppProps({ languageModalVisible: false, selectedLanguage: item.symbol });
          setTimeout(() => {
            this.context.getSubtitles(this.context.state.currentVideoInfo.videoId)
          }, 100)
        }}>
          <View style={[style.row, style.vcenter, style.fullWidth, { width: '100%', marginBottom: 20, height: 30 }]}>
            {/* <Image style={{ width: 40, height: '100%' }} source={{ uri: `https://countryflagsapi.com/png/${item.symbol}` }} /> */}
            <Text style={{ fontSize: 20 }}>{colors.getFlags(item.symbol)}</Text>
            <Text style={{ color: colors.textColor, flex: 1, marginLeft: 20 }}>{item.lang}</Text>

          </View>
        </TouchableOpacity>

      )
    }

    return (

      <View style={[style.container, { alignItems: 'center', justifyContent: 'flex-start', padding: 10 }]}>

        <View style={{
          width: "100%",
          alignItems: 'center',
          justifyContent: 'center',
          flex: this.context.state.isPortrait ? 1 : 1,
          height: this.context.state.isPortrait ? 'auto' : 'auto',
          padding: 0
        }}>
          {this.context.state.currentVideoInfo ? <YouTube containerClassName="youtube-player" opts={{
            width: '100%',
            height: '100%'
          }} ref={p => this.player = p} videoId={this.context.state.currentVideoInfo.videoId} onStateChange={this.onPlayerState.bind(this)}
                                                          onError={e => this.onPlayerError(e)}

          /> : null}
        </View>
        {/*<View style={{}}></View>*/}
        <Subtitles time={this.context.state.currentPlayingTime || 0} allText={this.context.state.subtitles || []}></Subtitles>
        {this.context.state.isPortrait ? <View style={{ flex: 1 }}></View> : null}
        {
          this.context.state.isPortrait ?
            <ButtonBar language={this.context.state.selectedLanguage} onAction={this.onAction.bind(this)} style={{
              width: '100%',
              marginTop: 10,
              maxWidth: 500
            }}></ButtonBar> : null
        }

        <View style={[style.row, style.hcenter, { marginTop: 10 }]}>
          <TouchableOpacity style={{ display: this.context.state.isMobile && !this.context.state.isPortrait ? "none" : "flex" }} onPress={this.downloadApp.bind(this, 'ios')}>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              borderRadius: colors.borderRadius,
              padding: 5,
              alignItems: 'center'
            }}>
              {/*  <Text style={{ color: colors.textColor, flex: 1 }}>App Store</Text> */}
              <Image style={{
                height: 21,
                width: 21
              }} source={{ uri: 'https://raw.githubusercontent.com/crisand/youvistore/main/appstore.png' }}></Image>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={{
            marginLeft: 10,
            display: this.context.state.isMobile && !this.context.state.isPortrait ? "none" : "flex"
          }} onPress={this.downloadApp.bind(this, 'android')}>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              borderRadius: colors.borderRadius,
              padding: 5,
              alignItems: 'center'
            }}>
              {/*  <Text style={{ color: colors.textColor, flex: 1 }}>Google Play</Text> */}
              <Image style={{
                height: 21,
                width: 21
              }} source={{ uri: 'https://raw.githubusercontent.com/crisand/youvistore/main/googleplay.png' }}></Image>
            </View>
          </TouchableOpacity>
        </View>
        {
          this.context.state.languageModalVisible ? <Modal
            animationType="slide" style={{
            display: "flex",
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
            transparent={true}
            visible={this.context.state.languageModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}

          ><TouchableOpacity onPress={() => {
          }} style={style.modalOverlay}>
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>

              <View style={{
                width: "90%",
                height: "80%",
                padding: 20,
                backgroundColor: colors.backgroundColor,
                borderRadius: colors.borderRadius
              }}>

                <TouchableOpacity onPress={() => {
                  this.context.setAppProps({ languageModalVisible: false })
                }} style={{
                  width: '100%',
                  borderRadius: colors.borderRadius,
                  marginBottom: 0,
                  backgroundColor: colors.backgroundColor,
                  height: 40,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <FontAwesome5 name="arrow-down" size={13} color={colors.textColor}/>
                </TouchableOpacity>
                <View style={{ width: '100%', display: 'flex', height: 50, marginTop: 0, flexDirection: 'column' }}>
                  <TextInput
                    style={styles.input} onChangeText={(txt) => {
                    this.context.setAppProps({ filterLang: txt })
                  }}
                    placeholder="Search"
                    placeholderTextColor={colors.textColor}
                  />
                  <FontAwesome style={{
                    position: 'absolute',
                    right: 10,
                    top: 6
                  }} size={20} color={colors.textColor} name="search"></FontAwesome>
                </View>
                <FlatList horizontal={false}
                          data={env.languages.filter(lang => lang.lang.toLowerCase().indexOf(this.context.state.filterLang?.toLowerCase() || '') !== -1)} style={{
                  width: '100%',
                  flex: 1
                }}
                          renderItem={renderLangItem}
                          keyExtractor={(item) => item.lang}
                />
              </View></View>
          </TouchableOpacity>
          </Modal> : null
        }
        {
          this.context.state.shareModalVisible ? <Modal
            animationType="slide" style={{
            display: "flex",
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
            transparent={true}
            visible={this.context.state.shareModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}
          ><TouchableOpacity onPress={() => {
            this.context.setAppProps({ shareModalVisible: false })
          }} style={style.modalOverlay}>
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <View style={{
                width: 350,
                height: 380,
                padding: 20,
                backgroundColor: colors.backgroundColor,
                borderRadius: colors.borderRadius
              }}>
                <Share title={this.context.state.currentVideoInfo?.title} picture={"https://google.com"} lang={this.context.state.selectedLanguage} videoId={this.context.state.currentVideoInfo?.videoId} onShare={() => {
                  this.context.setAppProps({ shareModalVisible: false })
                }}></Share>
              </View>
            </View>
          </TouchableOpacity>
          </Modal> : null
        }
        {
          this.context.state.showDownloadAppModal ? <Modal
            animationType="slide" style={{
            display: "flex",
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
            transparent={true}
            visible={this.context.state.shareModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}
          >
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <View style={{
                width: '100%',
                height: '100%',
                padding: 20,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: colors.backgroundColor,
                borderRadius: colors.borderRadius
              }}>
                <Text style={{
                  color: colors.textColor,
                  fontSize: 16,
                  textAlign: 'center',
                  width: '100%',
                  marginBottom: 300
                }}>{this.props.t("Download the mobile app for a better experience")}</Text>
                <TouchableOpacity style={{ display: this.context.state.os === 'ios' ? "flex" : "none" }} onPress={this.downloadApp.bind(this, 'ios')}>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderRadius: colors.borderRadius,
                    borderColor: '#ffffff',
                    backgroundColor: colors.orange,
                    width: 300,
                    padding: 15,
                    borderWidth: 0,
                    alignItems: 'center'
                  }}>
                    <Text style={{
                      color: colors.textColor,
                      flex: 1,
                      fontSize: 20,
                      fontWeight: 'bold',
                      marginRight: 10
                    }}>App Store</Text>
                    <Image style={{
                      height: 21,
                      width: 21
                    }} source={{ uri: 'https://raw.githubusercontent.com/crisand/youvistore/main/appstore.png' }}></Image>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={{
                  marginLeft: 10,
                  display: this.context.state.os === 'android' ? "flex" : "none"
                }} onPress={this.downloadApp.bind(this, 'android')}>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderRadius: colors.borderRadius,
                    backgroundColor: colors.orange,
                    borderColor: '#ffffff',
                    padding: 15,
                    width: 300,
                    borderWidth: 0,
                    alignItems: 'center'
                  }}>
                    <Text style={{
                      color: colors.textColor, flex: 1, fontSize: 20,
                      fontWeight: 'bold', marginRight: 10
                    }}>Google Play</Text>
                    <Image style={{
                      height: 21,
                      width: 21
                    }} source={{ uri: 'https://raw.githubusercontent.com/crisand/youvistore/main/googleplay.png' }}></Image>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

          </Modal> : null
        }
        {
          this.context.state.relatedModalVisible ? <Modal
            animationType="slide" style={{
            display: "flex",
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
            transparent={true}
            visible={this.context.state.relatedModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}
          >
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <View style={{
                width: '100%',
                maxWidth: 500,
                height: '100%',
                borderWidth: 0,
                padding: 0,
                backgroundColor: colors.backgroundColor,
                borderColor: colors.primaryColor,
                borderRadius: colors.borderRadius
              }}>
                <TouchableOpacity onPress={() => {
                  this.context.setAppProps({ relatedModalVisible: false })
                }} style={{
                  width: '100%',
                  borderRadius: colors.borderRadius,
                  marginBottom: 0,
                  backgroundColor: colors.backgroundColor,
                  height: 40,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <FontAwesome5 name="arrow-down" size={13} color={colors.textColor}/>
                </TouchableOpacity>
                <RelatedClipsScreen onPress={this.onRelatedPress.bind(this)}></RelatedClipsScreen>
              </View>
            </View>

          </Modal> : null
        }
        {
          this.context.state.channelModalVisible ? <Modal
            animationType="slide" style={{
            display: "flex",
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
            transparent={true}
            visible={this.context.state.channelModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}
          >
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <View style={{
                width: '100%',
                maxWidth: 500,
                height: '100%',
                borderWidth: 0,
                padding: 0,
                backgroundColor: colors.backgroundColor,
                borderColor: colors.primaryColor,
                borderRadius: colors.borderRadius
              }}>
                <TouchableOpacity onPress={() => {
                  this.context.setAppProps({ channelModalVisible: false })
                }} style={{
                  width: '100%',
                  borderRadius: colors.borderRadius,
                  marginBottom: 0,
                  backgroundColor: colors.backgroundColor,
                  height: 40,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <FontAwesome5 name="arrow-down" size={13} color={colors.textColor}/>
                </TouchableOpacity>

                {this.context.state.channelList?.channel?.snippet.thumbnails && this.context.state.user ? <View style={{
                  width: '100%',
                  height: 50,
                  padding: 10,
                  marginTop: 0,
                  alignItems: 'center',
                  marginBottom: 10,
                  borderRadius: colors.borderRadius,
                  backgroundColor: colors.backgroundColor,
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                  <View style={[style.userButton, style.hright, {
                    alignItems: 'center',
                    marginLeft: 10,
                    marginRight: 10
                  }]}>
                    <Image style={{ resizeMode: 'cover', width: '100%', height: '100%' }} source={{
                      uri: this.context.state.channelList?.channel?.snippet.thumbnails.medium.url
                    }}></Image>
                  </View>
                  <Text style={{
                    flex: 1,
                    color: colors.textColor
                  }}>{this.context.state.channelList?.channel?.snippet.title}</Text>
                  <MaterialCommunityIcons.Button onPress={this.subscribeToChannel.bind(this)} backgroundColor={colors.backgroundColor} name="youtube-subscription" style={{
                    color: colors.textColor,
                    padding: 2,
                    fontSize: 11,
                    paddingLeft: 10,
                    paddingRight: 10
                  }} size={24} color={colors.textColor}>{this.props.t('Subscribe to channel')}</MaterialCommunityIcons.Button>
                </View> : null}
                <ChannelClipsScreen onPress={this.onRelatedPress.bind(this)}></ChannelClipsScreen>
              </View>
            </View>

          </Modal> : null
        }
        {busyIndicator()}
      </View>

    )
  };

}

export default withTranslation()(WebVideoScreen);

const styles = StyleSheet.create(
  {
    input: {
      width: '100%',
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: colors.textColor,
      color: colors.textColor
    },
    screenItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: 10,
      width: '100%',
      height: '100%',
      backgroundColor: colors.textColor
    }
  });
